import DataStruct from "@/shared/data/datastruct";
import Media, { ServerMediaDataType } from "@/shared/data/media";

/**
 * The Rubric Data-Class containing all properties for a Rubric.
 */
export default class Rubric extends DataStruct {
  readonly rubric_id: number;
  name = "";
  name_path = "";

  /**
   * An optional image of this {@link Rubric} or the first image of a parent {@link Rubric}.
   */
  image: Media | null = null;

  /**
   * Creates a new instance of Rubric with given data.
   *
   * @param data The data to load
   * @throws TypeError if any property does not fit the type
   */
  constructor(data: { rubric_id: number }) {
    super();

    if (DataStruct.validateValue(data, "rubric_id", "number")) {
      this.rubric_id = data.rubric_id;
    } else {
      // We cannot initialize without an id
      throw new TypeError("Could not initialize Rubric. Invalid rubric_id.");
    }
  }

  /**
   * Creates a new Rubric-object with the given data returned from the server.
   *
   * @param data
   */
  static fromServer(data: ServerRubricDataType): Rubric {
    const rubric = new Rubric({
      rubric_id: data.rubric_id,
    });

    // Load the data
    if (DataStruct.validateValue(data, "name", "string"))
      rubric.name = data.name;
    if (DataStruct.validateValue(data, "name_path", "string"))
      rubric.name_path = <string>data.name_path;

    if (DataStruct.validateValue(data, "image", "object")) {
      rubric.image = Media.fromServer(<ServerMediaDataType>data.image);
    }

    return rubric;
  }

  /**
   * Returns a Label of this rubric to use in an autocomplete.
   */
  get searchLabel(): string {
    return this.name_path.replaceAll("|", " / ");
  }
}

/**
 * The data structure defined by the MSUevent-Server.
 */
export type ServerRubricDataType = {
  rubric_id: number;
  name: string;
  name_path: string;
  image?: ServerMediaDataType;
};
