
import { Component, Prop, Vue } from "vue-property-decorator";
import RecordingEvent from "@/shared/data/recording_event";
import { FormDefinition } from "@/shared/data/form";
import FrontendSettings from "@/frontend/settings/settings";
import QuokkaAdditionField from "@/components/quokka_layout/ui/additional-fields/QuokkaAdditionField.vue";
import AdditionFieldsForm from "@/components/quokka_layout/ui/additional-fields/AdditionFieldsForm.vue";

@Component({
  components: {
    AdditionFieldsForm,
    QuokkaAdditionField,
  },
})
export default class QuokkaEventRecordingFreeFieldsTab extends Vue {
  /** The {@link RecordingEvent} used as model */
  @Prop({ required: true }) recordingEvent!: RecordingEvent;

  /** Whether the fields of this tab should be disabled or not */
  @Prop() disabled!: boolean;

  freeFieldDefinition: FormDefinition | null = [];

  mounted(): void {
    if (FrontendSettings.eventRecording.additionalFieldsFormDefinition) {
      this.freeFieldDefinition =
        FrontendSettings.eventRecording.additionalFieldsFormDefinition;
    }
  }
}
