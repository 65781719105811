
import { Component, Prop, Vue } from "vue-property-decorator";
import { RawLocation } from "vue-router";

export type AlertCardType = "success" | "info";

/**
 * A card to show an alert like a success message, information or error.
 */
@Component
export default class AlertCard extends Vue {
  /**
   * The type of this {@link AlertCard}.
   */
  @Prop({ required: true }) type!: AlertCardType;

  /**
   * The text to display.
   */
  @Prop({ required: true }) text!: string;

  /**
   * The size of the {@link text}.
   */
  @Prop() textSize!: "h4" | "h5";

  /**
   * The class for the text according to {@link textSize}.
   *
   * Defaults to h4.
   */
  get textSizeClass(): string {
    switch (this.textSize) {
      case "h5":
        return "text-h5";
      case "h4":
      default:
        return "text-h4";
    }
  }

  /**
   * The action button to display.
   */
  @Prop({ required: true }) action!: {
    label: string;
    button: { to: RawLocation } | { callback: () => void };
  };

  /**
   * The mdi-icon to display.
   * Set null to display no icon.
   *
   * If not given, a default icon is used per {@link AlertCardType}.
   */
  @Prop() icon!: string | null;

  /**
   * Returns the mdi-icon to display.
   */
  get displayIcon(): string | null {
    if (this.icon || this.icon === null) return this.icon;
    switch (this.type) {
      case "success":
        return "mdi-check-circle";
      case "info":
        return "mdi-information";
      default:
        return null;
    }
  }

  /**
   * The elevation of the card.
   */
  @Prop({ default: 2 }) elevation!: number;
}
