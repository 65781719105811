/**
 * This file contains global functions to sanitize and escape html-content for output.
 */

import DOMPurify from "dompurify";

/**
 * Sanitizes and escapes the given content to output in a v-html.
 *
 * This will prevent XSS attacks and more.
 *
 * @param content
 */
export function sanitizeContent(content: string): string {
  return DOMPurify.sanitize(content);
}
