/**
 *Object to be used by the signup form
 */
import Address from "@/shared/data/address";

export default class SignupData {
  username: string | null = null;

  salutation: Salutation = "";
  firstName: string | null = null;
  lastName: string | null = null;
  password1: string | null = null;
  password2: string | null = null;
  email: string | null = null;

  organization: Address | null = null;
  freeOrganization: string | null = null;
  street: string | null = null;
  plz: string | null = null;
  community: string | null = null;
  phone: string | null = null;
  private_street: string | null = null;
  private_plz: string | null = null;
  private_community: string | null = null;
  private_phone: string | null = null;

  acceptAGB = false;
  acceptDSGVO = false;
  acceptAds = false;

  url: string | null = null;
  role: string | null = null;
}

export type Salutation = "" | "1" | "2" | "3";
