
import { Component, Emit, Vue, Watch } from "vue-property-decorator";
import { formatDate, formatTime, parseDate } from "@/shared/lib/datetime";
import { Order } from "@/shared/data/advert";
import { formatCurrency } from "@/shared/lib/currency";
import FrontendSettings from "@/frontend/settings/settings";
import {
  RecordingEventAppointmentsFree,
  RecordingEventAppointmentsSeries,
  RecordingEventAppointmentType,
} from "@/shared/data/event_appointments";
import LabeledSheet from "@/components/quokka_layout/ui/LabeledSheet.vue";
import { DateTime } from "luxon";
import TimeInfo from "@/shared/data/time_info";
import RouteHelper from "@/frontend/lib/route_helper";
import {
  getRecordingEvent,
  loadImageFromEvent,
  MediaInfoReturnType,
} from "@/frontend/lib/api";
import RecordingEvent from "@/shared/data/recording_event";

@Component({
  components: { LabeledSheet },
  data() {
    return { FrontendSettings, formatCurrency };
  },
})
export default class QuokkaAdsDetailsView extends Vue {
  loading = false;

  private event: RecordingEvent | null = null;
  private availableMedia: string[] = [];
  private tmpMediaIds: number[] = [];

  /** Panel index */
  panel = [];

  /**
   * Returns the order, if set in route.
   */
  get order(): Order | null {
    // RecordingEvent
    if (RouteHelper.hasParam(this.$route, "order")) {
      return RouteHelper.getParam(this.$route, "order");
    }
    return null;
  }

  /**
   * Return the label for given event field.
   *
   * @param field
   */
  getEventFieldLabel(field: string): string {
    let callLabelGetter = "this." + field;

    if (field === "title") return "Titel";
    if (field === "longdesc") callLabelGetter += "1";
    if (field === "shortdesc") callLabelGetter += "1";
    callLabelGetter += "Label";

    return eval(callLabelGetter);
  }

  // <editor-fold desc="descFieldLabels">

  /**
   * Returns the label for the `shortdesc1` field.
   */
  get shortdesc1Label(): string {
    return (
      FrontendSettings.fieldSettings.recording.shortdesc1.label ?? "Kurztext"
    );
  }

  /**
   * Returns the label for the `shortdesc2` field.
   */
  get shortdesc2Label(): string {
    return (
      FrontendSettings.fieldSettings.recording.shortdesc2.label ?? "Kurztext 2"
    );
  }

  /**
   * Returns the label for the `shortdesc3` field.
   */
  get shortdesc3Label(): string {
    return (
      FrontendSettings.fieldSettings.recording.shortdesc3.label ?? "Kurztext 3"
    );
  }

  /**
   * Returns the label for the `longdesc1` fields or button.
   */
  get longdesc1Label(): string {
    return (
      FrontendSettings.fieldSettings.recording.longdesc1.label ?? "Langtext"
    );
  }

  /**
   * Returns the label for the `longdesc2` fields or button.
   */
  get longdesc2Label(): string {
    return (
      FrontendSettings.fieldSettings.recording.longdesc2.label ?? "Langtext 2"
    );
  }

  /**
   * Returns the label for the `longdesc3` fields or button.
   */
  get longdesc3Label(): string {
    return (
      FrontendSettings.fieldSettings.recording.longdesc3.label ?? "Langtext 3"
    );
  }

  // <editor-fold>

  /**
   * Sets the order as successfully booked
   *
   * @param data
   */
  @Emit("set-is-order-completed")
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setIsOrderCompleted(data: boolean): void {
    return;
  }

  /**
   * Load the advert media
   */
  @Watch("order", { immediate: true })
  onSelectedEventChange(): void {
    this.tmpMediaIds = [];
    this.availableMedia = [];

    if (this.order?.order_item) {
      this.order?.order_item.forEach((orderItem) => {
        if (orderItem.order_item_content?.advert_order_item_content_id) {
          if (
            !this.tmpMediaIds.includes(
              orderItem.order_item_content?.advert_order_item_content_id
            )
          ) {
            this.tmpMediaIds.push(
              orderItem.order_item_content?.advert_order_item_content_id
            );
            loadImageFromEvent({
              load: orderItem.order_item_content?.advert_order_item_content_id,
              isTableId: "Y",
            })
              .then((response: MediaInfoReturnType) => {
                this.availableMedia.push(response["base64"]);
              })
              .catch((e) => {
                console.error(e);
              });
          }
        }
      });
    }
  }

  /**
   * Prepares the data at the first load of component.
   */
  mounted(): void {
    this.loading = true;

    if (
      this.order?.order_item[0].order_item_content?.event_id !== null &&
      this.order?.order_item[0].order_item_content?.event_id !== undefined &&
      this.order?.order_item[0].order_item_content?.event_id > 0
    ) {
      if (this.order.order_item[0].order_item_content?.event_id) {
        getRecordingEvent(
          this.order.order_item[0].order_item_content?.event_id
        ).then((recordingEvent) => {
          this.event = recordingEvent;

          if (this.event?.event_id) {
            if (this.appointmentsFree)
              this.appointments.free = this.appointmentsFree;
            if (this.appointmentsSeries)
              this.appointments.series = this.appointmentsSeries;
            if (!this.event.appointments)
              this.event.appointments = this.appointments.free;
          }

          this.loading = false;
        });
      } else {
        this.loading = false;
      }
    }
  }

  /**
   * Formats a json-date from event in german date format.
   *
   * @param date
   * @param toFormat
   * @param fromFormat
   */
  formatBookingDate(
    date: string,
    toFormat?: string,
    fromFormat?: string
  ): string | undefined {
    if (fromFormat === undefined) fromFormat = "yyyy-MM-dd";
    if (toFormat === undefined) toFormat = "dd.MM.yyyy";

    let parsedDate = parseDate(date, fromFormat);
    let finishFormattedDate = formatDate(parsedDate, toFormat);
    if (finishFormattedDate !== undefined) {
      return finishFormattedDate;
    }

    return "";
  }

  /**
   * Formats a edv-date in german date format.
   *
   * @param date
   * @param toFormat
   */
  formatEventDateData(date: DateTime, toFormat?: string): string {
    if (toFormat === undefined) toFormat = "dd.MM.yyyy";

    return date.toFormat(toFormat);
  }

  getDayNumber(englishDay: string): number | null {
    switch (englishDay) {
      case "monday":
        return 1;
      case "tuesday":
        return 2;
      case "wednesday":
        return 3;
      case "thursday":
        return 4;
      case "friday":
        return 5;
      case "saturday":
        return 6;
      case "sunday":
        return 7;
    }
    return null;
  }

  /**
   * Returns the label of the weekday for given dayOfWeek.
   *
   * @param dayOfWeek
   */
  getWeekdayLabel(dayOfWeek: number | null): string {
    if (!dayOfWeek) return "";
    return formatDate(parseDate(dayOfWeek.toString(), "c"), "cccc") || "";
  }

  /**
   * Returns the label for the given {@link TimeInfo}.
   * The label includes the time, when the event takes place.
   *
   * @param timeInfo
   */
  getTimeInfoLabel(timeInfo: TimeInfo): string {
    // Full day, if given so or neither from nor until time are available
    if (timeInfo.full || (!timeInfo.fromTime && !timeInfo.untilTime)) {
      return "ganztags";
    }

    // Start time
    let title = formatTime(timeInfo.fromTime, "HH:mm") ?? "";

    let endTimeFormatted = undefined;
    if (
      timeInfo.untilTime &&
      (endTimeFormatted = formatTime(timeInfo.untilTime, "HH:mm"))
    ) {
      // Add "to" label
      title += title > "" ? " bis " : "Bis ";

      // Add to time
      title += endTimeFormatted;
    } else {
      // We only got a start date => Add a "from" label
      title = "Ab " + title;
    }

    // Add suffix
    title += " Uhr";

    return title;
  }

  // <editor-fold desc="getEventAppointments">

  /**
   * The currently used {@link RecordingEventAppointments}.
   */
  appointments: {
    free: RecordingEventAppointmentsFree;
    series: RecordingEventAppointmentsSeries;
  } = {
    free: new RecordingEventAppointmentsFree(),
    series: new RecordingEventAppointmentsSeries(),
  };

  /**
   * Returns the key of the active type.
   */
  get activeType(): RecordingEventAppointmentType {
    return this.appointmentsFree ? "free" : "series";
  }

  /**
   * Whether the active appointments type is `series` or not.
   */
  get isSeries(): boolean {
    return this.activeType === "series";
  }

  /**
   * This setter is just to prevent errors with the v-model.
   * The actual value is set through the @change event.
   *
   * @param value
   */
  set isSeries(value: boolean) {
    return;
  }

  /**
   * Returns the {@link RecordingEventAppointmentsFree} if it is active.
   * Otherwise, undefined.
   */
  get appointmentsFree(): RecordingEventAppointmentsFree | undefined {
    return this.event?.appointments?.type === "free"
      ? (this.event.appointments as RecordingEventAppointmentsFree)
      : undefined;
  }

  /**
   * Returns the {@link RecordingEventAppointmentsSeries} if it is active.
   * Otherwise, undefined.
   */
  get appointmentsSeries(): RecordingEventAppointmentsSeries | undefined {
    return this.event?.appointments?.type === "series"
      ? (this.event.appointments as RecordingEventAppointmentsSeries)
      : undefined;
  }

  /**
   * Switch the type of the active appointments.
   *
   * Available types are "free" or "series"
   */
  switchAppointmentType(): void {
    if (this.event?.event_id) {
      if (this.appointmentsFree) {
        this.event.appointments = this.appointments.series;
      } else if (this.appointmentsSeries) {
        this.event.appointments = this.appointments.free;
      }
    }
  }
  // </editor-fold>
}
