import DataStruct from "@/shared/data/datastruct";
import { DateTime } from "luxon";
import { formatTime, parseTime, TimeFormats } from "@/shared/lib/datetime";

/**
 * The TimeInfo Data-Class containing all properties for a TimeInfo.
 */
export default class TimeInfo extends DataStruct {
  private from: DateTime | null = null;
  private until: DateTime | null = null;
  full = false;
  endsNextDay = false;
  private _vacation: VacationTimeInfo = VacationTimeInfo.NONE;
  private _holiday: HolidayTimeInfo = HolidayTimeInfo.NONE;
  text = "";

  /**
   * Creates a new Community-object with the given data returned from the server.
   *
   * @param data
   */
  static fromServer(data: ServerTimeInfoDataType): TimeInfo {
    const timeInfo = new TimeInfo();

    // Load the data
    if (DataStruct.validateValue(data, "from", "string"))
      timeInfo.setTime("from", <string>data.from, "HH:mm");
    if (DataStruct.validateValue(data, "until", "string"))
      timeInfo.setTime("until", <string>data.until, "HH:mm");
    if (DataStruct.validateValue(data, "full", "boolean"))
      timeInfo.full = <boolean>data.full;
    if (DataStruct.validateValue(data, "endsNextDay", "boolean"))
      timeInfo.endsNextDay = <boolean>data.endsNextDay;
    if (DataStruct.validateValue(data, "text", "string"))
      timeInfo.text = <string>data.text;
    if (DataStruct.validateValue(data, "holiday", "number"))
      timeInfo.holiday = <number>data.holiday;
    if (DataStruct.validateValue(data, "vacation", "number"))
      timeInfo.vacation = <number>data.vacation;
    if (DataStruct.validateValue(data, "text", "string"))
      timeInfo.text = <string>data.text;

    return timeInfo;
  }

  get holiday(): HolidayTimeInfo {
    return this._holiday;
  }

  set holiday(value: HolidayTimeInfo) {
    if (value) this._holiday = value;
    else this._holiday = HolidayTimeInfo.NONE;
  }

  get vacation(): VacationTimeInfo {
    return this._vacation;
  }

  set vacation(value: VacationTimeInfo) {
    if (value) this._vacation = value;
    else this._vacation = VacationTimeInfo.NONE;
  }

  get fromTime(): DateTime | null {
    return this.from;
  }

  set fromTime(value: DateTime | null) {
    this.from = value;
  }

  get untilTime(): DateTime | null {
    return this.until;
  }

  set untilTime(value: DateTime | null) {
    this.until = value;
  }

  /**
   * Returns set date of field in the given format.
   *
   * @param field the time-field - from or until
   * @param format the format - defaults to "HH:mm"
   * @returns {string?} the formatted time or undefined, if no time is set
   *
   * @see https://moment.github.io/luxon/#/formatting?id=table-of-tokens for formatting options.
   */
  formatTime(
    field: "from" | "until",
    format: string | TimeFormats = "HH:mm"
  ): string | undefined {
    return formatTime(this[field], format);
  }

  /**
   * Sets the given time for the given field.
   *
   * @param field the time-field - from or until
   * @param time the time to set for field. If undefined, null or empty, the time will be unset
   * @param format the format of given time - defaults to "HH:mm"
   * @throws DateTimeParseError if the time could not be parsed with given format.
   *
   * @see https://moment.github.io/luxon/#/formatting?id=table-of-tokens for formatting options.
   */
  setTime(
    field: "from" | "until",
    time: string | undefined | null,
    format: string | TimeFormats = "HH:mm"
  ): void {
    this[field] = parseTime(time, format) ?? null;
  }
}

/**
 * The data structure defined by the MSUevent-Server.
 */
export type ServerTimeInfoDataType = {
  from?: string;
  until?: string;
  full?: boolean;
  endsNextDay?: boolean;
  holiday?: number;
  vacation?: number;
  text?: string;
};

/**
 * Holiday = Feiertage
 */
export enum HolidayTimeInfo {
  NONE = 0,
  ONLY_HOLIDAY = 1,
  NOT_HOLIDAY = 2,
}

/**
 * Vacation = Ferien
 */
export enum VacationTimeInfo {
  NONE = 0,
  ONLY_VACATION = 1,
  NOT_HOLIDAY = 2,
}
