
import { Component, Prop, Vue } from "vue-property-decorator";
import { Order } from "@/shared/data/advert";
import { DateFormats, formatDate, parseDate } from "@/shared/lib/datetime";
import { DateTime } from "luxon";
import { formatCurrency } from "@/shared/lib/currency";

/**
 * An item to display information about a {@link AdvertOrder} in a {@link QuokkaAdListAdminMyAdList}.
 */
@Component({
  data() {
    return { formatCurrency };
  },
})
export default class QuokkaAdListAdminMyAdListItem extends Vue {
  /**
   * The {@link AdvertOrder} to display.
   *
   * null for loading animation.
   */
  @Prop({ required: true }) advertOrder!: Order | null;

  /**
   * Tries to format the given date to the given format.
   * If date is null or format fails, an empty string is returned.
   *
   * @param date
   * @param format
   */
  formatDate(date: DateTime | null, format: string | DateFormats): string {
    return date ? formatDate(date, format) ?? "" : "";
  }

  /**
   *
   * @param date
   * @param format
   */
  formatDateStr(
    date: string | null,
    format: string | DateFormats
  ): string | undefined {
    let parsedDate = parseDate(date, "yyyy-MM-dd");
    return formatDate(parsedDate, format);
  }

  /**
   * Tries to get the named field of AdvertOrderItemContentData[]
   * @param content
   * @param name
   */
  getContentField(name: string): string {
    if (this.advertOrder == null) return "";
    for (const orderItem of this.advertOrder.order_item) {
      if (
        orderItem.order_item_content &&
        orderItem.order_item_content.advert_content
      ) {
        for (const content of orderItem.order_item_content.advert_content) {
          if (content.name == name) {
            return content.value;
          }
        }
      }
    }
    return "";
  }
}
