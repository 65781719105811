
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import {
  AdvertCampaign,
  AdvertChannel,
  AdvertSlot,
} from "@/shared/data/advert";
import { sanitizeContent } from "@/shared/lib/dompurify";

@Component({
  data() {
    return { sanitizeContent };
  },
})
export default class QuokkaAdsRecordingSlotSelectionView extends Vue {
  @Prop({ required: true }) selectedChannel!: AdvertChannel;
  @Prop({ required: true }) campaigns!: AdvertCampaign[];

  @Emit("slot-select")
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  selectSlot(slot: AdvertSlot): void {
    return;
  }
}
