import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import QuokkaEventRecordingView from "@/views/layouts/quokka/admin/QuokkaEventRecordingView.vue";
import AdminView from "@/views/layouts/AdminView.vue";
import QuokkaLayoutView from "@/views/layouts/quokka/QuokkaLayoutView.vue";
import QuokkaNotFoundView from "@/views/layouts/quokka/QuokkaNotFoundView.vue";
import QuokkaAdminView from "@/views/layouts/quokka/admin/QuokkaAdminView.vue";
import QuokkaEventsAdminView from "@/views/layouts/quokka/admin/QuokkaEventsAdminView.vue";
import QuokkaLoginView from "@/views/layouts/quokka/auth/QuokkaLoginView.vue";
import QuokkaSignUpView from "@/views/layouts/quokka/auth/QuokkaSignUpView.vue";
import FrontendSettings from "@/frontend/settings/settings";
import QuokkaEventsListAdminView from "@/views/layouts/quokka/admin/QuokkaEventsListAdminView.vue";
import QuokkaPasswordResetView from "@/views/layouts/quokka/auth/QuokkaPasswordResetView.vue";
import QuokkaRequestPasswordResetView from "@/views/layouts/quokka/auth/QuokkaRequestPasswordResetView.vue";
import QuokkaConfirmEMailView from "@/views/layouts/quokka/auth/QuokkaConfirmEMailView.vue";
import QuokkaRequestConfirmEMailView from "@/views/layouts/quokka/auth/QuokkaRequestConfirmEMailView.vue";
import QuokkaPersonDataChangeView from "@/views/layouts/quokka/auth/QuokkaPersonDataChangeView.vue";
import QuokkaAdsRecordingView from "@/views/layouts/quokka/admin/ads/QuokkaAdsRecordingView.vue";
import QuokkaMagicLinkView from "@/views/layouts/quokka/auth/QuokkaMagicLinkView.vue";
import QuokkaAdsListAdminView from "@/views/layouts/quokka/admin/ads/QuokkaAdsListAdminView.vue";
import QuokkaAdsAdminView from "@/views/layouts/quokka/admin/ads/QuokkaAdsAdminView.vue";
import QuokkaAdminMainView from "@/views/layouts/quokka/admin/QuokkaAdminMainView.vue";
import QuokkaAdsDetailsView from "@/views/layouts/quokka/admin/ads/QuokkaAdsDetailsView.vue";
import QuokkaArticlesAdminView from "@/views/layouts/quokka/admin/QuokkaArticlesAdminView.vue";
import QuokkaArticlesListAdminView from "@/views/layouts/quokka/admin/QuokkaArticlesListAdminView.vue";
import QuokkaArticlesRecordingView from "@/views/layouts/quokka/admin/QuokkaArticlesRecordingView.vue";

Vue.use(VueRouter);

export default function getRouter(): VueRouter {
  // If other Layouts are added, this constant can be used to decide, which Component to build.
  // const layout: Layout = Object.values(Layout).includes(FrontendSettings.layout) ? FrontendSettings.layout : Layout.Quokka;

  // Children routes for the "admin" route
  const adminChildrenRoutes: RouteConfig[] = [
    // My profile
    {
      path: "my-profile",
      name: "my-profile",
      component: QuokkaPersonDataChangeView,
      meta: {
        page_title: FrontendSettings.authenticatedArea.titleMyProfile,
      },
    },
  ];

  // Events Recording
  if (FrontendSettings.applicationSettings.activateEventsRecording) {
    adminChildrenRoutes.push({
      path: "events",
      name: "events-admin",
      component: QuokkaEventsAdminView,
      meta: {
        page_title: FrontendSettings.eventRecording.titleMyEvents,
      },
      redirect: {
        name: "my-events",
      },
      children: [
        {
          path: "my-events",
          name: "my-events",
          component: QuokkaEventsListAdminView,
          meta: {
            page_title: FrontendSettings.eventRecording.titleMyEvents,
          },
        },
        {
          path: "recording",
          name: "events-recording",
          component: QuokkaEventRecordingView,
          meta: {
            page_title: FrontendSettings.eventRecording.titleRecordingEvents,
          },
        },
        {
          path: "recording/:event_id",
          name: "events-recording-edit",
          component: QuokkaEventRecordingView,
          meta: {
            page_title: FrontendSettings.eventRecording.titleRecordingEvents,
          },
        },
      ],
    });
  }

  // Ads
  if (FrontendSettings.applicationSettings.activateAdverts) {
    adminChildrenRoutes.push({
      path: "ads",
      name: "ads",
      component: QuokkaAdsAdminView,
      redirect: {
        name: "my-ads",
      },
      children: [
        // My-Ads
        {
          path: "my-ads",
          name: "my-ads",
          component: QuokkaAdsListAdminView,
          meta: {
            page_title: FrontendSettings.advertSettings.titleMyAdverts,
          },
        },
        // Ads-Recording
        {
          path: "recording",
          name: "ads-recording",
          component: QuokkaAdsRecordingView,
          meta: {
            page_title: FrontendSettings.advertSettings.titleRecordingAdverts,
          },
        },
        // Ads-Details
        {
          path: "details/:order",
          name: "ads-details",
          component: QuokkaAdsDetailsView,
          meta: {
            page_title: FrontendSettings.advertSettings.titleShowAdverts,
          },
        },
      ],
    });
  }

  // Articles
  if (FrontendSettings.applicationSettings.activateArticles) {
    adminChildrenRoutes.push({
      path: "articles",
      name: "articles-admin",
      component: QuokkaArticlesAdminView,
      meta: {
        page_title: FrontendSettings.articleRecording.titleMyArticles,
      },
      redirect: {
        name: "my-articles",
      },
      children: [
        {
          path: "my-articles",
          name: "my-articles",
          component: QuokkaArticlesListAdminView,
          meta: {
            page_title: FrontendSettings.articleRecording.titleMyArticles,
          },
        },
        {
          path: "recording",
          name: "articles-recording",
          component: QuokkaArticlesRecordingView,
          meta: {
            page_title:
              FrontendSettings.articleRecording.titleRecordingArticles,
          },
        },
        {
          path: "recording/:article_id",
          name: "articles-recording-edit",
          component: QuokkaArticlesRecordingView,
          meta: {
            page_title:
              FrontendSettings.articleRecording.titleRecordingArticles,
          },
        },
      ],
    });
  }

  const routes: Array<RouteConfig> = [
    {
      path: "/404",
      name: "404",
      component: QuokkaNotFoundView,
      meta: {
        page_title: "Nicht gefunden",
      },
    },

    // If we are at the root-path, we redirect to /events
    {
      path: "/",
      name: "root",
      redirect: "/events",
    },

    // Login / SignUp for Admin Area
    {
      path: "/login",
      name: "login",
      component: QuokkaLoginView,
      meta: {
        page_title: "Anmelden",
      },
    },
    {
      path: "/signup",
      name: "signup",
      component: QuokkaSignUpView,
      meta: {
        page_title: "Registrieren",
      },
    },
    {
      path: "/confirm-mail",
      name: "confirm-mail",
      component: QuokkaConfirmEMailView,
      meta: {
        page_title: "E-Mail bestätigen",
      },
    },
    {
      path: "/request-confirm-mail",
      name: "request-confirm-mail",
      component: QuokkaRequestConfirmEMailView,
      meta: {
        page_title: "E-Mail bestätigen",
      },
    },
    {
      path: "/reset-password",
      name: "reset-password",
      component: QuokkaRequestPasswordResetView,
      meta: {
        page_title: "Passwort zurücksetzen",
      },
    },
    {
      path: "/chpw",
      name: "change-password",
      component: QuokkaPasswordResetView,
      meta: {
        page_title: "Passwort ändern",
      },
    },
    {
      path: "/magic",
      name: "magic",
      component: QuokkaMagicLinkView,
      meta: {
        page_title: "Passwort setzen",
      },
    },
    // Secured Admin Area
    {
      path: "/secure",
      name: "secure",
      component: AdminView,
      redirect: {
        name: "admin",
      },
      children: [
        {
          path: "/",
          name: "admin",
          component: QuokkaAdminView,
          redirect: {
            name: "admin-main",
          },
          children: [
            // Admin Main View
            {
              path: "/",
              name: "admin-main",
              component: QuokkaAdminMainView,
              meta: {
                page_title: "Übersicht",
              },
            },
            ...adminChildrenRoutes,
          ],
        },
        {
          path: "/cpd",
          name: "change-person-data",
          component: QuokkaPersonDataChangeView,
          meta: {
            page_title: "Persönliche Daten ergänzen",
          },
        },
        {
          path: "*",
          redirect: {
            name: "admin",
          },
        },
      ],
    },

    // Events List and Details
    {
      path: "/events",
      name: "events",
      component: QuokkaLayoutView,
      meta: {
        page_title: "Veranstaltungen",
      },
    },
    {
      path: "/events/:event_id/:title",
      name: "event-details",
      component: QuokkaLayoutView,
      meta: {
        page_title: "Veranstaltung",
      },
    },
    {
      path: "/events/:event_id",
      name: "event-details-simple",
      redirect: "/events/:event_id/.", // We add a '.' to redirect to the correct path
    },

    // Else, we always redirect to 404
    {
      path: "*",
      redirect: {
        name: "404",
      },
    },
  ];

  return new VueRouter({
    mode: "hash",
    base: FrontendSettings.embedPath,
    routes,
    scrollBehavior: (to, from, savedPosition) => {
      // Check, if this is the first time, then we ignore it
      if (Vue.prototype.$enableRouterScrollBehaviour !== true) {
        Vue.prototype.$enableRouterScrollBehaviour = true;
        return;
      }
      if (savedPosition) return savedPosition;

      const element = document.getElementsByClassName("router-scroll-anker")[0];
      if (element) {
        const headerOffset = FrontendSettings.router.scrollBehaviourOffset;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition =
          elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    },
  });
}
