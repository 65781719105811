
import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";
import { formatTime, parseTime } from "@/shared/lib/datetime";
import { DateTime } from "luxon";
import FrontendLogger from "@/frontend/lib/logger";

/**
 * A timepicker to pick hours and minutes.
 *
 * Provides a visible selection + text-based time parsing.
 */
@Component
export default class QuokkaTimePicker extends Vue {
  /**
   * The v-model of this time-picker.
   */
  @VModel() time!: DateTime | null;

  /**
   * Update input field value with set time.
   */
  mounted(): void {
    this.onTimeModelUpdate();
  }

  /**
   * Sets the current value of the v-model to given value.
   *
   * If the value fails to parse to a {@link DateTime}, null will be set.
   *
   * @param value
   */
  set timeValue(value: string | null) {
    this.time = parseTime(value, "HH:mm") ?? null;
  }

  /**
   * Parses and returns the value of the current v-model.
   */
  get timeValue(): string | null {
    return formatTime(this.time, "HH:mm") ?? null;
  }

  /**
   * Watches the v-model and updates the {@link inputFieldValue} on change.
   */
  @Watch("time")
  onTimeModelUpdate(): void {
    this.inputFieldValue = this.timeValue;
  }

  /**
   * A list of valid formats that can be parsed.
   *
   * @see https://moment.github.io/luxon/#/formatting?id=table-of-tokens for formatting options.
   */
  readonly validFormats: string[] = [
    "HH:mm",
    "HH.mm",
    "H:mm",
    "H.mm",
    "HH:m",
    "HH.m",
    "H:m",
    "H.m",
    "HHmm",
    "HH",
    "H",
  ];

  /**
   * The label for this field.
   */
  @Prop({ required: true }) label!: string;

  /**
   * Rules to set for this field.
   */
  @Prop() rules!: ((value: string) => boolean | string)[];

  /**
   * Whether this field should be disabled or not.
   */
  @Prop() disabled!: boolean;

  /**
   * Whether to show the picker modal or not.
   */
  showPickerModal = false;

  /**
   * An error message to display for this field.
   */
  errorMessage = "";

  /**
   * The model of the input field.
   */
  inputFieldValue: string | null = null;

  /**
   * Validates and sets the current value to the {@link inputFieldValue}.
   *
   * @param value
   */
  set pickerModel(value: string | null) {
    this.inputFieldValue = value;
    this.parseInput();
  }

  /**
   * Returns the value of the {@link inputFieldValue} for the picker-model.
   */
  get pickerModel(): string | null {
    return this.inputFieldValue;
  }

  /**
   * Tries to parse the hour to a full DateTime, if no time is currently selected.
   *
   * This is done, so that the user can just select the hour, and the minutes (00) will be added automatically.
   *
   * @param hour
   */
  clickHour(hour: number): void {
    const currentSelectedTime = parseTime(this.inputFieldValue, "HH:mm");

    if (!currentSelectedTime) {
      // Nothing selected => We use the hour to create a new DateTime
      const time: string =
        hour.toString().length === 1 ? "0" + hour.toString() : hour.toString();
      try {
        const parsed = parseTime(time, "H");

        if (parsed) {
          this.inputFieldValue = formatTime(parsed, "HH:mm") ?? "";
          // Update model
          this.timeValue = this.inputFieldValue;
          return;
        }
      } catch (e) {
        // Something went wrong, this should not happen
        FrontendLogger.error({
          message: "Failed to parse the hour to a DateTime-object.",
          data: hour,
          scope: "ui",
        });
      }
    }
  }

  /**
   * Tries to parse the current {@link inputFieldValue} to a valid time and sets it
   * to the {@link inputFieldValue} and the v-model.
   */
  parseInput(): void {
    this.errorMessage = "";

    if (this.inputFieldValue === null) {
      this.inputFieldValue = null;
      // Update model
      this.timeValue = this.inputFieldValue;
      return;
    }

    // Go through every possible format and try to parse it
    for (const validFormat of this.validFormats) {
      try {
        const parsed = parseTime(this.inputFieldValue, validFormat);

        if (parsed) {
          this.inputFieldValue = formatTime(parsed, "HH:mm") ?? "";
          // Update model
          this.timeValue = this.inputFieldValue;
          return;
        }
      } catch (e) {
        // We don't need to do anything, because we want to validate with the other formats, too
      }
    }

    // Failed to validate the input => Error and replace with old valid value
    this.errorMessage = "Ungültige Uhrzeit '" + this.inputFieldValue + "'";
    this.inputFieldValue = this.timeValue;
  }
}
