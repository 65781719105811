/**
 * This error is used for when an {@link RecordingEvent} is not valid
 */
export default class RecordingEventValidationError extends Error {
  reason: string | RecordingEventValidationErrorReason;

  constructor(props: {
    message: string;
    reason: RecordingEventValidationErrorReason;
  }) {
    super(props.message);

    this.reason = props.reason;

    Object.setPrototypeOf(this, RecordingEventValidationError.prototype);
  }
}

/**
 * Contains a few possible reasons, why an {@link RecordingEvent} is not valid.
 */
export enum RecordingEventValidationErrorReason {
  /**
   * Unknown reason.
   */
  UNKNOWN = "unknown",
  /**
   * Reason ,every date is expired.
   */
  EXPIRED = "expired",
}
