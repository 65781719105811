import DataStruct from "@/shared/data/datastruct";

/** Data type for media information, delivered by server */
export type ServerMediaInformationDataType = {
  media_id: number;
  upload_key: string;
  title?: string;
  desc?: string;
  author?: string;
  copyright?: string;
  agency?: string;
  location?: string;
};

/** Data type for use in {@link QuokkaMediaUpload} */
export type DisplayInformationDataType = {
  media_id: number;
  upload_key: string;
  title: string | null;
  desc: string | null;
  author: string | null;
  copyright: string | null;
  agency: string | null;
  location: string | null;
  width: number | null;
  height: number | null;
  mimetype: string | null;
  base64: string | null;
};

/** Data type for media information , used for all instances of {@link QuokkaMediaUpload} */
export class MediaInformationData extends DataStruct {
  media_id: number;
  upload_key: string;
  title: string | null = null;
  desc: string | null = null;
  author: string | null = null;
  copyright: string | null = null;
  agency: string | null = null;
  location: string | null = null;

  protected constructor(data: { media_id: number; upload_key: string }) {
    super();

    if (DataStruct.validateValue(data, "media_id", "number")) {
      this.media_id = <number>data.media_id;
    } else {
      // We cannot initialize without an id
      throw new TypeError(
        "Could not initialize RecordingMedia. Invalid media_id."
      );
    }

    if (DataStruct.validateValue(data, "upload_key", "string")) {
      this.upload_key = <string>data.upload_key;
    } else {
      // We cannot initialize without an id
      throw new TypeError(
        "Could not initialize RecordingMedia. Invalid media_id."
      );
    }
  }

  /**
   *  Create {@link MediaInformationData} from {@link ServerMediaInformationDataType}
   * @param data
   */
  static fromServer(
    data: ServerMediaInformationDataType
  ): MediaInformationData {
    const information = new MediaInformationData({
      media_id: data.media_id ?? 0,
      upload_key: data.upload_key ?? "",
    });

    if (DataStruct.validateValue(data, "title", "string"))
      information.title = <string>data.title;

    if (DataStruct.validateValue(data, "desc", "string"))
      information.desc = <string>data.desc;

    if (DataStruct.validateValue(data, "author", "string"))
      information.author = <string>data.author;

    if (DataStruct.validateValue(data, "copyright", "string"))
      information.copyright = <string>data.copyright;

    if (DataStruct.validateValue(data, "agency", "string"))
      information.agency = <string>data.agency;

    if (DataStruct.validateValue(data, "location", "string"))
      information.location = <string>data.location;

    return information;
  }
}
