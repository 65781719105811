
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import Tag from "@/shared/data/tag";
import { getTags } from "@/frontend/lib/api";
import FrontendLogger from "@/frontend/lib/logger";

@Component
export default class QuokkaEventTagSearch extends Vue {
  /** The v-model of this autocomplete */
  @VModel({ required: true }) tag!: Tag | null;

  /** The rules to apply to this field */
  @Prop() rules!: ((value: string) => boolean | string)[];

  /** Whether this field is disabled or not */
  @Prop() disabled!: boolean;

  /** A label to use for the field. If not set uses default label */
  @Prop() label!: string;

  /** The label for the field */
  get fieldLabel(): string {
    if (this.label > "") return this.label;
    return "Schlagwort";
  }

  /** If given, this list of {@link Tag}s will be used instead of loading it. */
  @Prop() initAvailableTags!: Tag[] | null;

  /** Available Tags */
  availableTags: Tag[] = [];

  /** Whether the selected Tag from the V-Model is contained in availableTags */
  selectedTagIsContained = false;

  /** The current search query */
  tagSearchQuery: string | null = null;

  /** Whether the {@link Tag}s are being loaded or not */
  tagLoading = false;

  /** Error message to be displayed for field */
  errorMessage = "";

  /**
   * Checks, if we got {@link initAvailableTags} or not.
   * If not, fetch {@link Tag}s from server.
   */
  mounted(): void {
    if (this.initAvailableTags) {
      this.availableTags = this.initAvailableTags;
    } else {
      this.tagLoading = true;

      // Load all tags
      getTags({ type: "event" })
        .then((tags) => {
          this.availableTags = tags;
        })
        .catch((e) => {
          FrontendLogger.error({
            message: "Failed to get Tags for the Tag-Search field.",
            scope: "api",
            data: e,
          });
          this.errorMessage = "Ein Fehler ist aufgetreten";
        })
        .finally(() => {
          this.tagLoading = false;
        });
    }
  }

  /** Hide the selected item, if only one tag is available and this is the selcted one */
  get hideSelected(): boolean {
    return !(
      (this.availableTags.length == 1 &&
        this.tag &&
        this.availableTags[0].tag_id === this.tag.tag_id &&
        this.tag.name
          .toLowerCase()
          .includes(this.tagSearchQueryString.toLowerCase())) ||
      this.selectedTagIsContained
    );
  }

  get tagSearchQueryString(): string {
    return this.tagSearchQuery ?? "";
  }
}
