
import Event from "@/shared/data/event";
import { Component, Prop, Vue } from "vue-property-decorator";
import { DateTime } from "luxon";
import { DateFormats, formatDate } from "@/shared/lib/datetime";

@Component
export default class QuokkaEventListCard extends Vue {
  @Prop({ required: true }) event!: Event | undefined;

  get cardHeaderStyle(): string {
    return this.event?.getPreviewImage()?.getImageUrl()
      ? "background-image: url(" +
          this.event.getPreviewImage()?.getImageUrl(true) +
          ");"
      : "";
  }

  /**
   * Checks, if this date has both, a from and to date
   */
  get hasFromToDates(): boolean {
    return (
      this.event !== undefined &&
      this.event.firstDate !== null &&
      this.event.lastDate !== null &&
      this.formatDate(this.event.firstDate, "yyyy-MM-dd") !==
        this.formatDate(this.event.lastDate, "yyyy-MM-dd")
    );
  }

  /**
   * Tries to format the given date to the given format.
   * If date is null or format fails, an empty string is returned.
   *
   * @param date
   * @param format
   */
  formatDate(date: DateTime | null, format: string | DateFormats): string {
    return date ? formatDate(date, format) ?? "" : "";
  }

  showDetails(): void {
    if (!(this.event instanceof Event)) return;

    this.$router.push({
      name: "event-details",
      params: {
        event_id: this.event.event_id.toString(),
        title: this.event.urlTitle,
      },
    });
  }
}
