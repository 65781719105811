
import {
  createAdvertOrder,
  loadImageFromEvent,
  MediaInfoReturnType,
  OrderBookingAnswer,
} from "@/frontend/lib/api";
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { formatDate, formatTime, parseDate } from "@/shared/lib/datetime";
import {
  AdvertCampaign,
  AdvertChannel,
  AdvertContent,
  AdvertMedium,
  AdvertSlot,
  Order,
  OrderAddress,
  OrderItem,
} from "@/shared/data/advert";
import RecordingEvent from "@/shared/data/recording_event";
import { formatCurrency } from "@/shared/lib/currency";
import FrontendSettings from "@/frontend/settings/settings";
import {
  RecordingEventAppointmentsFree,
  RecordingEventAppointmentsSeries,
  RecordingEventAppointmentType,
} from "@/shared/data/event_appointments";
import QuokkaDatePicker from "@/components/quokka_layout/ui/QuokkaDatePicker.vue";
import LabeledSheet from "@/components/quokka_layout/ui/LabeledSheet.vue";
import { DateTime } from "luxon";
import TimeInfo from "@/shared/data/time_info";
/**
 * Booking overview
 */
@Component({
  components: { LabeledSheet, QuokkaDatePicker },
  data() {
    return { FrontendSettings, formatCurrency };
  },
})
export default class QuokkaAdsRecordingOverview extends Vue {
  /** The campaigns */
  @Prop({ required: true }) campaigns!: AdvertCampaign[];
  /** Selected channel data */
  @Prop({ required: true }) selectedChannel!: AdvertChannel;
  /** Selected slot data */
  @Prop({ required: true }) selectedSlot!: AdvertSlot;
  /** Selected medium data */
  @Prop({ required: true }) selectedMedium!: AdvertMedium;
  /** OrderItem array */
  @Prop({ required: true }) currentOrderItems!: OrderItem[];
  /** Content array */
  @Prop({ required: true }) contentData!: AdvertContent;
  /** Logged in user billing address */
  @Prop({ required: true }) personBillingAddress!: OrderAddress;
  /** Different billing address */
  @Prop({ required: true }) differentBillingAddress!: OrderAddress;
  /** Selected Event */
  @Prop({ required: true }) selectedRecordingEvent!: RecordingEvent;

  /** selected campaign */
  selectedCampaign: AdvertCampaign | null = null;
  /** Total price for order */
  totalOrderPrice = 0;
  /** Is the component still loading? */
  loading = true;
  /** Was the booking successful? */
  successfulBooking = false;

  /** The currency symbol */
  currencySymbol = "";
  /** The currency as string (de-de) */
  currencySymbolStr = "";
  /** contains the error message */
  errorMsg = "";

  /** is event valid */
  validEvent = false;

  imprintDialog = false;
  dataprivacyDialog = false;
  agbDialog = false;

  /** is agb accepted */
  acceptAGB = false;
  /** is dataprivacy accepted */
  acceptDSGVO = false;

  /** Panel index */
  panel = [];

  availableMedia: string[] = [];

  showSeriesDate: string[] = [];

  /** Rules for validating form fields  */
  rules = {
    acceptAGB: (value: boolean): boolean | string =>
      value || "Die Zustimmung ist verpflichtend",
    acceptDSGVO: (value: boolean): boolean | string =>
      value || "Die Zustimmung ist verpflichtend",
  };

  /**
   * Return the label for given event field.
   *
   * @param field
   */
  getEventFieldLabel(field: string): string {
    let callLabelGetter = "this." + field;

    if (field === "title") return "Titel";
    if (field === "longdesc") callLabelGetter += "1";
    if (field === "shortdesc") callLabelGetter += "1";
    callLabelGetter += "Label";

    return eval(callLabelGetter);
  }

  // <editor-fold desc="descFieldLabels">

  /**
   * Returns the label for the `shortdesc1` field.
   */
  get shortdesc1Label(): string {
    return (
      FrontendSettings.fieldSettings.recording.shortdesc1.label ?? "Kurztext"
    );
  }

  /**
   * Returns the label for the `shortdesc2` field.
   */
  get shortdesc2Label(): string {
    return (
      FrontendSettings.fieldSettings.recording.shortdesc2.label ?? "Kurztext 2"
    );
  }

  /**
   * Returns the label for the `shortdesc3` field.
   */
  get shortdesc3Label(): string {
    return (
      FrontendSettings.fieldSettings.recording.shortdesc3.label ?? "Kurztext 3"
    );
  }

  /**
   * Returns the label for the `longdesc1` fields or button.
   */
  get longdesc1Label(): string {
    return (
      FrontendSettings.fieldSettings.recording.longdesc1.label ?? "Langtext"
    );
  }

  /**
   * Returns the label for the `longdesc2` fields or button.
   */
  get longdesc2Label(): string {
    return (
      FrontendSettings.fieldSettings.recording.longdesc2.label ?? "Langtext 2"
    );
  }

  /**
   * Returns the label for the `longdesc3` fields or button.
   */
  get longdesc3Label(): string {
    return (
      FrontendSettings.fieldSettings.recording.longdesc3.label ?? "Langtext 3"
    );
  }

  // <editor-fold>

  /**
   * Sets the order as successfully booked
   *
   * @param data
   */
  @Emit("set-is-order-completed")
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setIsOrderCompleted(data: boolean): void {
    return;
  }

  @Watch("selectedRecordingEvent.event_id", { immediate: true })
  isEventValid(): void {
    try {
      //console.log(this.selectedRecordingEvent);
      this.selectedRecordingEvent.validate();
      this.validEvent = true;
    } catch (e) {
      //console.error(e);
      this.validEvent = false;
    }
  }

  @Watch("contentData.selectedMediaEventMediaId", { immediate: true })
  updateSelectedMedium(): void {
    this.loading = true;
    //console.log(this.selectedMedium);

    this.availableMedia = [];
    if (this.contentData?.selectedMediaEventMediaId) {
      this.contentData.selectedMediaEventMediaId.forEach((mediaId) => {
        loadImageFromEvent({
          load: mediaId,
        })
          .then((response: MediaInfoReturnType) => {
            this.availableMedia.push(response["base64"]);
            this.loading = false;
          })
          .catch((e) => {
            console.error(e);
            this.loading = false;
          });
      });
    }

    if (this.contentData?.advertMedia?.upload_media) {
      this.contentData.advertMedia.upload_media.forEach((loadTmpHashFile) => {
        loadImageFromEvent({
          load: loadTmpHashFile,
          tmpImgFile: "Y",
        })
          .then((response: MediaInfoReturnType) => {
            this.availableMedia.push(response["base64"]);
            this.loading = false;
          })
          .catch((e) => {
            console.error(e);
            this.loading = false;
          });
      });
    }

    this.loading = false;
  }

  /**
   * Prepares the data at the first load of component.
   */
  @Watch("selectedRecordingEvent.event_id", { immediate: true })
  updateSelectedRecordingEvent(): void {
    this.loading = true;

    this.campaigns.forEach((campaign) => {
      breakLoop: for (const channel of campaign.advert_channels) {
        if (
          channel.advert_channel_id == this.selectedChannel?.advert_channel_id
        ) {
          for (const slot of channel.advert_slots) {
            if (slot.advert_slot_id == this.selectedSlot?.advert_slot_id) {
              this.selectedCampaign = campaign;
              break breakLoop;
            }
          }
        }
      }
    });

    this.getCurrencySymbol();

    if (this.appointmentsFree) this.appointments.free = this.appointmentsFree;
    if (this.appointmentsSeries)
      this.appointments.series = this.appointmentsSeries;
    if (!this.selectedRecordingEvent.appointments)
      this.selectedRecordingEvent.appointments = this.appointments.free;
    this.loading = false;
  }

  /**
   * Formats a json-date from event in german date format.
   *
   * @param date
   * @param toFormat
   * @param fromFormat
   */
  formatBookingDate(
    date: string,
    toFormat?: string,
    fromFormat?: string
  ): string | undefined {
    if (fromFormat === undefined) fromFormat = "yyyy-MM-dd";
    if (toFormat === undefined) toFormat = "dd.MM.yyyy";

    let parsedDate = parseDate(date, fromFormat);
    let finishFormattedDate = formatDate(parsedDate, toFormat);
    if (finishFormattedDate !== undefined) {
      return finishFormattedDate;
    }

    return "";
  }

  /**
   * Formats a edv-date in german date format.
   *
   * @param date
   * @param toFormat
   */
  formatEventDateData(date: DateTime, toFormat?: string): string {
    if (toFormat === undefined) toFormat = "dd.MM.yyyy";

    return date.toFormat(toFormat);
  }

  getDayNumber(englishDay: string): number | null {
    switch (englishDay) {
      case "monday":
        return 1;
      case "tuesday":
        return 2;
      case "wednesday":
        return 3;
      case "thursday":
        return 4;
      case "friday":
        return 5;
      case "saturday":
        return 6;
      case "sunday":
        return 7;
    }
    return null;
  }

  /**
   * Returns the label of the weekday for given dayOfWeek.
   *
   * @param dayOfWeek
   */
  getWeekdayLabel(dayOfWeek: number | null): string {
    if (!dayOfWeek) return "";
    return formatDate(parseDate(dayOfWeek.toString(), "c"), "cccc") || "";
  }

  /**
   * Returns the label for the given {@link TimeInfo}.
   * The label includes the time, when the event takes place.
   *
   * @param timeInfo
   */
  getTimeInfoLabel(timeInfo: TimeInfo): string {
    // Full day, if given so or neither from nor until time are available
    if (timeInfo.full || (!timeInfo.fromTime && !timeInfo.untilTime)) {
      return "ganztags";
    }

    // Start time
    let title = formatTime(timeInfo.fromTime, "HH:mm") ?? "";

    let endTimeFormatted = undefined;
    if (
      timeInfo.untilTime &&
      (endTimeFormatted = formatTime(timeInfo.untilTime, "HH:mm"))
    ) {
      // Add "to" label
      title += title > "" ? " bis " : "Bis ";

      // Add to time
      title += endTimeFormatted;
    } else {
      // We only got a start date => Add a "from" label
      title = "Ab " + title;
    }

    // Add suffix
    title += " Uhr";

    return title;
  }

  /**
   * Get the currency symbol from the campaign.
   */
  getCurrencySymbol(): void {
    if (this.selectedCampaign !== null) {
      if (this.selectedCampaign?.advert_partner) {
        this.currencySymbol =
          this.selectedCampaign.advert_partner.currency_symbol;
        this.currencySymbolStr = this.selectedCampaign.advert_partner.currency;
      }
    }
  }

  /**
   * Calculates the total price of all items.
   */
  totalPrice(): number {
    let cacheTotalOrderPrice = 0;

    if (this.currentOrderItems !== []) {
      this.currentOrderItems.forEach(
        (orderItem) => (cacheTotalOrderPrice += orderItem.item_total_price)
      );
    }

    this.totalOrderPrice = cacheTotalOrderPrice;
    return cacheTotalOrderPrice;
  }

  /**
   * Sends the order to the endpoint to book.
   */
  book(): void {
    let totalPrice = 0;

    /**
     * Create order-object
     */
    let orderObj = new Order({ advertOrderId: 0 });
    orderObj.total_price = totalPrice;
    orderObj.person_address = this.personBillingAddress;
    orderObj.order_item = this.currentOrderItems;
    orderObj.invoice_address = this.differentBillingAddress;

    if (this.selectedCampaign?.advert_partner_id !== undefined) {
      orderObj.advert_partner_id = this.selectedCampaign?.advert_partner_id;
    }
    if (this.selectedCampaign?.advert_partner?.currency !== undefined) {
      orderObj.currency = this.selectedCampaign?.advert_partner?.currency;
    }
    if (this.selectedCampaign?.advert_partner?.currency_symbol !== undefined) {
      orderObj.currency_symbol =
        this.selectedCampaign?.advert_partner?.currency_symbol;
    }

    /**
     * Send order and content data to the booking endpoint.
     */
    createAdvertOrder({
      order: orderObj,
      orderContent: this.contentData,
    })
      .then((response: OrderBookingAnswer) => {
        this.errorMsg = "";
        this.successfulBooking = response["successFullBooking"];

        this.setIsOrderCompleted(this.successfulBooking);
      })
      .catch((e) => {
        //console.log(e);

        if (e.response.data.error === "ERROR_CODE_NO_VALID_EVENT") {
          this.validEvent = false;
          return;
        } else if (
          e.response.data.error === "ERROR_CODE_ORDER_BOOKING_FAILED"
        ) {
          this.errorMsg = "Fehler: Beim Speichern der Bestellung.";
          return;
        } else if (
          e.response.data.error === "ERROR_CODE_ITEM_ORDER_BOOKING_FAILED"
        ) {
          this.errorMsg = "Fehler: Beim Speichern der Bestellung.";
          return;
        } else if (e.response.data.error === "ERROR_CODE_SAVE_MEDIUM_FAILED") {
          this.errorMsg = "Fehler: Beim Speichern des Anhangs.";
          return;
        } else if (e.response.data.error === "ERROR_CODE_SAVE_CONTENT_FAILED") {
          this.errorMsg = "Fehler: Beim Speichern des Werbeinhalts.";
          return;
        } else if (e.response.data.error === "FAILED_CREATE_UPLOAD_DIRECTORY") {
          this.errorMsg =
            "Fehler: Das Medium konnte nicht auf dem Server abgelegt werden.";
          return;
        } else if (e.response.data.error === "ERROR_CODE_FAILED_COPY") {
          this.errorMsg =
            "Fehler: Das Medium konnte nicht aus der Veranstaltung übernommen werden.";
          return;
        } else if (
          e.response.data.error === "ERROR_CODE_CONFERMATION_MAIL_FAILED"
        ) {
          this.errorMsg =
            "Fehler: Die Auftragsbestätigungsmail konnte nicht gesendet werden.";
          return;
        }

        let dateErrorMsg = e.response.data.error.split(
          "ERROR_CODE_DATE_BOOKED_UP_"
        );
        dateErrorMsg = dateErrorMsg[1].split("_##_");

        this.errorMsg =
          "Das Datum " +
          this.formatBookingDate(dateErrorMsg[0]) +
          " ist für " +
          dateErrorMsg[1] +
          " bereits ausgebucht.";

        //console.error(e);
      });
  }
  // <editor-fold desc="getEventAppointments">

  /**
   * The currently used {@link RecordingEventAppointments}.
   */
  appointments: {
    free: RecordingEventAppointmentsFree;
    series: RecordingEventAppointmentsSeries;
  } = {
    free: new RecordingEventAppointmentsFree(),
    series: new RecordingEventAppointmentsSeries(),
  };

  /**
   * Returns the key of the active type.
   */
  get activeType(): RecordingEventAppointmentType {
    return this.appointmentsFree ? "free" : "series";
  }

  /**
   * Whether the active appointments type is `series` or not.
   */
  get isSeries(): boolean {
    return this.activeType === "series";
  }

  /**
   * This setter is just to prevent errors with the v-model.
   * The actual value is set through the @change event.
   *
   * @param value
   */
  set isSeries(value: boolean) {
    return;
  }

  /**
   * Returns the {@link RecordingEventAppointmentsFree} if it is active.
   * Otherwise, undefined.
   */
  get appointmentsFree(): RecordingEventAppointmentsFree | undefined {
    return this.selectedRecordingEvent.appointments?.type === "free"
      ? (this.selectedRecordingEvent
          .appointments as RecordingEventAppointmentsFree)
      : undefined;
  }

  /**
   * Returns the {@link RecordingEventAppointmentsSeries} if it is active.
   * Otherwise, undefined.
   */
  get appointmentsSeries(): RecordingEventAppointmentsSeries | undefined {
    return this.selectedRecordingEvent.appointments?.type === "series"
      ? (this.selectedRecordingEvent
          .appointments as RecordingEventAppointmentsSeries)
      : undefined;
  }

  /**
   * Switch the type of the active appointments.
   *
   * Available types are "free" or "series"
   */
  switchAppointmentType(): void {
    if (this.appointmentsFree) {
      this.selectedRecordingEvent.appointments = this.appointments.series;
    } else if (this.appointmentsSeries) {
      this.selectedRecordingEvent.appointments = this.appointments.free;
    }
  }

  // </editor-fold>
}
