var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('MediaUploader',{attrs:{"media-uploader-data":_vm.mediaUploaderData,"disabled":_vm.disabled,"type":_vm.mediaType,"max-files":_vm.maxFiles}})],1)],1),_c('v-expansion-panels',{staticClass:"mt-3"},_vm._l((_vm.availableMedia),function(media,i){return _c('div',{key:i,staticClass:"full-width"},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"expansion-panel-header-color mb-3",attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{staticClass:"expansion-panel-header-color"},[_vm._v(" mdi-chevron-down ")])]},proxy:true}],null,true)},[(media.base64)?_c('v-img',{staticClass:"mr-4",attrs:{"src":media.base64,"max-height":"100px","max-width":"100px"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.getMediaTitle(media))+" ")],1),_c('v-expansion-panel-content',[_c('v-row',[_c('v-col',{staticClass:"mb-0",staticStyle:{"padding-left":"10%","padding-right":"10%"},attrs:{"cols":"12"}},[(
                  _vm.mediaConfigData['additionalImageInformationFields'][
                    'title'
                  ] &&
                  _vm.mediaConfigData['additionalImageInformationFields'][
                    'title'
                  ]['active'] === 'Y'
                )?_c('v-text-field',{attrs:{"name":i + '_title',"label":_vm.mediaConfigData['additionalImageInformationFields'][
                    'title'
                  ]['label'] +
                  (_vm.mediaConfigData['additionalImageInformationFields'][
                    'title'
                  ]['require'] === 'Y'
                    ? ' *'
                    : ''),"maxlength":_vm.mediaConfigData['additionalImageInformationFields'][
                    'title'
                  ]['maxlength'],"rules":_vm.mediaConfigData['additionalImageInformationFields'][
                    'title'
                  ]['require'] === 'Y'
                    ? [_vm.rules.required]
                    : []},model:{value:(media['title']),callback:function ($$v) {_vm.$set(media, 'title', $$v)},expression:"media['title']"}}):_vm._e()],1),_c('v-col',{staticClass:"mb-0",staticStyle:{"padding-left":"10%","padding-right":"10%"},attrs:{"cols":"12"}},[(
                  _vm.mediaConfigData['additionalImageInformationFields'][
                    'desc'
                  ] &&
                  _vm.mediaConfigData['additionalImageInformationFields']['desc'][
                    'active'
                  ] === 'Y'
                )?_c('v-text-field',{attrs:{"name":i + '_desc',"label":_vm.mediaConfigData['additionalImageInformationFields']['desc'][
                    'label'
                  ] +
                  (_vm.mediaConfigData['additionalImageInformationFields'][
                    'desc'
                  ]['require'] === 'Y'
                    ? ' *'
                    : ''),"maxlength":_vm.mediaConfigData['additionalImageInformationFields']['desc'][
                    'maxlength'
                  ],"rules":_vm.mediaConfigData['additionalImageInformationFields']['desc'][
                    'require'
                  ] === 'Y'
                    ? [_vm.rules.required]
                    : []},model:{value:(media['desc']),callback:function ($$v) {_vm.$set(media, 'desc', $$v)},expression:"media['desc']"}}):_vm._e()],1),_c('v-col',{staticClass:"mb-0",staticStyle:{"padding-left":"10%","padding-right":"10%"},attrs:{"cols":"12"}},[(
                  _vm.mediaConfigData['additionalImageInformationFields'][
                    'agency'
                  ] &&
                  _vm.mediaConfigData['additionalImageInformationFields'][
                    'agency'
                  ]['active'] === 'Y'
                )?_c('v-text-field',{attrs:{"name":i + '_agency',"label":_vm.mediaConfigData['additionalImageInformationFields'][
                    'agency'
                  ]['label'] +
                  (_vm.mediaConfigData['additionalImageInformationFields'][
                    'agency'
                  ]['require'] === 'Y'
                    ? ' *'
                    : ''),"maxlength":_vm.mediaConfigData['additionalImageInformationFields'][
                    'agency'
                  ]['maxlength'],"rules":_vm.mediaConfigData['additionalImageInformationFields'][
                    'agency'
                  ]['require'] === 'Y'
                    ? [_vm.rules.required]
                    : []},model:{value:(media['agency']),callback:function ($$v) {_vm.$set(media, 'agency', $$v)},expression:"media['agency']"}}):_vm._e()],1),_c('v-col',{staticClass:"mb-0",staticStyle:{"padding-left":"10%","padding-right":"10%"},attrs:{"cols":"12"}},[(
                  _vm.mediaConfigData['additionalImageInformationFields'][
                    'author'
                  ] &&
                  _vm.mediaConfigData['additionalImageInformationFields'][
                    'author'
                  ]['active'] === 'Y'
                )?_c('v-text-field',{attrs:{"name":i + '_author',"label":_vm.mediaConfigData['additionalImageInformationFields'][
                    'author'
                  ]['label'] +
                  (_vm.mediaConfigData['additionalImageInformationFields'][
                    'author'
                  ]['require'] === 'Y'
                    ? ' *'
                    : ''),"maxlength":_vm.mediaConfigData['additionalImageInformationFields'][
                    'author'
                  ]['maxlength'],"rules":_vm.mediaConfigData['additionalImageInformationFields'][
                    'author'
                  ]['require'] === 'Y'
                    ? [_vm.rules.required]
                    : []},model:{value:(media['author']),callback:function ($$v) {_vm.$set(media, 'author', $$v)},expression:"media['author']"}}):_vm._e()],1),_c('v-col',{staticClass:"mb-0",staticStyle:{"padding-left":"10%","padding-right":"10%"},attrs:{"cols":"12"}},[(
                  _vm.mediaConfigData['additionalImageInformationFields'][
                    'location'
                  ] &&
                  _vm.mediaConfigData['additionalImageInformationFields'][
                    'location'
                  ]['active'] === 'Y'
                )?_c('v-text-field',{attrs:{"name":i + '_location',"label":_vm.mediaConfigData['additionalImageInformationFields'][
                    'location'
                  ]['label'] +
                  (_vm.mediaConfigData['additionalImageInformationFields'][
                    'location'
                  ]['require'] === 'Y'
                    ? ' *'
                    : ''),"maxlength":_vm.mediaConfigData['additionalImageInformationFields'][
                    'location'
                  ]['maxlength'],"rules":_vm.mediaConfigData['additionalImageInformationFields'][
                    'location'
                  ]['require'] === 'Y'
                    ? [_vm.rules.required]
                    : []},model:{value:(media['location']),callback:function ($$v) {_vm.$set(media, 'location', $$v)},expression:"media['location']"}}):_vm._e()],1),_c('v-col',{staticClass:"mb-0",staticStyle:{"padding-left":"10%","padding-right":"10%"},attrs:{"cols":"12"}},[(
                  _vm.mediaConfigData['additionalImageInformationFields'][
                    'copyright'
                  ] &&
                  _vm.mediaConfigData['additionalImageInformationFields'][
                    'copyright'
                  ]['active'] === 'Y'
                )?_c('v-text-field',{attrs:{"name":i + '_copyright',"label":_vm.mediaConfigData['additionalImageInformationFields'][
                    'copyright'
                  ]['label'] +
                  (_vm.mediaConfigData['additionalImageInformationFields'][
                    'copyright'
                  ]['require'] === 'Y'
                    ? ' *'
                    : ''),"maxlength":_vm.mediaConfigData['additionalImageInformationFields'][
                    'copyright'
                  ]['maxlength'],"rules":_vm.mediaConfigData['additionalImageInformationFields'][
                    'copyright'
                  ]['require'] === 'Y'
                    ? [_vm.rules.required]
                    : []},model:{value:(media['copyright']),callback:function ($$v) {_vm.$set(media, 'copyright', $$v)},expression:"media['copyright']"}}):_vm._e()],1)],1)],1)],1)],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }