
import { Component, Vue } from "vue-property-decorator";
import AuthView from "@/views/layouts/quokka/auth/AuthView.vue";
import { requestPasswordReset } from "@/frontend/lib/api";
import FrontendLogger from "@/frontend/lib/logger";

/**
 * Here the person can make a request to reset their password.
 */
@Component({
  components: { AuthView },
})
export default class QuokkaRequestPasswordResetView extends Vue {
  /** Clientside data validation of signup form */
  formValid = false;
  /** Display Snackbars */
  snackbar = false;
  /** Displayed Text in Snackbar */
  snackbarText = "Einige verpflichtende Angaben sind nicht vollständig!";
  /** Variable to disable form and buttons while loading */
  loading = false;
  /** Variable to disable form when success is returned */
  success = false;
  /** Contains the entered username  */
  username: string | null = null;

  /** Rules for validating form fields  */
  rules = {
    usernameCheck: (value: string): boolean | string => {
      if (!value || value.trim().length <= 0) return "Benutzernamen eingeben.";
      else return true;
    },
  };

  /** Submit the entered data to backend */
  onSubmit(): void {
    if (this.formValid && this.username) {
      this.loading = true;

      requestPasswordReset(this.username)
        .then(() => {
          this.success = true;
        })
        .catch((e) => {
          FrontendLogger.error({
            message: "Failed to request password reset.",
            data: e,
          });
          this.snackbar = true;
          this.snackbarText = "Eine E-Mail konnte nicht verschickt werden.";
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.snackbar = true;
      this.snackbarText = "Bitte geben Sie Ihren Benutzernamen ein.";
    }
  }
}
