
import { Component, Emit, Vue } from "vue-property-decorator";
import FrontendSettings from "@/frontend/settings/settings";

/**
 * The {@link QuokkaAdminView} is a wrapper for all quokka-views shown inside the secured admin area.
 *
 * Provides the logged in {@link Person} to the router-view.
 */

@Component({
  data() {
    return { FrontendSettings };
  },
})
export default class QuokkaAdsLandingPage extends Vue {
  /**
   * List of available cards to build.
   */
  actions: {
    title: string;
    icon: string;
    routeName?: string;
    adsTabName?: string;
  }[] = [
    /*{
      title:
        "Weitere " +
        FrontendSettings.languageSettings.lbl_advert +
        "hinzufügen",
      icon: "mdi-cart-arrow-down",
      routeName: "ads-recording",
    },*/
    {
      title: FrontendSettings.advertSettings.titleShowAdverts,
      icon: "mdi-feature-search-outline",
      adsTabName: "overview",
    },
    {
      title: FrontendSettings.advertSettings.titleMyAdverts,
      icon: "mdi-calendar-multiple-check",
      routeName: "my-ads",
    },
  ];

  @Emit("go-to-ads-tab")
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  goToAdsTab(adsTabName: string): void {
    return;
  }

  cardAction(adsRabName: string): void {
    switch (adsRabName) {
      case "overview":
        this.goToAdsTab("successOverview");
        break;
    }
  }
}
