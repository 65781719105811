import Vue from "vue";
import App from "./App.vue";
import getRouter from "./router";
import vuetify from "./plugins/vuetify";
import { Settings } from "luxon";
import FrontendSettings from "@/frontend/settings/settings";
import FrontendLogger from "@/frontend/lib/logger";
import axios from "axios";
import Authorization from "@/frontend/lib/auth";
import "typeface-roboto";

Vue.config.productionTip = false;

// Set default locale for DateTime
Settings.defaultLocale = "de";

// Check, if we got a host-url in the #app element
let host =
  document.getElementById("msu-event-online")?.getAttribute("host") ?? "/";

// Add / to the end
if (!/\/$/.test(host)) host += "/";

Vue.prototype.$host = host;

// Initialize Axios Interceptor
axios.interceptors.request.use((config) => {
  try {
    if (config.headers)
      config.headers.token = Authorization.getValidatedToken();
  } catch (e) {
    // We don't add anything
  }
  return config;
});

// Add Axios interceptor to check for maintenance
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Check, if maintenance is enabled
    if (error.response.status === 503) {
      FrontendSettings.enableMaintenance();
    }
    throw error;
  }
);

// Load Settings
FrontendSettings.load()
  .then(() => {
    const router = getRouter();

    // Initialize Vue
    const vue = new Vue({
      router,
      vuetify,
      render: (h) => h(App),
    }).$mount("#msu-event-online");

    // Set themes
    // Light theme
    for (const colorKey of Object.keys(FrontendSettings.theme.light)) {
      const color = FrontendSettings.theme.light[colorKey];
      if (color && color > "") {
        vue.$vuetify.theme.themes.light[colorKey] = color;
      }
    }
    // Dark theme
    for (const colorKey of Object.keys(FrontendSettings.theme.dark)) {
      const color = FrontendSettings.theme.dark[colorKey];
      if (color && color > "") {
        vue.$vuetify.theme.themes.dark[colorKey] = color;
      }
    }
    vue.$vuetify.theme.dark = FrontendSettings.theme.isDark;
  })
  .catch((e) => {
    FrontendLogger.error({
      message: "Failed to load settings.",
      data: e,
    });
  });
