/**
 * A Filter class to hold the filter for the event list.
 *
 * All properties default to null.
 * If a filter is null, it is not active.
 */
import { DateTime } from "luxon";
import { formatDate } from "@/shared/lib/datetime";
import Rubric from "@/shared/data/rubric";
import Community from "@/shared/data/community";
import FrontendLogger from "@/frontend/lib/logger";
import Tag from "@/shared/data/tag";
import Address from "@/shared/data/address";

export default class EventListFilter {
  /**
   * A text to search for in title and texts.
   */
  searchQuery: string | null = null;

  /**
   * The location to filter for.
   */
  locationCommunity: Community | null = null;

  /**
   * The location address to filter for.
   */
  addressLocation: Address | null = null;

  /**
   * The range for the address to filter for
   */
  locationRange: number | null = null;

  /**
   * The promoter address to filter for.
   */
  addressPromoter: Address | null = null;

  /**
   * The rubric to filter for.
   */
  rubric: Rubric | null = null;

  /**
   * The tag to filter for.
   */
  tag: Tag | null = null;

  /**
   * The start date of when the event takes place.
   */
  private _dateFrom: DateTime | null = null;

  get dateFrom(): DateTime | undefined {
    return this._dateFrom ?? undefined;
  }

  set dateFrom(value: DateTime | undefined) {
    this._dateFrom = value ?? null;
  }

  /**
   * The end date of when the event takes place.
   */
  private _dateUntil: DateTime | null = null;

  get dateUntil(): DateTime | undefined {
    return this._dateUntil ?? undefined;
  }

  set dateUntil(value: DateTime | undefined) {
    this._dateUntil = value ?? null;
  }

  /**
   * Checks, if any filters are active.
   */
  hasActiveFilter(): boolean {
    let hasActiveFilter = false;
    Object.keys(this).forEach((key) => {
      if (
        ((<Record<string, unknown>>this)[key] as unknown) !== null &&
        ((<Record<string, unknown>>this)[key] as string) > ""
      )
        hasActiveFilter = true;
    });
    return hasActiveFilter;
  }

  /**
   * Returns a list of the active filters with its label and a function to reset this filter.
   */
  getActiveFilterLabels(): {
    filterName: string;
    label: string;
    clear: () => void;
  }[] {
    const labels = [];

    // Search Query
    if (this.searchQuery !== null && this.searchQuery.trim() > "") {
      labels.push({
        filterName: "searchQuery",
        label: this.searchQuery,
        clear: () => (this.searchQuery = null),
      });
    }

    // Location Community
    if (this.locationCommunity !== null) {
      labels.push({
        filterName: "locationCommunity",
        label: this.locationCommunity.name,
        clear: () => (this.locationCommunity = null),
      });
    }

    // Address location
    if (this.addressLocation !== null) {
      labels.push({
        filterName: "addressLocation",
        label: this.addressLocation.name,
        clear: () => (this.addressLocation = null),
      });
    }

    // Address promoter
    if (this.addressPromoter !== null) {
      labels.push({
        filterName: "addressPromoter",
        label: this.addressPromoter.name,
        clear: () => (this.addressPromoter = null),
      });
    }

    // Rubric
    if (this.rubric !== null) {
      labels.push({
        filterName: "rubric",
        label: this.rubric.searchLabel,
        clear: () => (this.rubric = null),
      });
    }

    // Tag
    if (this.tag !== null) {
      labels.push({
        filterName: "tag",
        label: this.tag.name,
        clear: () => (this.tag = null),
      });
    }

    // Date
    try {
      const formattedFromDate = formatDate(this._dateFrom, "dd.MM.yyyy") ?? "";
      const formattedUntilDate =
        formatDate(this._dateUntil, "dd.MM.yyyy") ?? "";

      if (formattedFromDate > "") {
        const label = {
          filterName: "date",
          label: "",
          clear: () => {
            this._dateFrom = null;
            this._dateUntil = null;
          },
        };

        labels.push(label);

        label.label = formattedFromDate;

        if (formattedUntilDate > "") {
          // Start and End Date
          label.label += " - " + formattedUntilDate;
        } else {
          // Only Start Date
          label.label = "Ab " + label.label;
        }
      } else if (formattedUntilDate > "") {
        labels.push({
          filterName: "date",
          label: "Bis " + formattedUntilDate,
          clear: () => {
            this._dateFrom = null;
            this._dateUntil = null;
          },
        });
      }
    } catch (e) {
      FrontendLogger.error({
        message: "Failed to create filter label for 'date'. ",
        data: e,
      });
    }

    return labels;
  }
}
