
import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";
import { AuthenticatedAreaSettings } from "@/shared/data/settings";
import FrontendSettings from "@/frontend/settings/settings";
import { getStringValueList } from "@/frontend/lib/api";
import FrontendLogger from "@/frontend/lib/logger";
import SignupData from "@/shared/data/signup";
import QuokkaEventAddressSearch from "@/components/quokka_layout/QuokkaEventAddressSearch.vue";
import Person from "@/shared/data/person";
import { sanitizeContent } from "@/shared/lib/dompurify";

/**
 * Component to display fields to edit a {@link Person}s data like addresses,
 * password, name, mail etc.
 *
 * Can be used in signup, person-edit, etc.
 */
@Component({
  components: { QuokkaEventAddressSearch },
  data() {
    return {
      FrontendSettings,
      sanitizeContent,
    };
  },
})
export default class QuokkaPersonDetails extends Vue {
  @VModel() formValid!: boolean;

  @Prop({ default: null }) signUpData!: SignupData | null;
  @Prop({ default: null }) person!: Person | null;

  formModels: {
    main: boolean;
    officialAddressActive: boolean;
    privateAddressActive: boolean;
  } = {
    main: false,
    officialAddressActive: false,
    privateAddressActive: false,
  };

  /**
   * Watches the form models and updates the components model accordingly.
   */
  @Watch("formModels", { deep: true, immediate: true })
  onFormModelChange(): void {
    this.formValid =
      this.formModels.main &&
      (!this.fieldSettings.enableOfficialAddress ||
        this.formModels.officialAddressActive) &&
      (!this.fieldSettings.enablePrivateAddress ||
        this.formModels.privateAddressActive);
  }

  /**
   * Returns the available data object.
   */
  get dataObject(): SignupData | Person | null {
    return this.signUpData ? this.signUpData : this.person ? this.person : null;
  }

  /**
   * Whether person or signupData is given.
   */
  get changePersonData(): boolean {
    if (this.signUpData) return false;
    else return true;
  }

  /**
   * Field Settings
   */
  fieldSettings: AuthenticatedAreaSettings = FrontendSettings.authenticatedArea;

  /**
   * Whether the details are currently loading or not.
   */
  loading = true;

  /**
   * Whether required person data is missing, for the view
   */
  missingData = false;

  /**
   * Information Text displayed if required person data is missing
   */
  missingDataInfo = "";

  /**
   * Whether the official address fields should be shown or not.
   *
   * This is enabled by default, if private address is disabled.
   */
  officialAddressActive = !this.fieldSettings.enablePrivateAddress;

  /**
   * Whether the private address fields should be shown or not.
   */
  privateAddressActive = true;

  /** Values of the salutation select */
  salutationValues = [
    { text: "Herr", value: "2" },
    { text: "Frau", value: "3" },
    { text: "Diverse", value: "1" },
  ];

  /**
   * Available roles for signup, select deactivates when no roles from backend
   */
  availableRoles: { key: string; value: string }[] = [];

  /**
   * URL for conditions.
   */
  urlConditions = FrontendSettings.links.conditions;

  /**
   * URL for privacy policy.
   */
  urlPrivacyPolicy = FrontendSettings.links.privacyPolicy;

  /**
   * URL for imprint.
   */
  urlImprint = FrontendSettings.links.imprint;

  /**
   * Whether agreeing to the Terms and Conditions is required or not.
   */
  agreementsConditionsRequired =
    FrontendSettings.authenticatedArea.requiredConditions;

  /**
   * Whether agreeing to the Terms and Conditions required or not.
   */
  agreementsPrivacyPolicyRequired =
    FrontendSettings.authenticatedArea.requiredPrivacyPolicy;

  /**
   * Variable to display text-field version of Address/Organization instead of autocomplete from backend
   */
  organizationFieldType: "text" | "autocomplete" =
    FrontendSettings.fieldSettings.auth.organization.type;

  /**
   * Whether password1 should be visible.
   */
  showPassword1 = false;

  /**
   * Whether password2 should be visible.
   */
  showPassword2 = false;

  /**
   * Display function/person_role, currently not needed.
   */
  showRole = false;

  /**
   * Rules for validating form fields
   */
  rules = {
    requiredRules: (value: string): boolean | string => {
      if (!value || value.trim().length <= 0) return "Pflichtfeld";
      else return true;
    },
    requiredRolesRules: (value: string): boolean | string => {
      if (!this.showRole) return true;
      if (this.availableRoles.length <= 0) return true;
      if (!value || value.trim().length <= 0) return "Pflichtfeld";
      else return true;
    },
    requiredTextAddress: (value: string): boolean | string => {
      if (this.organizationFieldType !== "text") return true;
      if (!value || value.trim().length <= 0) return "Pflichtfeld";
      else return true;
    },
    requiredSelectAddress: (value: string): boolean | string => {
      if (this.organizationFieldType !== "autocomplete") return true;
      if (!value) return "Pflichtfeld";
      else return true;
    },
    loginRules: (value: string | null): boolean | string => {
      if (!this.changePersonData && (!value || value.trim().length <= 0)) {
        return "Pflichtfeld";
      }

      if (
        value &&
        this.fieldSettings.profileFieldSettings.login.min_length &&
        value.trim().length <
          this.fieldSettings.profileFieldSettings.login.min_length
      )
        return "Zu wenige Zeichen";
      if (
        value &&
        this.fieldSettings.profileFieldSettings.login.max_length &&
        value.trim().length >
          this.fieldSettings.profileFieldSettings.login.max_length
      )
        return "Zu viele Zeichen";
      return true;
    },
    password: (value: string | null): boolean | string => {
      // A password is only required in signup
      if (!this.changePersonData && (!value || value.trim().length <= 0))
        return "Pflichtfeld";
      if (value === null || value.trim().length <= 0) {
        // We only validate the password, if we got one
        return true;
      }
      if (value.length <= 7) return "Passwort braucht mindestens 8 Zeichen";
      if (!/[!@§$%&?#+*-]+/.test(value))
        return "Passwort muss Sonderzeichen enthalten";
      return true;
    },
    passwordRepeat: (value: string): boolean | string => {
      if (this.dataObject?.password1 && value !== this.dataObject?.password1)
        return "Passwörter stimmen nicht überein";
      return true;
    },
    passwordOld: (value: string | null): boolean | string => {
      if (this.dataObject?.password1 && (!value || value.trim().length <= 0))
        return "Pflichtfeld";
      return true;
    },
    checkboxRules: (value: boolean): boolean | string =>
      value || "Die Zustimmung ist verpflichtend",
    checkboxConditions: (value: boolean): boolean | string => {
      if (
        this.agreementsConditionsRequired &&
        this.urlConditions !== "" &&
        !value
      ) {
        return "Die Zustimmung ist verpflichtend";
      }
      return true;
    },
    checkboxPrivacyPolicy: (value: boolean): boolean | string => {
      if (
        this.agreementsPrivacyPolicyRequired &&
        this.urlPrivacyPolicy !== "" &&
        !value
      ) {
        return "Die Zustimmung ist verpflichtend";
      }
      return true;
    },
    emailRules: (value: string): boolean | string => {
      if (/.+@.+\..+/.test(value)) return true;
      return "E-Mail ungültig";
    },
    addressActiveRequired: (
      privateAddressActive: boolean,
      officialAddressActive: boolean
    ): boolean | string => {
      if (
        !this.fieldSettings.enablePrivateAddress &&
        !this.fieldSettings.enableOfficialAddress
      ) {
        return true;
      }
      if (!privateAddressActive && !officialAddressActive) {
        return false;
      }
      if (
        officialAddressActive &&
        !privateAddressActive &&
        !this.fieldSettings.enableOfficialAddress
      ) {
        return false;
      }
      if (
        privateAddressActive &&
        !officialAddressActive &&
        !this.fieldSettings.enablePrivateAddress
      ) {
        return false;
      }
      return true;
    },
    officialOrganisationRules: (value: string): boolean | string => {
      if (!this.fieldSettings.enableOfficialAddress) return true;
      if (
        (value == "" || !value) &&
        this.fieldSettings.profileFieldSettings.official_address.organisation
          .required
      )
        return "Firma/Organisation ist verpflichtend";
      if (
        this.fieldSettings.profileFieldSettings.official_address.organisation
          .min &&
        value.length <
          this.fieldSettings.profileFieldSettings.official_address.organisation
            .min
      ) {
        return "Zu wenige Zeichen";
      }
      if (
        this.fieldSettings.profileFieldSettings.official_address.organisation
          .max &&
        value.length >
          this.fieldSettings.profileFieldSettings.official_address.organisation
            .max
      ) {
        return "Zu viele Zeichen";
      }
      if (
        value &&
        this.fieldSettings.profileFieldSettings.official_address.organisation
          .regex &&
        value.search(
          this.fieldSettings.profileFieldSettings.official_address.organisation
            .regex
        ) == -1
      )
        return "Firma/Organisation ungültig";
      return true;
    },
    officialPlzRules: (value: string): boolean | string => {
      if (!this.fieldSettings.enableOfficialAddress) return true;
      if (
        (value == "" || !value) &&
        this.fieldSettings.profileFieldSettings.official_address.plz.required
      )
        return "Postleitzahl ist verpflichtend";
      if (
        value &&
        this.fieldSettings.profileFieldSettings.official_address.plz.regex &&
        value.search(
          this.fieldSettings.profileFieldSettings.official_address.plz.regex
        ) == -1
      )
        return "Postleitzahl ungültig";
      return true;
    },
    officialStreetRules: (value: string): boolean | string => {
      if (!this.fieldSettings.enableOfficialAddress) return true;
      if (
        (value == "" || !value) &&
        this.fieldSettings.profileFieldSettings.official_address.street.required
      )
        return "Straße und Hausnummer sind verpflichtend";
      if (
        value &&
        this.fieldSettings.profileFieldSettings.official_address.street.regex &&
        value.search(
          this.fieldSettings.profileFieldSettings.official_address.street.regex
        ) == -1
      )
        return "Straße und Hausnummer ungültig";
      return true;
    },
    officialCommunityRules: (value: string): boolean | string => {
      if (!this.fieldSettings.enableOfficialAddress) return true;
      if (
        (value == "" || !value) &&
        this.fieldSettings.profileFieldSettings.official_address.community
          .required
      )
        return "Ort ist verpflichtend";
      if (
        value &&
        this.fieldSettings.profileFieldSettings.official_address.community
          .regex &&
        value.search(
          this.fieldSettings.profileFieldSettings.official_address.community
            .regex
        ) == -1
      )
        return "Ort ungültig";
      return true;
    },
    officialPhoneRules: (value: string): boolean | string => {
      if (!this.fieldSettings.enableOfficialAddress) return true;
      if (
        (value == "" || !value) &&
        this.fieldSettings.profileFieldSettings.official_address.phone.required
      )
        return "Telefonnummer ist verpflichtend";
      if (
        value &&
        this.fieldSettings.profileFieldSettings.official_address.phone.regex &&
        value.search(
          this.fieldSettings.profileFieldSettings.official_address.phone.regex
        ) == -1
      )
        return "Telefonnummer ungültig";
      return true;
    },
    privatePlzRules: (value: string): boolean | string => {
      if (!this.fieldSettings.enablePrivateAddress) return true;
      if (
        (value == "" || !value) &&
        this.fieldSettings.profileFieldSettings.private_address.plz.required
      )
        return "Postleitzahl ist verpflichtend";
      if (
        value &&
        this.fieldSettings.profileFieldSettings.private_address.plz.regex &&
        value.search(
          this.fieldSettings.profileFieldSettings.private_address.plz.regex
        ) == -1
      )
        return "Postleitzahl ungültig";
      return true;
    },
    privateStreetRules: (value: string): boolean | string => {
      if (!this.fieldSettings.enablePrivateAddress) return true;
      if (
        (value == "" || !value) &&
        this.fieldSettings.profileFieldSettings.private_address.street.required
      )
        return "Straße und Hausnummer ist verpflichtend";
      if (
        value &&
        this.fieldSettings.profileFieldSettings.private_address.street.regex &&
        value.search(
          this.fieldSettings.profileFieldSettings.private_address.street.regex
        ) == -1
      )
        return "Straße und Hausnummer ungültig";
      return true;
    },
    privateCommunityRules: (value: string): boolean | string => {
      if (!this.fieldSettings.enablePrivateAddress) return true;
      if (
        (value == "" || !value) &&
        this.fieldSettings.profileFieldSettings.private_address.community
          .required
      )
        return "Ort ist verpflichtend";
      if (
        value &&
        this.fieldSettings.profileFieldSettings.private_address.community
          .regex &&
        value.search(
          this.fieldSettings.profileFieldSettings.private_address.community
            .regex
        ) == -1
      )
        return "Ort ungültig";
      return true;
    },
    privatePhoneRules: (value: string): boolean | string => {
      if (!this.fieldSettings.enablePrivateAddress) return true;
      if (
        (value == "" || !value) &&
        this.fieldSettings.profileFieldSettings.private_address.phone.required
      )
        return "Telefonnummer ist verpflichtend";
      if (
        value &&
        this.fieldSettings.profileFieldSettings.private_address.phone.regex &&
        value.search(
          this.fieldSettings.profileFieldSettings.private_address.phone.regex
        ) == -1
      )
        return "Telefonnummer ungültig";
      return true;
    },
  };

  mounted(): void {
    if (!this.dataObject) {
      console.error(
        "There is no signupData or personData given to build the person-details."
      );
      return;
    }

    if (localStorage.getItem("dataUpdateNeeded") === "true") {
      this.missingData = true;
    }

    if (this.fieldSettings.missingPersonDataMessage) {
      this.missingDataInfo = this.fieldSettings.missingPersonDataMessage;
    }

    // Check, which address should be active
    if (
      this.dataObject.organization ||
      this.dataObject.plz ||
      this.dataObject.street ||
      this.dataObject.community ||
      this.dataObject.phone ||
      this.dataObject.freeOrganization
    ) {
      this.officialAddressActive = true;
    }

    if (
      !this.dataObject.private_plz &&
      !this.dataObject.private_street &&
      !this.dataObject.private_community &&
      !this.dataObject.private_phone &&
      this.officialAddressActive
    ) {
      // Disable private address since official address is active and we got no data here
      this.privateAddressActive = false;
    }

    const promises: Promise<unknown>[] = [];

    /** Get available person_roles from DB, disables select when no roles from backend*/
    promises.push(
      getStringValueList({
        type: "person_roles",
      }).then((roles) => {
        this.availableRoles = roles;
      })
    );

    Promise.all(promises)
      .catch((e) => {
        FrontendLogger.error({
          message: "Failed to load field data for signup.",
          data: e,
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  /**
   * Getter for the model of "acceptPrivacyPolicy".
   */
  get acceptPrivacyPolicyModel(): boolean {
    if (this.signUpData) return this.signUpData.acceptDSGVO;
    else if (this.person) return this.person.acceptPrivacyPolicy;
    return false;
  }

  /**
   * Setter for the model of "acceptPrivacyPolicy".
   *
   * This setter is NOT unused. It is used as v-model
   */
  set acceptPrivacyPolicyModel(value: boolean) {
    if (this.signUpData) this.signUpData.acceptDSGVO = value;
    else if (this.person) this.person.acceptPrivacyPolicy = value;
  }

  /**
   * Getter for the model of "acceptConditions".
   */
  get acceptConditionsModel(): boolean {
    if (this.signUpData) return this.signUpData.acceptAGB;
    else if (this.person) return this.person.acceptConditions;
    return false;
  }

  /**
   * Setter for the model of "acceptConditions".
   *
   * This setter is NOT unused. It is used as v-model
   */
  set acceptConditionsModel(value: boolean) {
    if (this.signUpData) this.signUpData.acceptAGB = value;
    else if (this.person) this.person.acceptConditions = value;
  }

  /**
   * Returns the label for the official address label.
   */
  get officialAddressLabel(): string {
    return this.fieldSettings.labelOfficialAddress
      ? this.fieldSettings.labelOfficialAddress
      : "Geschäftsadresse";
  }

  /**
   * Returns the string for the official address info or null if not set.
   */
  get OfficialAddressInfo(): string | null {
    return this.fieldSettings.officialAddressInfo
      ? this.fieldSettings.officialAddressInfo
      : null;
  }

  /**
   * Returns the label for the private address label.
   */
  get privateAddressLabel(): string {
    return this.fieldSettings.labelPrivateAddress
      ? this.fieldSettings.labelPrivateAddress
      : "Private Adresse";
  }

  /**
   * Returns the string for the private address info or null if not set.
   */
  get PrivateAddressInfo(): string | null {
    return this.fieldSettings.privateAddressInfo
      ? this.fieldSettings.privateAddressInfo
      : null;
  }

  get officialOrganisationLabel(): string {
    const customLabel =
      this.fieldSettings.profileFieldSettings.official_address.organisation
        .label;
    let label = customLabel ? customLabel : "Firma / Organisation";
    if (
      this.fieldSettings.profileFieldSettings.official_address.organisation
        .required
    )
      label = label + " *";
    return label;
  }
  get officialStreetLabel(): string {
    const customLabel =
      this.fieldSettings.profileFieldSettings.official_address.street.label;
    let label = customLabel ? customLabel : "Straße und Hausnummer";
    if (
      this.fieldSettings.profileFieldSettings.official_address.street.required
    )
      label = label + " *";
    return label;
  }
  get officialPlzLabel(): string {
    const customLabel =
      this.fieldSettings.profileFieldSettings.official_address.plz.label;
    let label = customLabel ? customLabel : "PLZ";
    if (this.fieldSettings.profileFieldSettings.official_address.plz.required)
      label = label + " *";
    return label;
  }
  get officialCommunityLabel(): string {
    const customLabel =
      this.fieldSettings.profileFieldSettings.official_address.community.label;
    let label = customLabel ? customLabel : "Ort";
    if (
      this.fieldSettings.profileFieldSettings.official_address.community
        .required
    )
      label = label + " *";
    return label;
  }
  get officialPhoneLabel(): string {
    const customLabel =
      this.fieldSettings.profileFieldSettings.official_address.phone.label;
    let label = customLabel ? customLabel : "Telefon";
    if (this.fieldSettings.profileFieldSettings.official_address.phone.required)
      label = label + " *";
    return label;
  }
  get privateStreetLabel(): string {
    const customLabel =
      this.fieldSettings.profileFieldSettings.private_address.street.label;
    let label = customLabel ? customLabel : "Straße und Hausnummer";
    if (this.fieldSettings.profileFieldSettings.private_address.street.required)
      label = label + " *";
    return label;
  }
  get privatePlzLabel(): string {
    const customLabel =
      this.fieldSettings.profileFieldSettings.private_address.plz.label;
    let label = customLabel ? customLabel : "PLZ";
    if (this.fieldSettings.profileFieldSettings.private_address.plz.required)
      label = label + " *";
    return label;
  }
  get privateCommunityLabel(): string {
    const customLabel =
      this.fieldSettings.profileFieldSettings.private_address.community.label;
    let label = customLabel ? customLabel : "Ort";
    if (
      this.fieldSettings.profileFieldSettings.private_address.community.required
    )
      label = label + " *";
    return label;
  }
  get privatePhoneLabel(): string {
    const customLabel =
      this.fieldSettings.profileFieldSettings.private_address.phone.label;
    let label = customLabel ? customLabel : "Telefon";
    if (this.fieldSettings.profileFieldSettings.private_address.phone.required)
      label = label + " *";
    return label;
  }
}
