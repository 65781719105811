import DataStruct from "@/shared/data/datastruct";
import FrontendSettings from "@/frontend/settings/settings";

/**
 * The data structure defined by the MSUevent-Server.
 */
export type ServerMediaDataType = {
  type: MediaType;
  mime_type: string;
};

export type ServerMediaImageDataType = ServerMediaDataType & {
  original: ServerMediaImageViewDataType;
  small: ServerMediaImageViewDataType | null;
};

export type ServerMediaImageViewDataType = {
  path: string;
  height: number;
  width: number;
};

export type ServerRecordingMediaDataType = ServerMediaDataType & {
  media_id: number;
};

export type MediaType = "image";

/**
 * The Media Data-Class containing all properties for an Media.
 */
export default abstract class Media extends DataStruct {
  /**
   * The {@link MediaType} of this {@link Media}.
   */
  type: MediaType;

  /**
   * The MIME-Type of this media.
   */
  mime_type: string | null = null;

  /**
   * Creates a new instance of Media with given data.
   *
   * @param type The type of this media
   * @param data The data to load
   * @throws TypeError if any property does not fit the type
   */
  protected constructor(type: MediaType, data: ServerMediaDataType) {
    super();

    this.type = type;

    if (DataStruct.validateValue(data, "mime_type", "string")) {
      this.mime_type = data.mime_type;
    } else {
      // We cannot initialize without an id
      throw new TypeError("Could not initialize Media. Invalid media-type.");
    }
  }

  /**
   * Creates a new Media-object with the given data returned from the server.
   * The returned Media-object will be a subclass of {@link Media} according to set {@link data.type}.
   *
   * @param data
   *
   * @throws TypeError if any property does not fit the type or the media-type is not supported.
   */
  static fromServer(data: ServerMediaDataType): Media {
    // Check, which type of media we got
    switch (data.type) {
      case "image":
        return new MediaImage(data as ServerMediaImageDataType);

      default:
        throw new TypeError(
          "Could not create Media. Unsupported media-type '" + data.type + "'."
        );
    }
  }
}

/**
 * =========================
 * The available Media-Types
 * =========================
 */

/**
 * The EventImage Data-Class containing all properties for an EventImage.
 */
export class MediaImage extends Media {
  /**
   * The {@link MediaImageView} of the original resolution.
   */
  original: MediaImageView;

  /**
   * The {@link MediaImageView} of a smaller, compressed resolution.
   */
  small: MediaImageView | null = null;

  /**
   * Creates a new instance of EventImage with given data.
   *
   * @param data The data to load
   * @throws TypeError if any property does not fit the type
   */
  constructor(data: ServerMediaImageDataType) {
    super("image", data);

    // Load the data
    this.original = new MediaImageView(data.original);
    if (data.small) this.small = new MediaImageView(data.small);
  }

  /**
   * Returns the image url of this {@link EventImage} or undefined, if none is available.
   *
   * @param {boolean} small if true, the small, compressed image will be used. If not available, the original image is always the fallback.
   */
  getImageUrl(small = false): string | undefined {
    const path =
      small && this.small?.path ? this.small.path : this.original.path;
    if (FrontendSettings.host !== "/") return FrontendSettings.host + path;
    return path;
  }
}

export class MediaImageView extends DataStruct {
  /**
   * The path of this {@link MediaImageView}.
   */
  path: string;

  /**
   * The height in pixels of this {@link MediaImageView}.
   */
  height: number;

  /**
   * The width in pixels of this {@link MediaImageView}.
   */
  width: number;

  /**
   * Creates a new instance of EventImage with given data.
   *
   * @param data The data to load
   * @throws TypeError if any property does not fit the type
   */
  constructor(data: ServerMediaImageViewDataType) {
    super();

    // Load the data
    if (DataStruct.validateValue(data, "path", "string")) this.path = data.path;
    else
      throw new TypeError("Could not initialize EventImageView. Invalid path.");

    if (DataStruct.validateValue(data, "height", "number") && data.height > 0)
      this.height = data.height;
    else
      throw new TypeError(
        "Could not initialize EventImageView. Invalid height."
      );

    if (DataStruct.validateValue(data, "width", "number") && data.width > 0)
      this.width = data.width;
    else
      throw new TypeError(
        "Could not initialize EventImageView. Invalid width."
      );
  }

  /**
   * Returns the aspect ratio of this {@link MediaImageView} according to height and width.
   * The aspect ratio of an image is width / height.
   */
  get aspectRatio(): number {
    return this.width / this.height;
  }
}

/**
 * The media of an {@link RecordingEvent}.
 */
export class RecordingMedia extends Media {
  readonly media_id: number;

  /**
   * Whether this {@link Media} should be deleted for the {@link RecordingEvent} or not.
   *
   * @private
   */
  private delete = false;

  /**
   * Creates a new instance of RecordingMedia with given data.
   *
   * @param type The type of this media
   * @param data The data to load
   * @throws TypeError if any property does not fit the type
   */
  protected constructor(type: MediaType, data: ServerRecordingMediaDataType) {
    super(type, data);

    this.type = data.type;

    if (DataStruct.validateValue(data, "media_id", "number")) {
      this.media_id = <number>data.media_id;
    } else {
      // We cannot initialize without an id
      throw new TypeError(
        "Could not initialize RecordingMedia. Invalid media_id."
      );
    }
  }

  /**
   * Creates a new RecordingMedia-object with the given data returned from the server.
   *
   * @param data
   *
   * @throws TypeError if any property does not fit the type or the media-type is not supported.
   */
  static fromServer(data: ServerRecordingMediaDataType): RecordingMedia {
    // Check type
    let type: MediaType;
    if (DataStruct.validateValue(data, "type", "string")) {
      type = data.type;
    } else {
      // We cannot initialize without an id
      throw new TypeError("Could not initialize RecordingMedia. Invalid type.");
    }

    const recordingMedia = new RecordingMedia(type, data);

    if (DataStruct.validateValue(data, "mime_type", "string")) {
      recordingMedia.mime_type = data.mime_type;
    } else {
      // We cannot initialize without an id
      throw new TypeError(
        "Could not initialize RecordingMedia. Invalid media-type."
      );
    }

    return recordingMedia;
  }

  /**
   * Marks this {@link Media} as "to delete" for the next save of the {@link RecordingEvent}.
   */
  public deleteMedia(): void {
    this.delete = true;
  }

  /**
   * Returns, if this {@link Media} is set as "to delete"
   */
  public isToDelete(): boolean {
    return this.delete;
  }
}
