import DataStruct from "@/shared/data/datastruct";
import Community, { ServerCommunityDataType } from "@/shared/data/community";

/**
 * The Address Data-Class containing all properties for an Address.
 */
export default class Address extends DataStruct {
  readonly address_id: number;
  main_address: Address | null = null;
  name = "";
  extended_name?: string;
  zipcode?: string;
  street?: string;
  phone?: string;
  fax?: string;
  mobile?: string;
  email?: string;
  url?: string;
  community: Community;
  district?: Community;

  /**
   * Creates a new instance of Address with given data.
   *
   * @param data The data to load
   * @throws TypeError if any property does not fit the type
   */
  constructor(data: { address_id: number; community: Community }) {
    super();

    if (DataStruct.validateValue(data, "address_id", "number")) {
      this.address_id = data.address_id;
    } else {
      // We cannot initialize without an id
      throw new TypeError("Could not initialize Address. Invalid address_id.");
    }

    if (DataStruct.validateValue(data, "community", "object")) {
      this.community = data.community;
    } else {
      throw new TypeError(
        "Could not initialize Address. Missing or invalid community."
      );
    }
  }

  /**
   * Tries to get the link from set url and returns it.
   * This will make sure, that the url starts with https:// or http://
   * If no url is set or the set url does not match a regular url expression, an empty string is returned.
   *
   * Example:
   * www.msu.biz/events => https://www.msu.biz/events
   */
  public get urlLink(): string {
    if (this.url && this.url > "") {
      const regex = new RegExp(/^(https:\/\/|http:\/\/)/gm);

      if (!regex.test(this.url)) {
        // Add https://
        return "https://" + this.url;
      }

      return this.url;
    }

    return "";
  }

  /**
   * Tries to get the Domain from set url and returns it.
   * If no url is set or the set url does not match a regular url expression, the original url is returned.
   *
   * Example:
   * https://www.msu.biz/events => msu.biz
   */
  public get urlDomain(): string {
    if (this.url && this.url > "") {
      const regex = new RegExp(
        /(?:www.)?([-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b)([-a-zA-Z0-9()@:%_+.~#?&/=]*)/gm
      );

      const matches = regex.exec(this.url);

      if (matches !== null) {
        return matches[1];
      }
    }

    return "";
  }

  /**
   * Returns a Label of this address to use in an autocomplete.
   *
   * Label looks like:
   * Main Address Name (Extended Main Address Name) ( - department Address Name (Extended Address Name)) - Street Number, Zipcode Community
   *
   * Example:
   * Stadthalle (am Schloss) ( - Großer Saal (links)) - Hauptstraße 1, 10115 Berlin
   */
  get searchLabel(): string {
    let label = "";

    // Get Address Name
    if (this.main_address && this.main_address.name) {
      // We got a main Address => Use this name first
      label += this.main_address.name;
      if (
        this.main_address.extended_name &&
        this.main_address.extended_name > ""
      )
        label += " " + this.main_address.extended_name;
      label += " - ";
    } // else this is the main Address

    label += this.name;
    if (this.extended_name) label += " " + this.extended_name;

    // Add street + number
    label += " - ";
    if (this.street && this.street > "") label += this.street + " ";
    if ((this.zipcode && this.zipcode > "") || this.community.name_path > "") {
      if (this.street && this.street > "") label += ", ";
      if (this.zipcode && this.zipcode > "") label += this.zipcode;
      if (this.community.name_path && this.community.name_path > "")
        label += " " + this.community.name_path;
      if (this.district?.name && this.district?.name > "")
        label += " - " + this.district.name;
    }

    return label;
  }

  /**
   * Creates a new Address-object with the given data returned from the server.
   *
   * @param data
   * @throws TypeError if any property does not fit the type
   */
  static fromServer(data: ServerAddressDataType): Address {
    const address = new Address({
      address_id: data.address_id,
      community: Community.fromServer(data.community),
    });

    // Load the data
    if (DataStruct.validateValue(data, "name", "string"))
      address.name = data.name;
    if (DataStruct.validateValue(data, "main_address", "object")) {
      address.main_address = Address.fromServer(
        <ServerAddressDataType>data.main_address
      );
    }
    if (DataStruct.validateValue(data, "district", "object")) {
      address.district = Community.fromServer(
        <ServerCommunityDataType>data.district
      );
    }
    if (DataStruct.validateValue(data, "extended_name", "string"))
      address.extended_name = data.extended_name;
    if (DataStruct.validateValue(data, "zipcode", "string"))
      address.zipcode = data.zipcode;
    if (DataStruct.validateValue(data, "street", "string"))
      address.street = data.street;
    if (DataStruct.validateValue(data, "phone", "string"))
      address.phone = data.phone;
    if (DataStruct.validateValue(data, "fax", "string")) address.fax = data.fax;
    if (DataStruct.validateValue(data, "mobile", "string"))
      address.mobile = data.mobile;
    if (DataStruct.validateValue(data, "email", "string"))
      address.email = data.email;
    if (DataStruct.validateValue(data, "url", "string")) address.url = data.url;

    return address;
  }
}

/**
 * The data structure as returned by the MSUevent-Server.
 */
export type ServerAddressDataType = {
  address_id: number;
  main_address: ServerAddressDataType | null;
  name: string;
  location: boolean;
  promoter: boolean;
  ticket_agency: boolean;
  extended_name?: string;
  zipcode?: string;
  street?: string;
  phone?: string;
  fax?: string;
  mobile?: string;
  email?: string;
  url?: string;
  community: ServerCommunityDataType;
  district?: ServerCommunityDataType;
};
