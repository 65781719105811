
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { AdvertChannel } from "@/shared/data/advert";

@Component
export default class QuokkaAdsRecordingEventSelection extends Vue {
  @Prop({ required: true }) selectedChannel: AdvertChannel | undefined;

  @Emit("event-selection")
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  eventSelection(type: string): void {
    return;
  }
}
