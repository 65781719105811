
import { Component, Prop, Vue } from "vue-property-decorator";
import { getEvent } from "@/frontend/lib/api";
import Event from "@/shared/data/event";
import EventDetailOverview from "@/components/quokka_layout/QuokkaEventDetailOverview.vue";
import QuokkaAddressOverview from "@/components/quokka_layout/QuokkaAddressOverview.vue";
import QuokkaEventDetailCalendar from "@/components/quokka_layout/event-detail/QuokkaEventDetailCalendar.vue";
import RouteHelper from "@/frontend/lib/route_helper";
import EventLink, { EventLinkUsage } from "@/shared/data/event_link";
import FrontendSettings from "@/frontend/settings/settings";

@Component({
  components: {
    QuokkaEventDetailCalendar,
    QuokkaAddressOverview,
    EventDetailOverview,
  },
})
export default class QuokkaEventDetailsView extends Vue {
  @Prop({ required: true }) eventId!: number;
  private event?: Event | null = null;

  imageSlider = 0;

  mounted(): void {
    if (this.eventId <= 0) {
      // Return to the list
      this.$router.replace({ name: "events" });
      return;
    }

    getEvent(this.eventId)
      .then((event: Event) => {
        this.event = event;
        // Add the event title to the page title
        document.title = document.title + " - " + this.event.text.title;

        // Set the title to the url, if it doesn't match
        if (
          RouteHelper.getParam<string>(this.$route, "title") !==
          this.event.urlTitle
        ) {
          this.$router.replace({
            name: "event-details",
            params: {
              event_id: this.event.event_id.toString(),
              title: this.event.urlTitle,
            },
          });
        }
      })
      .catch(() => {
        // Event not found or an error occurred.
        this.$router.replace({ name: "404" });
      });
  }

  /**
   * Returns the rubric path of this {@link Rubric}.
   * Replaces the '|' with ' - '.
   */
  get rubricPath(): string {
    const rubricPath =
      this.event?.rubric?.name_path ?? this.event?.rubric?.name ?? "";

    return rubricPath.replaceAll("|", " - ");
  }

  /**
   * Returns a list of all available {@link EventLink}s to display.
   */
  get eventLinks(): {
    label: string;
    links: EventLink[];
  }[] {
    const availableLinksByUsage: { [key in EventLinkUsage]: EventLink[] } = {
      ticket: [],
      promoter: [],
      location: [],
      event: [],
      artist: [],
    };

    this.event?.links.forEach((link) => {
      availableLinksByUsage[link.usage].push(link);
    });

    const availableLinks: {
      label: string;
      links: EventLink[];
    }[] = [];

    (Object.keys(availableLinksByUsage) as EventLinkUsage[]).forEach(
      (usage: EventLinkUsage) => {
        if (availableLinksByUsage[usage].length > 0) {
          availableLinks.push({
            label: EventLink.getUsageLabel(usage),
            links: availableLinksByUsage[usage],
          });
        }
      }
    );

    return availableLinks;
  }

  /**
   * Whether to display the `shortdesc1` or not.
   */
  get displayShortdesc1(): boolean {
    return FrontendSettings.eventDetails.shortdesc1.visible;
  }

  /**
   * Whether to display the `shortdesc2` or not.
   */
  get displayShortdesc2(): boolean {
    return FrontendSettings.eventDetails.shortdesc2.visible;
  }

  /**
   * Whether to display the `shortdesc3` or not.
   */
  get displayShortdesc3(): boolean {
    return FrontendSettings.eventDetails.shortdesc3.visible;
  }

  /**
   * Whether to display the `longdesc1` or not.
   */
  get displayLongdesc1(): boolean {
    return FrontendSettings.eventDetails.longdesc1.visible;
  }

  /**
   * Whether to display the `longdesc2` or not.
   */
  get displayLongdesc2(): boolean {
    return FrontendSettings.eventDetails.longdesc2.visible;
  }

  /**
   * Whether to display the `longdesc3` or not.
   */
  get displayLongdesc3(): boolean {
    return FrontendSettings.eventDetails.longdesc3.visible;
  }
}
