
import { Component, Prop, Vue } from "vue-property-decorator";
import Address from "@/shared/data/address";

@Component
export default class QuokkaAddressOverview extends Vue {
  @Prop({ required: true }) address!: Address;

  get phoneLink(): string {
    return "tel:" + this.address.phone;
  }
}
