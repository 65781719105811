
import { Component, Emit, Vue } from "vue-property-decorator";
import QuokkaEventsListAdminView from "@/views/layouts/quokka/admin/QuokkaEventsListAdminView.vue";
import QuokkaEventListAdminMyEventList from "@/components/quokka_layout/admin/QuokkaEventListAdminMyEventList.vue";
import RecordingEvent from "@/shared/data/recording_event";

/**
 * TODO: Doc
 */
@Component({
  components: { QuokkaEventListAdminMyEventList, QuokkaEventsListAdminView },
})
export default class QuokkaAdsRecordingExistingEvent extends Vue {
  /**
   * TODO: Docs
   * @param event
   */
  @Emit("event-select")
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onEventSelect(event: RecordingEvent): void {
    return;
  }
}
