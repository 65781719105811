
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import { getRubrics } from "@/frontend/lib/api";
import Rubric from "@/shared/data/rubric";
import FrontendLogger from "@/frontend/lib/logger";

@Component
export default class QuokkaEventRubricSearch extends Vue {
  /**
   * The v-model of this autocomplete.
   */
  @VModel({ required: true }) rubric!: Rubric | null;

  /**
   * The type of the Rubric-tree. Will be used, if the data needs to be fetched from the server.
   */
  @Prop({ required: true }) tree!: "child" | "master";

  /**
   * The rules to apply to this field.
   */
  @Prop() rules!: ((value: string) => boolean | string)[];

  /**
   * Whether this field is disabled or not.
   */
  @Prop() disabled!: boolean;

  /**
   * A label to use for the field.
   * If not set, a default label will be used.
   */
  @Prop() label!: string;

  /**
   * The label for the field.
   */
  get fieldLabel(): string {
    if (this.label > "") return this.label;
    return "Rubrik";
  }

  /**
   * If given, this list of {@link Rubric}s will be used instead of loading it.
   */
  @Prop() initAvailableRubrics!: Rubric[] | null;

  /**
   * The available list of Rubrics to use.
   */
  availableRubrics: Rubric[] = [];

  /**
   * Whether the selected Rubric from the v-model is contained in availableRubrics.
   */
  selectedRubricIsContained = false;

  /**
   * The current search query.
   */
  rubricSearchQuery: string | null = null;

  /**
   * Whether the {@link Rubric}s are being loaded or not.
   */
  rubricLoading = false;

  /**
   * An error message of this field
   */
  errorMessage = "";

  /**
   * Checks, if we got {@link initAvailableRubrics} or not.
   * If not, the {@link tree} will be used to fetch {@link Rubric}s from server.
   */
  mounted(): void {
    if (this.initAvailableRubrics) {
      this.availableRubrics = this.initAvailableRubrics;
    } else {
      this.rubricLoading = true;

      // Load all rubrics
      getRubrics({ tree: this.tree })
        .then((rubrics) => {
          this.availableRubrics = rubrics;
        })
        .catch((e) => {
          FrontendLogger.error({
            message: "Failed to get Rubrics for the Rubric-Search field.",
            scope: "api",
            data: e,
          });
          this.errorMessage = "Ein Fehler ist aufgetreten.";
        })
        .finally(() => {
          this.rubricLoading = false;
        });
    }
  }

  /**
   * Hide the selected item, if only one rubric is available and this is the selected one.
   */
  get hideSelected(): boolean {
    return !(
      (this.availableRubrics.length === 1 &&
        this.rubric &&
        this.availableRubrics[0].rubric_id === this.rubric.rubric_id &&
        this.rubric.searchLabel
          .toLocaleLowerCase()
          .includes(this.rubricSearchQueryString.toLocaleLowerCase())) ||
      this.selectedRubricIsContained
    );
  }

  get rubricSearchQueryString(): string {
    return this.rubricSearchQuery ?? "";
  }
}
