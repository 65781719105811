
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import {
  FilepondFile,
  FilepondServerConfiguration,
} from "@/frontend/lib/filepond";
import vueFilePond from "vue-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import FilePond plugins
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FrontendSettings from "@/frontend/settings/settings";
import Authorization from "@/frontend/lib/auth";
import { RecordingMedia } from "@/shared/data/media";
import FrontendLogger from "@/frontend/lib/logger";
import { MediaUploaderData } from "@/shared/lib/media_uploader";
import { FilePond } from "filepond";

// Create component
vueFilePond(
  FilePondPluginFileValidateType,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  FilePondPluginImagePreview
);

/**
 * Possible error codes received from the MSUeventv5-server when trying to validate uploaded media.
 */
enum MediaValidationErrorCode {
  /** The image width resolution is too low. */
  RESOLUTION_PX_MIN_WIDTH = 10,
  /** The image width resolution is too high. */
  RESOLUTION_PX_MAX_WIDTH = 11,
  /** The image height resolution is too low. */
  RESOLUTION_PX_MIN_HEIGHT = 12,
  /** The image height resolution is too high. */
  RESOLUTION_PX_MAX_HEIGHT = 13,
  /** The image dpi resolution is too low. */
  RESOLUTION_MIN_DPI = 20,
  /** The image dpi resolution is too high. */
  RESOLUTION_MAX_DPI = 21,
  /** The image mime type is not allowed */
  NOT_ALLOWED_MIME_TYPE = 30,
  /** The uploaded file is too large */
  FILE_TOO_SMALL = 40,
  /** The uploaded file is too small */
  FILE_TOO_BIG = 41,
}

/**
 * Media validation upload error.
 */
type MediaUploadValidationErrorType = {
  /** The error message */
  error: string | null;
  /** The error code*/
  error_code: MediaValidationErrorCode | null;
};

/**
 * Filepound media upload error.
 */
type FilePondErrorType = {
  /** Contains the validation errors. */
  body: MediaUploadValidationErrorType;
  /** Contains the error code that is relevant for the user. */
  code: number;
  /** Contains the headers from error. */
  headers: string;
  /** Type of error. */
  type: string;
};

/**
 * Component for Media upload.
 * Wrapper for the FilePond lib.
 *
 * Allows the user to upload files and view uploaded files.
 *
 * The MediaUploader requires any object containing the properties of {@ink MediaUploaderData} to work.
 * This object can contain any other objects, too.
 *
 *  TODO: For embedded application, the files do not get the name.
 */
@Component
export default class MediaUploader extends Vue {
  /**
   * The data object containing data required for the uploader to work.
   *
   * Any object can be used for this as long as it contains at least the properties of {@ink MediaUploaderData}.
   */
  @Prop({ required: true }) mediaUploaderData!: MediaUploaderData;

  /**
   * Which type of media the {@link MediaUploader} is uploading.
   */
  @Prop({ required: true }) type!: "event" | "article" | "ads";

  /**
   * Whether the fields of this tab should be disabled or not.
   */
  @Prop() disabled!: boolean;

  /**
   * The maximum number of files to upload.
   *
   * Defaults to 10.
   */
  @Prop({ default: 10 }) maxFiles!: number;

  /**
   * Available rules for the fields to verify the value.
   */
  rules: { [key: string]: (value: string) => boolean | string } = {
    filepond: (value: string): boolean | string => {
      return value <= "";
    },
  };

  /**
   * The files currently added in filepond.
   */
  files: { id: string | undefined; serverId: string | number | undefined }[] =
    [];

  /**
   * Contains the file-ids with its corresponding upload-key when uploaded.
   */
  mediaKeys: { [key: string]: string } = {};

  /**
   * Show the warning snackbar or not.
   */
  showWarningSnackbar = false;

  /**
   * A warning message to show in snackbar.
   */
  warningSnackbarMessage = "";

  /**
   * Edits options of the filepond instance.
   */
  mounted(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const pond: { _pond: FilePond } = this.$refs["filepond"];

    if (pond && pond._pond) {
      const instance: FilePond = pond._pond;

      instance.setOptions({
        /**
         * Check, if we got a custom error message, then we set it for this uploaded media.
         *
         * This function has be set via the setOptions because the filepond-vue-adaption seems to have wrong type
         * definitions for this.
         *
         * @param error
         */
        labelFileProcessingError: (error: FilePondErrorType): string => {
          return error.body.error ?? "Fehler beim Hochladen";
        },
      });
    }
  }

  /**
   * Returns the url of the api-endpoint for media.
   */
  get serverConfiguration(): FilepondServerConfiguration {
    const config: FilepondServerConfiguration = {
      url: FrontendSettings.host + "api/v1/media",
    };

    config.process = {
      ondata: (formData: FormData) => {
        formData.append("type", this.type);
        return formData;
      },
      /**
       * If an error occurs, we process and return it.
       * The data will then be available in the error-property in `body`.
       *
       * @param error
       */
      onerror: (error: string): MediaUploadValidationErrorType => {
        if (
          /^[\],:{}\s]*$/.test(
            error
              .replace(/\\["\\/bfnrtu]/g, "@")
              .replace(
                /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/g,
                "]"
              )
              .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
          )
        ) {
          const errorJson = JSON.parse(error);

          FrontendLogger.error({
            scope: "api",
            message: errorJson.errorMessage,
            data: errorJson,
          });

          //console.log(errorJson);

          return errorJson;
        } else {
          const errorJson = {
            error: null,
            error_code: null,
          };

          //console.log("errorJson", errorJson);

          //the json is not ok
          FrontendLogger.error({
            scope: "api",
            message: "invalid json error object",
            data: error,
          });

          return errorJson;
        }
      },
    };

    // Get Authorization token, because we cannot retrieve media without a logged in person.
    try {
      const token = Authorization.getValidatedToken();
      config.load = {
        headers: {
          token: token,
        },
      };
    } catch (e) {
      FrontendLogger.error({
        scope: "ui",
        message: "Failed to get authorization token to request media.",
        data: e,
      });
    }

    return config;
  }

  /**
   * List of uploaded media files to show in the filepond component.
   */
  get availableFiles(): FilepondFile[] {
    const availableFiles: FilepondFile[] = [];

    if (this.mediaUploaderData)
      this.mediaUploaderData.uploaded_media.forEach(
        (eventMedia: RecordingMedia) => {
          availableFiles.push({
            source: eventMedia.media_id.toString(),
            options: {
              type: "local",
            },
          });
        }
      );

    return availableFiles;
  }

  /**
   * This watcher watches the files from filepond and updates {@link recordingEvent.fileUploadReady} accordingly.
   *
   * {@link recordingEvent.fileUploadReady} is true, if no available `file.id` is missing in {@link mediaKeys}.
   */
  @Watch("files", { deep: true })
  onFilesChange(): void {
    if (!this.mediaUploaderData) return;
    for (const file of this.files) {
      if (!file.id || file.id.trim() === "") {
        // This file has no id, this should never happen
        continue;
      }

      // Check, if this file is already an uploaded media
      if (
        !file.serverId ||
        !parseInt(file.serverId.toString()) ||
        !this.mediaUploaderData.uploaded_media.find(
          (obj) => obj.media_id === parseInt(file.serverId?.toString() ?? "")
        )
      ) {
        // This file is not an uploaded media

        // Check, if this file-id is newly uploaded
        if (!this.mediaKeys[file.id]) {
          // This id is not uploaded yet
          this.mediaUploaderData.fileUploadReady = false;
          return;
        }
      }
    }
    this.mediaUploaderData.fileUploadReady = true;
  }

  /**
   * Event triggered when a file has been uploaded.
   *
   * @param error set if the server responded with an error
   * @param error.code the http-code
   * @param error.type type of the error
   * @param error.body the message of the error-code
   * @param uploadInfo info about the file-upload
   * @param uploadInfo.serverId contains the stringified json response from the server (exists on error, too)
   */
  onFileProcessed(
    error: { code: number; type: string; body: string },
    uploadInfo: { serverId: string; id: string }
  ): void {
    // failed to add
    if (error) return;

    // Get the response json
    const response = JSON.parse(uploadInfo.serverId);
    if (response && response.data && response.data.upload_key) {
      // We got an upload-key => Add it
      this.mediaUploaderData.upload_media.push(response.data.upload_key);
      this.mediaKeys[uploadInfo.id] = response.data.upload_key;
      this.onFileUploaded();
    } else {
      // We did not get an upload-key => We remove the image, because we don't know what happened here...
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.$refs.filepond?.removeFile(uploadInfo.id);
    }

    this.showWarningSnackbar = false;
    this.warningSnackbarMessage = "";
  }

  /**
   * Event triggered when an uploaded file is being deleted.
   *
   * @param error set if the server responded with an error
   * @param error.code the http-code
   * @param error.type type of the error
   * @param error.body the message of the error-code
   * @param uploadInfo info about the file-upload
   * @param uploadInfo.serverId contains the stringified json response from the server (exists on error, too)
   *                            or the media-id for an already uploaded file
   */
  onFileRemove(
    error: { code: number; type: string; body: string },
    uploadInfo: { serverId: string | number; id: string }
  ): void {
    // failed to remove
    if (error) return;
    let countUploadMedia = this.mediaUploaderData.upload_media.length;

    if (this.mediaKeys[uploadInfo.id]) {
      // Remove the upload_key from object
      const index = this.mediaUploaderData.upload_media.indexOf(
        this.mediaKeys[uploadInfo.id]
      );
      this.mediaUploaderData.upload_media.splice(index, 1);
      delete this.mediaKeys[uploadInfo.id];
    }

    // Check, if this file was an already uploaded file => Set as deleted in RecordingEvent
    if (
      uploadInfo &&
      uploadInfo.serverId &&
      parseInt(uploadInfo.serverId.toString())
    ) {
      this.mediaUploaderData.uploaded_media.forEach((media: RecordingMedia) => {
        if (media.media_id === parseInt(uploadInfo.serverId.toString())) {
          media.deleteMedia();
        }
      });
    }

    this.showWarningSnackbar = false;
    this.warningSnackbarMessage = "";
    if (
      this.mediaUploaderData.upload_media.length == countUploadMedia &&
      this.mediaUploaderData.uploaded_media.length >
        this.mediaUploaderData.countRemovedUploads
    ) {
      this.mediaUploaderData.countRemovedUploads =
        this.mediaUploaderData.countRemovedUploads + 1;
    }

    this.onFileRemoved();
  }

  /**
   * Event called when filepond throws a warning.
   *
   * @param error Information about the warning
   * @param file List of files that were uploaded
   */
  onWarning(
    error: { code: number; body: string | "Max files" },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    file: FilepondFile[] | undefined
  ): void {
    if (error.body === "Max files") {
      this.showWarningSnackbar = true;
      this.warningSnackbarMessage =
        "Es können maximal " + this.maxFiles + " hochgeladen werden.";
    } else {
      this.showWarningSnackbar = true;
      this.warningSnackbarMessage = "Ein unbekannter Fehler ist aufgetreten.";
    }
  }

  @Emit("file-uploaded")
  onFileUploaded(): void {
    return;
  }

  @Emit("file-removed")
  onFileRemoved(): void {
    return;
  }
}
