
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { AdvertCampaign, AdvertMedium } from "@/shared/data/advert";
import { sanitizeContent } from "@/shared/lib/dompurify";

@Component({
  data() {
    return { sanitizeContent };
  },
})
export default class QuokkaAdsRecordingMediumSelectionView extends Vue {
  @Prop({ required: true }) campaigns!: AdvertCampaign[];

  /**
   * Returns all available AdsMediums of all AdsCampaigns.
   */
  get availableMediums(): AdvertMedium[] {
    const mediums: AdvertMedium[] = [];

    this.campaigns.forEach((campaign) => {
      campaign.advert_channels.forEach((channel) => {
        const medium = channel.advert_medium;
        if (medium) {
          if (
            mediums.findIndex(
              (findInMediums) =>
                findInMediums.advert_medium_id === medium.advert_medium_id
            ) === -1
          ) {
            mediums.push(medium);
          }
        }
      });
    });

    return mediums;
  }

  @Emit("medium-select")
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  selectMedium(medium: AdvertMedium): void {
    return;
  }
}
