
import { Component, Vue, Watch } from "vue-property-decorator";
import AlertCard from "@/components/quokka_layout/ui/AlertCard.vue";
import RecordingArticle from "@/shared/data/recording_article";
import QuokkaArticleListAdminMyArticleList from "@/components/quokka_layout/admin/QuokkaArticleListAdminMyArticleList.vue";
import {
  getRecordingArticles,
  GetRecordingArticlesReturnType,
} from "@/frontend/lib/api";
import QuokkaArticleListFilterContent from "@/views/layouts/quokka/admin/my-article/QuokkaArticleListFilterContent.vue";
import EventListFilter from "@/shared/lib/event-list-filter";
import FrontendSettings from "@/frontend/settings/settings";

/**
 * A view to show all recorded {@link RecordingEvent}s of the logged-in user.
 */
@Component({
  components: {
    AlertCard,
    QuokkaArticleListAdminMyArticleList,
    QuokkaArticleListFilterContent,
  },
})
export default class QuokkaArticlesListAdminView extends Vue {
  /**
   * List of available {@link RecordingArticle}s of the logged-in user for the page in url.
   */
  availableRecordingArticles: RecordingArticle[] | null = null;

  loading = true;

  showFilterDrawer = false;
  pageNumber = 1;
  totalPageNumber = 1;
  /**
   * The currently used {@link EventListFilter}.
   * Is set from and to the Vue instance for persistence when changing routes.
   */
  filter: EventListFilter;

  /**
   * A copy of {@link filter} used in the filter-popup.
   */
  changingFilter: EventListFilter = new EventListFilter();

  /**
   * The total amount of article.
   */
  totalFilterArticleAmountPreview = 0;

  /** Title 'record article' */
  titleRecording = FrontendSettings.articleRecording.titleRecordingArticles;

  /**
   * The key of the last called Promise that fetches the total amount of events to preview.
   *
   * The total amount will only be set, if the key is the active key to prevent wrong values.
   */
  totalFilterArticleAmountPreviewLoading = false;

  updateFilterArticleCountPreviewTimeout?: number;

  /**
   * This constructor sets the {@link filter} from the Vue instance, if available or creates a new one if not.
   */
  constructor() {
    super();
    this.filter = Vue.prototype.$eventFilter ?? new EventListFilter();
  }

  /**
   * Tries to load available {@link RecordingArticle}s.
   */
  mounted(): void {
    this.loadArticleList();
  }

  /**
   * Loads the list of article.
   */
  loadArticleList(): void {
    this.loading = true;

    getRecordingArticles({
      page: this.pageNumber,
      query: this.filter.searchQuery ?? undefined,
      start_date: this.filter.dateFrom ?? undefined,
      end_date: this.filter.dateUntil ?? undefined,
      communities: this.filter.locationCommunity
        ? [this.filter.locationCommunity]
        : undefined,
      rubric: this.filter.rubric ?? undefined,
      addressLocation: this.filter.addressLocation ?? undefined,
      addressPromoter: this.filter.addressPromoter ?? undefined,
    })
      .then((data: GetRecordingArticlesReturnType) => {
        this.totalFilterArticleAmountPreview = data.resultsCountOverall;
        this.availableRecordingArticles = data.recordingArticles;
        this.totalPageNumber = data.totalPageNumber;
        this.loading = false;
      })
      .catch(() => {
        // TODO: Fehler
        this.loading = false;
      });
  }

  /**
   * Loads the match count for the preview in the filter.
   */
  loadArticleListPreview(): void {
    getRecordingArticles({
      page: this.pageNumber,
      query: this.changingFilter.searchQuery ?? undefined,
      start_date: this.changingFilter.dateFrom ?? undefined,
      end_date: this.changingFilter.dateUntil ?? undefined,
      communities: this.changingFilter.locationCommunity
        ? [this.changingFilter.locationCommunity]
        : undefined,
      rubric: this.changingFilter.rubric ?? undefined,
      addressLocation: this.changingFilter.addressLocation ?? undefined,
      addressPromoter: this.changingFilter.addressPromoter ?? undefined,
    })
      .then((data: GetRecordingArticlesReturnType) => {
        this.totalFilterArticleAmountPreview = data.resultsCountOverall;
        this.totalFilterArticleAmountPreviewLoading = false;
      })
      .catch(() => {
        // TODO: Fehler
        this.totalFilterArticleAmountPreviewLoading = false;
      });
  }

  /**
   * Triggered when user scrolls to the next page.
   */
  @Watch("pageNumber", { deep: true, immediate: true })
  loadNextPage(): void {
    this.loadArticleList();
  }

  /**
   * Called, when the filter should be opened.
   */
  openFilter(): void {
    // Set the changingFilter
    this.changingFilter = new EventListFilter();
    Object.assign(this.changingFilter, this.filter);

    this.showFilterDrawer = true;
  }

  /**
   * Resets all active filters.
   */
  resetFilter(): void {
    this.changingFilter = new EventListFilter();
    this.totalFilterArticleAmountPreview = 0;

    this.loadArticleList();
  }

  /**
   * Called, when an active filter chip is cleared.
   */
  clearChip(): void {
    console.log("Clear");
    this.loadArticleList();
  }

  /**
   * This watcher watches the filter and writes it into the Vue instance on change.
   */
  @Watch("filter", { deep: true, immediate: true })
  onFilterChange(): void {
    Vue.prototype.$eventFilter = this.filter;
  }

  /**
   * Loads the match count for the preview in the filter.
   */
  @Watch("changingFilter", { deep: true })
  onChangingFilterChange(): void {
    this.totalFilterArticleAmountPreviewLoading = true;

    clearTimeout(this.updateFilterArticleCountPreviewTimeout);
    this.updateFilterArticleCountPreviewTimeout = setTimeout(() => {
      this.loadArticleListPreview();
    }, 500);
  }

  /**
   * Called, when user clicked on the search button in filter drawer.
   */
  search(): void {
    // Replace the filter with the changed filter
    this.filter = this.changingFilter;
    this.changingFilter = new EventListFilter();
    Object.assign(this.changingFilter, this.filter);

    this.loadArticleList();
    this.showFilterDrawer = false;
    this.pageNumber = 1;
  }

  /**
   * Removes a article from List with given {@link articleId}
   * @param articleId
   */
  removeArticleFromList(articleId: number): void {
    if (this.availableRecordingArticles == null) {
      return;
    }
    this.availableRecordingArticles = this.availableRecordingArticles.filter(
      (availableRecordingArticles) =>
        availableRecordingArticles.article_id != articleId
    );
  }
}
