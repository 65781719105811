var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',[(!_vm.availableAdvertOrders || _vm.availableAdvertOrders.length > 0)?_c('div',[_c('div',{staticClass:"my-4 d-flex justify-end"},[_c('router-link',{staticClass:"ma-0 pa-0",attrs:{"to":{ name: 'ads-recording' }}},[_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.availableAdvertOrders,"x-large":""}},[_vm._v(" "+_vm._s(_vm.FrontendSettings.advertSettings.titleRecordingAdverts)+" ")])],1)],1),_c('QuokkaAdListAdminMyAdList',{attrs:{"available-advert-orders":_vm.availableAdvertOrders}})],1):_c('AlertCard',{attrs:{"type":"information","icon":null,"text":'Sie haben noch keine ' +
        _vm.FrontendSettings.languageSettings.lbl_advert +
        ' gebucht',"text-size":"h5","action":{
        label: _vm.FrontendSettings.advertSettings.titleRecordingAdverts,
        button: {
          to: { name: 'ads-recording' },
        },
      }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }