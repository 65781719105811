var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"full-height",staticStyle:{"height":"100%"}},[(_vm.event === undefined)?_c('v-card',{staticClass:"my-3 mx-2 event-list-card",attrs:{"elevation":"2"}},[_c('v-skeleton-loader',{attrs:{"type":"image, card-heading, list-item-three-line, actions shape"}})],1):_c('router-link',{attrs:{"to":{
      name: 'event-details',
      params: {
        event_id: _vm.event.event_id.toString(),
        title: _vm.event.urlTitle,
      },
    }}},[_c('v-card',{staticClass:"my-3 mx-2 event-list-card",attrs:{"elevation":"2"}},[_c('div',{staticClass:"card-header",style:(_vm.cardHeaderStyle)},[(
            _vm.event.getPreviewImage() &&
            _vm.event.getPreviewImage().getImageUrl() > ''
          )?_c('v-img',{staticClass:"card-image",attrs:{"lazy-src":_vm.event.getPreviewImage().getImageUrl(true),"src":_vm.event.getPreviewImage().getImageUrl(),"contain":"","height":"200px"}}):_c('div',{staticClass:"card-image-background d-flex align-center justify-center"},[_vm._v(" "+_vm._s(_vm.event.rubric.name)+" ")]),_c('div',{staticClass:"next-date"},[_vm._v(" "+_vm._s(_vm.event.nextEventDate.formatDate("dd"))),_c('br'),_vm._v(" "+_vm._s(_vm.event.nextEventDate.formatDate("MMM").replace(".", ""))+" ")])],1),_c('div',{staticClass:"content d-flex flex-column"},[_c('v-card-subtitle',{staticClass:"pb-0 d-flex justify-space-between flex-wrap",staticStyle:{"white-space":"nowrap"}},[_c('span',[_c('b',{staticClass:"mr-2",staticStyle:{"white-space":"nowrap"},attrs:{"color":"grey lighten-2","title":_vm.event.location
                  ? _vm.event.location.community.name_path.concat(
                      _vm.event.location.district
                        ? ' - ' + _vm.event.location.district.name
                        : ''
                    )
                  : _vm.event.promoter.community.name_path}},[_vm._v(" "+_vm._s(_vm.event.location ? _vm.event.location.community.name_path.concat( _vm.event.location.district ? " - " + _vm.event.location.district.name : "" ) : _vm.event.promoter.community.name_path)+" ")])]),(_vm.hasFromToDates)?_c('span',{staticClass:"text-right d-flex"},[_c('span',[_vm._v(_vm._s(_vm.formatDate(_vm.event.firstDate, "dd.MM.yyyy")))]),_c('span',{staticStyle:{"white-space":"nowrap"}},[_c('span',{staticClass:"mx-1"},[_vm._v("-")]),_c('span',[_vm._v(_vm._s(_vm.formatDate(_vm.event.lastDate, "dd.MM.yyyy")))])])]):_vm._e()]),_c('v-card-title',{staticClass:"word-break-hyphens"},[_vm._v(" "+_vm._s(_vm.event.text.title)+" ")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.event.text.shortdesc)+" ")]),_c('v-card-actions',{staticClass:"pt-0 align-end flex-grow-1"},[_c('router-link',{attrs:{"to":{
              name: 'event-details',
              params: {
                event_id: _vm.event.event_id.toString(),
                title: _vm.event.urlTitle,
              },
            }}},[_c('v-btn',{attrs:{"color":"primary","text":""}},[_vm._v(" Weitere Infos ")])],1)],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }