import Address, { ServerAddressDataType } from "@/shared/data/address";
import Rubric, { ServerRubricDataType } from "@/shared/data/rubric";
import DataStruct from "@/shared/data/datastruct";
import EventDate, { ServerEventDateDataType } from "@/shared/data/event_date";
import Tag, { ServerTagDataType } from "@/shared/data/tag";
import Text, { ServerTextDataType } from "@/shared/data/text";
import { specialCharsToEnglish } from "@/shared/lib/constants";
import { DateTime } from "luxon";
import { parseDate } from "@/shared/lib/datetime";
import EventLink, { ServerEventLinkDataType } from "@/shared/data/event_link";
import Media, { MediaImage, ServerMediaDataType } from "@/shared/data/media";

/**
 * The Event Data-Class containing all properties for an Event.
 */
export default class Event extends DataStruct {
  readonly event_id: number;
  text: Text = new Text();
  priority = "";

  // Dates
  dates: EventDate[] = [];
  firstDate: DateTime | null = null;
  lastDate: DateTime | null = null;

  // Address
  location?: Address;
  promoter?: Address;

  rubric?: Rubric;
  tags: Tag[] = [];
  media: Media[] = [];
  links: EventLink[] = [];

  ticketshops: Address[] = [];
  additional_fields?: AdditionalFieldsDataType[];

  /**
   * Creates a new instance of Event with given data.
   *
   * @param data The data to load
   * @throws TypeError if any property does not fit the type
   */
  constructor(data: { event_id: number }) {
    super();

    if (DataStruct.validateValue(data, "event_id", "number")) {
      this.event_id = data.event_id;
    } else {
      // We cannot initialize without an id
      throw new TypeError("Could not initialize Event. Invalid event_id.");
    }
  }

  /**
   * Returns the set title escaped for url.
   */
  get urlTitle(): string {
    const replacementsCharsString = Object.keys(specialCharsToEnglish).join("");

    const regexKeepOnlyValid = new RegExp(
      "[^a-z\\d\\s" + replacementsCharsString + "]",
      "g"
    );
    const regexReplaceSpecialChars = new RegExp(
      "[" + replacementsCharsString + "]",
      "g"
    );

    let title = this.text.title;

    title = title
      .toLowerCase()
      .replaceAll(/[-]/gm, " ")
      .replaceAll(regexKeepOnlyValid, "")
      .replaceAll(/[\s]+/g, "-")
      .replace(regexReplaceSpecialChars, function (m) {
        if (Object.prototype.hasOwnProperty.call(specialCharsToEnglish, m)) {
          return specialCharsToEnglish[m];
        }
        return "";
      });

    return title;
  }

  /**
   * Returns the next date, when this event takes place from today
   */
  get nextEventDate(): EventDate | undefined {
    const today = DateTime.now().startOf("day");
    for (const date of this.dates) {
      if (date.dateObject && date.dateObject.toMillis() >= today.toMillis()) {
        return date;
      }
    }
  }

  /**
   * Returns all available images.
   */
  get images(): MediaImage[] {
    return this.media.filter((obj) => obj.type === "image") as MediaImage[];
  }

  /**
   * Creates a new Event-object with the given data returned from the server.
   *
   * @param data
   */
  static fromServer(data: ServerEventDataType): Event {
    const event = new Event({
      event_id: data.event_id,
    });

    // Load the data
    if (DataStruct.validateValue(data, "text", "object")) {
      event.text = Text.fromServer(<ServerTextDataType>data.text);
    }

    if (DataStruct.validateValue(data, "priority", "string"))
      event.priority = <string>data.priority;

    // Dates
    if (Object.prototype.hasOwnProperty.call(data, "calendar_data")) {
      event.dates = [];
      data.calendar_data?.forEach((item) =>
        event.dates.push(EventDate.fromServer(item))
      );
    }

    if (DataStruct.validateValue(data, "first_date", "string"))
      event.firstDate =
        parseDate(<string>data.first_date, "yyyy-MM-dd") ?? null;
    if (DataStruct.validateValue(data, "last_date", "string"))
      event.lastDate = parseDate(<string>data.last_date, "yyyy-MM-dd") ?? null;

    if (DataStruct.validateValue(data, "location", "object")) {
      event.location = Address.fromServer(<ServerAddressDataType>data.location);
    }
    if (DataStruct.validateValue(data, "promoter", "object")) {
      event.promoter = Address.fromServer(<ServerAddressDataType>data.promoter);
    }

    if (DataStruct.validateValue(data, "rubric", "object")) {
      event.rubric = Rubric.fromServer(<ServerRubricDataType>data.rubric);
    }
    if (Object.prototype.hasOwnProperty.call(data, "tags")) {
      event.tags = [];
      data.tags?.forEach((item) => event.tags.push(Tag.fromServer(item)));
    }
    if (Object.prototype.hasOwnProperty.call(data, "media")) {
      event.media = [];
      data.media?.forEach((item) =>
        event.media.push(MediaImage.fromServer(item))
      );
    }
    if (Object.prototype.hasOwnProperty.call(data, "links")) {
      event.links = [];
      data.links?.forEach((item) =>
        event.links.push(EventLink.fromServer(item))
      );
    }

    if (data.additional_fields && data.additional_fields.length > 0) {
      event.additional_fields = [];
      data.additional_fields.forEach((additionalField) => {
        event.additional_fields?.push(additionalField);
      });
    }

    if (data.ticketshops && data.ticketshops?.length > 0) {
      data.ticketshops.forEach((ticketshop) => {
        event.ticketshops.push(
          Address.fromServer(<ServerAddressDataType>ticketshop)
        );
      });
    } else {
      event.ticketshops = [];
    }

    return event;
  }

  /**
   * Creates a new Event with an id of 0.
   */
  static create(): Event {
    return new Event({
      event_id: 0,
    });
  }

  /**
   * Returns the first set {@link EventImage} for this {@link Event}.
   * If no media is set, the {@link Rubric} image will be used, if available.
   */
  getPreviewImage(): MediaImage | undefined {
    // Get first image
    return (
      (this.media.find((obj) => obj.type === "image") as MediaImage) ??
      this.rubric?.image ??
      null
    );
  }
}
/**
 * The type for the additional fields delivered by the server
 */
export type AdditionalFieldsDataType = {
  label: string;
  name: string;
  value: string;
  type: string;
};

/**
 * The data structure as returned by the MSUevent-Server.
 */
export type ServerEventDataType = {
  event_id: number;
  text: ServerTextDataType;
  priority?: string;
  calendar_data: ServerEventDateDataType[];
  first_date: string;
  last_date: string;
  location?: ServerAddressDataType;
  promoter?: ServerAddressDataType;
  rubric: ServerRubricDataType;
  tags?: ServerTagDataType[];
  media?: ServerMediaDataType[];
  links?: ServerEventLinkDataType[];
  ticketshops?: ServerAddressDataType[];
  additional_fields?: AdditionalFieldsDataType[];
};
