
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import RecordingEvent from "@/shared/data/recording_event";
import QuokkaEventListAdminMyEventListItem from "@/components/quokka_layout/admin/QuokkaEventListAdminMyEventListItem.vue";
import {
  getRecordingEvents,
  GetRecordingEventsReturnType,
} from "@/frontend/lib/api";
import AlertCard from "@/components/quokka_layout/ui/AlertCard.vue";
import Event from "@/shared/data/event";
import EventListFilter from "@/shared/lib/event-list-filter";
import QuokkaEventListFilterContent from "@/components/quokka_layout/admin/my-events/QuokkaMyEventListFilterContent.vue";
import FrontendSettings from "@/frontend/settings/settings";

/**
 * The available display types for this event list.
 *
 * - event-list: used in the my-events view
 * - ads-event-selection: used embedded in the ads recording to select an existing event
 */
export type EventListAdminDisplayType = "event-list" | "ads-event-selection";

/**
 * A list of {@link QuokkaEventListAdminMyEventListItem}s to display available {@link RecordingEvent}s.
 *
 * Use display-type to change some of the behaviour.
 */
@Component({
  components: {
    AlertCard,
    QuokkaEventListAdminMyEventListItem,
    QuokkaEventListFilterContent,
  },
})
export default class QuokkaEventListAdminMyEventList extends Vue {
  /**
   * List of available {@link RecordingEvent}s of the logged-in user for the page in url.
   */
  availableRecordingEvents: RecordingEvent[] | null = null;

  /**
   * The display type of this event-list.
   *
   * - event_list
   * Item: Builds an edit button and routes to the edit page.
   * List: Adds elevation to the list.
   *
   * - ads-event-selection
   * Item: Builds a button to select this event and emits the {@link onEventSelect}.
   */
  @Prop({ required: true, default: "event-list" })
  eventListAdminDisplayType!: EventListAdminDisplayType;

  loading = true;

  showFilterDrawer = false;
  eventsLoading = false;
  pageNumber = 1;
  totalPageNumber = 1;
  events: Event[] = [];
  totalEventsAmount = 0;
  /**
   * The currently used {@link EventListFilter}.
   * Is set from and to the Vue instance for persistence when changing routes.
   */
  filter: EventListFilter;

  /**
   * A copy of {@link filter} used in the filter-popup.
   */
  changingFilter: EventListFilter = new EventListFilter();

  /**
   * The total amount of events as a preview.
   */
  totalFilterEventsAmountPreview = 0;

  /**
   * Whether the total amount of events to preview is loading or not.
   */
  totalFilterEventsAmountPreviewLoading = true;

  /**
   * The key of the last called Promise that fetches the total amount of events to preview.
   *
   * The total amount will only be set, if the key is the active key to prevent wrong values.
   */
  totalFilterEventsAmountPreviewActivePromiseKey = 0;

  updateFilterEventCountPreviewTimeout?: number;

  /** 'Record new event' button */
  titleButton = "Veranstaltung erfassen";

  /**
   * This constructor sets the {@link filter} from the Vue instance, if available or creates a new one if not.
   */
  constructor() {
    super();
    this.filter = Vue.prototype.$eventFilter ?? new EventListFilter();
  }

  /**
   * Tries to load available {@link RecordingEvent}s.
   */
  mounted(): void {
    this.loadEventList();
    this.titleButton = FrontendSettings.eventRecording.titleRecordingEvents;
  }

  /**
   * Called, when the filter should be opened.
   */
  openFilter(): void {
    // Set the changingFilter
    this.changingFilter = new EventListFilter();
    Object.assign(this.changingFilter, this.filter);

    this.showFilterDrawer = true;
  }

  /**
   * Resets all active filters.
   */
  resetFilter(): void {
    this.changingFilter = new EventListFilter();
  }

  /**
   * Called, when an active filter chip is cleared.
   */
  clearChip(): void {
    this.loadEventList();
  }

  /**
   * This watcher watches the filter and writes it into the Vue instance on change.
   */
  @Watch("filter", { deep: true, immediate: true })
  onFilterChange(): void {
    Vue.prototype.$eventFilter = this.filter;
  }

  /**
   * Returns the elevation of the list according to the display type.
   */
  get elevation(): number {
    return this.eventListAdminDisplayType === "event-list" ? 2 : 0;
  }

  /**
   * Called, when user clicked on the search button in filter drawer.
   */
  search(): void {
    // Replace the filter with the changed filter
    this.filter = this.changingFilter;
    this.changingFilter = new EventListFilter();
    Object.assign(this.changingFilter, this.filter);

    this.loadEventList();
    this.showFilterDrawer = false;
    this.pageNumber = 1;
  }

  loadEventList(): void {
    this.loading = true;

    getRecordingEvents({
      page: this.pageNumber,
      query: this.filter.searchQuery ?? undefined,
      start_date: this.filter.dateFrom ?? undefined,
      end_date: this.filter.dateUntil ?? undefined,
      communities: this.filter.locationCommunity
        ? [this.filter.locationCommunity]
        : undefined,
      rubric: this.filter.rubric ?? undefined,
      tags: this.filter.tag ?? undefined,
      event_list_display_type: this.eventListAdminDisplayType,
    })
      .then((data: GetRecordingEventsReturnType) => {
        this.availableRecordingEvents = data.recordingEvents;
        this.totalPageNumber = data.pagesCountOverall;
        this.totalEventsAmount = data.resultsCountOverall;
        this.pageNumber = data.page;
        this.totalFilterEventsAmountPreview = this.totalEventsAmount;
        this.loading = false;
      })
      .catch(() => {
        // TODO: Fehler
        this.loading = false;
      });
  }

  /**
   * Triggered when user scrolls to the next page.
   */
  @Watch("pageNumber", { deep: true, immediate: true })
  loadNextPage(): void {
    this.loadEventList();
  }

  /**
   *
   */
  loadEventListPreview(): void {
    this.totalFilterEventsAmountPreviewLoading = true;

    getRecordingEvents({
      page: this.pageNumber,
      query: this.changingFilter.searchQuery ?? undefined,
      start_date: this.changingFilter.dateFrom ?? undefined,
      end_date: this.changingFilter.dateUntil ?? undefined,
      communities: this.changingFilter.locationCommunity
        ? [this.changingFilter.locationCommunity]
        : undefined,
      rubric: this.changingFilter.rubric ?? undefined,
      tags: this.changingFilter.tag ?? undefined,
      event_list_display_type: this.eventListAdminDisplayType,
    })
      .then((data: GetRecordingEventsReturnType) => {
        this.totalFilterEventsAmountPreview = data.resultsCountOverall;
        this.totalFilterEventsAmountPreviewLoading = false;
      })
      .catch(() => {
        // TODO: Fehler
        this.totalFilterEventsAmountPreviewLoading = false;
      });
  }

  @Watch("changingFilter", { deep: true })
  onChangingFilterChange(): void {
    clearTimeout(this.updateFilterEventCountPreviewTimeout);
    this.updateFilterEventCountPreviewTimeout = setTimeout(() => {
      this.loadEventListPreview();
    }, 500);
  }

  /**
   * Emitted when user selects an item.
   *
   * Only available, if the displayType is set to a selectable type.
   *
   * @param event
   */
  @Emit("event-select")
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onEventSelect(event: number | string | RecordingEvent): void {
    return;
  }

  /**
   * Tries to remove an {@link RecordingEvent} from the list.
   *
   * @param event_id
   */
  removeEventFromAvailableList(event_id: number): void {
    if (this.availableRecordingEvents == null) {
      return;
    }

    this.availableRecordingEvents = this.availableRecordingEvents.filter(
      (availableRecordingEvent) => availableRecordingEvent.event_id != event_id
    );
  }

  isValid(recordingEvent: RecordingEvent): boolean {
    try {
      recordingEvent.validate();
      return true;
    } catch (e) {
      return false;
    }
  }
}
