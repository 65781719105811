
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import RecordingArticle from "@/shared/data/recording_article";
import QuokkaArticleListAdminMyArticleListItem from "@/components/quokka_layout/admin/QuokkaArticleListAdminMyArticleListItem.vue";

/**
 * A list of {@link QuokkaArticleListAdminMyArticleListItem}s to display available {@link RecordingArticle}s.
 */
@Component({
  components: { QuokkaArticleListAdminMyArticleListItem },
})
export default class QuokkaArticleListAdminMyArticleList extends Vue {
  /**
   * List of available {@link RecordingArticle}s.
   * null for loading animation.
   */
  @Prop({ required: true }) availableRecordingArticles!:
    | RecordingArticle[]
    | null;

  /**
   * Emits an {@link RecordingEvent} to remove it from the list.
   *
   * @param article_id
   */
  @Emit("article-deleted")
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  removeArticleFromAvailableList(article_id: number): void {
    return;
  }
}
