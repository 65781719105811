
/** Tab for the Article-Recording containing the appointments for the type 'series' */
import { Component, Prop, Vue } from "vue-property-decorator";
import QuokkaDatePicker from "@/components/quokka_layout/ui/QuokkaDatePicker.vue";
import RecordingArticle from "@/shared/data/recording_article";
import { RecordingEventAppointmentsSeries } from "@/shared/data/event_appointments";
import FrontendSettings from "@/frontend/settings/settings";
import { sanitizeContent } from "@/shared/lib/dompurify";

@Component({
  components: { QuokkaDatePicker },
  data() {
    return { sanitizeContent };
  },
})
export default class QuokkaArticleRecordingAppointmentsSeriesTab extends Vue {
  /** The {@link RecordingArticle} used as model. */
  @Prop({ required: true }) recordingArticle!: RecordingArticle;

  /** Whether the fields of this tab should be disabled or not. */
  @Prop() disabled!: boolean;

  /** The {@link RecordingEventAppointmentsSeries} used as model */
  @Prop({ required: true }) appointments!: RecordingEventAppointmentsSeries;

  /** Available rules for the fields to verify the value. */
  rules: { [key: string]: (value: string) => boolean | string } = {
    date: (value: string): boolean | string => {
      if (!value || value.trim() === "") return "Datum eingeben";
      return true;
    },
  };
  /**
   * Returns the `appointmentsSeriesInfo` defined in the settings.
   */
  get appointmentsSeriesInfo(): string | null {
    return FrontendSettings.articleRecording.appointmentsSeriesInfo;
  }
}
