import DataStruct from "@/shared/data/datastruct";

/**
 * The Tag Data-Class containing all properties for a Tag.
 */
export default class Tag extends DataStruct {
  readonly tag_id: number;
  name = "";
  type: TagType;

  /**
   * Creates a new instance of Tag with given data.
   *
   * @param data The data to load
   * @throws TypeError if any property does not fit the type
   */
  constructor(data: { tag_id: number; type: TagType }) {
    super();

    if (DataStruct.validateValue(data, "tag_id", "number")) {
      this.tag_id = data.tag_id;
    } else {
      // We cannot initialize without an id
      throw new TypeError("Could not initialize Tag. Invalid tag_id.");
    }

    if (DataStruct.validateValue(data, "type", "string")) {
      this.type = data.type;
    } else {
      // We cannot initialize without an id
      throw new TypeError("Could not initialize Tag. Invalid tag-type.");
    }
  }

  /**
   * Creates a new Tag-object with the given data returned from the server.
   *
   * @param data
   */
  static fromServer(data: ServerTagDataType): Tag {
    const tag = new Tag({
      tag_id: data.tag_id,
      type: data.type,
    });

    // Load the data
    if (DataStruct.validateValue(data, "name", "string")) tag.name = data.name;

    return tag;
  }
}

/**
 * The data structure defined by the MSUevent-Server.
 */
export type ServerTagDataType = {
  tag_id: number;
  name: string;
  type: TagType;
};

export type TagType = "event" | "address" | "community";
