
import { Component, Vue } from "vue-property-decorator";

/**
 * The {@link QuokkaArticlesAdminView} is the admin view to manage or record articles.
 */
@Component
export default class QuokkaArticlesAdminView extends Vue {
  /**
   * Returns the current page-title from route-meta, if available.
   * A default title otherwise.
   */
  get pageTitle(): string {
    return this.$route.meta?.page_title ?? "";
  }
}
