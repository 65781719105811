
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { DateTime } from "luxon";
import RecordingArticle from "@/shared/data/recording_article";
import {
  RecordingEventAppointments,
  RecordingEventAppointmentsFree,
} from "@/shared/data/event_appointments";
import { deleteRecordingArticle } from "@/frontend/lib/api";
import FrontendLogger from "@/frontend/lib/logger";
import { sanitizeContent } from "@/shared/lib/dompurify";

/**
 * An item to display information about a {@link RecordingEvent} in a {@link QuokkaEventListAdminMyEventList}.
 */
@Component({
  data() {
    return { sanitizeContent };
  },
})
export default class QuokkaArticleListAdminMyArticleListItem extends Vue {
  successSnackbarMessage = "";
  errorSnackbarMessage = "";
  deleteDialog = false;
  /**
   * The {@link RecordingEvent} to display.
   */
  @Prop({ required: true }) recordingArticle!: RecordingArticle | null;

  /**
   * Tries to format the given date to the given format.
   * @param date
   */
  formatDate(date: DateTime | null): string {
    if (!date) return "";
    else {
      return date.day + "." + date.month + "." + date.year;
    }
  }

  /** Returns formatted first free release date */
  releaseDate(dates: RecordingEventAppointments): string {
    if (this.recordingArticle?.article_release_info?.type === "free") {
      let datesNew = dates as RecordingEventAppointmentsFree;
      if (
        datesNew &&
        datesNew.dates[0] &&
        datesNew.dates[0].dateObject &&
        datesNew.dates[0].dateObject !== null
      )
        return datesNew.dates[0].dateObject?.toFormat("dd.MM.yyyy");
    }
    return "";
  }

  /**
   * Emitted when user deletes this item.
   *
   * Only available, if the displayType is set to a selectable type.
   *
   * @param articleId
   */
  @Emit("article-deleted")
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onArticleDelete(articleId: number): void {
    return;
  }
  /**
   * Tries to delete the {@link RecordingArticle} with given id.
   *
   * @param articleId
   */
  deleteArticle(articleId: number): void {
    this.successSnackbarMessage = "";
    this.errorSnackbarMessage = "";

    deleteRecordingArticle(articleId)
      .then(() => {
        this.successSnackbarMessage =
          "Die Veranstaltung wurde erfolgreich gelöscht.";
        this.onArticleDelete(articleId);
      })
      .catch((e) => {
        this.errorSnackbarMessage =
          "Der Artikel konnte nicht gelöscht werden. Ein unbekannter Fehler ist aufgetreten.";
        FrontendLogger.error({
          message: "Failed to delete Event.",
          data: e,
          scope: "api",
        });
      })
      .finally(() => (this.deleteDialog = false));
    return;
  }
}
