
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import {
  AdvertCampaign,
  AdvertChannel,
  AdvertSlot,
  OrderItem,
} from "@/shared/data/advert";
import {
  AdvertAvailabilityReturnType,
  buildOrderItems,
  BuildOrderReturnType,
  getAdvertAvailability,
} from "@/frontend/lib/api";
import { formatDate, parseDate } from "@/shared/lib/datetime";
import FrontendSettings from "@/frontend/settings/settings";

@Component({
  data() {
    return { FrontendSettings };
  },
})
export default class QuokkaAdsRecordingWhenView extends Vue {
  /**
   * The model of the input field.
   */
  noDay: boolean | false = true;
  manyPlaces: boolean | true = false;
  fewPlaces: boolean | true = false;
  noPlaces: boolean | true = false;
  bookAble: boolean | true = false;
  dateAlreadyBooked: boolean | true = false;
  optionalChannelPlace: boolean | true = false;
  onlyOptionalChannelPlace: boolean | true = false;

  totalPrice = 0;
  optChnBtns: boolean[] = [];

  selectedDate = "";
  selectedDays: string[] = [];
  optionalChannel: AdvertChannel[] = [];

  selectSelectedDaysAndMediums: string | null = null;

  @Prop({ required: true }) selectedChannel: AdvertChannel | undefined;
  @Prop({ required: true }) selectedSlot: AdvertSlot | undefined;
  @Prop({ required: true }) campaigns!: AdvertCampaign[];
  @Prop({ required: true }) currentOrderItems!: OrderItem[];

  selectedCampaign: AdvertCampaign | null = null;
  currencySymbol = "";

  @Emit("set-current-order-items")
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setCurrentOrderItems(data: OrderItem[]): void {
    return;
  }

  @Emit("when-go-next")
  whenGoNext(): void {
    return;
  }

  addDay(
    channelId: number,
    datePickerModel: string,
    action: string,
    optChannelInt: number | false
  ): void {
    if (optChannelInt !== false) {
      this.optChnBtns[optChannelInt] = true;
    }

    this.bookAble = false;

    let selectedSlotId = 0;
    if (this.selectedSlot?.advert_slot_id != undefined) {
      selectedSlotId = this.selectedSlot.advert_slot_id;
    }

    buildOrderItems({
      date: datePickerModel,
      action: action,
      slot_id: selectedSlotId,
      channel_id: channelId,
      order_items: this.currentOrderItems,
    })
      .then((response: BuildOrderReturnType) => {
        this.dateAlreadyBooked = false;
        this.totalPrice = response.totalPrice;
        this.setCurrentOrderItems(response.orderItems);
      })
      .catch((e) => {
        if (e.response.data.error === "ERROR_CODE_DATE_ALREADY_BOOKED") {
          this.noDay = false;
          this.manyPlaces = false;
          this.noPlaces = false;
          this.fewPlaces = false;
          this.bookAble = false;

          this.dateAlreadyBooked = true;
        }
      });
  }

  mounted(): void {
    this.campaigns.forEach((campaign) => {
      breakLoop: for (const channel of campaign.advert_channels) {
        if (
          channel.advert_channel_id == this.selectedChannel?.advert_channel_id
        ) {
          for (const slot of channel.advert_slots) {
            if (slot.advert_slot_id == this.selectedSlot?.advert_slot_id) {
              this.selectedCampaign = campaign;
              break breakLoop;
            }
          }
        }
      }
    });

    this.getCurrencySymbol();
  }

  /**
   * Validates and sets the current value to the {@link selectedMedium}.
   *
   * @param value
   */
  set datePickerModel(value: string | null) {
    if (value == null) {
      this.noDay = true;
    } else {
      this.selectedDate = value;
      this.noDay = false;
    }
  }

  /**
   * Returns the value of the {@link inputFieldValue} for the picker-model.
   */
  get datePickerModel(): string | null {
    return this.selectedDate;
  }

  /**
   * Formats a edv date in german format.
   *
   * @param date
   */
  formatGermanDate(date: string): string | undefined {
    let parsedDate = parseDate(date, "yyyy-MM-dd");

    return formatDate(parsedDate, "dd.MM.yyyy");
  }

  /**
   * Finds the currency symbol from the selected campaign.
   */
  getCurrencySymbol(): void {
    if (this.selectedCampaign !== null) {
      this.currencySymbol =
        this.selectedCampaign.advert_partner.currency_symbol;
    }
  }

  /**
   * Check if a date is available
   *
   * @param selectedChannelId
   * @param selectedSlotId
   */
  getAvailabilityForDateAndMedium(
    selectedChannelId: number,
    selectedSlotId: number
  ): void {
    if (this.selectedDate == null) {
      this.noDay = true;
      return;
    }

    getAdvertAvailability({
      date: this.selectedDate,
      slot_id: selectedSlotId,
      channel_id: selectedChannelId,
    })
      .then((response: AdvertAvailabilityReturnType) => {
        if (response["availableAmount"] > 0) {
          if (response["availableAmount"] > 2) {
            this.noDay = false;
            this.fewPlaces = false;
            this.noPlaces = false;

            this.manyPlaces = true;
            this.bookAble = true;
          } else {
            this.noDay = false;
            this.manyPlaces = false;
            this.noPlaces = false;

            this.fewPlaces = true;
            this.bookAble = true;
          }
        } else {
          this.fewPlaces = false;
          this.manyPlaces = false;
          this.noDay = false;
          this.bookAble = false;

          this.noPlaces = true;
          this.onlyOptionalChannelPlace = true;
        }

        if (Object.keys(response["optionalChannel"]).length > 0) {
          this.optionalChannel = response["optionalChannel"];
          this.optionalChannelPlace = true;
          this.onlyOptionalChannelPlace = false;
          this.optChnBtns = [];

          for (const optChannelId of Object.keys(response["optionalChannel"])) {
            let optChannelIdInt = Number.parseInt(optChannelId);
            this.optChnBtns[optChannelIdInt] = false;
          }
        } else {
          this.optionalChannel = [];
          this.optionalChannelPlace = false;
          this.onlyOptionalChannelPlace = false;
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }

  getAvailableMediumsAndChannelsForDayAndSpace = (): number => {
    return 0;
  };
}
