
import { Component, Vue } from "vue-property-decorator";
import QuokkaEventListAdminMyEventList from "@/components/quokka_layout/admin/QuokkaEventListAdminMyEventList.vue";
import AlertCard from "@/components/quokka_layout/ui/AlertCard.vue";
import RecordingEvent from "@/shared/data/recording_event";

/**
 * A view to show all recorded {@link RecordingEvent}s of the logged-in user.
 */
@Component({
  components: { AlertCard, QuokkaEventListAdminMyEventList },
})
export default class QuokkaEventsListAdminView extends Vue {
  /**
   * The list of {@link RecordingEvent}.
   *
   * Will be filled by the {@link QuokkaEventListAdminMyEventList}.
   */
  availableRecordingEvents: RecordingEvent[] = [];

  /**
   * Called, when the filter should be opened.
   */
  openFilter(): void {
    // Onl
  }
}
