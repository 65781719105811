
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import RecordingEvent from "@/shared/data/recording_event";
import { DateTime } from "luxon";
import { DateFormats, formatDate } from "@/shared/lib/datetime";
import { EventListAdminDisplayType } from "@/components/quokka_layout/admin/QuokkaEventListAdminMyEventList.vue";
import { deleteRecordingEvent } from "@/frontend/lib/api";
import FrontendLogger from "@/frontend/lib/logger";
import FrontendSettings from "@/frontend/settings/settings";

/**
 * An item to display information about a {@link RecordingEvent} in a {@link QuokkaEventListAdminMyEventList}.
 */
@Component
export default class QuokkaEventListAdminMyEventListItem extends Vue {
  /**
   * The {@link RecordingEvent} to display.
   *
   * null for loading animation.
   */
  @Prop({ required: true }) recordingEvent!: RecordingEvent | null;

  /**
   * The display type of the Event-list-view.
   *
   * - event_list
   * Builds an edit button and routes to the edit page.
   *
   * - ads-event-selection
   * Builds a button to select this event and emits the {@link onEventSelect}.
   */
  @Prop({ required: true })
  eventListAdminDisplayType!: EventListAdminDisplayType;

  /**
   * Tries to format the given date to the given format.
   * If date is null or format fails, an empty string is returned.
   *
   * @param date
   * @param format
   */
  successSnackbarMessage = "";
  errorSnackbarMessage = "";
  deleteDialog = false;

  formatDate(date: DateTime | null, format: string | DateFormats): string {
    return date ? formatDate(date, format) ?? "" : "";
  }

  hasFromAndUntilDate(): boolean {
    return (
      this.recordingEvent?.appointments?.firstDate?.toMillis() !==
        this.recordingEvent?.appointments?.lastDate?.toMillis() ?? false
    );
  }

  /**
   * Emitted when user selects this item.
   *
   * Only available, if the displayType is set to a selectable type.
   *
   * @param eventId
   */
  @Emit("event-select")
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onEventSelect(eventId: number | RecordingEvent): void {
    return;
  }

  /**
   * Emitted when user deletes this item.
   *
   * Only available, if the displayType is set to a selectable type.
   *
   * @param eventId
   */
  @Emit("event-deleted")
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onEventDelete(eventId: number): void {
    return;
  }

  /**
   * Tries to delete the {@link RecordingEvent} with given id.
   *
   * @param eventId
   */
  deleteEvent(eventId: number): void {
    this.successSnackbarMessage = "";
    this.errorSnackbarMessage = "";

    deleteRecordingEvent(eventId)
      .then(() => {
        this.successSnackbarMessage =
          "Die Veranstaltung wurde erfolgreich gelöscht.";
        this.onEventDelete(eventId);
      })
      .catch((e) => {
        switch (e.response.data.error) {
          case "USED_IN_ADVERT":
            this.errorSnackbarMessage =
              "Die Veranstaltung ist mit einer " +
              FrontendSettings.languageSettings.lbl_advert +
              " verknüpft und konnte daher nicht gelöscht werden.";
            break;
          case "UNKNOWN_ERROR":
          default:
            this.errorSnackbarMessage =
              "Die Veranstaltung konnte nicht gelöscht werden. Ein unbekannter Fehler ist aufgetreten.";
        }

        FrontendLogger.error({
          message: "Failed to delete Event.",
          data: e,
          scope: "api",
        });
      })
      .finally(() => (this.deleteDialog = false));
    return;
  }
}
