
import { Component, Prop, Vue } from "vue-property-decorator";
import RecordingArticle from "@/shared/data/recording_article";
import QuokkaDatePicker from "@/components/quokka_layout/ui/QuokkaDatePicker.vue";
import QuokkaEventRecordingAppointmentsFreeTimeListItem from "@/components/quokka_layout/recording/QuokkaEventRecordingAppointmentsFreeTimeListItem.vue";
import LabeledSheet from "@/components/quokka_layout/ui/LabeledSheet.vue";
import QuokkaEventAddressSearch from "@/components/quokka_layout/QuokkaEventAddressSearch.vue";
import QuokkaEventRubricSearch from "@/components/quokka_layout/QuokkaEventRubricSearch.vue";
import { RecordingEventAppointmentsFree } from "@/shared/data/event_appointments";
import { formatDate, parseDate } from "@/shared/lib/datetime";
import { DateTime } from "luxon";
import FrontendLogger from "@/frontend/lib/logger";
import EventDate from "@/shared/data/event_date";
import TimeInfo from "@/shared/data/time_info";
import FrontendSettings from "@/frontend/settings/settings";
import { sanitizeContent } from "@/shared/lib/dompurify";

/** Tab for the Article-Recording containing the appointments for the type "free" */
@Component({
  components: {
    QuokkaDatePicker,
    QuokkaEventRecordingAppointmentsFreeTimeListItem,
    LabeledSheet,
    QuokkaEventAddressSearch,
    QuokkaEventRubricSearch,
  },
  data() {
    return { sanitizeContent };
  },
})
export default class QuokkaArticleRecordingAppointmentsFreeTab extends Vue {
  /** The {@link RecordingArticle} used */
  @Prop({ required: true }) recordingArticle!: RecordingArticle;

  /** Whether the fields of this tab should be disabled or not */
  @Prop() disabled!: boolean;

  /** The {@link RecordingEventAppointmentsFree} used */
  @Prop({ required: true }) appointments!: RecordingEventAppointmentsFree;

  /** Available rules for the fields to verify the value */
  rules: { [key: string]: (value: string) => boolean | string } = {
    required: (value: string): boolean | string => {
      if (!value || value.trim() === "") return "Datum eingeben";
      return true;
    },
    date: (value: string): boolean | string => {
      const parsedDate = parseDate(value, "dd.MM.yyyy");
      const today = DateTime.now();

      if (!value) return true;

      if (!parsedDate) return "Ungültiges Datum";

      const formattedDate = formatDate(parsedDate, "yyyy-MM-dd");
      const formattedToday = formatDate(today, "yyyy-MM-dd");

      if (!formattedDate || !formattedToday) {
        FrontendLogger.error({
          message:
            "Something went wrong trying to validate date-value '" +
            value +
            "'. Could not format date or today's date.",
          scope: "invalid-data",
          data: {
            formattedDate,
            formattedToday,
          },
        });
        return "Ungültiges Datum";
      }

      // Check, if the date has passed
      if (formattedDate < formattedToday) {
        return "Termin ist abgelaufen";
      }
      return true;
    },
  };

  /**
   * Checks based on the articleDefinition whether a field is required
   * @param fieldIndex
   */
  getDateFieldIsRequired(fieldIndex: number): boolean {
    if (
      this.recordingArticle &&
      this.recordingArticle.articleDefinition &&
      this.recordingArticle.articleDefinition.publicationDateConfig
        .publication_min_dates > 0 &&
      this.recordingArticle.articleDefinition.publicationDateConfig
        .publication_min_dates > fieldIndex
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Checks, if the {@link RecordingEventAppointmentsFree} have at least one {@link EventDate}.
   * If not, an empty one will be added so that we always have a line visible.
   */
  mounted(): void {
    if (this.appointments.dates.length <= 0) {
      // Add a default date
      const eventDate = new EventDate();
      eventDate.timeInfo.push(new TimeInfo());
      this.appointments.dates.push(eventDate);
    }
  }

  /** Adds a new, empty {@link EventDate}. */
  addArticleDate(): void {
    const eventDate = new EventDate();
    eventDate.timeInfo.push(new TimeInfo());
    this.appointments.dates.push(eventDate);
  }

  /**
   * Removes the {@link EventDate} at given index.
   * @param index
   */
  removeArticleDate(index: number): void {
    this.appointments.dates.splice(index, 1);
  }
  /**
   * Returns the `appointmentsFreeInfo` defined in the settings.
   */
  get appointmentsFreeInfo(): string | null {
    return FrontendSettings.articleRecording.appointmentsFreeInfo;
  }
}
