
import { Component, Prop, Vue } from "vue-property-decorator";
import EventListFilter from "@/shared/lib/event-list-filter";
import QuokkaEventRubricSearch from "@/components/quokka_layout/QuokkaEventRubricSearch.vue";
import QuokkaEventCommunitySearch from "@/components/quokka_layout/QuokkaEventCommunitySearch.vue";
import { DateTime } from "luxon";
import QuokkaDatePicker from "@/components/quokka_layout/ui/QuokkaDatePicker.vue";
import QuokkaEventTagSearch from "@/components/quokka_layout/QuokkaEventTagSearch.vue";
import QuokkaEventAddressSearch from "@/components/quokka_layout/QuokkaEventAddressSearch.vue";

@Component({
  components: {
    QuokkaEventAddressSearch,
    QuokkaEventTagSearch,
    QuokkaDatePicker,
    QuokkaEventCommunitySearch,
    QuokkaEventRubricSearch,
  },
})
export default class QuokkaArticleListFilterContent extends Vue {
  @Prop({ required: true }) filter!: EventListFilter;

  /**
   * Sets the start and end-date filters according to the timespan.
   * Available time spans:
   * - today: from and until date are today
   * - this-week: from date is today, until date sunday of this week
   * - one-week: from date is today, until date is today + 1 week
   * - one-month: from date is today, until date is today + 1 month
   *
   * @param timespan today: from and to date are today
   */
  setDateFilter(
    timespan: "today" | "this-week" | "one-week" | "one-month"
  ): void {
    switch (timespan) {
      case "this-week": {
        const today = DateTime.now();
        this.filter.dateFrom = DateTime.fromObject({
          day: today.day,
          month: today.month,
          year: today.year,
        });

        const nextWeek = today.plus({ day: 7 - today.weekday });
        this.filter.dateUntil = DateTime.fromObject({
          day: nextWeek.day,
          month: nextWeek.month,
          year: nextWeek.year,
        });
        break;
      }
      case "one-week": {
        const today = DateTime.now();
        this.filter.dateFrom = DateTime.fromObject({
          day: today.day,
          month: today.month,
          year: today.year,
        });
        const nextWeek = today.plus({ week: 1 });
        this.filter.dateUntil = DateTime.fromObject({
          day: nextWeek.day,
          month: nextWeek.month,
          year: nextWeek.year,
        });
        break;
      }
      case "one-month": {
        const today = DateTime.now();
        this.filter.dateFrom = DateTime.fromObject({
          day: today.day,
          month: today.month,
          year: today.year,
        });
        const nextMonth = today.plus({ month: 1 });
        this.filter.dateUntil = DateTime.fromObject({
          day: nextMonth.day,
          month: nextMonth.month,
          year: nextMonth.year,
        });
        break;
      }
      case "today":
      default: {
        const today = DateTime.now();
        this.filter.dateFrom = DateTime.fromObject({
          day: today.day,
          month: today.month,
          year: today.year,
        });
        this.filter.dateUntil = DateTime.fromObject({
          day: today.day,
          month: today.month,
          year: today.year,
        });
        break;
      }
    }
  }
}
