import DataStruct from "@/shared/data/datastruct";

/**
 * The Community Data-Class containing all properties for a Community.
 */
export default class Community extends DataStruct {
  readonly community_id: number;
  readonly district_id: number | null = null;
  name = "";
  name_path = "";

  /**
   * Creates a new instance of Community with given data.
   *
   * @param data The data to load
   * @throws TypeError if any property does not fit the type
   */
  constructor(data: { community_id: number; district_id: number | null }) {
    super();

    if (DataStruct.validateValue(data, "community_id", "number")) {
      this.community_id = data.community_id;
    } else {
      // We cannot initialize without an id
      throw new TypeError("Could not initialize Community. Invalid id.");
    }

    if (DataStruct.validateValue(data, "district_id", "number")) {
      this.district_id = data.district_id;
    }
  }

  get id(): number {
    return this.district_id ?? this.community_id;
  }

  /**
   * Returns a Label of this community to use in an autocomplete.
   *
   * Example:
   * Aschaffenburg - Leider
   */
  get searchLabel(): string {
    let label = this.name_path;

    // Replace | with -
    label = label.replaceAll("|", " - ");

    return label;
  }

  /**
   * Creates a new Community-object with the given data returned from the server.
   *
   * @param data
   */
  static fromServer(data: ServerCommunityDataType): Community {
    const community = new Community({
      community_id: data.community_id,
      district_id: data.district_id,
    });

    // Load the data
    if (DataStruct.validateValue(data, "name", "string"))
      community.name = data.name;
    if (DataStruct.validateValue(data, "name_path", "string"))
      community.name_path = <string>data.name_path;

    return community;
  }
}

/**
 * The data structure defined by the MSUevent-Server.
 */
export type ServerCommunityDataType = {
  community_id: number;
  district_id: number | null;
  name: string;
  name_path: string;
};
