var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',[(
        !_vm.availableRecordingArticles || _vm.availableRecordingArticles.length > 0
      )?_c('div',[_c('div',{staticClass:"my-4 d-flex justify-space-between"},[_c('v-btn',{staticClass:"mr-10",attrs:{"disabled":_vm.loading,"color":"primary","large":""},on:{"click":_vm.openFilter}},[_c('v-icon',{attrs:{"left":"","size":"25px"}},[_vm._v(" mdi-filter-variant ")]),_vm._v(" Filter ")],1),_c('router-link',{staticClass:"ma-0 pa-0",attrs:{"to":{ name: 'articles-recording' }}},[_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.availableRecordingArticles,"x-large":""}},[_vm._v(" "+_vm._s(_vm.titleRecording)+" ")])],1)],1),_c('div',{staticClass:"mt-5 mb-5"},_vm._l((_vm.filter.getActiveFilterLabels()),function(activeFilter){return _c('v-chip',{key:activeFilter.filterName,staticClass:"mr-3",attrs:{"close":""},on:{"click:close":() => {
              activeFilter.clear();
              _vm.clearChip();
            }}},[_vm._v(_vm._s(activeFilter.label))])}),1),_c('QuokkaArticleListAdminMyArticleList',{attrs:{"available-recording-articles":_vm.availableRecordingArticles},on:{"article-deleted":_vm.removeArticleFromList}})],1):_vm._e(),(
        !_vm.loading &&
        (_vm.availableRecordingArticles === null ||
          _vm.availableRecordingArticles.length <= 0)
      )?_c('div',{staticClass:"my-4 d-flex justify-start"},[_c('v-btn',{staticClass:"mr-10",attrs:{"disabled":_vm.loading,"color":"primary","large":""},on:{"click":_vm.openFilter}},[_c('v-icon',{attrs:{"left":"","size":"25px"}},[_vm._v(" mdi-filter-variant ")]),_vm._v(" Filter ")],1)],1):_vm._e(),(
        !_vm.loading &&
        (_vm.availableRecordingArticles === null ||
          _vm.availableRecordingArticles.length <= 0)
      )?_c('div',{staticClass:"mt-5 mb-5"},_vm._l((_vm.filter.getActiveFilterLabels()),function(activeFilter){return _c('v-chip',{key:activeFilter.filterName,staticClass:"mr-3",attrs:{"close":""},on:{"click:close":() => {
            activeFilter.clear();
            _vm.clearChip();
          }}},[_vm._v(_vm._s(activeFilter.label))])}),1):_vm._e(),(
        !_vm.loading &&
        (_vm.availableRecordingArticles === null ||
          _vm.availableRecordingArticles.length <= 0)
      )?_c('AlertCard',{attrs:{"type":"information","icon":null,"text":"Sie haben noch keine Artikel verfasst","text-size":"h5","action":{
        label: _vm.titleRecording,
        button: {
          to: { name: 'articles-recording' },
        },
      }}}):_vm._e(),(_vm.totalPageNumber > 1)?_c('v-pagination',{attrs:{"length":_vm.totalPageNumber,"circle":"","total-visible":"7"},on:{"click":_vm.loadArticleList},model:{value:(_vm.pageNumber),callback:function ($$v) {_vm.pageNumber=$$v},expression:"pageNumber"}}):_vm._e(),_c('v-navigation-drawer',{staticClass:"filter-drawer",attrs:{"stateless":"","absolute":"","width":"450px"},model:{value:(_vm.showFilterDrawer),callback:function ($$v) {_vm.showFilterDrawer=$$v},expression:"showFilterDrawer"}},[_c('div',{staticClass:"grey lighten-2 pa-5 d-flex justify-space-between align-center"},[_c('v-icon',{attrs:{"size":"25px"},on:{"click":function($event){_vm.showFilterDrawer = false}}},[_vm._v(" mdi-close ")]),_c('b',{staticClass:"d-flex"},[_c('div',{class:'mr-2 ' +
              (_vm.totalFilterArticleAmountPreviewLoading
                ? 'grey--text'
                : 'primary--text'),staticStyle:{"transition":"all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)"}},[_vm._v(" "+_vm._s(_vm.totalFilterArticleAmountPreview)+" ")]),_vm._v(" "+_vm._s(_vm.totalFilterArticleAmountPreview === 1 ? "Ergebnis" : "Ergebnisse")+" ")]),_c('v-btn',{attrs:{"disabled":_vm.totalFilterArticleAmountPreviewLoading,"color":"primary","large":""},on:{"click":_vm.search}},[_c('v-icon',{attrs:{"left":"","size":"25px"}},[_vm._v(" mdi-magnify ")]),_vm._v(" Suchen ")],1)],1),_c('QuokkaArticleListFilterContent',{attrs:{"filter":_vm.changingFilter}}),_c('div',{staticClass:"px-10"},[_c('v-btn',{attrs:{"disabled":_vm.loading || !_vm.changingFilter.hasActiveFilter(),"block":"","color":"primary","outlined":"","x-large":""},on:{"click":_vm.resetFilter}},[_c('v-icon',{attrs:{"left":"","size":"25px"}},[_vm._v(" mdi-reload ")]),_vm._v(" Filter zurücksetzen ")],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }