
import { Component, Prop, Vue } from "vue-property-decorator";
import Person from "@/shared/data/person";
import QuokkaEventAddressSearch from "@/components/quokka_layout/QuokkaEventAddressSearch.vue";
import { changePersonData, changePersonResponseType } from "@/frontend/lib/api";
import FrontendLogger from "@/frontend/lib/logger";
import QuokkaPersonDetails from "@/components/quokka_layout/admin/QuokkaPersonDetails.vue";
import FrontendSettings from "@/frontend/settings/settings";

/** The View to display user profile and change personal data */
@Component({
  components: { QuokkaPersonDetails, QuokkaEventAddressSearch },
})
export default class QuokkaPersonDataChangeView extends Vue {
  /**
   * The currently logged in {@link Person}.
   */
  @Prop({ required: true }) loggedInPerson!: Person;

  /** Clientside Data validation */
  formValid = true;

  /** Title */
  title = FrontendSettings.authenticatedArea.titleMyProfile;

  /** Display Snackbar and base displayed text */
  snackbar = false;
  snackbarText = "Einige verpflichtende Angaben sind nicht vollständig.";
  /** Variable to disable form buttons while loading and processing */
  loading = false;
  /** Variable for enabling success message display and text */
  success = false;
  successText = "Ihre Angaben wurden erfolgreich aktualisiert!";

  /** If required person data is missing or not */
  missingData = false;

  mounted(): void {
    // check if person data is missing
    if (localStorage.getItem("dataUpdateNeeded") === "true") {
      this.missingData = true;
    }
  }

  /** Submit Person data to backend */
  onSubmit(): void {
    if (this.formValid) {
      this.loading = true;
      changePersonData(this.loggedInPerson)
        .then((person: changePersonResponseType) => {
          this.success = true;
          this.successText = person.savedType;

          // This means we got here from login
          if (this.missingData) {
            // remove localStorage Item to disable routing to this view
            localStorage.removeItem("dataUpdateNeeded");
            // so after save, we return to the standard admin-page
            this.$router.push({
              name: "secure",
            });
          }
        })
        .catch((e) => {
          this.snackbar = true;
          /** specific messages depending on error code */
          switch (e.response.data.error_code) {
            case 0:
              this.snackbarText =
                "Einige der angegebenen Daten sind nicht korrekt.";
              break;
            case 1:
              this.snackbarText =
                "Geänderte Daten konnten nicht gespeichert werden. Bitte versuchen Sie es später noch einmal.";
              break;
            case 2:
              this.snackbarText =
                "Einige verpflichtende Angaben sind nicht vollständig.";
              break;
            case 3:
              this.snackbarText =
                "Die angegebene Email-Adresse ist bereits in Gebrauch.";
              break;
            case 4:
              this.snackbarText = "Das aktuelle Passwort ist nicht korrekt.";
              break;
            default:
              this.snackbarText =
                "Geänderte Daten konnten nicht gespeichert werden. Bitte versuchen Sie es später noch einmal.";
              break;
          }
          FrontendLogger.error({
            message: "Failed to save changed data.",
            data: e,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.snackbar = true;
      this.snackbarText =
        "Einige verpflichtende Angaben sind nicht vollständig.";
    }
  }
  /** Check if both new passwords are the same */
  matchPasswords(): boolean {
    if (this.loggedInPerson?.password1 === this.loggedInPerson?.password2) {
      return true;
    } else return false;
  }
  /** check if old password is required */
  requireOldPassword(): boolean {
    if (
      this.loggedInPerson?.password2 !== null &&
      this.loggedInPerson?.password1 !== null
    ) {
      return true;
    } else return false;
  }
}
