/**
 * Formats the given {@link value} into a valid currency.
 *
 * The default format for locales is `de-de` and currency symbol `€`.
 *
 * @param {number} value The number value to format
 * @param options
 * @param {boolean} options.removeCurrencySymbol Whether to add the currency symbol or not
 * @param {boolean} options.currency Currency to use
 * @param {boolean} options.currencySymbol The Currency Symbol to use
 */
export function formatCurrency(
  value: number,
  options:
    | undefined
    | {
        removeCurrencySymbol?: boolean;
        currency?: string;
        currencySymbol?: string;
      } = {}
): string {
  let local = "de-de";
  if (options?.currency && options.currency == "CHF") {
    local = "de-ch";
  }

  let formatted = value.toLocaleString(local, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: false,
  });
  // Add Currency Symbol
  if (!options || !options.removeCurrencySymbol)
    formatted += " " + options?.currencySymbol ?? "€";

  return formatted;
}
