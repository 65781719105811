
import { Component, Prop, Vue } from "vue-property-decorator";

/**
 * A wrapping component for v-sheet to add a label in the sheets top left stroke.
 *
 * Use the <slot> for sheets content.
 */
@Component
export default class LabeledSheet extends Vue {
  /**
   * The label to add to this sheet.
   */
  @Prop({ required: true }) label!: string;
}
