import DataStruct from "@/shared/data/datastruct";
import Address, { ServerAddressDataType } from "@/shared/data/address";
import Rubric, { ServerRubricDataType } from "@/shared/data/rubric";
import Tag, { ServerTagDataType } from "@/shared/data/tag";
import {
  RecordingEventAppointments,
  RecordingEventAppointmentsFree,
  ServerRecordingEventAppointmentsDataType,
} from "@/shared/data/event_appointments";
import EventDate, { ServerEventDateDataType } from "@/shared/data/event_date";
import { DateTime } from "luxon";
import { ArticleDefinition } from "@/shared/data/article_definition";
import { parseDate } from "@/shared/lib/datetime";
import { InputAdditionField } from "@/shared/data/addition_field";
import { getArticleDefinition } from "@/frontend/lib/api";
import {
  RecordingMedia,
  ServerRecordingMediaDataType,
} from "@/shared/data/media";
import {
  MediaInformationData,
  ServerMediaInformationDataType,
} from "@/shared/data/media_information";

/**
 * The RecordingArticle Data-Class, containing all properties for a recorded Article
 */
export default class RecordingArticle extends DataStruct {
  readonly article_id: number;
  article_data: { [key: string]: string | boolean | DateTime | number | null } =
    {};
  article_release_info: RecordingEventAppointments | null = null;
  article_title: string | null = null;
  event_date: EventDate;
  location: Address | null = null;
  promoter: Address | null = null;
  rubric: Rubric | null = null;
  tags: Tag[] = [];
  articleDefinition: ArticleDefinition | null = null;
  additionalImageInformation: MediaInformationData[] = [];

  /**
   * List of `upload_key`s from the uploaded media.
   * Only set to add media. Will not be output by the server.
   */
  upload_media: string[] = [];

  /** List of uploaded {@link Media} assigned to the {@link RecordingArticle}. */
  uploaded_media: RecordingMedia[] = [];

  /** Whether all added files are successfully uploaded. */
  fileUploadReady = true;

  /**
   * The count of removed, already saved files
   */
  countRemovedUploads = 0;

  /**
   * Cache of available {@link ArticleDefinition}s loaded from the server.
   * The key is the rubric-id.
   */
  static articleDefinitionsCache: {
    [key: string]: Promise<ArticleDefinition>;
  } = {};

  /** Creates a new instance of Article with given data.
   * @param data
   * @throws TypeError if any data does not match type
   */
  constructor(data: { article_id: number }) {
    super();
    if (DataStruct.validateValue(data, "article_id", "number")) {
      this.article_id = data.article_id;
    } else {
      throw new TypeError("Could not initialize Article. Invalid id.");
    }
    this.event_date = new EventDate();
  }

  /**
   * Creates a new RecordingArticle-object with data from Server
   *  @param data
   *  @throws TypeError
   */
  static async fromServer(
    data: ServerRecordingArticleDataType
  ): Promise<RecordingArticle> {
    return new Promise<RecordingArticle>((resolve, reject) => {
      const article = new RecordingArticle({
        article_id: data.article_id ?? 0,
      });

      // Load the data
      // Appointments
      if (DataStruct.validateValue(data, "article_release_info", "object")) {
        article.article_release_info = RecordingEventAppointments.fromServer(
          data.article_release_info
        );
      } else {
        article.article_release_info = new RecordingEventAppointmentsFree();
      }
      if (DataStruct.validateValue(data, "event_date", "object")) {
        article.event_date = EventDate.fromServer(data.event_date);
      } else {
        article.event_date = new EventDate();
        article.event_date.dateObject = DateTime.now();
      }

      if (DataStruct.validateValue(data, "location", "object")) {
        article.location = Address.fromServer(
          <ServerAddressDataType>data.location
        );
      }
      if (DataStruct.validateValue(data, "promoter", "object")) {
        article.promoter = Address.fromServer(
          <ServerAddressDataType>data.promoter
        );
      }
      if (DataStruct.validateValue(data, "rubric", "object")) {
        article.rubric = Rubric.fromServer(<ServerRubricDataType>data.rubric);
      }

      if (Object.prototype.hasOwnProperty.call(data, "tags")) {
        article.tags = [];
        data.tags?.forEach((item) => article.tags.push(Tag.fromServer(item)));
      }

      if (Object.prototype.hasOwnProperty.call(data, "uploaded_media")) {
        article.uploaded_media = [];
        data.uploaded_media?.forEach((item) =>
          article.uploaded_media.push(RecordingMedia.fromServer(item))
        );
      }

      if (
        data.article_title != undefined &&
        DataStruct.validateValue(data, "article_title", "string")
      ) {
        article.article_title = data.article_title;
      } else {
        article.article_title = "Eigener Artikel"; // TODO: Warum default wert? - Fallback für Artikelübersicht, falls kein Titelfeld definiert ist
      }

      if (
        Object.prototype.hasOwnProperty.call(data, "additionalImageInformation")
      ) {
        article.additionalImageInformation = [];
        data.additionalImageInformation?.forEach((item) => {
          article.additionalImageInformation.push(
            MediaInformationData.fromServer(item)
          );
        });
      }

      // Get Article-Definition
      const rubricId = article.rubric?.rubric_id ?? null;
      if (!rubricId) {
        // Missing Rubric id
        reject(
          new Error(
            "Failed to parse RecordingArticle-object. Missing rubric-id to load article definition."
          )
        );
        return;
      }

      let getArticleDefinitionPromise;
      if (RecordingArticle.articleDefinitionsCache[rubricId]) {
        getArticleDefinitionPromise =
          RecordingArticle.articleDefinitionsCache[rubricId];
      } else {
        getArticleDefinitionPromise = getArticleDefinition(rubricId.toString());
        RecordingArticle.articleDefinitionsCache[rubricId] =
          getArticleDefinitionPromise;
      }

      getArticleDefinitionPromise
        .then((articleDefinition) => {
          if (!articleDefinition) {
            throw new Error("Failed to load article definition.");
          }

          // Load and parse article data
          if (DataStruct.validateValue(data, "article_data", "object")) {
            data.article_data.forEach((item) => {
              article.article_data[item.field_name] = item.field_value;

              // if input Field is a date parse String to date

              if (articleDefinition) {
                for (const formRow of articleDefinition.formDefinition) {
                  for (const additionField of formRow) {
                    if (
                      !additionField.additionField ||
                      !additionField.additionField.type
                    )
                      continue;
                    if (
                      item.field_name == additionField.additionField.name &&
                      additionField.additionField.type == "input"
                    ) {
                      const inputField = <InputAdditionField>(
                        additionField.additionField
                      );

                      if (inputField.input_type == "date") {
                        try {
                          const parsedDate = parseDate(
                            <string>item.field_value,
                            "yyyy-MM-dd"
                          );
                          if (parsedDate)
                            article.article_data[item.field_name] = parsedDate;
                        } catch (e) {
                          throw new TypeError(
                            "Invalid date-value '" +
                              item.field_value +
                              "' for article-field '" +
                              item.field_name +
                              "'."
                          );
                        }
                      }
                      break;
                    }
                  }
                }
              }
            });
          }

          // set articleDefinition in article
          article.articleDefinition = articleDefinition;
          resolve(article);
        })
        .catch((e) => {
          reject(
            new Error(
              "Failed to load RecordingArticle from server. " + e.toString()
            )
          );
        });
    });
  }

  /** Creates a new RecordingArticle with an id of 0 */
  static create(): RecordingArticle {
    return new RecordingArticle({
      article_id: 0,
    });
  }
}

/** The data structure as returned from the MSUevent-Server */
export type ServerRecordingArticleDataType = {
  article_id?: number;
  promoter?: ServerAddressDataType;
  location?: ServerAddressDataType;
  rubric: ServerRubricDataType;
  event_date: ServerEventDateDataType;
  article_release_info: ServerRecordingEventAppointmentsDataType;
  tags?: ServerTagDataType[];
  article_data: ServerAdditionFieldValuesType[];
  article_title?: string;
  uploaded_media?: ServerRecordingMediaDataType[];
  additionalImageInformation?: ServerMediaInformationDataType[];
};

/** The data structure for RecordingArticle saving */
export type ServerAdditionFieldValuesType = {
  field_name: string;
  field_value: string | number;
};
