
import { Component, Vue } from "vue-property-decorator";
import AlertCard from "@/components/quokka_layout/ui/AlertCard.vue";
import { getAdvertOrders, GetAdvertOrdersReturnType } from "@/frontend/lib/api";
import { Order } from "@/shared/data/advert";
import QuokkaAdListAdminMyAdList from "@/components/quokka_layout/admin/ads/QuokkaAdListAdminMyAdList.vue";
import FrontendSettings from "@/frontend/settings/settings";

/**
 * A view to show all recorded {@link Order}s of the logged-in user.
 *
 * TODO: Pager
 */
@Component({
  data() {
    return { FrontendSettings };
  },
  components: { AlertCard, QuokkaAdListAdminMyAdList },
})
export default class QuokkaAdsListAdminView extends Vue {
  /**
   * List of available {@link Order}s of the logged-in user for the page in url.
   */
  availableAdvertOrders: Order[] | null = null;

  /**
   * Tries to load available {@link Order}s.
   */
  mounted(): void {
    getAdvertOrders({})
      .then((data: GetAdvertOrdersReturnType) => {
        this.availableAdvertOrders = data.advertOrders;
      })
      .catch((reason) => {
        // TODO: Fehler
        console.error("Error " + reason);
      });
  }
}
