
import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";
import { formatDate, parseDate } from "@/shared/lib/datetime";
import { DateTime } from "luxon";
import FrontendLogger from "@/frontend/lib/logger";

/**
 * A datepicker to pick hours and minutes.
 *
 * Provides a visible selection + text-based date parsing.
 */
@Component
export default class QuokkaDatePicker extends Vue {
  /**
   * The v-model of this time-picker.
   */
  @VModel() date!: DateTime | null;

  /**
   * Update input field value with set date.
   */
  mounted(): void {
    this.onDateModelUpdate();
  }

  /**
   * Sets the current value of the v-model to given value.
   *
   * If the value fails to parse to a {@link DateTime}, null will be set.
   *
   * @param value
   */
  set dateValue(value: string | null) {
    this.date = parseDate(value, "dd.MM.yyyy") ?? null;
  }

  /**
   * Parses and returns the value of the current v-model.
   */
  get dateValue(): string | null {
    return formatDate(this.date, "dd.MM.yyyy") ?? null;
  }

  /**
   * Watches the v-model and updates the {@link inputFieldValue} on change.
   */
  @Watch("date")
  onDateModelUpdate(): void {
    this.inputFieldValue = this.dateValue;
  }

  /**
   * A list of valid formats that can be parsed.
   *
   * @see https://moment.github.io/luxon/#/formatting?id=table-of-tokens for formatting options.
   */
  readonly validFormats: string[] = [
    // These formats should be available with all permutations of the divider `.` with these: `none`, `-`, `/`
    "dd.MM.yyyy",
    "dd.MM.yy",
    "dd.MM.y",
    "dd.M.yyyy",
    "dd.M.yy",
    "dd.M.y",
    "d.MM.yyyy",
    "d.MM.yy",
    "d.MM.y",
    "d.M.yyyy",
    "d.M.yy",
    "d.M.y",
    // Formats with divider `-`
    "yyyy-MM-dd",
    "yy-MM-dd",
    "y-MM-dd",
    "yyyy-M-dd",
    "yy-M-dd",
    "y-M-dd",
    "yyy-MM-d",
    "yy-MM-d",
    "y-MM-d",
    "yyy-M-d",
    "yy-M-d",
    "y-M-d",
    // Formats with divider `none`
    "ddMMyyyy",
    "ddMMyy",
    "ddMMy",
    "ddMyyyy",
    "ddMyy",
    "ddMy",
    "dMMyyyy",
    "dMMyy",
    "dMMy",
    "dMyyyy",
    "dMyy",
    "dMy",
    // Formats with divider `/`
    "dd/MM/yyyy",
    "dd/MM/yy",
    "dd/MM/y",
    "dd/M/yyyy",
    "dd/M/yy",
    "dd/M/y",
    "d/MM/yyyy",
    "d/MM/yy",
    "d/MM/y",
    "d/M/yyyy",
    "d/M/yy",
    "d/M/y",
  ];

  /**
   * The label for this field.
   */
  @Prop({ required: true }) label!: string;

  /**
   * Rules to set for this field.
   */
  @Prop() rules!: ((value: string) => boolean | string)[];

  /**
   * Whether this field should be disabled or not.
   */
  @Prop() disabled!: boolean;

  /**
   * Whether to show the picker modal or not.
   */
  showPickerModal = false;

  /**
   * An error message to display for this field.
   */
  errorMessage = "";

  /**
   * The model of the input field.
   */
  inputFieldValue: string | null = null;

  /**
   * Validates and sets the current value to the {@link inputFieldValue}.
   *
   * @param value
   */
  set pickerModel(value: string | null) {
    this.inputFieldValue = value;
    this.parseInput();
  }

  /**
   * Returns the value of the {@link inputFieldValue} for the picker-model.
   */
  get pickerModel(): string | null {
    return (
      formatDate(parseDate(this.inputFieldValue, "dd.MM.yyyy"), "yyyy-MM-dd") ??
      null
    );
  }

  /**
   * Tries to parse the year to a full DateTime, if no date is currently selected.
   * If a date is already available, we just change the year of it.
   *
   * This is done, so that the user can just select the year, and the month and day (01) will be added automatically.
   *
   * @param year
   */
  clickYear(year: number): void {
    let currentSelectedDate = parseDate(this.inputFieldValue, "dd.MM.yyyy");

    if (!currentSelectedDate) {
      // Nothing selected => We use the year to create a new DateTime
      try {
        const parsed = parseDate(year.toString(), "yyyy");

        if (parsed) {
          this.inputFieldValue = formatDate(parsed, "dd.MM.yyyy") ?? "";
          // Update model
          this.dateValue = this.inputFieldValue;
          return;
        }
      } catch (e) {
        // Something went wrong, this should not happen
        FrontendLogger.error({
          message: "Failed to parse the year to a DateTime-object.",
          data: year,
          scope: "ui",
        });
      }
    } else {
      // Change the year
      currentSelectedDate = currentSelectedDate.set({
        year: year,
      });
      this.inputFieldValue =
        formatDate(currentSelectedDate, "dd.MM.yyyy") ?? "";
      // Update model
      this.dateValue = this.inputFieldValue;
      return;
    }
  }

  /**
   * Tries to parse the month to a full DateTime, if no date is currently selected.
   * If a date is already available, we just change the month of it.
   *
   * This is done, so that the user can just select the month, and the day (01) will be added automatically.
   *
   * @param yearMonth the year with the month in format "yyyy-MM"
   */
  clickMonth(yearMonth: number): void {
    let currentSelectedDate = parseDate(this.inputFieldValue, "dd.MM.yyyy");

    try {
      const parsed = parseDate(yearMonth.toString(), "yyyy-MM");

      if (parsed) {
        if (!currentSelectedDate) {
          // Nothing selected => We use the year to create a new DateTime
          this.inputFieldValue = formatDate(parsed, "dd.MM.yyyy") ?? "";
          // Update model
          this.dateValue = this.inputFieldValue;
          return;
        } else {
          // Change the month
          currentSelectedDate = currentSelectedDate.set({
            month: parsed.month,
          });
          this.inputFieldValue =
            formatDate(currentSelectedDate, "dd.MM.yyyy") ?? "";
          // Update model
          this.dateValue = this.inputFieldValue;
          return;
        }
      }
    } catch (e) {
      // Something went wrong, this should not happen
      FrontendLogger.error({
        message: "Failed to parse the month to a DateTime-object.",
        data: yearMonth,
        scope: "ui",
      });
    }
  }

  /**
   * Tries to parse the current {@link inputFieldValue} to a valid date and sets it
   * to the {@link inputFieldValue} and the v-model.
   */
  parseInput(): void {
    this.errorMessage = "";

    if (this.inputFieldValue === null) {
      this.inputFieldValue = null;
      // Update model
      this.dateValue = this.inputFieldValue;
      return;
    }

    // Go through every possible format and try to parse it
    for (const validFormat of this.validFormats) {
      try {
        const parsed = parseDate(this.inputFieldValue, validFormat);

        if (parsed) {
          this.inputFieldValue = formatDate(parsed, "dd.MM.yyyy") ?? "";
          // Update model
          this.dateValue = this.inputFieldValue;
          return;
        }
      } catch (e) {
        // We don't need to do anything, because we want to validate with the other formats, too
      }
    }

    // Failed to validate the input => Error and replace with old valid value
    this.errorMessage = "Ungültiges Datum '" + this.inputFieldValue + "'";
    this.inputFieldValue = this.dateValue;
  }
}
