import DataStruct from "@/shared/data/datastruct";
import Media, {
  MediaImage,
  RecordingMedia,
  ServerMediaDataType,
} from "@/shared/data/media";
import { parseDate } from "@/shared/lib/datetime";
import { DateTime } from "luxon";
import { MediaUploaderData } from "@/shared/lib/media_uploader";
import {
  DisplayInformationDataType,
  MediaInformationData,
} from "@/shared/data/media_information";

/**
 * AdvertCampaign class
 */
export class AdvertCampaign extends DataStruct {
  readonly advert_campaign_id: number;
  advert_partner_id = 0;
  name = "";
  advert_channels: AdvertChannel[] = [];
  advert_partner!: ServerAdvertPartnerDataType;

  /**
   * Creates a new instance of AdvertCampaign with given data.
   *
   * @param data The data to load
   * @throws TypeError if any property does not fit the type
   */
  constructor(data: { advertCampaignId: number }) {
    super();

    if (DataStruct.validateValue(data, "advertCampaignId", "number")) {
      this.advert_campaign_id = data.advertCampaignId;
    } else {
      // We cannot initialize without an id
      throw new TypeError(
        "Could not initialize AdvertCampaign. Invalid advertCampaignId."
      );
    }
  }

  /**
   * Creates a new AdvertCampaign-object with the given data returned from the server.
   *
   * @param data
   * @throws TypeError if any property does not fit the type
   */
  static fromServer(data: ServerAdvertCampaignDataType): AdvertCampaign {
    const advertCampaign = new AdvertCampaign({
      advertCampaignId: data.advert_campaign_id,
    });

    // Load the data
    if (DataStruct.validateValue(data, "advert_partner_id", "number"))
      advertCampaign.advert_partner_id = data.advert_partner_id;
    if (DataStruct.validateValue(data, "name", "string"))
      advertCampaign.name = data.name;
    if (Object.prototype.hasOwnProperty.call(data, "advert_channels")) {
      advertCampaign.advert_channels = [];
      data.advert_channels?.forEach((item) =>
        advertCampaign.advert_channels.push(AdvertChannel.fromServer(item))
      );
    }
    if (Object.prototype.hasOwnProperty.call(data, "advert_partner")) {
      advertCampaign.advert_partner = data.advert_partner;
    }

    return advertCampaign;
  }

  /**
   * TODO: Doc und holen
   */
  get currency(): {
    key: string;
    symbol: string;
  } {
    return {
      key: "",
      symbol: "",
    };
  }
}

/**
 * The data structure as returned by the MSUevent-Server.
 */
export type ServerAdvertCampaignDataType = {
  advert_campaign_id: number;
  advert_partner_id: number;
  name: string;
  advert_channels: ServerAdvertChannelDataType[];
  advert_partner: ServerAdvertPartnerDataType;
};

/**
 * AdvertChannel class
 */
export class AdvertChannel extends DataStruct {
  readonly advert_channel_id: number;
  name = "";
  description = "";
  sort: number | null = null;
  order_mode = "";
  must_link_to_event = ""; // TODO enum? boolean!
  linked_event_priority = "";
  advert_medium: AdvertMedium | null = null;
  advert_slots: AdvertSlot[] = [];

  /**
   * Creates a new instance of AdvertChannel with given data.
   *
   * @param data The data to load
   * @throws TypeError if any property does not fit the type
   */
  constructor(data: { advertChannelId: number }) {
    super();

    if (DataStruct.validateValue(data, "advertChannelId", "number")) {
      this.advert_channel_id = data.advertChannelId;
    } else {
      // We cannot initialize without an id
      throw new TypeError(
        "Could not initialize AdvertChannel. Invalid advertChannelId."
      );
    }
  }

  /**
   * Creates a new AdvertChannel-object with the given data returned from the server.
   *
   * @param data
   * @throws TypeError if any property does not fit the type
   */
  static fromServer(data: ServerAdvertChannelDataType): AdvertChannel {
    const advertChannel = new AdvertChannel({
      advertChannelId: data.advert_channel_id,
    });

    // Load the data
    if (DataStruct.validateValue(data, "sort", "number"))
      advertChannel.sort = data.sort;
    if (DataStruct.validateValue(data, "name", "string"))
      advertChannel.name = data.name;
    if (DataStruct.validateValue(data, "description", "string"))
      advertChannel.description = data.description;
    if (DataStruct.validateValue(data, "order_mode", "string"))
      advertChannel.order_mode = data.order_mode;
    if (DataStruct.validateValue(data, "order_mode", "string"))
      advertChannel.must_link_to_event = data.must_link_to_event;
    if (DataStruct.validateValue(data, "linked_event_priority", "string"))
      advertChannel.linked_event_priority = data.linked_event_priority;
    if (DataStruct.validateValue(data, "advert_medium", "object"))
      advertChannel.advert_medium = AdvertMedium.fromServer(data.advert_medium);
    if (Object.prototype.hasOwnProperty.call(data, "advert_slots")) {
      advertChannel.advert_slots = [];
      data.advert_slots?.forEach((item) =>
        advertChannel.advert_slots.push(AdvertSlot.fromServer(item))
      );
    }
    return advertChannel;
  }
}

/**
 * The data structure as returned by the MSUevent-Server.
 */
export type ServerAdvertChannelDataType = {
  advert_channel_id: number;
  name: string;
  description: string;
  sort: number;
  order_mode: string;
  must_link_to_event: string;
  linked_event_priority: string;
  advert_medium: ServerAdvertMediumDataType;
  advert_slots: ServerAdvertSlotDataType[];
};

/**
 * AdvertPartnerCompanyAddress class
 */
export class AdvertPartnerCompanyAddress extends DataStruct {
  link_website = "";
  link_tos = "";
  link_legalnotice = "";
  link_dataprivacy = "";

  /**
   * Creates a new AdvertPartner-object with the given data returned from the server.
   *
   * @param data
   * @throws TypeError if any property does not fit the type
   */
  static fromServer(
    data: ServerAdvertPartnerCompanyAddressDataType
  ): AdvertPartnerCompanyAddress {
    const advertPartnerCompanyAddress = new AdvertPartnerCompanyAddress();

    // Load the data
    if (DataStruct.validateValue(data, "link_website", "string"))
      advertPartnerCompanyAddress.link_website = data.link_website;
    if (DataStruct.validateValue(data, "link_tos", "string"))
      advertPartnerCompanyAddress.link_tos = data.link_tos;
    if (DataStruct.validateValue(data, "link_legalnotice", "string"))
      advertPartnerCompanyAddress.link_legalnotice = data.link_legalnotice;
    if (DataStruct.validateValue(data, "link_dataprivacy", "string"))
      advertPartnerCompanyAddress.link_dataprivacy = data.link_dataprivacy;

    return advertPartnerCompanyAddress;
  }
}

/**
 * The data structure as returned by the MSUevent-Server.
 */
export type ServerAdvertPartnerCompanyAddressDataType = {
  link_website: string;
  link_tos: string;
  link_legalnotice: string;
  link_dataprivacy: string;
};

/**
 * AdvertPartner class
 */
export class AdvertPartner extends DataStruct {
  readonly advert_partner_id: number;
  name = "";
  active = "";
  complete = "";
  currency = "";
  currency_symbol = "";
  company_address: AdvertPartnerCompanyAddress | null = null;
  alt_invoice_address_allow = "";
  alt_invoice_address_settings: {
    [k: string]: unknown;
    label: unknown;
    allow: unknown;
    require: unknown;
  } | null = null;
  template_dataprivacy = "";
  template_legalnotice = "";
  template_tos = "";

  /**
   * Creates a new instance of AdvertPartner with given data.
   *
   * @param data The data to load
   * @throws TypeError if any property does not fit the type
   */
  constructor(data: { advert_partner_id: number }) {
    super();

    if (DataStruct.validateValue(data, "advertPartnerId", "number")) {
      this.advert_partner_id = data.advert_partner_id;
    } else {
      // We cannot initialize without an id
      throw new TypeError(
        "Could not initialize AdvertPartner. Invalid advertPartnerId."
      );
    }
  }

  /**
   * Creates a new AdvertPartner-object with the given data returned from the server.
   *
   * @param data
   * @throws TypeError if any property does not fit the type
   */
  static fromServer(data: ServerAdvertPartnerDataType): AdvertPartner {
    const advertPartner = new AdvertPartner({
      advert_partner_id: data.advert_partner_id,
    });

    // Load the data
    if (DataStruct.validateValue(data, "name", "string"))
      advertPartner.name = data.name;
    if (DataStruct.validateValue(data, "active", "string"))
      advertPartner.active = data.active;
    if (DataStruct.validateValue(data, "complete", "string"))
      advertPartner.complete = data.complete;
    if (DataStruct.validateValue(data, "currency", "string"))
      advertPartner.currency = data.currency;
    if (DataStruct.validateValue(data, "currency_symbol", "string"))
      advertPartner.currency_symbol = data.currency_symbol;
    if (DataStruct.validateValue(data, "alt_invoice_address_allow", "string"))
      advertPartner.alt_invoice_address_allow = data.alt_invoice_address_allow;
    if (
      Object.prototype.hasOwnProperty.call(data, "alt_invoice_address_settings")
    ) {
      advertPartner.alt_invoice_address_settings =
        data.alt_invoice_address_settings;
    }
    if (DataStruct.validateValue(data, "template_dataprivacy", "string"))
      advertPartner.template_dataprivacy = data.template_dataprivacy;
    if (DataStruct.validateValue(data, "template_legalnotice", "string"))
      advertPartner.template_legalnotice = data.template_legalnotice;
    if (DataStruct.validateValue(data, "template_tos", "string"))
      advertPartner.template_tos = data.template_tos;
    if (Object.prototype.hasOwnProperty.call(data, "company_address")) {
      advertPartner.company_address = AdvertPartnerCompanyAddress.fromServer(
        data.company_address
      );
    }

    return advertPartner;
  }
}

/**
 * The data structure as returned by the MSUevent-Server.
 */
export type ServerAdvertPartnerDataType = {
  advert_partner_id: number;
  name: string;
  active: string;
  complete: string;
  currency: string;
  currency_symbol: string;
  company_address: AdvertPartnerCompanyAddress;
  template_legalnotice: string;
  template_dataprivacy: string;
  template_tos: string;
  alt_invoice_address_allow: string;
  alt_invoice_address_settings: {
    [k: string]: unknown;
    label: { [k: string]: string };
    allow: { [k: string]: string };
    require: { [k: string]: string };
  };
};

/**
 * AdvertMedium class
 */
export class AdvertMedium extends DataStruct {
  readonly advert_medium_id: number;
  name = "";
  priority: number | null = null;
  type = "";
  community_id: number | null = null;
  media: Media[] = [];
  description = "";

  /**
   * Creates a new instance of AdvertMedium with given data.
   *
   * @param data The data to load
   * @throws TypeError if any property does not fit the type
   */
  constructor(data: { advertMediumId: number }) {
    super();

    if (DataStruct.validateValue(data, "advertMediumId", "number")) {
      this.advert_medium_id = data.advertMediumId;
    } else {
      // We cannot initialize without an id
      throw new TypeError(
        "Could not initialize AdvertMedium. Invalid advertMediumId."
      );
    }
  }

  /**
   * Creates a new AdvertMedium-object with the given data returned from the server.
   *
   * @param data
   * @throws TypeError if any property does not fit the type
   */
  static fromServer(data: ServerAdvertMediumDataType): AdvertMedium {
    const advertMedium = new AdvertMedium({
      advertMediumId: data.advert_medium_id,
    });

    // Load the data
    if (DataStruct.validateValue(data, "name", "string"))
      advertMedium.name = data.name;
    if (DataStruct.validateValue(data, "priority", "number"))
      advertMedium.priority = data.priority;
    if (DataStruct.validateValue(data, "type", "string"))
      advertMedium.type = data.type;
    if (DataStruct.validateValue(data, "description", "string"))
      advertMedium.description = data.description;
    if (DataStruct.validateValue(data, "community_id", "number"))
      advertMedium.community_id = data.community_id;
    if (Object.prototype.hasOwnProperty.call(data, "media")) {
      advertMedium.media = [];
      data.media?.forEach((item) =>
        advertMedium.media.push(Media.fromServer(item))
      );
    }

    return advertMedium;
  }

  /**
   * Returns the first set {@link MediaImage } for this {@link AdvertMedium}.
   */
  getPreviewImage(): MediaImage | undefined {
    // Get first image
    return (
      (this.media.find((obj) => obj.type === "image") as MediaImage) ?? null
    );
  }
}

/**
 * The data structure as returned by the MSUevent-Server.
 */
export type ServerAdvertMediumDataType = {
  advert_medium_id: number;
  name: string;
  priority: number;
  type: string;
  description: string;
  community_id: number;
  media: ServerMediaDataType[];
};

export class AdvertContentMediaUploaderData extends DataStruct {
  advert_id = 0;

  /**
   * List of newly uploaded media.
   * The strings are the upload_keys.
   */
  upload_media: string[] = [];

  /**
   * List of all uploaded media.
   *
   * Can contain media-ids of an {@link RecordingEvent} which should be added to this order.
   */
  uploaded_media: RecordingMedia[] = [];

  /**
   * Whether the uploader is ready to be saved or not.
   */
  fileUploadReady = false;

  /**
   * The count of removed, already saved files
   */
  countRemovedUploads = 0;

  /**
   * Additional media information
   */
  additionalImageInformation: MediaInformationData[] = [];

  /**
   * Creates a new instance of Advert with given data.
   *
   * @param data The data to load
   * @throws TypeError if any property does not fit the type
   */
  constructor(data: { advert_id: number }) {
    super();

    if (DataStruct.validateValue(data, "advert_id", "number")) {
      this.advert_id = data.advert_id;
    } else {
      // We cannot initialize without an id
      throw new TypeError("Could not initialize Advert. Invalid id.");
    }
  }

  /**
   * Creates a new AdvertContentMediaUploaderData with an id of 0.
   */
  static create(): AdvertContentMediaUploaderData {
    return new AdvertContentMediaUploaderData({
      advert_id: 0,
    });
  }
}

/**
 * AdvertContent-Data class
 */
export class AdvertContent extends DataStruct {
  advertContent = {};
  advertMedia: MediaUploaderData | null = null;
  selectedMediaEventMediaId: number[] = [];
  additionalImageInformation: DisplayInformationDataType[] = [];
  differentAdditionalImageInformation: { [key: number]: unknown } = {};
  eventId = 0;
}

/**
 * Order-Data class
 */
export class Order extends DataStruct {
  readonly advert_order_id: number;
  person_id: number | null = null;
  person_address: OrderAddress | null = null;
  order_item: OrderItem[] = [];
  advert_partner_id = 0;
  order_date_time: DateTime | null = null;
  currency = "";
  currency_symbol = "";
  vat_included = "";
  invoice_address: OrderAddress | null = null;
  total_price = 0;

  /**
   * Creates a new instance of Order with given data.
   *
   * @param data The data to load
   * @throws TypeError if any property does not fit the type
   */
  constructor(data: { advertOrderId: number }) {
    super();

    if (DataStruct.validateValue(data, "advertOrderId", "number")) {
      this.advert_order_id = data.advertOrderId;
    } else {
      // We cannot initialize without an id
      throw new TypeError(
        "Could not initialize AdvertOrder. Invalid advertOrderId."
      );
    }
  }

  /**
   * Creates a new Order-object with the given data returned from the server.
   *
   * @param data
   * @throws TypeError if any property does not fit the type
   */
  static fromServer(data: ServerAdvertOrderDataType): Order {
    const advertOrder = new Order({
      advertOrderId: data.advert_order_id,
    });

    // Load the data
    if (DataStruct.validateValue(data, "person_id", "number"))
      advertOrder.person_id = data.person_id;
    if (DataStruct.validateValue(data, "person_address", "object"))
      advertOrder.person_address = OrderAddress.fromServer(data.person_address);
    if (Object.prototype.hasOwnProperty.call(data, "order_item")) {
      advertOrder.order_item = [];
      data.order_item?.forEach((item) =>
        advertOrder.order_item.push(OrderItem.fromServer(item))
      );
    }
    if (DataStruct.validateValue(data, "advert_partner_id", "number"))
      advertOrder.advert_partner_id = data.advert_partner_id;
    if (DataStruct.validateValue(data, "order_date_time", "string"))
      advertOrder.order_date_time =
        parseDate(<string>data.order_date_time, "yyyy-MM-dd") ?? null;
    if (DataStruct.validateValue(data, "currency", "string"))
      advertOrder.currency = data.currency;
    if (DataStruct.validateValue(data, "currency_symbol", "string"))
      advertOrder.currency_symbol = data.currency_symbol;
    if (DataStruct.validateValue(data, "vat_included", "string"))
      advertOrder.vat_included = data.vat_included;
    if (DataStruct.validateValue(data, "invoice_address", "object"))
      advertOrder.invoice_address = OrderAddress.fromServer(
        data.invoice_address
      );
    if (DataStruct.validateValue(data, "total_price", "number"))
      advertOrder.total_price = data.total_price;
    return advertOrder;
  }
}

/**
 * The data structure as returned by the MSUevent-Server.
 */
export type ServerAdvertOrderDataType = {
  advert_order_id: number;
  person_id: number;
  person_address: OrderAddress;
  order_item: ServerOrderItemDataType[];
  advert_partner_id: number;
  order_date_time: string;
  currency: string;
  currency_symbol: string;
  vat_included: string;
  invoice_address: OrderAddress;
  total_price: number;
};

/**
 * // TODO sollte AdvertOrderItem heißen
 * OrderItem-Data class
 */
export class OrderItem extends DataStruct {
  advert_order_item_id = 0;
  advert_order_id = 0;
  order_number = "";
  advert_channel_id = 0;
  advert_channel_name = "";
  advert_slot_id = 0;
  advert_slot_name = "";
  order_item_content: AdvertOrderItemContent | null = null;
  booking_dates: string[] = [];
  event_id: number | null = null; // TODO: Überall bedacht, dass es null sein kann?
  item_price = 0;
  item_total_price = 0;

  /**
   * Calculate the total price for an item.
   */
  get totalPrice(): number {
    return this.booking_dates.length * this.item_price;
  }

  /**
   * Creates a new instance of OrderItem with given data.
   *
   * @param data The data to load
   * @throws TypeError if any property does not fit the type
   */
  constructor(data: { advertOrderItemId: number }) {
    super();

    if (DataStruct.validateValue(data, "advertOrderItemId", "number")) {
      this.advert_order_item_id = data.advertOrderItemId;
    } else {
      // We cannot initialize without an id
      throw new TypeError(
        "Could not initialize OrderItem. Invalid advertOrderItemId."
      );
    }
  }

  /**
   * Creates a new OrderItem-object with the given data.
   *
   * @param data
   * @throws TypeError if any property does not fit the type
   */
  static fromServer(data: ServerOrderItemDataType): OrderItem {
    const advertOrderItem = new OrderItem({
      advertOrderItemId: data.advert_order_item_id,
    });

    // Load the data
    if (DataStruct.validateValue(data, "advert_order_id", "number"))
      advertOrderItem.advert_order_id = data.advert_order_id;
    if (DataStruct.validateValue(data, "order_number", "string"))
      advertOrderItem.order_number = data.order_number;
    if (DataStruct.validateValue(data, "advert_channel_id", "number"))
      advertOrderItem.advert_channel_id = data.advert_channel_id;
    if (DataStruct.validateValue(data, "advert_channel_name", "string"))
      advertOrderItem.advert_channel_name = data.advert_channel_name;
    if (DataStruct.validateValue(data, "advert_slot_id", "number"))
      advertOrderItem.advert_slot_id = data.advert_slot_id;
    if (DataStruct.validateValue(data, "advert_slot_name", "string"))
      advertOrderItem.advert_slot_name = data.advert_slot_name;
    if (Object.prototype.hasOwnProperty.call(data, "order_item_content"))
      if (data.order_item_content !== null) {
        advertOrderItem.order_item_content = AdvertOrderItemContent.fromServer(
          data.order_item_content
        );
      }
    if (Object.prototype.hasOwnProperty.call(data, "booking_dates")) {
      advertOrderItem.booking_dates = [];
      data.booking_dates?.forEach((date) =>
        advertOrderItem.booking_dates.push(date)
      );
    }
    if (DataStruct.validateValue(data, "event_id", "number"))
      advertOrderItem.event_id = data.event_id;

    if (typeof data.item_price == "string") {
      if (DataStruct.validateValue(data, "item_price", "string"))
        advertOrderItem.item_price = parseFloat(data.item_price);
    } else if (typeof data.item_price == "number") {
      if (DataStruct.validateValue(data, "item_price", "number"))
        advertOrderItem.item_price = data.item_price;
    }
    if (typeof data.item_total_price == "string") {
      if (DataStruct.validateValue(data, "item_total_price", "string"))
        advertOrderItem.item_total_price = parseFloat(data.item_total_price);
    } else if (typeof data.item_total_price == "number") {
      if (DataStruct.validateValue(data, "item_total_price", "number"))
        advertOrderItem.item_total_price = data.item_total_price;
    }

    return advertOrderItem;
  }
}

/**
 * The data structure as returned by the MSUevent-Server.
 */
export type ServerOrderItemDataType = {
  advert_order_item_id: number;
  advert_order_id: number;
  order_number: string;
  advert_channel_id: number;
  advert_channel_name: string;
  advert_slot_id: number;
  advert_slot_name: string;
  order_item_content: ServerAdvertOrderItemContentDataType;
  booking_dates: [];
  item_price: number | string;
  item_total_price: number | string;
  event_id: number | null;
  create_person: string;
  update_person: string;
};

/**
 * AdvertOrderItemContent-Data class
 */
export class AdvertOrderItemContent extends DataStruct {
  advert_order_item_content_id = 0;
  advert_slot_id = 0;
  advert_content: AdvertOrderItemContentData[] = [];
  event_id: number | null = null; // TODO: Überall bedacht, dass es null sein kann?

  /**
   * Creates a new instance of AdvertOrderItemContent with given data.
   *
   * @param data The data to load
   * @throws TypeError if any property does not fit the type
   */
  constructor(data: { advertOrderItemContentId: number }) {
    super();

    if (DataStruct.validateValue(data, "advertOrderItemContentId", "number")) {
      this.advert_order_item_content_id = data.advertOrderItemContentId;
    } else {
      // We cannot initialize without an id
      throw new TypeError(
        "Could not initialize AdvertOrderItemContent. Invalid AdvertOrderItemContentId."
      );
    }
  }

  /**
   * Creates a new AdvertOrderItemContent-object with the given data returned from the server.
   *
   * @param data
   * @throws TypeError if any property does not fit the type
   */
  static fromServer(
    data: ServerAdvertOrderItemContentDataType
  ): AdvertOrderItemContent {
    const content = new AdvertOrderItemContent({
      advertOrderItemContentId: data.advert_order_item_content_id,
    });

    // Load the data
    if (DataStruct.validateValue(data, "advert_slot_id", "number"))
      content.advert_slot_id = data.advert_slot_id;
    if (Object.prototype.hasOwnProperty.call(data, "advert_content")) {
      content.advert_content = [];
      data.advert_content?.forEach((item) =>
        content.advert_content.push(AdvertOrderItemContentData.fromServer(item))
      );
    }
    if (DataStruct.validateValue(data, "event_id", "number"))
      content.event_id = data.event_id;

    return content;
  }
}

/**
 * The data structure as returned by the MSUevent-Server.
 */
export type ServerAdvertOrderItemContentDataType = {
  advert_order_item_content_id: number;
  advert_slot_id: number;
  advert_content: ServerAdvertOrderItemContentDataDataType[];
  event_id: number;
};

/**
 * AdvertOrderItemContentData-Data class
 */
export class AdvertOrderItemContentData extends DataStruct {
  name = "";
  label = "";
  value = "";

  /**
   * Creates a new AdvertOrderItemContentData-object with the given data returned from the server.
   *
   * @param data
   * @throws TypeError if any property does not fit the type
   */
  static fromServer(
    data: ServerAdvertOrderItemContentDataDataType
  ): AdvertOrderItemContentData {
    const contentData = new AdvertOrderItemContentData();

    // Load the data
    if (DataStruct.validateValue(data, "name", "string"))
      contentData.name = data.name;
    if (DataStruct.validateValue(data, "label", "string"))
      contentData.label = data.label;
    if (DataStruct.validateValue(data, "value", "string"))
      contentData.value = data.value;

    return contentData;
  }
}

/**
 * The data structure as returned by the MSUevent-Server.
 */
export type ServerAdvertOrderItemContentDataDataType = {
  name: string;
  label: string;
  value: string;
};

/**
 * OrderAddress-Data
 */
export class OrderAddress extends DataStruct {
  [key: string]: unknown;
  salutation = "";
  last_name = "";
  first_name = "";
  organization = "";
  street = "";
  zipcode = "";
  community = "";
  phone = "";
  mobile = "";
  email = "";
  url = "";
  attention = "";
  country = "";
  department = "";
  pobox = "";
  pocommunity = "";
  pozip = "";
  vat_id = "";

  /**
   * Creates a new OrderAddress-object with the given data returned from the server.
   *
   * @param data
   * @throws TypeError if any property does not fit the type
   */
  static fromServer(data: ServerOrderAddressDataType): OrderAddress {
    const orderAddress = new OrderAddress();
    // Load the data
    if (DataStruct.validateValue(data, "salutation", "string"))
      orderAddress.salutation = data.salutation;
    if (DataStruct.validateValue(data, "last_name", "string"))
      orderAddress.last_name = data.last_name;
    if (DataStruct.validateValue(data, "first_name", "string"))
      orderAddress.first_name = data.first_name;
    if (DataStruct.validateValue(data, "organization", "string"))
      orderAddress.organization = data.organization;
    if (DataStruct.validateValue(data, "country", "string"))
      orderAddress.country = data.country;
    if (DataStruct.validateValue(data, "street", "string"))
      orderAddress.street = data.street;
    if (DataStruct.validateValue(data, "zipcode", "string"))
      orderAddress.zipcode = data.zipcode;
    if (DataStruct.validateValue(data, "community", "string"))
      orderAddress.community = data.community;
    if (DataStruct.validateValue(data, "phone", "string"))
      orderAddress.phone = data.phone;
    if (DataStruct.validateValue(data, "mobile", "string"))
      orderAddress.mobile = data.mobile;
    if (DataStruct.validateValue(data, "email", "string"))
      orderAddress.email = data.email;
    if (DataStruct.validateValue(data, "url", "string"))
      orderAddress.url = data.url;
    if (DataStruct.validateValue(data, "attention", "string"))
      orderAddress.attention = data.attention;
    if (DataStruct.validateValue(data, "department", "string"))
      orderAddress.department = data.department;
    if (DataStruct.validateValue(data, "pobox", "string"))
      orderAddress.pobox = data.pobox;
    if (DataStruct.validateValue(data, "pocommunity", "string"))
      orderAddress.pocommunity = data.pocommunity;
    if (DataStruct.validateValue(data, "pozip", "string"))
      orderAddress.pozip = data.pozip;
    if (DataStruct.validateValue(data, "vat_id", "string"))
      orderAddress.vat_id = data.vat_id;

    return orderAddress;
  }
}

/**
 * The data structure as returned by the MSUevent-Server.
 */
export type ServerOrderAddressDataType = {
  salutation: string;
  last_name: string;
  first_name: string;
  organization: string;
  country: string;
  street: string;
  zipcode: string;
  community: string;
  phone: string;
  mobile: string;
  email: string;
  url: string;
  attention: string;
  department: string;
  pobox: string;
  pocommunity: string;
  pozip: string;
  vat_id: string;
};

/**
 * AdvertSlot class
 */
export class AdvertSlot extends DataStruct {
  readonly advert_slot_id: number;
  name = "";
  description = "";
  type = "";
  price: number | null = null;
  image_amount: number | null = null;
  image_required: string | null = null;
  image_height: number | null = null;
  image_width: number | null = null;
  image_resolution: number | null = null;
  content_definition: AdvertSlotContent[] = [];
  advert_channel_2_slot: AdvertChannel2Slot | null = null;
  media: Media[] = [];

  /**
   * Creates a new instance of AdvertSlot with given data.
   *
   * @param data The data to load
   * @throws TypeError if any property does not fit the type
   */
  constructor(data: { advertSlotId: number }) {
    super();

    if (DataStruct.validateValue(data, "advertSlotId", "number")) {
      this.advert_slot_id = data.advertSlotId;
    } else {
      // We cannot initialize without an id
      throw new TypeError(
        "Could not initialize AdvertSlot. Invalid advertSlotId."
      );
    }
  }

  /**
   * Creates a new AdvertMedium-object with the given data returned from the server.
   *
   * @param data
   * @throws TypeError if any property does not fit the type
   */
  static fromServer(data: ServerAdvertSlotDataType): AdvertSlot {
    const advertSlot = new AdvertSlot({
      advertSlotId: data.advert_slot_id,
    });

    // Load the data
    if (DataStruct.validateValue(data, "name", "string"))
      advertSlot.name = data.name;
    if (DataStruct.validateValue(data, "description", "string"))
      advertSlot.description = data.description;
    if (DataStruct.validateValue(data, "type", "string"))
      advertSlot.type = data.type;
    if (DataStruct.validateValue(data, "price", "number"))
      advertSlot.price = data.price;
    if (DataStruct.validateValue(data, "image_amount", "number"))
      advertSlot.image_amount = data.image_amount;
    if (DataStruct.validateValue(data, "image_required", "string"))
      advertSlot.image_required = data.image_required;
    if (DataStruct.validateValue(data, "image_height", "number"))
      advertSlot.image_height = data.image_height;
    if (DataStruct.validateValue(data, "image_width", "number"))
      advertSlot.image_width = data.image_width;
    if (DataStruct.validateValue(data, "image_resolution", "number"))
      advertSlot.image_resolution = data.image_resolution;
    if (Object.prototype.hasOwnProperty.call(data, "content_definition")) {
      advertSlot.content_definition = [];
      data.content_definition?.forEach((item) =>
        advertSlot.content_definition.push(AdvertSlotContent.fromServer(item))
      );
    }
    if (Object.prototype.hasOwnProperty.call(data, "channel2slot_data")) {
      advertSlot.advert_channel_2_slot = AdvertChannel2Slot.fromServer(
        data.channel2slot_data
      );
    }
    if (Object.prototype.hasOwnProperty.call(data, "media")) {
      advertSlot.media = [];
      data.media?.forEach((item) =>
        advertSlot.media.push(Media.fromServer(item))
      );
    }

    return advertSlot;
  }

  /**
   * Returns the first set {@link MediaImage } for this {@link Media}.
   */
  getPreviewImage(): MediaImage | undefined {
    // Get first image
    return (
      (this.media.find((obj) => obj.type === "image") as MediaImage) ?? null
    );
  }
}

/**
 * The data structure as returned by the MSUevent-Server.
 */
export type ServerAdvertSlotDataType = {
  advert_slot_id: number;
  name: string;
  description: string;
  type: string;
  price: number;
  image_amount: number;
  image_required: string;
  image_height: number;
  image_width: number;
  image_resolution: number;
  content_definition: ServerAdvertSlotContentType[];
  channel2slot_data: ServerAdvertChannel2SlotType;
  media: ServerMediaDataType[];
};

/**
 * AdvertSlotContent class
 */
export class AdvertSlotContent extends DataStruct {
  active = "";
  required = "";
  fieldType = "";
  fieldName = "";
  fieldLabel = "";
  fieldInfo = "";
  externalName = "";
  maxLength: number | null = null;

  /**
   * Creates a new instance of AdvertSlotContent.
   *
   * @throws TypeError if any property does not fit the type
   */
  constructor() {
    super();
  }

  /**
   * Creates a new AdvertSlotContent-object with the given data returned from the server.
   *
   * @param data
   * @throws TypeError if any property does not fit the type
   */
  static fromServer(data: ServerAdvertSlotContentType): AdvertSlotContent {
    const advertSlotContent = new AdvertSlotContent();

    // Load the data
    if (DataStruct.validateValue(data, "active", "string"))
      advertSlotContent.active = data.active;
    if (DataStruct.validateValue(data, "required", "string"))
      advertSlotContent.required = data.required;
    if (DataStruct.validateValue(data, "fieldType", "string"))
      advertSlotContent.fieldType = data.fieldType;
    if (DataStruct.validateValue(data, "fieldName", "string"))
      advertSlotContent.fieldName = data.fieldName;
    if (DataStruct.validateValue(data, "fieldLabel", "string"))
      advertSlotContent.fieldLabel = data.fieldLabel;
    if (DataStruct.validateValue(data, "fieldInfo", "string"))
      advertSlotContent.fieldInfo = data.fieldInfo;
    if (DataStruct.validateValue(data, "maxLength", "number"))
      advertSlotContent.maxLength = data.maxLength;
    if (DataStruct.validateValue(data, "externalName", "string"))
      advertSlotContent.externalName = data.externalName;

    return advertSlotContent;
  }
}

/**
 * The data structure as returned by the MSUevent-Server.
 */
export type ServerAdvertSlotContentType = {
  active: string;
  required: string;
  fieldType: string;
  fieldName: string;
  fieldLabel: string;
  fieldInfo: string;
  maxLength: number;
  externalName: string;
};

/**
 * AdvertSlotContent class
 */
export class AdvertChannel2Slot extends DataStruct {
  price = 0;
  order_no = "";
  // bulk_price = [];
  book_days_before = 0;

  /**
   * Creates a new instance of AdvertChannel2Slot.
   *
   * @throws TypeError if any property does not fit the type
   */
  constructor() {
    super();
  }

  /**
   * Creates a new AdvertSlotContent-object with the given data returned from the server.
   *
   * @param data
   * @throws TypeError if any property does not fit the type
   */
  static fromServer(data: ServerAdvertChannel2SlotType): AdvertChannel2Slot {
    const advertChannel2SlotObj = new AdvertChannel2Slot();

    // Load the data
    if (DataStruct.validateValue(data, "price", "number"))
      advertChannel2SlotObj.price = data.price;
    if (DataStruct.validateValue(data, "order_no", "string"))
      advertChannel2SlotObj.order_no = data.order_no;
    if (DataStruct.validateValue(data, "book_days_before", "number"))
      advertChannel2SlotObj.book_days_before = data.book_days_before;

    return advertChannel2SlotObj;
  }
}

/**
 * The data structure from "AdvertChannel2Slot" as returned by the MSUevent-Server.
 */
export type ServerAdvertChannel2SlotType = {
  price: number;
  order_no: string;
  book_days_before: number;
};

/**
 * Returns all available AdsMediums of all AdsCampaigns.
 * @param campaigns
 * @param selectedMedium
 */
export function getChannels(
  campaigns: AdvertCampaign[] | null,
  selectedMedium: AdvertMedium | null
): AdvertChannel[] {
  const channels: AdvertChannel[] = [];

  if (campaigns == null || selectedMedium == null) return channels;

  campaigns.forEach((campaign) => {
    campaign.advert_channels.forEach((channel) => {
      const medium = channel.advert_medium;
      if (
        medium &&
        medium?.advert_medium_id == selectedMedium.advert_medium_id
      ) {
        channels.push(channel);
      }
    });
  });
  return channels;
}
