
import { Component, Prop, Vue } from "vue-property-decorator";
import QuokkaEventRubricSearch from "@/components/quokka_layout/QuokkaEventRubricSearch.vue";
import RecordingEvent from "@/shared/data/recording_event";
import { EventRecordingFieldData } from "@/frontend/lib/event_recording_data";
import FrontendSettings from "@/frontend/settings/settings";
import LabeledSheet from "@/components/quokka_layout/ui/LabeledSheet.vue";
import Rubric from "@/shared/data/rubric";

/**
 * Tab for the Event-Recording containing the texts for the {@link RecordingEvent}.
 */
@Component({
  components: {
    LabeledSheet,
    QuokkaEventRubricSearch,
  },
})
export default class QuokkaEventRecordingAddRubricsTab extends Vue {
  /**
   * Available rules for the fields to verify the value.
   */
  rules: { [key: string]: (value: string) => boolean | string } = {
    rubric: (value: string): boolean | string => {
      if (!value) {
        return "Rubrik wählen";
      }
      return true;
    },
  };

  /** The selected {@link Rubric}s */
  selectedRubrics: Rubric[] | null = null;

  /**
   * The {@link EventRecordingFieldData} containing available data for the select fields.
   */
  @Prop({ default: null })
  eventRecordingFieldData!: EventRecordingFieldData | null;
  /**
   * The {@link RecordingEvent} used as model.
   */
  @Prop({ required: true }) recordingEvent!: RecordingEvent;

  /**
   * Whether the fields of this tab should be disabled or not.
   */
  @Prop() disabled!: boolean;

  /** The maximal number of additional rubrics for event */
  rubricsMaxNumber = 0;
  /** The minimum of additional rubrics for event */
  rubricsMinNumber = 0;
  /** Manages how many rubric-autocomplete are displayed */
  rubricsShowNumber = 0;

  /**
   * Gets the max allowed amount of links and checks already set links, if they surpass the amount.
   * If a link surpasses the maximum allowed amount, it will be deleted.
   */
  mounted(): void {
    this.rubricsMaxNumber =
      FrontendSettings.fieldSettings.recording.addRubrics.rubricsMax;
    this.rubricsMinNumber =
      FrontendSettings.fieldSettings.recording.addRubrics.rubricsMin;
    this.rubricsShowNumber = this.rubricsMinNumber;

    if (
      this.rubricsShowNumber <= 0 &&
      this.rubricsShowNumber < this.rubricsMaxNumber
    ) {
      this.rubricsShowNumber++;
    }

    if (this.recordingEvent.add_rubrics === null) {
      this.$set(this.recordingEvent, "add_rubrics", [] as Rubric[]);
    } else if (
      this.recordingEvent.add_rubrics.length > this.rubricsShowNumber
    ) {
      this.rubricsShowNumber = this.recordingEvent.add_rubrics.length;
    }

    if (this.recordingEvent.add_rubrics !== null) {
      this.selectedRubrics = this.recordingEvent.add_rubrics;
    }

    if (this.rubricsShowNumber < this.rubricsMaxNumber) {
      this.rubricsShowNumber++;
    }
  }

  /**
   * Manged the rubric selection.
   *
   * @param index
   * @param selectedRubric
   */
  onRubricSelection(index: number, selectedRubric: Rubric[] | null): void {
    if (selectedRubric !== null) {
      this.selectAddRubric(index, selectedRubric);
    } else {
      if (this.selectedRubrics !== null) {
        this.selectedRubrics.splice(index, 1);
      }
    }

    // set v-model
    this.$set(this.recordingEvent, "add_rubrics", this.selectedRubrics);

    if (this.selectedRubrics !== null) {
      if (
        this.selectedRubrics.length == this.rubricsShowNumber &&
        this.selectedRubrics.length < this.rubricsMaxNumber
      ) {
        this.rubricsShowNumber++;
      }
    }
  }

  /**
   * Sets the selected rubric.
   *
   * @param index
   * @param rubric
   */
  selectAddRubric(index: number, rubric: Rubric | Rubric[]): void {
    if (this.selectedRubrics === null) {
      this.selectedRubrics = [];
    }

    if (Array.isArray(rubric)) {
      // Prüft, dass keine Rubric doppelt gelistet wird.
      rubric.forEach((r, i) => {
        if (this.selectedRubrics !== null) {
          const existingIndex = this.selectedRubrics.findIndex(
            (item) => item.rubric_id === r.rubric_id
          );

          if (existingIndex !== -1) {
            // Rubric existiert bereits, also überschreibe sie an der vorhandenen Position
            this.selectedRubrics.splice(existingIndex, 1, r);
          } else {
            // Rubric existiert nicht, füge sie an der angegebenen Position hinzu
            this.selectedRubrics[index + i] = r;
          }
        }
      });
    } else {
      // Rubric Daten merken.
      const existingIndex = this.selectedRubrics.findIndex(
        (item) => item.rubric_id === rubric.rubric_id
      );

      if (existingIndex !== -1) {
        // Rubric existiert bereits, also überschreibe sie an der vorhandenen Position
        this.selectedRubrics.splice(existingIndex, 1, rubric);
      } else {
        // Rubric existiert nicht, füge sie an der angegebenen Position hinzu
        this.selectedRubrics[index] = rubric;
      }
    }
  }
}
