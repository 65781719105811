
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import QuokkaEventRubricSearch from "@/components/quokka_layout/QuokkaEventRubricSearch.vue";
import QuokkaEventAddressSearch from "@/components/quokka_layout/QuokkaEventAddressSearch.vue";
import QuokkaEventRecordingAppointmentsFreeTab from "@/components/quokka_layout/recording/QuokkaEventRecordingAppointmentsFreeTab.vue";
import {
  RecordingEventAppointmentsFree,
  RecordingEventAppointmentsSeries,
  RecordingEventAppointmentType,
} from "@/shared/data/event_appointments";
import RecordingEvent from "@/shared/data/recording_event";
import QuokkaEventRecordingAppointmentsSeriesTab from "@/components/quokka_layout/recording/QuokkaEventRecordingAppointmentsSeriesTab.vue";
import FrontendSettings from "@/frontend/settings/settings";
import { sanitizeContent } from "@/shared/lib/dompurify";

/**
 * Tab for the Event-Recording containing the appointments.
 */
@Component({
  components: {
    QuokkaEventRecordingAppointmentsSeriesTab,
    QuokkaEventRecordingAppointmentsFreeTab,
    QuokkaEventAddressSearch,
    QuokkaEventRubricSearch,
  },
  data() {
    return { sanitizeContent };
  },
})
export default class QuokkaEventRecordingAppointmentsTab extends Vue {
  /**
   * The {@link RecordingEvent} used as model.
   */
  @Prop({ required: true }) recordingEvent!: RecordingEvent;

  /**
   * Whether the fields of this tab should be disabled or not.
   */
  @Prop() disabled!: boolean;

  /**
   * The currently used {@link RecordingEventAppointments}.
   */
  appointments: {
    free: RecordingEventAppointmentsFree;
    series: RecordingEventAppointmentsSeries;
  } = {
    free: new RecordingEventAppointmentsFree(),
    series: new RecordingEventAppointmentsSeries(),
  };

  /**
   * Watch for a changed recordingEvent after a save (and initially) to set the {@link appointments}-objects accordingly.
   */
  @Watch("recordingEvent", { immediate: true })
  onRecordingEventChanged(): void {
    if (this.appointmentsFree) this.appointments.free = this.appointmentsFree;
    if (this.appointmentsSeries)
      this.appointments.series = this.appointmentsSeries;
    if (!this.recordingEvent.appointments)
      this.recordingEvent.appointments = this.appointments.free;
  }

  /**
   * Returns the key of the active type.
   */
  get activeType(): RecordingEventAppointmentType {
    return this.appointmentsFree ? "free" : "series";
  }

  /**
   * Whether the active appointments type is `series` or not.
   */
  get isSeries(): boolean {
    return this.activeType === "series";
  }

  /**
   * This setter is just to prevent errors with the v-model.
   * The actual value is set through the @change event.
   *
   * @param value
   */
  set isSeries(value: boolean) {
    return;
  }

  /**
   * Returns the {@link RecordingEventAppointmentsFree} if it is active.
   * Otherwise, undefined.
   */
  get appointmentsFree(): RecordingEventAppointmentsFree | undefined {
    return this.recordingEvent.appointments?.type === "free"
      ? (this.recordingEvent.appointments as RecordingEventAppointmentsFree)
      : undefined;
  }

  /**
   * Returns the {@link RecordingEventAppointmentsSeries} if it is active.
   * Otherwise, undefined.
   */
  get appointmentsSeries(): RecordingEventAppointmentsSeries | undefined {
    return this.recordingEvent.appointments?.type === "series"
      ? (this.recordingEvent.appointments as RecordingEventAppointmentsSeries)
      : undefined;
  }
  /**
   * Returns the `appointmentsInfo` defined in the settings.
   */
  get appointmentsInfo(): string | null {
    return FrontendSettings.eventRecording.appointmentsInfo;
  }

  /**
   * Switch the type of the active appointments.
   *
   * Available types are "free" or "series"
   */
  switchAppointmentType(): void {
    if (this.appointmentsFree) {
      this.recordingEvent.appointments = this.appointments.series;
    } else if (this.appointmentsSeries) {
      this.recordingEvent.appointments = this.appointments.free;
    }
  }
}
