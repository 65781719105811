
import { Component, Vue } from "vue-property-decorator";
import RouteHelper from "@/frontend/lib/route_helper";
import QuokkaEventRecordingAdmin from "@/components/quokka_layout/recording/QuokkaEventRecordingAdmin.vue";

/**
 * A view to create a new Event. (Record Event)
 *
 * Required fields in the tab views are currently firmly defined:
 * - Rubric
 * - Location
 * - At least one Appointment with time
 * - Title
 * - Longdesc
 */
@Component({
  components: { QuokkaEventRecordingAdmin },
})
export default class QuokkaEventRecordingView extends Vue {
  /**
   * Returns the event-id, if set in route.
   */
  get eventId(): number | null {
    // RecordingEvent
    if (
      RouteHelper.hasParam(this.$route, "event_id") &&
      parseInt(RouteHelper.getParam(this.$route, "event_id"))
    ) {
      return parseInt(RouteHelper.getParam(this.$route, "event_id"));
    }
    return null;
  }
}
