
import { Component, Vue } from "vue-property-decorator";
import Authorization, {
  GetTokenError,
  GetTokenErrorReason,
} from "@/frontend/lib/auth";
import { LogoutReason } from "@/views/layouts/quokka/auth/QuokkaLoginView.vue";
import Person from "@/shared/data/person";
import { getPersonData } from "@/frontend/lib/api";
import FrontendLogger from "@/frontend/lib/logger";

/**
 * The {@link AdminView} is a wrapper for all views in all layouts shown inside the secured admin area.
 * It will check, if a valid token is available and if not, route to /login.
 *
 * Provides the logged in {@link Person} to the router-view.
 */
@Component
export default class AdminView extends Vue {
  /**
   * Whether the user is authorized or not.
   */
  authorized = false;

  /**
   * The logged in {@link Person}.
   */
  loggedInPerson: Person | null = null;

  /**
   * Checks, if the user is logged in.
   *
   * TODO: Start a timeout to renew token or show re-login dialog.
   */
  mounted(): void {
    // Check, if we got a token
    try {
      Authorization.getValidatedToken();

      this.authorized = true;

      // Get logged in Person
      getPersonData()
        .then((person) => {
          this.loggedInPerson = person;
        })
        .catch((e) => {
          // Failed to get person
          FrontendLogger.error({
            scope: "invalid-data",
            message: "Failed to get logged in Person.",
            data: e,
          });
          throw new GetTokenError("unknown-error");
        });
    } catch (e) {
      // Failed to get token => Redirect to /login

      let logoutReason: LogoutReason | undefined;
      switch (e.reason as GetTokenErrorReason) {
        case "expired":
          logoutReason = LogoutReason.EXPIRED;
          break;
        case "no-token":
          logoutReason = LogoutReason.NO_TOKEN;
          break;
        default:
          break;
      }

      const query: {
        logout?: LogoutReason;
      } = {};

      if (logoutReason) {
        query.logout = logoutReason;
      }

      // Redirect to /login
      this.$router.replace({
        name: "login",
        query: query,
      });
    }
  }
}
