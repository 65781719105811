import DataStruct from "@/shared/data/datastruct";
import TimeInfo, { ServerTimeInfoDataType } from "@/shared/data/time_info";
import { DateFormats, formatDate, parseDate } from "@/shared/lib/datetime";
import { DateTime } from "luxon";

/**
 * The EventDate Data-Class containing all properties for an EventDate.
 */
export default class EventDate extends DataStruct {
  private date: DateTime | null = null;
  holidayName = "";
  vacationName = "";
  timeInfo: TimeInfo[] = [];

  /**
   * Creates a new EventDate-object with the given data returned from the server.
   *
   * @param data
   * @throws DateTimeParseError if the date could not be parsed with given format.
   */
  static fromServer(data: ServerEventDateDataType): EventDate {
    const eventDate = new EventDate();

    // Load the data
    if (DataStruct.validateValue(data, "date", "string"))
      eventDate.setDate(<string>data.date, "yyyy-MM-dd");
    if (DataStruct.validateValue(data, "holiday_name", "string"))
      eventDate.holidayName = <string>data.holiday_name;
    if (DataStruct.validateValue(data, "vacation_name", "string"))
      eventDate.vacationName = <string>data.vacation_name;

    // Times
    if (Object.prototype.hasOwnProperty.call(data, "time_info")) {
      eventDate.timeInfo = [];
      data.time_info?.forEach((item) =>
        eventDate.timeInfo.push(TimeInfo.fromServer(item))
      );
    }

    return eventDate;
  }

  get dateObject(): DateTime | null {
    return this.date;
  }

  set dateObject(value: DateTime | null) {
    this.date = value;
  }

  /**
   * Returns set date in the given format.
   *
   * @param format the format - defaults to "dd.MM.yyyy"
   * @returns {string?} the formatted date or undefined, if no date is set
   *
   * @see https://moment.github.io/luxon/#/formatting?id=table-of-tokens for formatting options.
   */
  formatDate(format: string | DateFormats = "dd.MM.yyyy"): string | undefined {
    return formatDate(this.date, format);
  }

  /**
   * Tries to set the given date with given format.
   *
   * @param date the date to set. If undefined, null or empty, the date will be unset
   * @param format the format of given date
   * @throws DateTimeParseError if the date could not be parsed with given format.
   *
   * @see https://moment.github.io/luxon/#/formatting?id=table-of-tokens for formatting options.
   */
  setDate(
    date: string | undefined,
    format: string | DateFormats = "dd.MM.yyyy"
  ): void {
    this.date = parseDate(date, format) ?? null;
  }

  /**
   * Returns the first available TimeInfo or undefined, if no date is available.
   */
  get firstTime(): TimeInfo | undefined {
    return this.timeInfo[0];
  }
}

/**
 * The data structure defined by the MSUevent-Server.
 */
export type ServerEventDateDataType = {
  date?: string;
  holiday_name?: string;
  vacation_name?: string;
  time_info?: ServerTimeInfoDataType[];
};
