
import { Component, Prop, Vue } from "vue-property-decorator";
import { sanitizeContent } from "@/shared/lib/dompurify";
import RecordingEvent from "@/shared/data/recording_event";
import FrontendSettings from "@/frontend/settings/settings";
import { getAddresses, GetAddressReturnType } from "@/frontend/lib/api";
import FrontendLogger from "@/frontend/lib/logger";

/**
 * Tab for Event-Recording containing the ticket agency selection for the {@link RecordingEvent}
 */
@Component({
  data() {
    return { sanitizeContent };
  },
})
export default class QuokkaEventRecordingTicketsTab extends Vue {
  /** The {@link RecordingEvent} used as modal. */
  @Prop({ required: true }) recordingEvent!: RecordingEvent;

  /** Whether the fields of this tab should be disabled or not. */
  @Prop() disabled!: boolean;

  /** Available ticket agencies, formatted for autocomplete. */
  ticketAgencies: { searchLabel: string; id: string }[] = [];

  /** The information to display in the tab.*/
  ticketsInfo: string | null = FrontendSettings.eventRecording.ticketsInfo;

  /** Maximum allowed Ticket Agencies */
  maxTickets = 10;

  /** active ticket agency slots */
  countTicketAgencies = 0;

  mounted(): void {
    getAddresses({ type: "ticket_agency" }).then(
      (response: GetAddressReturnType) => {
        response.addresses.forEach((address) => {
          this.ticketAgencies.push({
            searchLabel: address.name,
            id: address.address_id.toString(),
          });
        });
      }
    );
  }

  /**
   * Add a ticket agency to an event
   */
  addTicketAgency(): void {
    if (this.recordingEvent.ticket_agencies.length < this.maxTickets) {
      this.countTicketAgencies += 1;
      this.recordingEvent.ticket_agencies.push("empty");
    } else {
      FrontendLogger.error({
        message: "Tried to add new ticket agency while no more can be added.",
        scope: "ui",
      });
    }
  }

  /**
   * Removes a ticket agency from an event
   * @param index
   */
  removeTicketAgency(index: number): void {
    this.recordingEvent.ticket_agencies.splice(index, 1);
    this.countTicketAgencies -= 1;
  }
}
