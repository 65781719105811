
import { Component, Prop, Vue } from "vue-property-decorator";
import { executeReport, ReportExecutionResponse } from "@/frontend/lib/api";
import { sanitizeContent } from "@/shared/lib/dompurify";

/**
 * Available Status for this view.
 */
type ViewStatus = "loading" | "initialized" | "failed-to-execute";

/**
 * Builds a preview for executed Report(s).
 */
@Component({
  data() {
    return { sanitizeContent };
  },
})
export default class QuokkaReportPreview extends Vue {
  /**
   * Either Event or Article ids to execute the reports with.
   */
  @Prop({ required: true }) ids!:
    | { event_ids: number[] }
    | { article_ids: number[] };

  /**
   * The current status of this view.
   */
  viewStatus: ViewStatus = "loading";

  /**
   * Current executed Report(s) to display.
   */
  executedReports: ReportExecutionResponse | null = null;

  /**
   * Execute Report(s).
   */
  mounted(): void {
    this.executeReports();
  }

  /**
   * Executes the Report(s) with given id(s).
   */
  executeReports(): void {
    this.viewStatus = "loading";

    executeReport(this.ids)
      .then((response) => {
        this.executedReports = response;
        this.viewStatus = "initialized";
      })
      .catch(() => {
        this.viewStatus = "failed-to-execute";
      });
  }
}
