/**
 * This file contains global definitions that can be used,
 */

/**
 * This object contains special chars and their corresponding english version.
 */
export const specialCharsToEnglish: Record<string, string> = {
  ß: "ss",
  ä: "ae",
  ö: "oe",
  ü: "ue",
  // French
  â: "a",
  à: "a",
  æ: "a",
  ê: "e",
  è: "e",
  é: "e",
  ë: "e",
  î: "i",
  ï: "i",
  ô: "o",
  ò: "o",
  œ: "o",
  û: "u",
  ù: "u",
  ç: "c",
};
