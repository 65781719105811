
import { Component, Vue } from "vue-property-decorator";

/**
 * The {@link QuokkaAdsAdminView} is the admin view to view or record Ads.
 */
@Component
export default class QuokkaAdsAdminView extends Vue {
  /**
   * Returns the current page-title from route-meta, if available.
   * Otherwise, null.
   */
  get pageTitle(): string | null {
    if (this.$route.name == "ads-recording") return null;
    return this.$route.meta?.page_title ?? null;
  }
}
