
/**
 * Tab for Article-Recording containing information about the {@link RecordingArticle} like {@link Rubric}, location, promoter and tags.
 */
import { Component, Prop, Vue } from "vue-property-decorator";
import QuokkaEventAddressSearch from "@/components/quokka_layout/QuokkaEventAddressSearch.vue";
import RecordingArticle from "@/shared/data/recording_article";
import { ArticleRecordingFieldData } from "@/frontend/lib/article_recording_data";
import QuokkaEventRubricSearch from "@/components/quokka_layout/QuokkaEventRubricSearch.vue";
import { formatDate, parseDate } from "@/shared/lib/datetime";
import { DateTime } from "luxon";
import FrontendLogger from "@/frontend/lib/logger";
import QuokkaDatePicker from "@/components/quokka_layout/ui/QuokkaDatePicker.vue";
import { ArticleDefinition } from "@/shared/data/article_definition";
import QuillEditor from "@/components/quokka_layout/ui/QuillEditor.vue";
import FrontendSettings from "@/frontend/settings/settings";
import { sanitizeContent } from "@/shared/lib/dompurify";

@Component({
  components: {
    QuillEditor,
    QuokkaEventAddressSearch,
    QuokkaEventRubricSearch,
    QuokkaDatePicker,
  },
  data() {
    return { sanitizeContent };
  },
})
export default class QuokkaArticleRecordingGeneralTab extends Vue {
  /** The {@link RecordingArticle} */
  @Prop({ required: true }) recordingArticle!: RecordingArticle;

  /** The {@link ArticleDefinition} used to build layout */
  @Prop({ required: true }) articleDefinition!: ArticleDefinition;

  /** The {@link ArticleRecordingFieldData} containing available data for the select fields. */
  @Prop({ default: null })
  articleRecordingFieldData!: ArticleRecordingFieldData | null;

  /** Whether the fields of this tab should be disabled or not */
  @Prop() disabled!: boolean;

  /** Rules for clientside validation */
  rules: { [key: string]: (value: string) => boolean | string } = {
    location: (value: string): boolean | string => {
      if (!this.articleDefinition.location_enabled) return true;
      if (value || (!value && this.checkPromoter())) {
        return true;
      }
      return "Veranstaltungsort wählen";
    },
    promoter: (value: string): boolean | string => {
      if (!this.articleDefinition.promoter_enabled) return true;
      if (value || (!value && this.checkLocation())) {
        return true;
      }
      return "Veranstalter wählen";
    },
    tags: (value: string[] | string | undefined): boolean | string => {
      if (!this.articleDefinition.tags_enabled) return true;
      if (value === undefined || value === "" || value.length < 1)
        return "Mindestens ein Schlagwort auswählen";
      if (value) {
        return true;
      }
      return "Mindestens ein Schlagwort auswählen";
    },
    date: (value: string): boolean | string => {
      if (!value || value.trim() === "") return "Datum eingeben";

      const parsedDate = parseDate(value, "dd.MM.yyyy");
      const today = DateTime.now();

      if (!parsedDate) return "Ungültiges Datum";

      const formattedDate = formatDate(parsedDate, "yyyy-MM-dd");
      const formattedToday = formatDate(today, "yyyy-MM-dd");

      if (!formattedDate || !formattedToday) {
        FrontendLogger.error({
          message:
            "Something went wrong trying to validate date-value '" +
            value +
            "'. Could not format date or today's date.",
          scope: "invalid-data",
          data: {
            formattedDate,
            formattedToday,
          },
        });
        return "Ungültiges Datum";
      }
      return true;
    },
  };
  /** Whether location is selected or not */
  private checkLocation(): boolean {
    return this.recordingArticle.location != null;
  }
  /** Whether promoter is selected or not */
  private checkPromoter(): boolean {
    return this.recordingArticle.promoter != null;
  }
  /**
   * Returns the `generalInfo` defined in the settings.
   */
  get generalInfo(): string | null {
    return FrontendSettings.articleRecording.generalInfo;
  }
}
