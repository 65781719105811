
import { Component, Vue, Watch } from "vue-property-decorator";
import { Route } from "vue-router";
import FrontendSettings from "@/frontend/settings/settings";
import { sanitizeContent } from "@/shared/lib/dompurify";

@Component({
  data() {
    return { sanitizeContent };
  },
})
export default class App extends Vue {
  /**
   * Whether the api-server is currently under maintenance or not.
   */
  maintenance = false;

  mounted(): void {
    FrontendSettings.onMaintenanceActive(() => {
      this.maintenance = true;
    });
    if (FrontendSettings.maintenance) {
      this.maintenance = true;
    }
  }

  /**
   * This function is called when the route changes.
   * If immediate: true is set for the @Watch, this function will also be called on load/reload.
   *
   * @param {Route} to The new route.
   */
  @Watch("$route", { immediate: true })
  onRouteChange(to: Route): void {
    if (typeof to.meta?.page_title !== "undefined")
      document.title = to.meta.page_title;
  }

  get fontFamily(): string | null {
    return FrontendSettings.theme.fontFamily;
  }

  get isEmbedded(): boolean {
    return FrontendSettings.host !== "/";
  }

  get footerContact(): string {
    return FrontendSettings.applicationSettings.footerContact ?? "";
  }
}
