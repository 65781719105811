/**
 * The {@link FrontendLogger} class is used to log different types of messages for the frontend.
 */
export default abstract class FrontendLogger {
  /**
   * This function logs an error with given data to the console.
   *
   * @param options Data of what to log
   */
  public static error(options: LogOptionsType): void {
    this.log(options, "error");
  }

  /**
   * This function logs a warning with given data to the console.
   *
   * @param options Data of what to log
   */
  public static warning(options: LogOptionsType): void {
    this.log(options, "warning");
  }

  /**
   * This function logs an info with given data to the console.
   *
   * @param options Data of what to log
   */
  public static info(options: LogOptionsType): void {
    this.log(options, "info");
  }

  /**
   * This function logs the given data to the console.
   *
   * @param options Data of what to log
   * @param type The type of this log
   */
  private static log(
    options: LogOptionsType,
    type: "error" | "info" | "warning" = "error"
  ): void {
    switch (type) {
      case "info":
        console.info("[MSU - INFO] " + options.message, options.data);
        break;
      case "warning":
        console.warn("[MSU - WARNING] " + options.message, options.data);
        break;
      case "error":
      default:
        console.error("[MSU - ERROR] " + options.message, options.data);
        break;
    }
  }
}

/**
 * Type Definition for the FrontendLoggers log() functions options.
 */
type LogOptionsType = {
  /**
   * The message to log.
   */
  message: string;
  /**
   * Any data to log with the message.
   * If data is an Error, it will be shortened with toString().
   */
  data?: unknown | Error;
  /**
   * The scope of this logged message.
   */
  scope?: "api" | "invalid-data" | "ui";
};
