
import { Component, Vue } from "vue-property-decorator";
import AuthView from "@/views/layouts/quokka/auth/AuthView.vue";
import { confirmEMail } from "@/frontend/lib/api";
import RouteHelper from "@/frontend/lib/route_helper";
import FrontendLogger from "@/frontend/lib/logger";

/**
 * Available error codes to display a specific error message.
 */
type ErrorCode = "missing-token" | "invalid_token" | "token_expired";

/**
 * The password change page.
 */
@Component({
  components: { AuthView },
})
export default class QuokkaConfirmEMailView extends Vue {
  /**
   * Whether the mail has been confirmed or not.
   *
   * If null, the request is still pending.
   */
  confirmed: boolean | null = null;

  /**
   * An error code to display a specific error message.
   */
  errorCode: ErrorCode | null = null;

  /**
   * Returns an error message for set {@link errorCode}.
   */
  get errorMessage(): string {
    switch (this.errorCode) {
      case "missing-token":
      case "invalid_token":
        return "Dieser Link ist ungültig.";
      case "token_expired":
        return "Dieser Link ist abgelaufen.";
      default:
        return "Irgendetwas ist schief gegangen.";
    }
  }

  /**
   * Tries to get the confirmation token from route and confirm the mail through the api.
   */
  mounted(): void {
    const token =
      RouteHelper.getQueryParam<string>(this.$route, "t")?.trim() ?? "";

    if (token == "") {
      // Missing token
      this.errorCode = "missing-token";
    }

    confirmEMail(token)
      .then(() => {
        this.confirmed = true;
      })
      .catch((e) => {
        FrontendLogger.error({
          message: "Failed to confirm E-Mail.",
          data: e,
          scope: "api",
        });
        if (e.response && e.response.data && e.response.data.error_code) {
          // We got an error code, see documentation for available codes we can get
          switch (e.response.data.error_code) {
            case 0:
              this.errorCode = "missing-token";
              break;
            case 1:
              this.errorCode = "invalid_token";
              break;
            case 5:
              this.errorCode = "token_expired";
              break;
          }
        }

        this.confirmed = false;
      });
  }
}
