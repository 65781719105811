
/** Tab for Article-Recording containing the Appointments. */
import Component from "vue-class-component";
import { Prop, Vue } from "vue-property-decorator";
import RecordingArticle from "@/shared/data/recording_article";
import QuokkaDatePicker from "@/components/quokka_layout/ui/QuokkaDatePicker.vue";
import QuokkaEventRecordingAppointmentsFreeTab from "@/components/quokka_layout/recording/QuokkaEventRecordingAppointmentsFreeTab.vue";
import {
  RecordingEventAppointmentsFree,
  RecordingEventAppointmentsSeries,
  RecordingEventAppointmentType,
} from "@/shared/data/event_appointments";
import QuokkaArticleRecordingAppointmentsFreeTab from "@/components/quokka_layout/recording/QuokkaArticleRecordingAppointmentsFreeTab.vue";
import QuokkaArticleRecordingAppointmentsSeriesTab from "@/components/quokka_layout/recording/QuokkaArticleRecordingAppointmentsSeriesTab.vue";
import { ArticleDefinition } from "@/shared/data/article_definition";
import FrontendSettings from "@/frontend/settings/settings";
import { sanitizeContent } from "@/shared/lib/dompurify";
@Component({
  components: {
    QuokkaArticleRecordingAppointmentsSeriesTab,
    QuokkaArticleRecordingAppointmentsFreeTab,
    QuokkaEventRecordingAppointmentsFreeTab,
    QuokkaDatePicker,
  },
  data() {
    return { sanitizeContent };
  },
})
export default class QuokkaArticleRecordingAppointmentsTab extends Vue {
  /** The{@link RecordingArticle} used */
  @Prop({ required: true }) recordingArticle!: RecordingArticle;

  /** The {@link ArticleDefinition} used to build layout */
  @Prop({ required: true }) articleDefinition!: ArticleDefinition;

  /** Whether the fields of this tab should be disabled or not */
  @Prop() disabled!: boolean;

  /** The currently used {@link RecordingEventAppointments}. */
  appointments: {
    free: RecordingEventAppointmentsFree;
    series: RecordingEventAppointmentsSeries;
  } = {
    free: new RecordingEventAppointmentsFree(),
    series: new RecordingEventAppointmentsSeries(),
  };

  mounted(): void {
    if (this.appointmentsFree) this.appointments.free = this.appointmentsFree;
    if (this.appointmentsSeries)
      this.appointments.series = this.appointmentsSeries;
    if (!this.recordingArticle.article_release_info)
      this.recordingArticle.article_release_info = this.appointments.free;
  }

  /**
   * Returns the key of the active type.
   */
  get activeType(): RecordingEventAppointmentType {
    return this.appointmentsFree ? "free" : "series";
  }

  /**
   * Whether the active appointments type is `series` or not.
   */
  get isSeries(): boolean {
    return this.activeType === "series";
  }

  /**
   * This setter is just to prevent errors with the v-model.
   * The actual value is set through the @change event.
   *
   * @param value
   */
  set isSeries(value: boolean) {
    return;
  }

  /**
   * Returns the {@link RecordingEventAppointmentsFree} if it is active.
   * Otherwise, undefined.
   */
  get appointmentsFree(): RecordingEventAppointmentsFree | undefined {
    return this.articleDefinition.publicationDateConfig
      .publication_dates_type === "amount"
      ? (this.recordingArticle
          .article_release_info as RecordingEventAppointmentsFree)
      : undefined;
  }

  /**
   * Returns the {@link RecordingEventAppointmentsSeries} if it is active.
   * Otherwise, undefined.
   */
  get appointmentsSeries(): RecordingEventAppointmentsSeries | undefined {
    return this.articleDefinition.publicationDateConfig
      .publication_dates_type === "period"
      ? (this.recordingArticle
          .article_release_info as RecordingEventAppointmentsSeries)
      : undefined;
  }
  /**
   * Returns the `appointmentsInfo` defined in the settings.
   */
  get appointmentsInfo(): string | null {
    return FrontendSettings.articleRecording.appointmentsInfo;
  }
}
