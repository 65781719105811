
import { Component, Prop, Vue } from "vue-property-decorator";
import RecordingEvent from "@/shared/data/recording_event";
import FrontendSettings from "@/frontend/settings/settings";
import { sanitizeContent } from "@/shared/lib/dompurify";
import QuokkaMediaUpload from "@/components/quokka_layout/ui/QuokkaMediaUpload.vue";
import { DisplayInformationDataType } from "@/shared/data/media_information";

/**
 * Tab for the Event-Recording containing media.
 */
@Component({
  components: { QuokkaMediaUpload },
  data() {
    return { sanitizeContent };
  },
})
export default class QuokkaEventRecordingMediaTab extends Vue {
  /**
   * The {@link RecordingEvent} used as model.
   */
  @Prop({ required: true }) recordingEvent!: RecordingEvent;

  /**
   * Whether the fields of this tab should be disabled or not.
   */
  @Prop() disabled!: boolean;

  /**
   * Advert additional image information fields
   */
  @Prop({ required: true }) mediaConfigData!: {
    [key: string]: { [key: string]: { [key: string]: unknown } };
  };

  /** Loaded Media Files */
  @Prop({ required: true }) availableMedia!: DisplayInformationDataType[];

  /**
   * Returns the `mediaInfo` defined in the settings.
   */
  get mediaInfo(): string | null {
    return FrontendSettings.eventRecording.mediaInfo;
  }
}
