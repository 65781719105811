import DataStruct from "@/shared/data/datastruct";
import EventDate, { ServerEventDateDataType } from "@/shared/data/event_date";
import { DateTime } from "luxon";
import TimeInfo, { ServerTimeInfoDataType } from "@/shared/data/time_info";
import { parseDate } from "@/shared/lib/datetime";

/**
 * Parent class for all appointment classes.
 */
export abstract class RecordingEventAppointments extends DataStruct {
  type: RecordingEventAppointmentType;

  /**
   * Creates a new instance of Tag with given data.
   *
   * @param data The data to load
   * @throws TypeError if any property does not fit the type
   */
  protected constructor(data: { type: RecordingEventAppointmentType }) {
    super();

    this.type = data.type;

    if (DataStruct.validateValue(data, "type", "string")) {
      this.type = data.type;
    } else {
      // We cannot initialize without an id
      throw new TypeError(
        "Could not initialize RecordingEventAppointments. Invalid type."
      );
    }
  }

  /**
   * Creates a new child RecordingEventAppointments-object with the given data returned from the server.
   *
   * @param data
   * @throws TypeError if any property does not fit the type
   */
  static fromServer(
    data: ServerRecordingEventAppointmentsDataType
  ): RecordingEventAppointments {
    switch (data.type) {
      case "free":
        return new RecordingEventAppointmentsFree(
          data as ServerRecordingEventAppointmentsFreeDataType
        );

      case "series":
        return new RecordingEventAppointmentsSeries(
          data as ServerRecordingEventAppointmentsSeriesDataType
        );

      default:
        throw new TypeError(
          "Could not initialize RecordingEventAppointments. Invalid type."
        );
    }
  }

  /**
   * Overridden by subclasses.
   *
   * Returns the first date defined for the appointments.
   *
   * Could be null, if nothing is set.
   */
  get firstDate(): DateTime | null {
    return null;
  }

  /**
   * Overridden by subclasses.
   *
   * Returns the last date defined for the appointments.
   *
   * Could be null, if nothing is set.
   */
  get lastDate(): DateTime | null {
    return null;
  }
}

/**
 * The data structure defined by the MSUevent-Server.
 */
export type ServerRecordingEventAppointmentsDataType =
  ServerRecordingEventAppointmentsFreeDataType & {
    type: RecordingEventAppointmentType;
  };

/**
 * Available types of appointments.
 */
export type RecordingEventAppointmentType = "free" | "series";

/**
 * #############################
 * FREE
 * #############################
 */

/**
 * The Data-Class containing all properties for RecordingEventAppointments with the type free.
 */
export class RecordingEventAppointmentsFree extends RecordingEventAppointments {
  dates: EventDate[] = [];

  /**
   * Creates a new instance of RecordingEventAppointmentsFree.
   *
   * @param data
   * @throws TypeError if any property does not fit the type
   */
  constructor(data: ServerRecordingEventAppointmentsFreeDataType = {}) {
    super({ type: "free" });

    // Load the data
    // Appointments
    if (Object.prototype.hasOwnProperty.call(data, "dates")) {
      this.dates = [];
      data.dates?.forEach((item) =>
        this.dates.push(EventDate.fromServer(item))
      );
    }
  }

  /**
   * Returns the first date defined for the appointments.
   *
   * Could be null, if nothing is set.
   */
  get firstDate(): DateTime | null {
    if (this.dates[0]) return this.dates[0].dateObject;
    return null;
  }

  /**
   * Returns the last date defined for the appointments.
   *
   * Could be null, if nothing is set.
   */
  get lastDate(): DateTime | null {
    const lastDate = this.dates[this.dates.length - 1];
    if (lastDate) return lastDate.dateObject;
    return null;
  }
}

/**
 * The data structure defined by the MSUevent-Server for the "free"-type.
 */
type ServerRecordingEventAppointmentsFreeDataType = {
  dates?: ServerEventDateDataType[];
};

/**
 * #############################
 * SERIES
 * #############################
 */

/**
 * The object that contains every day of a week with its list of {@link TimeInfo} objects.
 */
export type RecordingEventAppointmentsSeriesAppointmentsPerDay = {
  [key in Weekday]: TimeInfo[] | null;
};

/**
 * The Data-Class containing all properties for RecordingEventAppointments with the type series.
 */
export class RecordingEventAppointmentsSeries extends RecordingEventAppointments {
  /**
   * The available weekdays to use for loops.
   *
   * The order has to be correct.
   */
  WEEKDAYS = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  /**
   * The date on when this series starts.
   */
  start_date: DateTime | null = null;

  /**
   * The date on when this series end.
   */
  end_date: DateTime | null = null;

  /**
   * Definition of each day of the week with its {@link TimeInfo} objects.
   */
  appointmentsPerDay: RecordingEventAppointmentsSeriesAppointmentsPerDay = {
    monday: null,
    tuesday: null,
    wednesday: null,
    thursday: null,
    friday: null,
    saturday: null,
    sunday: null,
  };

  /**
   * Creates a new instance of RecordingEventAppointmentsFree.
   *
   * @param data
   * @throws TypeError if any property does not fit the type
   */
  constructor(data: ServerRecordingEventAppointmentsSeriesDataType = {}) {
    super({ type: "series" });

    // Load the data
    if (DataStruct.validateValue(data, "start_date", "string"))
      this.start_date = parseDate(data.start_date, "yyyy-MM-dd") ?? null;
    if (DataStruct.validateValue(data, "end_date", "string"))
      this.end_date = parseDate(data.end_date, "yyyy-MM-dd") ?? null;

    // Appointments
    if (Object.prototype.hasOwnProperty.call(data, "appointmentsPerDay")) {
      for (const weekday in data.appointmentsPerDay) {
        const typedWeekday = weekday as Weekday;
        if (
          Object.prototype.hasOwnProperty.call(this.appointmentsPerDay, weekday)
        ) {
          // Add EventTimes to this day
          const appointmentsForDay: TimeInfo[] = [];

          data.appointmentsPerDay[typedWeekday]?.forEach((item) => {
            appointmentsForDay.push(TimeInfo.fromServer(item));
          });

          this.appointmentsPerDay[typedWeekday] = appointmentsForDay;
        }
      }
    }
  }

  /**
   * Returns the start date defined for the appointments.
   *
   * Could be null, if nothing is set.
   */
  get firstDate(): DateTime | null {
    return this.start_date;
  }

  /**
   * Returns the end date defined for the appointments.
   *
   * Could be null, if nothing is set.
   */
  get lastDate(): DateTime | null {
    return this.end_date;
  }
}

/**
 * The data structure defined by the MSUevent-Server for the "series"-type.
 */
type ServerRecordingEventAppointmentsSeriesDataType = {
  start_date?: string;
  end_date?: string;
  appointmentsPerDay?: Partial<{
    [key in Weekday]: ServerTimeInfoDataType[];
  }>;
};

export type Weekday =
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday"
  | "sunday";
