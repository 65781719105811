
import { Component, Prop, Vue } from "vue-property-decorator";
import { Order } from "@/shared/data/advert";
import QuokkaAdListAdminMyAdListItem from "@/components/quokka_layout/admin/ads/QuokkaAdListAdminMyAdListItem.vue";

/**
 * A list of {@link QuokkaAdListAdminMyAdListItem}s to display available {@link Order}s.
 */
@Component({
  components: { QuokkaAdListAdminMyAdListItem },
})
export default class QuokkaAdListAdminMyAdList extends Vue {
  /**
   * List of available {@link Order}s.
   *
   * null for loading animation.
   */
  @Prop({ required: true }) availableAdvertOrders!: Order[] | null;
}
