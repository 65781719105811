import { DateTime } from "luxon";

/**
 * This error is used for when a DateTime could not be parsed.
 */
export default class DateTimeParseError extends Error {
  reason: string | DateTimeParseReason;
  datetime: DateTime;

  constructor(props: { message: string; reason: string; datetime: DateTime }) {
    super(props.message);

    this.reason = props.reason;
    this.datetime = props.datetime;

    Object.setPrototypeOf(this, DateTimeParseError.prototype);
  }
}

/**
 * Contains a few possible reasons, why a DateTime could not be parsed.
 */
export enum DateTimeParseReason {
  /**
   * Reason, when a date does not exist.
   */
  UNIT_OUT_OF_RANGE = "unit out of range",
  /**
   * Reason, when a date has invalid format.
   */
  UNPARSABLE = "unparsable",
}
