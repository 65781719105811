/**
 * The Person Data-Class containing all properties for a person/user
 */
import DataStruct from "@/shared/data/datastruct";
import Address, { ServerAddressDataType } from "@/shared/data/address";

export default class Person extends DataStruct {
  id: string | null = null;

  salutation: Salutation = "";
  firstName: string | null = null;
  lastName: string | null = null;
  password1: string | null = null;
  password2: string | null = null;
  email: string | null = null;
  passwordOld: string | null = null;

  organization: Address | null = null;
  freeOrganization: string | null = null;
  street: string | null = null;
  plz: string | null = null;
  community: string | null = null;
  phone: string | null = null;
  mobile: string | null = null;
  private_street: string | null = null;
  private_plz: string | null = null;
  private_community: string | null = null;
  private_phone: string | null = null;
  private_mobile: string | null = null;

  acceptPrivacyPolicy = false;
  acceptConditions = false;
  acceptAds = false;

  /**
   * Creates new instance of Person with given data
   * @param data The data to load
   * @throws TypeError if any property does not fit the type
   */
  constructor(data: { id: string }) {
    super();

    if (DataStruct.validateValue(data, "id", "string")) {
      this.id = data.id;
    }
  }

  /**
   * Creates new Person-Object with data returned from the server.
   * @param data
   */
  static fromServer(data: ServerPersonDataType): Person {
    const person = new Person({ id: data.id ?? "0" });
    // Load Data
    if (DataStruct.validateValue(data, "salutation", "string")) {
      person.salutation = data.salutation;
    }
    if (DataStruct.validateValue(data, "firstName", "string")) {
      person.firstName = data.firstName;
    }
    if (DataStruct.validateValue(data, "lastName", "string")) {
      person.lastName = data.lastName;
    }
    if (DataStruct.validateValue(data, "email", "string")) {
      person.email = data.email;
    }
    // Official Address
    if (
      data.street != undefined &&
      DataStruct.validateValue(data, "street", "string")
    ) {
      person.street = data.street;
    }
    if (
      data.plz != undefined &&
      DataStruct.validateValue(data, "plz", "string")
    ) {
      person.plz = data.plz;
    }
    if (
      data.community != undefined &&
      DataStruct.validateValue(data, "community", "string")
    ) {
      person.community = data.community;
    }
    if (
      data.phone != undefined &&
      DataStruct.validateValue(data, "phone", "string")
    ) {
      person.phone = data.phone;
    }
    if (
      data.freeOrganization != undefined &&
      DataStruct.validateValue(data, "freeOrganization", "string")
    ) {
      person.freeOrganization = data.freeOrganization;
    }
    if (
      data.organization != undefined &&
      DataStruct.validateValue(data, "organization", "object")
    ) {
      person.organization = Address.fromServer(
        <ServerAddressDataType>data.organization
      );
    }
    // Private Address
    if (
      data.private_street != undefined &&
      DataStruct.validateValue(data, "private_street", "string")
    ) {
      person.private_street = data.private_street;
    }
    if (
      data.private_plz != undefined &&
      DataStruct.validateValue(data, "private_plz", "string")
    ) {
      person.private_plz = data.private_plz;
    }
    if (
      data.private_community != undefined &&
      DataStruct.validateValue(data, "private_community", "string")
    ) {
      person.private_community = data.private_community;
    }
    if (
      data.private_phone != undefined &&
      DataStruct.validateValue(data, "private_phone", "string")
    ) {
      person.private_phone = data.private_phone;
    }

    if (DataStruct.validateValue(data, "acceptPrivacyPolicy", "boolean")) {
      person.acceptPrivacyPolicy = data.acceptPrivacyPolicy;
    }
    if (DataStruct.validateValue(data, "acceptConditions", "boolean")) {
      person.acceptConditions = data.acceptConditions;
    }

    if (DataStruct.validateValue(data, "acceptAds", "boolean")) {
      person.acceptAds = data.acceptAds;
    }
    // Fill in password as empty, not delivered by server
    person.password1 = null;
    person.password2 = null;
    person.passwordOld = null;
    return person;
  }

  /**
   * Returns the label of the set salutation.
   */
  get salutationLabel(): string {
    const salutations: { [key: string]: string } = {
      "2": "Herr",
      "3": "Frau",
    };
    return salutations[this.salutation] ?? "Diverse";
  }
}

/**
 * The Data structure as returned by the Server
 */
export type ServerPersonDataType = {
  id?: string;
  login: string;
  salutation: Salutation;
  firstName: string;
  lastName: string;
  organization?: ServerAddressDataType;
  freeOrganization?: string;
  street?: string;
  plz?: string;
  community?: string;
  phone?: string;
  private_street?: string;
  private_plz?: string;
  private_community?: string;
  private_phone?: string;
  email: string;
  acceptPrivacyPolicy: boolean;
  acceptConditions: boolean;
  acceptAds: boolean;
};

/**
 * Data Type for Salutation
 */
export type Salutation = "" | "1" | "2" | "3";
