import DataStruct from "./datastruct";

/**
 * AdditionFields Data class containing all information about Text definitions available for articles
 */
export abstract class AdditionField extends DataStruct {
  type: AdditionFieldType;
  field_config_id: number;
  name: string;

  /**
   * Creates a new Instance of AdditionField with given data.
   * @param data The data to load
   * @throws TypeError
   */
  protected constructor(data: {
    type: AdditionFieldType;
    field_config_id: number;
    name: string;
  }) {
    super();

    if (DataStruct.validateValue(data, "type", "string")) {
      this.type = data.type;
    } else {
      throw new TypeError(
        "Could not initialize AdditionField. Invalid 'type'."
      );
    }

    if (DataStruct.validateValue(data, "field_config_id", "number")) {
      this.field_config_id = data.field_config_id;
    } else {
      throw new TypeError(
        "Could not initialize AdditionField. Invalid 'field_config_id'."
      );
    }

    if (DataStruct.validateValue(data, "name", "string")) {
      this.name = data.name;
    } else {
      throw new TypeError(
        "Could not initialize AdditionField. Invalid 'name'."
      );
    }
  }

  /**
   * Creates a new child-object with the given data returned from the server.
   * @param data
   * @throws TypeError
   */
  static fromServer(data: ServerAdditionFieldDataType): AdditionField {
    switch (data.type) {
      case "input":
        return new InputAdditionField(data as ServerInputAdditionFieldDataType);
      case "select":
        return new SelectAdditionField(
          data as ServerSelectAdditionFieldDataType
        );
      case "radio":
        return new RadioAdditionField(data as ServerRadioAdditionFieldDataType);
      case "checkbox":
        return new CheckboxAdditionField(
          data as ServerCheckboxAdditionFieldDataType
        );
      case "textarea":
        return new TextareaAdditionField(
          data as ServerTextareaAdditionFieldDataType
        );
      default:
        throw new TypeError(
          "Could not initialize AdditionalFields. Invalid type."
        );
    }
  }
}

/** Available types for additional fields */
export type AdditionFieldType =
  | "input"
  | "select"
  | "radio"
  | "checkbox"
  | "textarea";

/** Available Input-Types for input-fields */
export type AdditionFieldInputType =
  | "date"
  | "datepicker"
  | "time"
  | "timepicker"
  | "email"
  | "text"
  | "url"
  | "tel"
  | "number";

/** The data structure defined by the MSUevent-Server for the "Input"-type */
export type ServerAdditionFieldDataType = {
  type: AdditionFieldType;
  required: boolean;
  field_config_id: number;
  name: string;
  input_type?: AdditionFieldInputType;
  placeholder?: string;
  label: string;
  info?: string;
  required_form_message?: string;
  maxlength?: number;
  minlength?: number;
  regextest?: string;
  counter?: boolean;
  valuelist: AdditionTypeValueListItem[];
  minval: string;
  maxval: string;
  usedefault: boolean;
  defaultvalue: string;
  comment: string; //?
  enable_wysiwyg: boolean;
  wysiwyg_options: WysiwygConfigDataType;
};

/**
 * ################################
 * INPUT
 * ################################
 */
/**  Data Class containing all properties for an AdditionField with the type 'input' */
export class InputAdditionField extends AdditionField {
  input_type: AdditionFieldInputType;
  placeholder?: string;
  label: string;
  info?: string;
  required: boolean;
  required_form_message?: string;
  maxlength?: number;
  minlength?: number;
  regextest?: string;
  counter: boolean;
  minval?: string;
  maxval?: string;

  constructor(data: ServerInputAdditionFieldDataType) {
    super({
      type: "input",
      field_config_id: data.field_config_id,
      name: data.name,
    });

    // Load data
    if (DataStruct.validateValue(data, "field_config_id", "number")) {
      this.field_config_id = data.field_config_id;
    } else {
      throw new TypeError(
        "Could not initialize InputAdditionalField. Invalid 'field_config_id'."
      );
    }
    if (DataStruct.validateValue(data, "name", "string")) {
      this.name = data.name;
    } else {
      throw new TypeError(
        "Could not initialize InputAdditionalField. Invalid 'name'."
      );
    }

    if (DataStruct.validateValue(data, "input_type", "string")) {
      this.input_type = data.input_type;
    } else {
      throw new TypeError(
        "Could not initialize InputAdditionalField. Invalid 'input_type'."
      );
    }

    if (DataStruct.validateValue(data, "placeholder", "string")) {
      this.placeholder = data.placeholder;
    }
    if (DataStruct.validateValue(data, "label", "string")) {
      this.label = data.label;
    } else {
      throw new TypeError(
        "Could not initialize InputAdditionalField. Invalid 'label'."
      );
    }
    if (DataStruct.validateValue(data, "info", "string")) {
      this.info = data.info;
    }
    if (DataStruct.validateValue(data, "required", "boolean")) {
      this.required = data.required;
    } else {
      throw new TypeError(
        "Could not initialize InputAdditionalField. Invalid 'required'."
      );
    }
    if (DataStruct.validateValue(data, "required_form_message", "string")) {
      this.required_form_message = data.required_form_message;
    }
    if (
      DataStruct.validateValue(data, "maxlength", "number") &&
      data.maxlength > 0
    ) {
      this.maxlength = data.maxlength;
    }
    if (
      DataStruct.validateValue(data, "minlength", "number") &&
      data.minlength > 0
    ) {
      this.minlength = data.minlength;
    }
    if (DataStruct.validateValue(data, "regextest", "string")) {
      this.regextest = data.regextest;
    }
    if (DataStruct.validateValue(data, "counter", "boolean")) {
      this.counter = data.counter;
    } else {
      throw new TypeError(
        "Could not initialize InputAdditionalField. Invalid 'counter'."
      );
    }
    if (DataStruct.validateValue(data, "minval", "string")) {
      this.minval = data.minval;
    }
    if (DataStruct.validateValue(data, "maxval", "string")) {
      this.maxval = data.maxval;
    }
  }
}

/** The data structure defined by the MSUevent-Server for the "input"-type */
type ServerInputAdditionFieldDataType = {
  field_config_id: number;
  name: string;
  input_type: AdditionFieldInputType;
  placeholder: string;
  label: string;
  info: string;
  required: boolean;
  required_form_message: string;
  maxlength: number;
  minlength: number;
  regextest: string;
  counter: boolean;
  minval: string;
  maxval: string;
  usedefault: boolean;
  defaultvalue: string;
};

/**
 * ################################
 * SELECT
 * ################################
 */
/**  Data Class containing all properties for an AdditionField with the type 'select' */
export class SelectAdditionField extends AdditionField {
  placeholder?: string;
  label: string;
  info?: string;
  required: boolean;
  required_form_message?: string;
  valuelist: AdditionTypeSelectListItem[];

  constructor(data: ServerSelectAdditionFieldDataType) {
    super({
      field_config_id: data.field_config_id,
      name: data.name,
      type: "select",
    });

    // Load data
    if (DataStruct.validateValue(data, "field_config_id", "number")) {
      this.field_config_id = data.field_config_id;
    } else {
      throw new TypeError(
        "Could not initialize InputAdditionalField. Invalid 'field_config_id'."
      );
    }
    if (DataStruct.validateValue(data, "name", "string")) {
      this.name = data.name;
    } else {
      throw new TypeError(
        "Could not initialize InputAdditionalField. Invalid 'name'."
      );
    }
    if (DataStruct.validateValue(data, "placeholder", "string")) {
      this.placeholder = data.placeholder;
    }
    if (DataStruct.validateValue(data, "label", "string")) {
      this.label = data.label;
    } else {
      throw new TypeError(
        "Could not initialize InputAdditionalField. Invalid 'label'."
      );
    }
    if (DataStruct.validateValue(data, "info", "string")) {
      this.info = data.info;
    }
    if (DataStruct.validateValue(data, "required", "boolean")) {
      this.required = data.required;
    } else {
      throw new TypeError(
        "Could not initialize InputAdditionalField. Invalid 'required'."
      );
    }
    if (DataStruct.validateValue(data, "required_form_message", "string")) {
      this.required_form_message = data.required_form_message;
    }
    this.valuelist = [];
    try {
      data.valuelist.forEach((item) =>
        this.valuelist.push({ text: item.text, value: item.key })
      );
    } catch (e) {
      throw new TypeError(
        "Could not initialize InputAdditionalField. Invalid 'valuelist' item."
      );
    }
  }
}

/** The data structure defined by the MSUevent-Server for the "select"-type */
type ServerSelectAdditionFieldDataType = {
  field_config_id: number;
  name: string;
  placeholder: string;
  label: string;
  info: string;
  required: boolean;
  required_form_message?: string;
  valuelist: AdditionTypeValueListItem[];
};

/** The data type for value lists */
type AdditionTypeValueListItem = {
  key: string;
  sort: string;
  text: string;
};

/** The data type for select lists */
type AdditionTypeSelectListItem = {
  text: string;
  value: string;
};

/**
 * ################################
 * RADIO
 * ################################
 */
/**  Data Class containing all properties for an AdditionField with the type 'radio' */
export class RadioAdditionField extends AdditionField {
  label: string;
  info?: string;
  required: boolean;
  required_form_message?: string;
  valuelist: AdditionTypeValueListItem[];

  constructor(data: ServerRadioAdditionFieldDataType) {
    super({
      field_config_id: data.field_config_id,
      name: data.name,
      type: "radio",
    });

    // Load data
    if (DataStruct.validateValue(data, "field_config_id", "number")) {
      this.field_config_id = data.field_config_id;
    } else {
      throw new TypeError(
        "Could not initialize InputAdditionalField. Invalid 'field_config_id'."
      );
    }
    if (DataStruct.validateValue(data, "name", "string")) {
      this.name = data.name;
    } else {
      throw new TypeError(
        "Could not initialize InputAdditionalField. Invalid 'name'."
      );
    }
    if (DataStruct.validateValue(data, "label", "string")) {
      this.label = data.label;
    } else {
      throw new TypeError(
        "Could not initialize InputAdditionalField. Invalid 'label'."
      );
    }
    if (DataStruct.validateValue(data, "info", "string")) {
      this.info = data.info;
    }
    if (DataStruct.validateValue(data, "required", "boolean")) {
      this.required = data.required;
    } else {
      throw new TypeError(
        "Could not initialize InputAdditionalField. Invalid 'required'."
      );
    }
    if (DataStruct.validateValue(data, "required_form_message", "string")) {
      this.required_form_message = data.required_form_message;
    }
    this.valuelist = [];
    try {
      data.valuelist.forEach((item) => this.valuelist.push(item));
    } catch (e) {
      throw new TypeError(
        "Could not initialize InputAdditionalField. Invalid 'valuelist' item."
      );
    }
  }
}

/** The data structure defined by the MSUevent-Server for the "radio"-type */
type ServerRadioAdditionFieldDataType = {
  field_config_id: number;
  name: string;
  label: string;
  info: string;
  required: boolean;
  required_form_message?: string;
  valuelist: AdditionTypeValueListItem[];
};

/**
 * ################################
 * CHECKBOX
 * ################################
 */
/**  Data Class containing all properties for an AdditionField with the type 'checkbox' */
export class CheckboxAdditionField extends AdditionField {
  label: string;
  info?: string;
  required: boolean;
  required_form_message?: string;

  constructor(data: ServerCheckboxAdditionFieldDataType) {
    super({
      field_config_id: data.field_config_id,
      name: data.name,
      type: "checkbox",
    });

    // Load data
    if (DataStruct.validateValue(data, "field_config_id", "number")) {
      this.field_config_id = data.field_config_id;
    } else {
      throw new TypeError(
        "Could not initialize InputAdditionalField. Invalid 'field_config_id'."
      );
    }
    if (DataStruct.validateValue(data, "name", "string")) {
      this.name = data.name;
    } else {
      throw new TypeError(
        "Could not initialize InputAdditionalField. Invalid 'name'."
      );
    }
    if (DataStruct.validateValue(data, "label", "string")) {
      this.label = data.label;
    } else {
      throw new TypeError(
        "Could not initialize InputAdditionalField. Invalid 'label'."
      );
    }
    if (DataStruct.validateValue(data, "info", "string")) {
      this.info = data.info;
    }
    if (DataStruct.validateValue(data, "required", "boolean")) {
      this.required = data.required;
    } else {
      throw new TypeError(
        "Could not initialize InputAdditionalField. Invalid 'required'."
      );
    }
    if (DataStruct.validateValue(data, "required_form_message", "string")) {
      this.required_form_message = data.required_form_message;
    }
  }
}

/** The data structure defined by the MSUevent-Server for the "checkbox"-type */
type ServerCheckboxAdditionFieldDataType = {
  field_config_id: number;
  name: string;
  label: string;
  info: string;
  required: boolean;
  required_form_message: string;
};

/**
 * ################################
 * TEXTAREA
 * ################################
 */
/**  Data Class containing all properties for an AdditionField with the type 'textarea' */
export class TextareaAdditionField extends AdditionField {
  input_type: AdditionFieldInputType;
  placeholder?: string;
  label: string;
  counter: boolean;
  info?: string;
  required: boolean;
  required_form_message?: string;
  maxlength?: number;
  minlength?: number;
  regextest?: string;
  enable_wysiwyg: boolean;
  wysiwyg_options?: WysiwygConfigDataType;

  constructor(data: ServerTextareaAdditionFieldDataType) {
    super({
      field_config_id: data.field_config_id,
      name: data.name,
      type: "textarea",
    });

    // Load data
    if (DataStruct.validateValue(data, "input_type", "string")) {
      this.input_type = data.input_type;
    } else {
      throw new TypeError(
        "Could not initialize InputAdditionalField. Invalid 'input_type'."
      );
    }
    if (DataStruct.validateValue(data, "placeholder", "string")) {
      this.placeholder = data.placeholder;
    }
    if (DataStruct.validateValue(data, "label", "string")) {
      this.label = data.label;
    } else {
      throw new TypeError(
        "Could not initialize InputAdditionalField. Invalid 'label'."
      );
    }
    if (DataStruct.validateValue(data, "info", "string")) {
      this.info = data.info;
    }
    if (DataStruct.validateValue(data, "required", "boolean")) {
      this.required = data.required;
    } else {
      throw new TypeError(
        "Could not initialize InputAdditionalField. Invalid 'required'."
      );
    }
    if (DataStruct.validateValue(data, "required_form_message", "string")) {
      this.required_form_message = data.required_form_message;
    }
    if (DataStruct.validateValue(data, "maxlength", "number")) {
      this.maxlength = data.maxlength;
    }
    if (DataStruct.validateValue(data, "minlength", "number")) {
      this.minlength = data.minlength;
    }
    if (DataStruct.validateValue(data, "regextest", "string")) {
      this.regextest = data.regextest;
    }
    if (DataStruct.validateValue(data, "counter", "boolean")) {
      this.counter = data.counter;
    } else {
      throw new TypeError(
        "Could not initialize InputAdditionalField. Invalid 'counter'."
      );
    }
    if (DataStruct.validateValue(data, "enable_wysiwyg", "boolean")) {
      this.enable_wysiwyg = data.enable_wysiwyg;
    } else {
      throw new TypeError(
        "Could not initialize InputAdditionalField. Invalid 'enable_wysiwyg'."
      );
    }
    if (data.wysiwyg_options !== null) {
      this.wysiwyg_options = data.wysiwyg_options;
    }
  }
}

/** The data structure defined by the MSUevent-Server for the "textarea"-type */
type ServerTextareaAdditionFieldDataType = {
  field_config_id: number;
  name: string;
  required: boolean;
  input_type: AdditionFieldInputType;
  placeholder: string;
  label: string;
  info: string;
  required_form_message: string;
  maxlength: number;
  minlength: number;
  regextest: string;
  counter: boolean;
  usedefault: boolean;
  defaultvalue: string;
  enable_wysiwyg: boolean;
  wysiwyg_options?: WysiwygConfigDataType;
};

/** The data structure for wysiwyg-textfield configuration */
type WysiwygConfigDataType = {
  modules: {
    toolbar: {
      display: string;
      bold: string;
      italic: string;
    };
  };
  readonly: boolean;
};
