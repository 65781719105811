import { DateTime } from "luxon";
import { formatDate, parseDate } from "@/shared/lib/datetime";

/**
 * This class contains functions to handle authorization of the client for the admin area.
 */
export default abstract class Authorization {
  /**
   * The DateTime format for the token's expiration datetime.
   *
   * @private
   */
  public static TokenExpirationFormat = "yyyy-MM-dd HH:mm";

  /**
   * Tries to get and validate the authorized token saved in the local storage.
   * If the token is not available or expired, null is returned.
   *
   * @throws Error
   */
  static getValidatedToken(): string {
    const token = localStorage.getItem("authorization_token");
    const expiration = parseDate(
      localStorage.getItem("authorization_expiration"),
      this.TokenExpirationFormat
    );

    if (!token || token.trim() == "" || !expiration) {
      this.clearToken();
      throw new GetTokenError("no-token");
    }

    // Check, if the token already expired
    if (expiration < DateTime.now()) {
      // Expired
      this.clearToken();
      throw new GetTokenError("expired");
    }

    return token;
  }

  /**
   * Sets the given token with expiration datetime to the local storage.
   *
   * @param token
   * @param expiration
   */
  static setToken(token: string, expiration: DateTime): void {
    const authExpiration = formatDate(expiration, this.TokenExpirationFormat);

    if (!token || !authExpiration) {
      // Invalid auth data
      return;
    }

    localStorage.setItem("authorization_token", token);
    localStorage.setItem("authorization_expiration", authExpiration);
  }

  /**
   * Clears the token and expiration datetime from local storage.
   */
  static clearToken(): void {
    localStorage.removeItem("authorization_token");
    localStorage.removeItem("authorization_expiration");
  }
}

/**
 * Error thrown, if the token could not be gotten.
 */
export class GetTokenError extends Error {
  reason: GetTokenErrorReason;

  constructor(reason: GetTokenErrorReason) {
    super();

    this.reason = reason;
  }
}

/**
 * Possible reasons, why a {@link GetTokenError} is thrown.
 */
export type GetTokenErrorReason = "no-token" | "expired" | "unknown-error";
