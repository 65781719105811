
import { Component, Prop, Vue } from "vue-property-decorator";
import FrontendSettings from "@/frontend/settings/settings";

/**
 * Wrapping View for authorization views input via the slot
 */
@Component
export default class AuthView extends Vue {
  /**
   * Fills main title of page
   */
  @Prop() title!: string;

  /**
   * URLs for conditions, privacy policy and imprint
   */
  urlConditions = "";
  urlPrivacyPolicy = "";
  urlImprint = "";

  mounted(): void {
    /**
     *  Get Urls for conditions, privacy policy and imprint
     */
    this.urlConditions = FrontendSettings.links.conditions;
    this.urlPrivacyPolicy = FrontendSettings.links.privacyPolicy;
    this.urlImprint = FrontendSettings.links.imprint;
  }

  /**
   * Whether to show the links in the card footer or not.
   */
  get showLinks(): boolean {
    return FrontendSettings.authenticatedArea.showLinksInCardFooters;
  }
}
