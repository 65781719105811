
import { Component, Prop, Vue } from "vue-property-decorator";
import QuokkaEventRubricSearch from "@/components/quokka_layout/QuokkaEventRubricSearch.vue";
import QuokkaEventAddressSearch from "@/components/quokka_layout/QuokkaEventAddressSearch.vue";
import {
  RecordingEventAppointmentsSeries,
  Weekday,
} from "@/shared/data/event_appointments";
import TimeInfo from "@/shared/data/time_info";
import LabeledSheet from "@/components/quokka_layout/ui/LabeledSheet.vue";
import RecordingEvent from "@/shared/data/recording_event";
import QuokkaEventRecordingAppointmentsFreeTimeListItem from "@/components/quokka_layout/recording/QuokkaEventRecordingAppointmentsFreeTimeListItem.vue";
import QuokkaDatePicker from "@/components/quokka_layout/ui/QuokkaDatePicker.vue";
import { formatDate, parseDate } from "@/shared/lib/datetime";
import FrontendSettings from "@/frontend/settings/settings";
import { sanitizeContent } from "@/shared/lib/dompurify";

/**
 * Tab for the Event-Recording containing the appointments for the type "free".
 */
@Component({
  components: {
    QuokkaDatePicker,
    QuokkaEventRecordingAppointmentsFreeTimeListItem,
    LabeledSheet,
    QuokkaEventAddressSearch,
    QuokkaEventRubricSearch,
  },
  data() {
    return { sanitizeContent };
  },
})
export default class QuokkaEventRecordingAppointmentsSeriesTab extends Vue {
  /**
   * The {@link RecordingEvent} used as model.
   */
  @Prop({ required: true }) recordingEvent!: RecordingEvent;

  /**
   * Whether the fields of this tab should be disabled or not.
   */
  @Prop() disabled!: boolean;

  /**
   * The {@link RecordingEventAppointmentsSeries} used as model.
   */
  @Prop({ required: true }) appointments!: RecordingEventAppointmentsSeries;

  /**
   * Available rules for the fields to verify the value.
   */
  rules: { [key: string]: (value: string) => boolean | string } = {
    date: (value: string): boolean | string => {
      if (!value || value.trim() === "") return "Datum eingeben";
      return true;
    },
  };

  /**
   * Returns a list of all available {@link Weekday}s with a list of defined {@link TimeInfo} objects for this day.
   */
  get weekdaysWithTimeInfo(): {
    weekday: Weekday;
    appointments: TimeInfo[];
  }[] {
    const weekdaysWithTimeInfo: {
      weekday: Weekday;
      appointments: TimeInfo[];
    }[] = [];

    Object.keys(this.appointments.appointmentsPerDay).forEach((key) => {
      const weekday = key as Weekday;
      weekdaysWithTimeInfo.push({
        weekday: weekday,
        appointments: this.appointments.appointmentsPerDay[weekday] ?? [],
      });
    });

    return weekdaysWithTimeInfo;
  }

  /**
   * Returns the label of the weekday for given dayOfWeek.
   *
   * @param dayOfWeek
   */
  getWeekdayLabel(dayOfWeek: number): string {
    return formatDate(parseDate(dayOfWeek.toString(), "c"), "cccc") || "";
  }

  /**
   * Adds a new, empty {@link TimeInfo} for the given {@link Weekday}.
   *
   * @param weekday
   */
  addTimeInfo(weekday: Weekday): void {
    if (!this.appointments.appointmentsPerDay[weekday]) {
      this.appointments.appointmentsPerDay[weekday] = [];
    }
    this.appointments.appointmentsPerDay[weekday]?.push(new TimeInfo());
  }

  /**
   * Removes the {@link TimeInfo} at given index from the given {@link Weekday}.
   *
   * @param weekday
   * @param index
   */
  removeTimeInfo(weekday: Weekday, index: number): void {
    if (this.appointments.appointmentsPerDay[weekday]) {
      this.appointments.appointmentsPerDay[weekday]?.splice(index, 1);
    }
  }
  /**
   * Returns the `appointmentsSeriesInfo` defined in the settings.
   */
  get appointmentsSeriesInfo(): string | null {
    return FrontendSettings.eventRecording.appointmentsSeriesInfo;
  }
}
