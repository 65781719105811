
import { Component, Prop, Vue } from "vue-property-decorator";
import QuokkaEventRubricSearch from "@/components/quokka_layout/QuokkaEventRubricSearch.vue";
import QuokkaEventAddressSearch from "@/components/quokka_layout/QuokkaEventAddressSearch.vue";
import LabeledSheet from "@/components/quokka_layout/ui/LabeledSheet.vue";
import RecordingArticle from "@/shared/data/recording_article";
import QuokkaAdditionField from "@/components/quokka_layout/ui/additional-fields/QuokkaAdditionField.vue";
import { ArticleDefinition } from "@/shared/data/article_definition";
import FrontendSettings from "@/frontend/settings/settings";
import AdditionFieldsForm from "@/components/quokka_layout/ui/additional-fields/AdditionFieldsForm.vue";
import { sanitizeContent } from "@/shared/lib/dompurify";

/** Tab for the Article-Recording containing the texts for the {@link RecordingArticle}. */
@Component({
  components: {
    AdditionFieldsForm,
    QuokkaAdditionField,
    LabeledSheet,
    QuokkaEventAddressSearch,
    QuokkaEventRubricSearch,
  },
  data() {
    return { sanitizeContent };
  },
})
export default class QuokkaArticleRecordingTextsTab extends Vue {
  /**The {@link RecordingArticle} used */
  @Prop({ required: true }) recordingArticle!: RecordingArticle;

  /** The {@link ArticleDefinition} used to build layout */
  @Prop({ required: true }) articleDefinition!: ArticleDefinition;

  /** Whether the fields of this tab should be disabled or not. */
  @Prop() disabled!: boolean;

  mounted(): void {
    for (const formRow of this.articleDefinition.formDefinition) {
      for (const additionField of formRow) {
        if (!additionField.additionField || !additionField.additionField.name)
          continue;

        if (
          !this.recordingArticle.article_data[additionField.additionField.name]
        )
          this.recordingArticle.article_data[additionField.additionField.name] =
            null;
      }
    }
  }
  /**
   * Returns the `textsInfo` defined in the settings.
   */
  get textsInfo(): string | null {
    return FrontendSettings.articleRecording.textsInfo;
  }
}
