import DataStruct from "../../shared/data/datastruct";

/**
 * The EventLink Data-Class containing all properties for a EventLink.
 */
export default class EventLink extends DataStruct {
  usage: EventLinkUsage;
  link: string | null = null;
  description: string | null = null;

  /**
   * Creates a new instance of EventLink with given data.
   *
   * @param data The data to load
   * @throws TypeError if any property does not fit the type
   */
  constructor(data: { usage: EventLinkUsage }) {
    super();

    if (DataStruct.validateValue(data, "usage", "string")) {
      this.usage = data.usage;
    } else {
      // We cannot initialize without an id
      throw new TypeError("Could not initialize EventLink. Invalid usage.");
    }
  }

  /**
   * Returns the label of the set `usage`.
   */
  get usageLabel(): string {
    return EventLink.getUsageLabel(this.usage);
  }

  /**
   * Creates a new EventLink-object with the given data returned from the server.
   *
   * @param data
   */
  static fromServer(data: ServerEventLinkDataType): EventLink {
    const link = new EventLink({
      usage: data.usage,
    });

    // Load the data
    if (DataStruct.validateValue(data, "link", "string"))
      link.link = <string>data.link;
    if (DataStruct.validateValue(data, "description", "string"))
      link.description = <string>data.description;

    return link;
  }

  /**
   * Returns the label of the given `usage`.
   */
  static getUsageLabel(usage: EventLinkUsage): string {
    switch (usage) {
      case "ticket":
        return "Ticketlink";
      case "location":
        return "Veranstaltungsort-Website";
      case "promoter":
        return "Veranstalter-Website";
      case "event":
        return "Event-Website";
      case "artist":
        return "Künstler-Website";
      default:
        return "";
    }
  }
}

/**
 * The data structure defined by the MSUevent-Server.
 */
export type ServerEventLinkDataType = {
  usage: EventLinkUsage;
  link?: string;
  description?: string;
};

export type EventLinkUsage =
  | "ticket"
  | "location"
  | "promoter"
  | "event"
  | "artist";
