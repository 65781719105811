/**
 * This class is used to work with dynamic routes.
 */
import { Route } from "vue-router";

abstract class RouteHelper {
  /**
   * Checks, if the route has a parameter with the given key.
   * Parameters are values directly inside the path, not after the '?' (query).
   * Parameter names are defined in Vue's-Router file.
   *
   * @param vueRoute Vue's Route. Mostly this.$route
   * @param key The name of the parameter
   * @return boolean
   */
  static hasParam(vueRoute: Route, key: string): boolean {
    return (
      Object.prototype.hasOwnProperty.call(vueRoute.params, key) &&
      vueRoute.params[key] !== undefined
    );
  }

  /**
   * Returns the value of the key, if available.
   * If not available, undefined is returned.
   *
   * @param vueRoute Vue's Route. Mostly this.$route
   * @param key The name of the parameter
   * @return unknown | undefined
   */
  static getParam<T>(vueRoute: Route, key: string): T {
    if (RouteHelper.hasParam(vueRoute, key)) {
      return <T>(<unknown>vueRoute.params[key]);
    }

    return <T>(<unknown>undefined);
  }

  /**
   * Checks, if the route has a query parameter with the given key.
   * Query-Parameters are values in the path, after the '?'..
   *
   * @param vueRoute Vue's Route. Mostly this.$route
   * @param key The name of the parameter
   * @return boolean
   */
  static hasQueryParam(vueRoute: Route, key: string): boolean {
    return (
      Object.prototype.hasOwnProperty.call(vueRoute.query, key) &&
      vueRoute.query[key] !== undefined
    );
  }

  /**
   * Returns the value of the key, if available.
   * If not available, undefined is returned.
   *
   * @param vueRoute Vue's Route. Mostly this.$route
   * @param key The name of the parameter
   * @return unknown | undefined
   */
  static getQueryParam<T>(vueRoute: Route, key: string): T {
    if (RouteHelper.hasQueryParam(vueRoute, key)) {
      return <T>(<unknown>vueRoute.query[key]);
    }

    return <T>(<unknown>undefined);
  }
}

export default RouteHelper;
