import { DateTime } from "luxon";
import DateTimeParseError from "@/frontend/lib/exceptions/DateTimeParseError";

/**
 * Returns given date in the given format.
 *
 * @param date the date to format
 * @param format the format - defaults to "dd.MM.yyyy"
 * @returns {string?} the formatted date or undefined, if date is not set or invalid.
 *
 * @see https://moment.github.io/luxon/#/formatting?id=table-of-tokens for formatting options.
 */
export function formatDate(
  date: DateTime | undefined | null,
  format: string | DateFormats = "dd.MM.yyyy"
): string | undefined {
  return date?.toFormat(format);
}

/**
 * Tries to parse the given date with given format.
 *
 * @param date the date to parse. If undefined, null or empty, parsed date will be undefined
 * @param format the format of given date
 * @throws DateTimeParseError if the date could not be parsed with given format.
 *
 * @see https://moment.github.io/luxon/#/formatting?id=table-of-tokens for formatting options.
 */
export function parseDate(
  date: string | undefined | null,
  format: string | DateFormats = "dd.MM.yyyy"
): DateTime | undefined {
  if (date === undefined || date === null || date.trim() === "") {
    return undefined;
  } else {
    const datetime = DateTime.fromFormat(date, format);
    if (datetime.isValid) {
      return datetime;
    } else {
      throw new DateTimeParseError({
        message:
          "Failed to parse given date '" +
          date +
          "' to format '" +
          format +
          "'. " +
          "[" +
          datetime.invalidReason +
          "] " +
          datetime.invalidExplanation,
        reason: datetime.invalidReason ?? "",
        datetime: datetime,
      });
    }
  }
}

/**
 * Returns given time in the given format.
 *
 * @param time the date to format
 * @param format the format - defaults to "HH:mm"
 * @returns {string?} the formatted time or undefined, if time is not set or invalid.
 *
 * @see https://moment.github.io/luxon/#/formatting?id=table-of-tokens for formatting options.
 */
export function formatTime(
  time: DateTime | undefined | null,
  format: string | TimeFormats = "HH:mm"
): string | undefined {
  return time?.toFormat(format);
}

/**
 * Tries to parse the given timestamp to {@link DateTime}.
 *
 * @param timestamp the timestamp to parse
 * @throws DateTimeParseError if the timestamp could not be parsed.
 */
export function parseTimestamp(
  timestamp: number | undefined | null
): DateTime | undefined {
  if (timestamp === undefined || timestamp === null || timestamp < 0) {
    return undefined;
  }

  const datetime = DateTime.fromSeconds(timestamp);
  if (datetime.isValid) {
    return datetime;
  } else {
    throw new DateTimeParseError({
      message:
        "Failed to parse given timestamp '" +
        timestamp +
        "'. " +
        "[" +
        datetime.invalidReason +
        "] " +
        datetime.invalidExplanation,
      reason: datetime.invalidReason ?? "",
      datetime: datetime,
    });
  }
}

/**
 * Tries to parse the given time with given format.
 *
 * @param time the time to parse. If undefined, null or empty, parsed time will be undefined
 * @param format the format of given time
 * @throws DateTimeParseError if the time could not be parsed with given format.
 *
 * @see https://moment.github.io/luxon/#/formatting?id=table-of-tokens for formatting options.
 */
export function parseTime(
  time: string | undefined | null,
  format: string | TimeFormats = "HH:mm"
): DateTime | undefined {
  if (time === undefined || time === null || time.trim() === "") {
    return undefined;
  } else {
    const datetime = DateTime.fromFormat(time, format);
    if (datetime.isValid) {
      return datetime;
    } else {
      throw new DateTimeParseError({
        message:
          "Failed to parse given time '" +
          time +
          "' to format '" +
          format +
          "'. " +
          "[" +
          datetime.invalidReason +
          "] " +
          datetime.invalidExplanation,
        reason: datetime.invalidReason ?? "",
        datetime: datetime,
      });
    }
  }
}

/**
 * Available Date-Formats.
 */
export type DateFormats = "yyyy-MM-dd" | "dd.MM.yyyy";

/**
 * Available Time-Formats.
 */
export type TimeFormats = "HH:mm";
