
import { Component, Prop, Vue } from "vue-property-decorator";
import { FormDefinition } from "@/shared/data/form";
import { DateTime } from "luxon";
import QuokkaAdditionField from "@/components/quokka_layout/ui/additional-fields/QuokkaAdditionField.vue";

@Component({ components: { QuokkaAdditionField } })
export default class AdditionFieldsForm extends Vue {
  /** The Definition of the addition fields */
  @Prop({ required: true }) definition!: FormDefinition;
  /** If the fields are disabled */
  @Prop() disabled!: boolean;
  /** The object for the modal */
  @Prop({ required: true }) data!: {
    [key: string]: string | boolean | DateTime | number | null;
  };

  mounted(): void {
    for (const formRow of this.definition) {
      for (const additionField of formRow) {
        if (!additionField.additionField || !additionField.additionField.name)
          continue;
        if (!this.data[additionField.additionField.name])
          this.data[additionField.additionField.name] = null;
      }
    }
  }
}
