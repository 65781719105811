
import { Component, Prop, Vue } from "vue-property-decorator";
import Event from "@/shared/data/event";
import QuokkaAddressOverview from "@/components/quokka_layout/QuokkaAddressOverview.vue";
import { DateTime } from "luxon";
import { DateFormats, formatDate } from "@/shared/lib/datetime";
import FrontendSettings from "@/frontend/settings/settings";

@Component({
  components: { QuokkaAddressOverview },
})
export default class QuokkaEventDetailOverview extends Vue {
  @Prop({ required: true }) event!: Event;

  /**
   * Checks, if this date has both, a from and to date
   */
  get hasFromToDates(): boolean {
    return (
      this.event.firstDate !== null &&
      this.event.lastDate !== null &&
      this.formatDate(this.event.firstDate, "yyyy-MM-dd") !==
        this.formatDate(this.event.lastDate, "yyyy-MM-dd")
    );
  }

  /**
   * Tries to format the given date to the given format.
   * If date is null or format fails, an empty string is returned.
   *
   * @param date
   * @param format
   */
  formatDate(date: DateTime | null, format: string | DateFormats): string {
    return date ? formatDate(date, format) ?? "" : "";
  }

  /**
   * Whether the years of the from and to date should be displayed or not.
   *
   * They are displayed, if from and to year is different or if the year is different to the current one now.
   */
  get displayFromToYears(): boolean {
    const currentYear = DateTime.now().year;
    const fromYear = parseInt(formatDate(this.event.firstDate, "yyyy") ?? "");
    const toYear = parseInt(formatDate(this.event.lastDate, "yyyy") ?? "");

    // Check, if from and to year is the same and if they are the current year
    if (fromYear === toYear && fromYear === currentYear) {
      return false;
    }
    return true;
  }

  /**
   * Returns the pixels to keep to the top for sticky divs.
   */
  get stickyTopPixels(): number {
    return (
      parseInt(FrontendSettings.router.scrollBehaviourOffset.toString()) + 50
    );
  }
}
