
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import { AdditionField } from "@/shared/data/addition_field";
import QuokkaCheckboxAdditionField from "@/components/quokka_layout/ui/additional-fields/QuokkaCheckboxAdditionField.vue";
import QuokkaInputAdditionField from "@/components/quokka_layout/ui/additional-fields/QuokkaInputAdditionField.vue";
import QuokkaRadioAdditionField from "@/components/quokka_layout/ui/additional-fields/QuokkaRadioAdditionField.vue";
import QuokkaSelectAdditionField from "@/components/quokka_layout/ui/additional-fields/QuokkaSelectAdditionField.vue";
import QuokkaTextareaAdditionField from "@/components/quokka_layout/ui/additional-fields/QuokkaTextareaAdditionField.vue";
import { DateTime } from "luxon";

@Component({
  components: {
    QuokkaCheckboxAdditionField,
    QuokkaInputAdditionField,
    QuokkaRadioAdditionField,
    QuokkaSelectAdditionField,
    QuokkaTextareaAdditionField,
  },
})
export default class QuokkaAdditionField extends Vue {
  /** The Model of this field */
  @VModel({ required: true }) model!:
    | string
    | boolean
    | DateTime
    | number
    | null;
  /** The {@link AdditionField} used as a model.*/
  @Prop({ required: true }) additionField!: AdditionField;
  /** Whether the field should be disabled or not */
  @Prop() disabled!: boolean;
}
