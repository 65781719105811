
import { Component, Vue } from "vue-property-decorator";
import AlertCard from "@/components/quokka_layout/ui/AlertCard.vue";
import RecordingArticle from "@/shared/data/recording_article";
import { ArticleRecordingFieldData } from "@/frontend/lib/article_recording_data";
import QuokkaArticleRecordingGeneralTab from "@/components/quokka_layout/recording/QuokkaArticleRecordingGeneralTab.vue";
import QuokkaArticleRecordingMediaTab from "@/components/quokka_layout/recording/QuokkaArticleRecordingMediaTab.vue";
import QuokkaArticleRecordingTextsTab from "@/components/quokka_layout/recording/QuokkaArticleRecordingTextsTab.vue";
import QuokkaArticleRecordingAppointmentsTab from "@/components/quokka_layout/recording/QuokkaArticleRecordingAppointmentsTab.vue";
import FrontendLogger from "@/frontend/lib/logger";
import RouteHelper from "@/frontend/lib/route_helper";
import {
  createRecordingArticle,
  getArticleDefinition,
  getMediaConfData,
  getMediaConfReturnData,
  getRecordingArticle,
  getRubrics,
  getTags,
  mediaConfData,
  updateRecordingArticle,
} from "@/frontend/lib/api";
import Rubric from "@/shared/data/rubric";
import {
  RecordingEventAppointmentsFree,
  RecordingEventAppointmentsSeries,
} from "@/shared/data/event_appointments";
import { DateTime, Duration } from "luxon";
import { formatDate } from "@/shared/lib/datetime";
import {
  ArticleDefinition,
  PublicationDateType,
} from "@/shared/data/article_definition";
import { TextareaAdditionField } from "@/shared/data/addition_field";
import QuokkaReportPreview from "@/components/quokka_layout/QuokkaReportPreview.vue";
import FrontendSettings from "@/frontend/settings/settings";
import {
  DisplayInformationDataType,
  MediaInformationData,
} from "@/shared/data/media_information";

/** Available tabs */
type RecordingTabs = "general" | "texts" | "media" | "appointments";

/**
 * View to record/create a new Article
 */
@Component({
  components: { QuokkaReportPreview, AlertCard },
  data() {
    return {
      FrontendSettings,
    };
  },
})
export default class QuokkaArticlesRecordingView extends Vue {
  /** The {@link RecordingArticle} which is recorded in this view */
  recordingArticle: RecordingArticle = RecordingArticle.create();
  /** The {@link ArticleRecordingFieldData} containing available data for select fields */
  articleRecordingFieldData: ArticleRecordingFieldData =
    new ArticleRecordingFieldData();
  /** Additional image information fields */
  mediaConfigData: {
    [key: string]: { [key: string]: { [key: string]: unknown } };
  } = {};
  /** The {@link ArticleDefinition} containing definition of form and fields */
  articleDefinition: ArticleDefinition | null = null;
  /** The {@link Rubric}s available for selection with articles */
  rubrics: Rubric[] | null = null;
  /** The selected {@link Rubric} for the article */
  activeRubric: Rubric | null = null;
  /** Whether rubric selection ist shown or not */
  selectRubric = true;
  /** Whether data is currently loading in or not */
  isLoading = false;
  /** Whether article is currently saving or not */
  saving = false;
  /** Whether the article was saved successfully or not, displays success message */
  articleCreated = false;
  /** Whether  all form fields are valid or not */
  formValid = true;
  /** Index of opened panel */
  expansionPanelsModel = 0;

  /** Title for 'my articles' */
  titleMain = FrontendSettings.articleRecording.titleMyArticles;

  /**
   * Index of opened panel of the heading expansion panels.
   */
  expansionPanelsModelHeading = 0;

  /** Message for errors, displayed in snackbar */
  errorMessage = "";
  /** Message for success, displayed in snackbar */
  successMessage = "";

  /** Loaded Media Files for media upload*/
  availableMedia: DisplayInformationDataType[] = [];

  /** List of available panels */
  panels: {
    key: RecordingTabs;
    title: string;
    component: unknown;
    visible?: boolean;
  }[] = [
    {
      key: "general",
      title: "Allgemein",
      component: QuokkaArticleRecordingGeneralTab,
    },
    {
      key: "appointments",
      title: "Publikationstermine",
      component: QuokkaArticleRecordingAppointmentsTab,
    },
    {
      key: "texts",
      title: "Artikel",
      component: QuokkaArticleRecordingTextsTab,
    },
  ];

  /** Load required Data for Form */
  mounted(): void {
    const promises: Promise<unknown>[] = [];

    // Load Mediaconfig
    getMediaConfData({
      type: mediaConfData.article,
    })
      .then((response: getMediaConfReturnData) => {
        this.mediaConfigData = response.mediaConfigData;
      })
      .catch((e) => {
        console.error(e);
      });

    //Load available field values
    promises.push(
      getRubrics({ type: "article", tree: "master" }).then((rubrics) => {
        this.rubrics = rubrics;
      })
    );

    //Tags
    promises.push(
      getTags({
        type: "event",
      }).then((tags) => {
        this.articleRecordingFieldData.tags = tags;
      })
    );

    //RecordingArticle
    if (
      RouteHelper.hasParam(this.$route, "article_id") &&
      parseInt(RouteHelper.getParam(this.$route, "article_id"))
    ) {
      promises.push(
        getRecordingArticle(
          parseInt(RouteHelper.getParam(this.$route, "article_id"))
        ).then((recordingArticle) => {
          this.recordingArticle = recordingArticle;
        })
      );
    }

    Promise.all(promises)
      .catch((e) => {
        FrontendLogger.error({
          message: "Failed to load field data for article recording.",
          data: e,
        });
      })
      .then(() => {
        if (this.recordingArticle.rubric) {
          this.activeRubric = this.recordingArticle.rubric;
          this.getTextDefinition();
          this.selectRubric = false;
        }
      });
  }

  /** Gets ArticleDefinition for selected rubric and shows recording form */
  getTextDefinition(): void {
    if (this.activeRubric !== null) {
      this.isLoading = true;
      getArticleDefinition(this.activeRubric.rubric_id.toString())
        .then((articleDefinition) => {
          // Set articleDefinition from Server
          this.articleDefinition = articleDefinition;
          this.recordingArticle.articleDefinition = this.articleDefinition;

          // Set Display tabs
          if (articleDefinition.media_enabled) {
            this.panels.push({
              key: "media",
              title: "Bilder",
              component: QuokkaArticleRecordingMediaTab,
              visible: FrontendSettings.articleRecording.showMediaTab,
            });
          }
        })
        .catch((e) => {
          FrontendLogger.error({
            message: "Failed to load field data for article recording.",
            data: e,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
      this.selectRubric = false;
    }
  }

  /** Tries to open current Tab
   * @param tab
   */
  openTab(tab: RecordingTabs): void {
    const index = this.panels.findIndex((panel) => panel.key === tab);
    if (index >= 0) {
      this.expansionPanelsModel = index;
    }
  }

  /** Show validation Error and open tab depending on the specific error
   * @param message
   * @param openTab
   */
  onValidationError(message: string, openTab: RecordingTabs | ""): false {
    this.errorMessage = message;
    if (openTab) {
      this.openTab(openTab);
      // Additional validation after opening tab
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.$refs.recordingForm?.validate();
      }, 100);
    }
    return false;
  }

  /** Tries to create the {@link RecordingArticle} with current set data */
  saveRecordingArticle(): void {
    this.errorMessage = "";
    this.successMessage = "";

    // Validate the values. This will only validate visible fields, hidden fields of closed tabs are ignored.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$refs.recordingForm?.validate();
    // TODO validate editor fields

    // Validate values
    if (!this.validateRecordingArticleData()) return;

    //Saving
    this.saving = true;

    // Prepare article for save
    if (!this.articleDefinition) {
      this.onValidationError("Ein unbekannter Fehler ist aufgetreten.", "");
      console.error("The articleDefinition is not loaded.");
      return;
    }

    this.recordingArticle.rubric = this.activeRubric;

    // Save Article
    const savePromis =
      this.recordingArticle.article_id > 0
        ? updateRecordingArticle(this.recordingArticle)
            .then((recordingArticle) => {
              // Article updated
              this.recordingArticle = recordingArticle;
              this.successMessage = "Der Artikel wurde aktualisiert.";
            })
            .catch((e) => {
              FrontendLogger.error({
                message: "Failed to update RecordingArticle",
                scope: "api",
                data: e,
              });
              this.errorMessage =
                "Der Artikel konnte nicht aktualisiert werden. Ein unbekannter Fehler ist aufgetreten.";
            })
            .then(() => {
              if (FrontendSettings.eventRecording.showReportPreview) {
                // Update Report Preview
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                this.$refs["reportPreview"].executeReports();
              }
            })
            .catch((e) => {
              FrontendLogger.error({
                message: "Failed to update report preview",
                scope: "ui",
                data: e,
              });
              switch (e.response.data.error_code) {
                case 1:
                  // Missing required Fields
                  this.errorMessage =
                    "Der Artikel konnte nicht gespeichert werden. Einige Pflichtfelder sind nicht ausgefüllt.";
                  break;
                case 7:
                  // Release Dates Error
                  this.errorMessage =
                    "Der Artikel konnte nicht gespeichert werden. Bitte kontrollieren Sie die Publikationstermine.";
                  break;
                default:
                  // Catch all for others
                  this.errorMessage =
                    "Der Artikel konnte nicht gespeichert werden. Ein unbekannter Fehler ist aufgetreten.";
                  break;
              }
              this.errorMessage = "Ein unbekannter Fehler ist aufgetreten.";
            })
        : createRecordingArticle(this.recordingArticle)
            .then(() => {
              // Article created
              this.articleCreated = true;
              this.recordingArticle = RecordingArticle.create();
            })
            .catch((e) => {
              FrontendLogger.error({
                message: "Failed to create RecordingArticle",
                scope: "api",
                data: e,
              });
              switch (e.response.data.error_code) {
                case 1:
                  // Missing required Fields
                  this.errorMessage =
                    "Der Artikel konnte nicht angelegt werden. Einige Pflichtfelder sind nicht ausgefüllt.";
                  break;
                case 7:
                  // Release Dates Error
                  this.errorMessage =
                    "Der Artikel konnte nicht angelegt werden. Bitte kontrollieren Sie die Publikationstermine.";
                  break;
                default:
                  // Catch all for others
                  this.errorMessage =
                    "Der Artikel konnte nicht angelegt werden. Ein unbekannter Fehler ist aufgetreten.";
                  break;
              }
            });
    savePromis.finally(() => {
      this.saving = false;
    });
  }

  /** Validates the set data of the {@link RecordingArticle} */
  validateRecordingArticleData(): boolean {
    // Validate location/promoter
    if (!this.recordingArticle.location && !this.recordingArticle.promoter) {
      return this.onValidationError(
        "Es wird ein Veranstaltungsort oder ein Veranstalter benötigt",
        "general"
      );
    }
    // Validate event date
    if (!this.recordingArticle.event_date) {
      return this.onValidationError(
        "Es wird ein Veranstaltungsdatum benötigt.",
        "general"
      );
    }
    //Validate schedule
    try {
      this.validateAppointments();
    } catch (e) {
      return this.onValidationError(e.message, "appointments");
    }
    // Validate Media
    if (!this.recordingArticle.fileUploadReady) {
      return this.onValidationError(
        "Es wurden nicht alle Medien erfolgreich hochgeladen.",
        "media"
      );
    }

    // Validate only article_data fields for wysiwyg-editor since the v-form cannot validate this
    if (this.articleDefinition) {
      for (const formRow of this.articleDefinition.formDefinition) {
        for (const formCol of formRow) {
          const additionalField =
            formCol.additionField as TextareaAdditionField;
          if (
            additionalField &&
            additionalField.type === "textarea" &&
            additionalField.enable_wysiwyg &&
            additionalField.required
          ) {
            const fieldValue =
              this.recordingArticle.article_data[additionalField.name];
            //console.log("value", fieldValue);
            let plainValue = "";
            if (typeof fieldValue == "string") {
              let doc = new DOMParser().parseFromString(
                fieldValue,
                "text/html"
              );
              plainValue = doc.body.textContent || "";
              //console.log("legth", additionalField.maxlength);
              //console.log("legth_plain", plainValue.length);
              if (
                additionalField.maxlength !== undefined &&
                plainValue.length - 1 > additionalField.maxlength
              ) {
                return this.onValidationError(
                  "Die maximale Länge des Feldes '" +
                    additionalField.label +
                    "' wurde überschritten",
                  "texts"
                );
              }
            }
            //console.log("plain_value", plainValue);
            if (fieldValue == null || plainValue == "") {
              return this.onValidationError(
                "Das Feld '" +
                  additionalField.label +
                  "' muss ausgefüllt werden",
                "texts"
              );
            }
          }
        }
      }
    }

    // Get media information from availableMedia
    if (this.availableMedia.length > 0) {
      let tmpInformation: MediaInformationData[] = [];
      this.availableMedia.forEach((data) => {
        tmpInformation.push({
          agency: data.agency,
          author: data.author,
          copyright: data.copyright,
          desc: data.desc,
          media_id: data.media_id,
          title: data.title,
          upload_key: data.upload_key,
          location: data.location,
        });
      });
      this.recordingArticle.additionalImageInformation =
        Object.assign(tmpInformation);
    }

    // Validate Media Data
    if (
      this.recordingArticle.upload_media.length > 0 ||
      (this.recordingArticle.uploaded_media.length > 0 &&
        this.recordingArticle.uploaded_media.length >
          this.recordingArticle.countRemovedUploads)
    ) {
      if (this.mediaConfigData.additionalImageInformationFields) {
        let error = false;
        this.recordingArticle.additionalImageInformation.forEach(
          (information) => {
            // check agency
            if (
              Object.prototype.hasOwnProperty.call(
                this.mediaConfigData.additionalImageInformationFields,
                "agency"
              )
            ) {
              if (
                this.mediaConfigData.additionalImageInformationFields.agency
                  .require &&
                this.mediaConfigData.additionalImageInformationFields.agency
                  .require === "Y"
              ) {
                if (!information.agency) {
                  error = true;
                  return this.onValidationError(
                    "Agency wurde nicht ausgefüllt.",
                    "media"
                  );
                }
              }
            }
            // check author
            if (
              Object.prototype.hasOwnProperty.call(
                this.mediaConfigData.additionalImageInformationFields,
                "author"
              )
            ) {
              if (
                this.mediaConfigData.additionalImageInformationFields.author
                  .require &&
                this.mediaConfigData.additionalImageInformationFields.author
                  .require === "Y"
              ) {
                if (!information.author) {
                  error = true;
                  return this.onValidationError(
                    "Author wurde nicht ausgefüllt.",
                    "media"
                  );
                }
              }
            }

            // check copyright
            if (
              Object.prototype.hasOwnProperty.call(
                this.mediaConfigData.additionalImageInformationFields,
                "copyright"
              )
            ) {
              if (
                this.mediaConfigData.additionalImageInformationFields.copyright
                  .require &&
                this.mediaConfigData.additionalImageInformationFields.copyright
                  .require === "Y"
              ) {
                if (!information.copyright) {
                  error = true;
                  return this.onValidationError(
                    "Copyright wurde nicht ausgefüllt.",
                    "media"
                  );
                }
              }
            }

            // check description
            if (
              Object.prototype.hasOwnProperty.call(
                this.mediaConfigData.additionalImageInformationFields,
                "desc"
              )
            ) {
              if (
                this.mediaConfigData.additionalImageInformationFields.desc
                  .require &&
                this.mediaConfigData.additionalImageInformationFields.desc
                  .require === "Y"
              ) {
                if (!information.desc) {
                  error = true;
                  return this.onValidationError(
                    "Beschreibung wurde nicht ausgefüllt.",
                    "media"
                  );
                }
              }
            }

            // check location
            if (
              Object.prototype.hasOwnProperty.call(
                this.mediaConfigData.additionalImageInformationFields,
                "location"
              )
            ) {
              if (
                this.mediaConfigData.additionalImageInformationFields.location
                  .require &&
                this.mediaConfigData.additionalImageInformationFields.location
                  .require === "Y"
              ) {
                if (!information.location) {
                  error = true;
                  return this.onValidationError(
                    "Location wurde nicht ausgefüllt.",
                    "media"
                  );
                }
              }
            }

            // check title
            if (
              Object.prototype.hasOwnProperty.call(
                this.mediaConfigData.additionalImageInformationFields,
                "title"
              )
            ) {
              if (
                this.mediaConfigData.additionalImageInformationFields.title
                  .require &&
                this.mediaConfigData.additionalImageInformationFields.title
                  .require === "Y"
              ) {
                if (!information.title) {
                  error = true;
                  return this.onValidationError(
                    "Titel wurde nicht ausgefüllt.",
                    "media"
                  );
                }
              }
            }
          }
        );
        if (error) {
          return false;
        }
      }
    }
    return true;
  }

  /** Resets the view */
  resetRecording(): void {
    this.recordingArticle = new RecordingArticle({ article_id: 0 });
    this.expansionPanelsModel = 0;
    this.articleCreated = false;
    this.selectRubric = true;
  }

  /** Validates the publishing dates
   *  @returns {true} on success
   *  @returns {Error} on empty, date_to < date_from or dates in the past
   */
  private validateAppointments(): true {
    if (!this.recordingArticle.article_release_info) {
      throw new Error("Es sind keine Publikationstermine erfasst.");
    }

    let dateConfig: PublicationDateType = {
      publication_dates_type: "amount",
      publication_min_dates: 0,
      publication_max_dates: 0,
      publication_min_days_start: 0,
      publication_max_days_end: 0,
    };

    // Load Config
    if (this.articleDefinition?.publicationDateConfig == undefined) {
      throw new Error("Keine Artikelkonfiguration vorhanden.");
    } else {
      dateConfig = this.articleDefinition.publicationDateConfig;
    }

    switch (this.recordingArticle.article_release_info.type) {
      case "free": {
        let freeAppointments = this.recordingArticle
          .article_release_info as RecordingEventAppointmentsFree;

        // Do exist?
        if (freeAppointments.dates.length <= 0) {
          throw new Error("Es ist kein Publikationstermin erfasst.");
        }

        // Check count of Dates
        if (
          dateConfig.publication_min_dates !== 0 &&
          freeAppointments.dates.length < dateConfig.publication_min_dates
        ) {
          throw new Error(
            "Es sind weniger als " +
              dateConfig.publication_min_dates +
              " Publikationstermine vorhanden."
          );
        }
        if (
          dateConfig.publication_max_dates !== 0 &&
          freeAppointments.dates.length > dateConfig.publication_max_dates
        ) {
          throw new Error(
            "Es sind mehr als " +
              dateConfig.publication_max_dates +
              " Publikationstermine vorhanden."
          );
        }
        for (const articleDate of freeAppointments.dates) {
          // Check, if all dates exist
          if (!articleDate.dateObject && dateConfig.publication_min_dates > 0) {
            throw new Error(
              "Ein Publikationstermin kann nicht ohne Datum angelegt werden."
            );
          }
          // Check that date isn't in the past or outside allowed window
          if (
            dateConfig.publication_min_days_start !== 0 &&
            dateConfig.publication_max_days_end !== 0
          ) {
            const today = DateTime.now();
            const maxDay = DateTime.now().plus(
              Duration.fromObject({
                days: dateConfig.publication_max_days_end,
              })
            );
            const minDay = DateTime.now().plus(
              Duration.fromObject({
                days: dateConfig.publication_min_days_start,
              })
            );
            const formattedDate = formatDate(
              articleDate.dateObject,
              "yyyy-MM-dd"
            );
            const formattedToday = formatDate(today, "yyyy-MM-dd");
            const formattedMax = formatDate(maxDay, "yyyy-MM-dd");
            const formattedMin = formatDate(minDay, "yyyy-MM-dd");

            if (
              !formattedDate ||
              !formattedToday ||
              !formattedMax ||
              !formattedMin
            ) {
              FrontendLogger.error({
                message:
                  "Something went wrong trying to validate date-value. Could not format date or today's date.",
                scope: "invalid-data",
                data: {
                  formattedDate,
                  formattedToday,
                  formattedMin,
                  formattedMax,
                },
              });
              throw new Error("Irgendetwas ist schief gelaufen.");
            }
            if (formattedDate < formattedToday) {
              throw new Error(
                "Mindestens ein Publikationstermin ist in der Vergangenheit."
              );
            }
            if (formattedDate < formattedMin) {
              throw new Error(
                "Mindestesns ein Publikationstermin ist vor dem ersten möglichen Datum (" +
                  formatDate(minDay, "dd.MM.yyyy") +
                  ")"
              );
            }
            if (formattedDate > formattedMax) {
              throw new Error(
                "Mindestesns ein Publikationstermin ist nach dem letzten möglichen Datum " +
                  formatDate(maxDay, "dd.MM.yyyy") +
                  ")"
              );
            }
          }
        }
        break;
      }
      case "series": {
        const appointmentsSeries = this.recordingArticle
          .article_release_info as RecordingEventAppointmentsSeries;
        if (!appointmentsSeries.start_date) {
          throw new Error("Eine Publikationszeitraum benötigt ein Startdatum.");
        }
        if (!appointmentsSeries.end_date) {
          throw new Error("Ein Publikationszeitraum benötigt ein Enddatum.");
        }
        if (
          appointmentsSeries.start_date.toMillis() >=
          appointmentsSeries.end_date.toMillis()
        ) {
          throw new Error("Das Enddatum muss nach dem Startdatum liegen.");
        }
        if (
          dateConfig.publication_max_days_end !== 0 ||
          dateConfig.publication_min_days_start !== 0
        ) {
          // Check if outside allowed window
          const formattedToday = formatDate(DateTime.now(), "yyyy-MM-dd");
          const formattedFirst = formatDate(
            appointmentsSeries.start_date,
            "yyyy-MM-dd"
          );
          const formattedLast = formatDate(
            appointmentsSeries.end_date,
            "yyyy-MM-dd"
          );
          const maxDate = DateTime.now().plus(
            Duration.fromObject({
              days: dateConfig.publication_max_days_end,
            })
          );
          const formattedMax = formatDate(maxDate, "yyyy-MM-dd");
          const minDate = DateTime.now().plus(
            Duration.fromObject({
              days: dateConfig.publication_min_days_start,
            })
          );
          const formattedMin = formatDate(minDate, "yyyy-MM-dd");

          if (
            !formattedFirst ||
            !formattedLast ||
            !formattedMax ||
            !formattedMin ||
            !formattedToday
          ) {
            FrontendLogger.error({
              message:
                "Something went wrong trying to validate date-value. Could not format date or today's date.",
              scope: "invalid-data",
              data: {
                formattedFirst,
                formattedLast,
                formattedMax,
                formattedMin,
                formattedToday,
              },
            });
            throw new Error("Irgendetwas ist schief gelaufen.");
          }
          if (formattedToday > formattedMin || formattedToday > formattedMax) {
            throw new Error(
              "Der Publikationszeitraum ist zumindest teilweise in der Vergangenheit."
            );
          }
          if (formattedFirst < formattedMin) {
            throw new Error(
              "Das Startdatum liegt vor dem ersten möglichen Termin (" +
                formatDate(minDate, "dd.MM.yyyy") +
                ")"
            );
          }
          if (formattedLast > formattedMax) {
            throw new Error(
              "Das Enddatum liegt nach dem letzten möglichen Termin (" +
                formatDate(maxDate, "dd.MM.yyyy") +
                ")"
            );
          }
        }

        break;
      }
      default: {
        throw new Error("Ein unbekannter Fehler ist aufgetreten.");
      }
    }
    return true;
  }

  getRubricNamePath(rubric: string): string {
    return rubric.replaceAll(/\|/g, " / ");
  }
}
