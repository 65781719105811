/**
 * Parent class for the data objects.
 */
export default abstract class DataStruct {
  /**
   * Checks, if the value with given name exists in data and validates it to the given type.
   * If the property does not exist in data, false is returned.
   * Otherwise, the value will be validated.
   * If types do not match, a TypeError will be thrown.
   *
   * @param data The object with the data
   * @param name The name of the value
   * @param type The type expected
   * @param nullable If true, the value can be null. Undefined is != null
   *
   * @throws TypeError
   *
   * @protected
   */
  protected static validateValue(
    data: Record<string, unknown>,
    name: string,
    type: Types,
    nullable = false
  ): boolean {
    if (typeof data !== "object" || data === null) return false;

    if (Object.prototype.hasOwnProperty.call(data, name)) {
      if (data[name] === null) return nullable ?? false;
      if (data[name] === undefined) return false;
      if (typeof data[name] === type) return true;
      else
        throw new TypeError(
          "Invalid value for '" + name + "'. Expected " + type + "."
        );
    }

    return false;
  }
}

/**
 * Available value types.
 */
declare type Types =
  | "number"
  | "string"
  | "object"
  | "boolean"
  | "undefined"
  | "bigint"
  | "function"
  | "symbol";
