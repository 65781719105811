
import { Component, VModel, Vue, Watch } from "vue-property-decorator";
import { getCommunities, GetCommunityReturnType } from "@/frontend/lib/api";
import Community from "@/shared/data/community";
import FrontendLogger from "@/frontend/lib/logger";

@Component
export default class QuokkaEventCommunitySearch extends Vue {
  @VModel({ required: true }) community!: Community | null;

  availableCommunities: Community[] = [];

  /**
   * The overall amount of results available on the server for the current query.
   */
  resultsCountOverall: number | null = null;

  get autocompleteItems(): (
    | Community
    | {
        searchLabel: string;
        id: string;
        disabled: boolean;
      }
  )[] {
    const items: (
      | Community
      | {
          searchLabel: string;
          id: string;
          disabled: boolean;
        }
    )[] = [...this.availableCommunities];

    if (
      this.resultsCountOverall &&
      this.availableCommunities.length < this.resultsCountOverall
    ) {
      items.push({
        searchLabel:
          "+ " +
          (this.resultsCountOverall - this.availableCommunities.length) +
          " weitere Ergebnisse",
        id: "__more_results__",
        disabled: true,
      });
    }

    return items;
  }

  selectedCommunityIsContained = false;
  communitySearchQuery: string | null = null;
  communityLoading = false;
  communitySearchTimeOut?: number;

  errorMessage = "";

  /**
   * Hide the selected item, if only one community is available and this is the selected one.
   */
  get hideSelected(): boolean {
    return !(
      (this.availableCommunities.length === 1 &&
        this.community &&
        this.availableCommunities[0].id === this.community.id &&
        this.community.name
          .toLocaleLowerCase()
          .includes(this.communitySearchQueryString.toLocaleLowerCase())) ||
      this.selectedCommunityIsContained
    );
  }

  get communitySearchQueryString(): string {
    return this.communitySearchQuery ?? "";
  }

  @Watch("community", { deep: true, immediate: true })
  onTimeModelChange(): void {
    if (this.community) this.availableCommunities.push(this.community);
    else this.onSearchQueryUpdate();
  }

  @Watch("communitySearchQuery")
  public onSearchQueryUpdate(): void {
    clearTimeout(this.communitySearchTimeOut);
    this.communitySearchTimeOut = setTimeout(() => {
      this.communityLoading = true;
      getCommunities({
        query:
          this.communitySearchQuery === null
            ? undefined
            : this.communitySearchQuery,
        per_page: 100,
      })
        .then((result: GetCommunityReturnType) => {
          this.resultsCountOverall = result.resultsCountOverall;
          this.availableCommunities = result.communities;
          // Check, if the selected community is available in the communities
          let isContained = false;
          if (this.community) {
            this.availableCommunities.forEach((obj: Community) => {
              if (this.community && obj.id === this.community.id)
                isContained = true;
            });
            if (!isContained) this.availableCommunities.push(this.community);
          }
          this.selectedCommunityIsContained = isContained;
        })
        .catch((e) => {
          this.errorMessage = "Beim Laden ist ein Fehler aufgetreten";
          FrontendLogger.error({ message: e });
        })
        .finally(() => {
          this.communityLoading = false;
        });
    }, 700);
  }
}
