import DataStruct from "@/shared/data/datastruct";

/**
 * The parent class for ValueListItems with different types of keys.
 */
abstract class ValueListItem<T extends string | number> extends DataStruct {
  readonly key: T;
  value = "";

  /**
   * Creates a new instance of ValueList with given data.
   *
   * @param data The data to load
   * @throws TypeError if any property does not fit the type
   */
  constructor(data: ServerValueListItemDataType) {
    super();

    if (DataStruct.validateValue(data, "id", "string")) {
      this.key = this.parseKey(data.id);
    } else {
      // We cannot initialize without an id
      throw new TypeError("Could not initialize ValueList. Invalid id.");
    }

    if (DataStruct.validateValue(data, "value", "string"))
      this.value = data.value;
  }

  /**
   * Parses the given key
   *
   * @param key
   */
  abstract parseKey(key: string): T;
}

/**
 * The ValueListItem Data-Class containing all properties for a ValueListItem with a string-key.
 */
export class StringValueListItem extends ValueListItem<string> {
  /**
   * Parses the given key to a string.
   *
   * @param key
   */
  parseKey(key: string): string {
    return key.toString();
  }
}

/**
 * The ValueListItem Data-Class containing all properties for a ValueListItem with a number-key.
 */
export class NumberValueListItem extends ValueListItem<number> {
  /**
   * Parses the given key to an integer.
   *
   * @param key
   * @throws TypeError if the key could not be parsed to an integer.
   */
  parseKey(key: string): number {
    if ((key as unknown) != Number(key))
      throw new TypeError(
        "Failed to parse key for NumberValueList. The key '" +
          key +
          "' is not a valid number."
      );
    return Number(key);
  }
}

/**
 * The data structure defined by the MSUevent-Server.
 */
export type ServerValueListItemDataType = {
  id: string;
  value: string;
};
