
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import {
  AdditionField,
  RadioAdditionField,
} from "@/shared/data/addition_field";

/**
 * An input field for an {@link RadioAdditionField} from field_config
 */
@Component
export default class QuokkaRadioAdditionField extends Vue {
  /** The Model of this field */
  @VModel({ required: true }) model!: string;
  /** The {@link AdditionField} used as a model.*/
  @Prop({ required: true }) additionField!: AdditionField & RadioAdditionField;
  /** Whether the field should be disabled or not */
  @Prop() disabled!: boolean;

  /** Rules for clientside validation */
  rules: { [key: string]: (value: string) => boolean | string } = {
    radioAdditionField: (value: string): boolean | string => {
      if (this.additionField.required && !value) {
        if (this.additionField.required_form_message)
          return this.additionField.required_form_message;
        return "Pflichtfeld";
      }
      return true;
    },
  };
}
