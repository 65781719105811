import { render, staticRenderFns } from "./QuokkaEventRubricSearch.vue?vue&type=template&id=3bbea2a9&scoped=true&"
import script from "./QuokkaEventRubricSearch.vue?vue&type=script&lang=ts&"
export * from "./QuokkaEventRubricSearch.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bbea2a9",
  null
  
)

export default component.exports