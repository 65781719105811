
import { Component, Vue } from "vue-property-decorator";
import AuthView from "@/views/layouts/quokka/auth/AuthView.vue";
import SignupData from "@/shared/data/signup";
import { signup, SignupResponseType } from "@/frontend/lib/api";
import FrontendLogger from "@/frontend/lib/logger";
import FrontendSettings from "@/frontend/settings/settings";
import QuokkaEventAddressSearch from "@/components/quokka_layout/QuokkaEventAddressSearch.vue";
import { AuthenticatedAreaSettings } from "@/shared/data/settings";
import QuokkaPersonDetails from "@/components/quokka_layout/admin/QuokkaPersonDetails.vue";
import { sanitizeContent } from "@/shared/lib/dompurify";

/**
 * The signup-{@link AuthView} for the quokka layout to sign up as an external person.
 */
@Component({
  components: { QuokkaPersonDetails, QuokkaEventAddressSearch, AuthView },
  data() {
    return { sanitizeContent };
  },
})
export default class QuokkaSignUpView extends Vue {
  /** Data Object for Signup */
  signUpData = new SignupData();

  /** Field Settings */
  fieldSettings: AuthenticatedAreaSettings = FrontendSettings.authenticatedArea;

  /** Clientside data validation of signup form */
  formValid = false;

  /** Display Snackbars */
  snackbar = false;
  /** Displayed Text in Snackbar */
  snackbarText = "Einige verpflichtende Angaben sind nicht vollständig!";
  /** Variable to disable form and buttons while loading */
  loading = false;
  /** Variable to disable form when success is returned */
  success = false;
  /** Variable to display email verification message */
  emailVerification = false;

  /** Submit signup data to backend */
  onSubmit(): void {
    if (this.formValid) {
      this.loading = true;

      // If no role is selected, set role to 0, workaround until settings for required fields
      if (this.signUpData.role === null) this.signUpData.role = "0";
      signup(this.signUpData)
        .then((signup: SignupResponseType) => {
          this.success = true;
          this.emailVerification = signup.emailVerification;
        })
        .catch((e) => {
          FrontendLogger.error({ message: "Failed to sign up.", data: e });
          /** specific messages depending on error code */
          switch (e.response.data.error_code) {
            case 0:
              this.snackbarText =
                "Einige verpflichtende Angaben sind nicht vollständig!";
              break;
            case 1:
              this.snackbarText =
                "Einige der angegebenen Daten sind nicht korrekt.";
              break;
            case 2:
              this.snackbarText =
                "Der angegebene Benutzername ist bereits vergeben.";
              break;
            case 3:
              this.snackbarText =
                "Die angegebene Email-Adresse ist bereits in Gebrauch.";
              break;
            case 4:
              this.snackbarText =
                "Registrierung konnte leider nicht vorgenommen werden. Bitte versuchen Sie es später noch einmal.";
              break;
            case 5:
              this.snackbarText =
                "Registrierung konnte leider nicht vorgenommen werden. Bitte stimmen Sie den AGB zu, um sich zu registrieren.";
              break;
            case 6:
              this.snackbarText =
                "Registrierung konnte leider nicht vorgenommen werden. Bitte stimmen Sie der Datenschutzerklärung zu, um sich zu registrieren.";
              break;
            default:
              this.snackbarText =
                'Registrierung konnte leider nicht vorgenommen werden! Falls Sie schon einen Zugang angelegt haben, benutzen Sie bitte die Funktion "Passwort vergessen".';
              break;
          }
          this.snackbar = true;
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.snackbar = true;
      this.snackbarText =
        "Einige verpflichtende Angaben sind nicht vollständig!";
    }
  }

  /**
   * Returns the `registrationSuccessMessage` defined in the settings.
   */
  get registrationSuccessMessage(): string | null {
    return (
      this.fieldSettings.registrationSuccessMessage ??
      "Registrierung erfolgreich"
    );
  }

  get loginInfo(): string | null {
    return this.fieldSettings.loginInfo !== ""
      ? this.fieldSettings.loginInfo
      : null;
  }
}
