
import { Component, Prop, Vue } from "vue-property-decorator";
import QuokkaEventRubricSearch from "@/components/quokka_layout/QuokkaEventRubricSearch.vue";
import QuokkaEventAddressSearch from "@/components/quokka_layout/QuokkaEventAddressSearch.vue";
import RecordingEvent from "@/shared/data/recording_event";
import FrontendSettings from "@/frontend/settings/settings";
import EventLink, { EventLinkUsage } from "@/shared/data/event_link";
import FrontendLogger from "@/frontend/lib/logger";
import LabeledSheet from "@/components/quokka_layout/ui/LabeledSheet.vue";
import QuillEditor, {
  QuillOptions,
} from "@/components/quokka_layout/ui/QuillEditor.vue";
import { sanitizeContent } from "@/shared/lib/dompurify";

/**
 * Tab for the Event-Recording containing the texts for the {@link RecordingEvent}.
 */
@Component({
  components: {
    QuillEditor,
    LabeledSheet,
    QuokkaEventAddressSearch,
    QuokkaEventRubricSearch,
  },
  data() {
    return { sanitizeContent };
  },
})
export default class QuokkaEventRecordingTextsTab extends Vue {
  /**
   * The {@link RecordingEvent} used as model.
   */
  @Prop({ required: true }) recordingEvent!: RecordingEvent;

  /**
   * Whether the fields of this tab should be disabled or not.
   */
  @Prop() disabled!: boolean;

  /*
   * Available rules for the fields to verify the value.
   */
  rules: { [key: string]: (value: string) => boolean | string } = {
    title: (value: string): boolean | string => {
      if (!value || value.trim() === "") return "Titel eingeben";
      const maxLength = this.titleMaxLength;
      if (maxLength && value.length > maxLength) return "Titel ist zu lang";
      return true;
    },
    shortdesc1: (value: string): boolean | string => {
      const maxLength = this.shortdesc1MaxLength;
      if (maxLength && value && value.length > maxLength)
        return this.shortdesc1Label + " ist zu lang";
      return true;
    },
    shortdesc2: (value: string): boolean | string => {
      const maxLength = this.shortdesc2MaxLength;
      if (maxLength && value && value.length > maxLength)
        return this.shortdesc2Label + " ist zu lang";
      return true;
    },
    shortdesc3: (value: string): boolean | string => {
      const maxLength = this.shortdesc3MaxLength;
      if (maxLength && value && value.length > maxLength)
        return this.shortdesc3Label + " ist zu lang";
      return true;
    },
    longdesc1: (value: string): boolean | string => {
      const maxLength = this.longdesc1MaxLength;
      if (maxLength && value && value.length > maxLength)
        return this.longdesc1Label + " ist zu lang";
      return true;
    },
    longdesc2: (value: string): boolean | string => {
      const maxLength = this.longdesc2MaxLength;
      if (maxLength && value && value.length > maxLength)
        return this.longdesc2Label + " ist zu lang";
      return true;
    },
    longdesc3: (value: string): boolean | string => {
      const maxLength = this.longdesc3MaxLength;
      if (maxLength && value && value.length > maxLength)
        return this.longdesc3Label + " ist zu lang";
      return true;
    },
    link: (value: string): boolean | string => {
      const regex =
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;
      if (!value || value.trim() === "") return "Link eingeben";
      if (!regex.test(value)) return "Ungültige URL";
      return true;
    },
    linkDescription: (value: string): boolean | string => {
      if (!value || value.trim() === "") return "Anzeigetext eingeben";
      return true;
    },
  };

  // Contains the {@link EventLinkUsage} as key and the max allowed amount as value
  allowedUsages: { [key in EventLinkUsage]: number } = {
    ticket: 0,
    location: 0,
    promoter: 0,
    event: 0,
    artist: 0,
  };

  /**
   * The total amount of links that are allowed according to the settings.
   * Will be calculated on mount.
   *
   * If 0, the links-box will not be displayed.
   */
  totalAllowedLinks = 0;

  /**
   * Gets the max allowed amount of links and checks already set links, if they surpass the amount.
   * If a link surpasses the maximum allowed amount, it will be deleted.
   */
  mounted(): void {
    // Get the max amount allowed for each usage
    Object.keys(FrontendSettings.fieldSettings.recording.links).forEach(
      (usage) => {
        const eventLink: EventLinkUsage = usage as EventLinkUsage;
        if (FrontendSettings.fieldSettings.recording.links[eventLink]) {
          const amountForUsage =
            FrontendSettings.fieldSettings.recording.links[eventLink].maxAmount;
          this.allowedUsages[eventLink] = amountForUsage;
          this.totalAllowedLinks += amountForUsage;
        }
      }
    );

    // Check, if any link is available, that passes the maximum amount => If so, remove it
    const allowedUsages: { [key in EventLinkUsage]: number } = Object.assign(
      {},
      this.allowedUsages
    );

    // Get all currently used usages and subtract it from the allowed amounts
    this.recordingEvent.links.forEach((eventLink: EventLink, i) => {
      if (allowedUsages[eventLink.usage] <= 0) {
        // This usage is already fully occupied
        FrontendLogger.warning({
          message:
            "The RecordingEvent has more `EventLink`s for the usage `" +
            eventLink.usage +
            "` than allowed. It will be removed.",
          scope: "invalid-data",
          data: this.recordingEvent,
        });

        // Remove it
        this.recordingEvent.links.splice(i, 1);

        return;
      }

      allowedUsages[eventLink.usage]--;
    });
  }

  /**
   * Returns the list of {@link EventLinkUsage}s available for an select.
   *
   * TODO: This causes slight lag because this getter is a bit expensive. Try to optimize this.
   *
   * @param selectedUsage The currently selected {@link EventLinkUsage}. Will always be extended.
   */
  getLinkUsageAvailableValues(
    selectedUsage: EventLinkUsage
  ): { text: string; value: string }[] {
    const availableUsages: { text: string; value: string }[] = [];

    const allowedUsages: { [key in EventLinkUsage]: number } = Object.assign(
      {},
      this.allowedUsages
    );

    // Get all currently used usages and subtract it from the allowed amounts
    this.recordingEvent.links.forEach((eventLink: EventLink) => {
      allowedUsages[eventLink.usage]--;
    });

    // Create array => For each remaining usages + the selectedUsage
    Object.keys(allowedUsages).forEach((usage) => {
      const eventLinkUsage: EventLinkUsage = usage as EventLinkUsage;
      if (allowedUsages[eventLinkUsage] && allowedUsages[eventLinkUsage] > 0) {
        // This usage can be used => add this usage to the available list
        availableUsages.push({
          value: eventLinkUsage.toString(),
          text: EventLink.getUsageLabel(eventLinkUsage),
        });
      } else if (selectedUsage === eventLinkUsage) {
        // If this usage is the selected usage and the max amount is reached, we still add it, that it is available for the select
        availableUsages.push({
          value: eventLinkUsage.toString(),
          text: EventLink.getUsageLabel(eventLinkUsage),
        });
      }
    });

    return availableUsages;
  }

  /**
   * Checks, if another {@link EventLink} can be added.
   *
   * TODO: This causes slight lag because this getter is a bit expensive. Try to optimize this.
   */
  get newLinkAddable(): boolean {
    // Get first available link
    const allowedUsages: { [key in EventLinkUsage]: number } = Object.assign(
      {},
      this.allowedUsages
    );

    // Get all currently used usages and subtract it from the allowed amounts
    this.recordingEvent.links.forEach((eventLink: EventLink) => {
      allowedUsages[eventLink.usage]--;
    });

    for (const usage of Object.keys(allowedUsages)) {
      const eventLinkUsage: EventLinkUsage = usage as EventLinkUsage;
      if (allowedUsages[eventLinkUsage] && allowedUsages[eventLinkUsage] > 0) {
        // We can add another one
        return true;
      }
    }

    return false;
  }

  /**
   * Adds a new {@link EventLink} to the {@link RecordingEvent}.
   *
   * TODO: This could cause slight lag because this getter is a bit expensive. Try to optimize this.
   */
  addLink(): void {
    let firstAvailableUsage: EventLinkUsage | null = null;

    // Get first available link
    const allowedUsages: { [key in EventLinkUsage]: number } = Object.assign(
      {},
      this.allowedUsages
    );

    // Get all currently used usages and subtract it from the allowed amounts
    this.recordingEvent.links.forEach((eventLink: EventLink) => {
      allowedUsages[eventLink.usage]--;
    });

    for (const usage of Object.keys(allowedUsages)) {
      const eventLinkUsage: EventLinkUsage = usage as EventLinkUsage;
      if (allowedUsages[eventLinkUsage] && allowedUsages[eventLinkUsage] > 0) {
        // This is the first available usage
        firstAvailableUsage = eventLinkUsage;
        break;
      }
    }

    if (firstAvailableUsage === null) {
      // No more link can be added
      FrontendLogger.error({
        message: "Tried to add a new link while no more link can be added.",
        scope: "ui",
      });
      return;
    }

    this.recordingEvent.links.push(
      new EventLink({
        usage: firstAvailableUsage,
      })
    );
  }

  /**
   * Removes the {@link EventLink} with given index from the {@link RecordingEvent}.
   *
   * @param index
   */
  removeLink(index: number): void {
    this.recordingEvent.links.splice(index, 1);
  }

  /**
   * Returns the `textsInfo` defined in the settings.
   */
  get textsInfo(): string | null {
    return FrontendSettings.eventRecording.textsInfo;
  }

  /**
   * Returns the max length of the `title` set in the {@link FrontendSettings}.
   */
  get titleMaxLength(): number | null {
    return FrontendSettings.fieldSettings.recording.title.maxLength;
  }

  /**
   * Returns the max length of the `shortdesc1` set in the {@link FrontendSettings}.
   */
  get shortdesc1MaxLength(): number | null {
    return FrontendSettings.fieldSettings.recording.shortdesc1.maxLength;
  }

  /**
   * Returns the max length of the `shortdesc2` set in the {@link FrontendSettings}.
   */
  get shortdesc2MaxLength(): number | null {
    return FrontendSettings.fieldSettings.recording.shortdesc2.maxLength;
  }

  /**
   * Returns the max length of the `shortdesc3` set in the {@link FrontendSettings}.
   */
  get shortdesc3MaxLength(): number | null {
    return FrontendSettings.fieldSettings.recording.shortdesc3.maxLength;
  }

  /**
   * Returns the max length of the `longdesc1` set in the {@link FrontendSettings}.
   */
  get longdesc1MaxLength(): number | null {
    return FrontendSettings.fieldSettings.recording.longdesc1.maxLength;
  }

  /**
   * Returns the max length of the `longdesc2` set in the {@link FrontendSettings}.
   */
  get longdesc2MaxLength(): number | null {
    return FrontendSettings.fieldSettings.recording.longdesc2.maxLength;
  }

  /**
   * Returns the max length of the `longdesc3` set in the {@link FrontendSettings}.
   */
  get longdesc3MaxLength(): number | null {
    return FrontendSettings.fieldSettings.recording.longdesc3.maxLength;
  }

  /**
   * Whether the `shortdesc1`-field is visible or not.
   */
  get showShortdesc1Field(): boolean {
    return (
      FrontendSettings.fieldSettings.recording.shortdesc1.visible &&
      !FrontendSettings.fieldSettings.recording.shortdesc_quill_options.active
    );
  }

  /**
   * Whether the 'shortdesc1'-field is an QuillEditor.
   */
  get showShortdesc1Editor(): boolean {
    return (
      FrontendSettings.fieldSettings.recording.shortdesc1.visible &&
      FrontendSettings.fieldSettings.recording.shortdesc_quill_options.active
    );
  }

  /** Whether the 'longdesc1'-field is disabled */
  get shortdesc1disabled(): boolean {
    return FrontendSettings.fieldSettings.recording.shortdesc_quill_options
      .readonly;
  }

  /** The Options of 'longdesc1'-field */
  get shortdesc1options(): QuillOptions {
    return FrontendSettings.fieldSettings.recording.shortdesc_quill_options;
  }

  /**
   * Whether the `shortdesc2`-field is visible or not.
   */
  get showShortdesc2Field(): boolean {
    return (
      FrontendSettings.fieldSettings.recording.shortdesc2.visible &&
      !FrontendSettings.fieldSettings.recording.shortdesc2_quill_options.active
    );
  }

  /**
   * Whether the 'shortdesc2'-field is an QuillEditor.
   */
  get showShortdesc2Editor(): boolean {
    return (
      FrontendSettings.fieldSettings.recording.shortdesc2.visible &&
      FrontendSettings.fieldSettings.recording.shortdesc2_quill_options.active
    );
  }

  /** Whether the 'longdesc2'-field is disabled */
  get shortdesc2disabled(): boolean {
    return FrontendSettings.fieldSettings.recording.shortdesc2_quill_options
      .readonly;
  }

  /** The Options of 'longdesc2'-field */
  get shortdesc2options(): QuillOptions {
    return FrontendSettings.fieldSettings.recording.shortdesc2_quill_options;
  }

  /**
   * Whether the `shortdesc3`-field is visible or not.
   */
  get showShortdesc3Field(): boolean {
    return (
      FrontendSettings.fieldSettings.recording.shortdesc3.visible &&
      !FrontendSettings.fieldSettings.recording.shortdesc3_quill_options.active
    );
  }

  /**
   * Whether the 'shortdesc3'-field is an QuillEditor.
   */
  get showShortdesc3Editor(): boolean {
    return (
      FrontendSettings.fieldSettings.recording.shortdesc3.visible &&
      FrontendSettings.fieldSettings.recording.shortdesc3_quill_options.active
    );
  }

  /** Whether the 'longdesc3'-field is disabled */
  get shortdesc3disabled(): boolean {
    return FrontendSettings.fieldSettings.recording.shortdesc3_quill_options
      .readonly;
  }

  /** The Options of 'longdesc1'-field */
  get shortdesc3options(): QuillOptions {
    return FrontendSettings.fieldSettings.recording.shortdesc3_quill_options;
  }

  /**
   * Whether the `longdesc1`-field is visible or not.
   */
  get showLongdesc1Field(): boolean {
    return (
      FrontendSettings.fieldSettings.recording.longdesc1.visible &&
      !FrontendSettings.fieldSettings.recording.longdesc_quill_options.active
    );
  }

  /**
   * Whether the 'longdesc1'-field is an QuillEditor.
   */
  get showLongdesc1Editor(): boolean {
    return (
      FrontendSettings.fieldSettings.recording.longdesc1.visible &&
      FrontendSettings.fieldSettings.recording.longdesc_quill_options.active
    );
  }

  /** Whether the 'longdesc1'-field is disabled */
  get longdesc1disabled(): boolean {
    return FrontendSettings.fieldSettings.recording.longdesc_quill_options
      .readonly;
  }

  /** The Options of 'longdesc1'-field */
  get longdesc1options(): QuillOptions {
    return FrontendSettings.fieldSettings.recording.longdesc_quill_options;
  }

  /**
   * Whether the `longdesc2`-field is visible or not.
   */
  get showLongdesc2Field(): boolean {
    return (
      FrontendSettings.fieldSettings.recording.longdesc2.visible &&
      !FrontendSettings.fieldSettings.recording.longdesc2_quill_options.active
    );
  }

  /**
   * Whether the 'longdesc2'-field is an QuillEditor.
   */
  get showLongdesc2Editor(): boolean {
    return (
      FrontendSettings.fieldSettings.recording.longdesc2.visible &&
      FrontendSettings.fieldSettings.recording.longdesc2_quill_options.active
    );
  }

  /** Whether the 'longdesc2'-field is disabled */
  get longdesc2disabled(): boolean {
    return FrontendSettings.fieldSettings.recording.longdesc2_quill_options
      .readonly;
  }

  /** The Options of 'longdesc2'-field */
  get longdesc2options(): QuillOptions {
    return FrontendSettings.fieldSettings.recording.longdesc2_quill_options;
  }

  /**
   * Whether the `longdesc3`-field is visible or not.
   */
  get showLongdesc3Field(): boolean {
    return (
      FrontendSettings.fieldSettings.recording.longdesc3.visible &&
      !FrontendSettings.fieldSettings.recording.longdesc3_quill_options.active
    );
  }

  /**
   * Whether the 'longdesc3'-field is an QuillEditor.
   */
  get showLongdesc3Editor(): boolean {
    return (
      FrontendSettings.fieldSettings.recording.longdesc3.visible &&
      FrontendSettings.fieldSettings.recording.longdesc3_quill_options.active
    );
  }

  /** Whether the 'longdesc3'-field is disabled */
  get longdesc3disabled(): boolean {
    return FrontendSettings.fieldSettings.recording.longdesc3_quill_options
      .readonly;
  }

  /** The Options of 'longdesc3'-field */
  get longdesc3options(): QuillOptions {
    return FrontendSettings.fieldSettings.recording.longdesc3_quill_options;
  }

  /**
   * Returns the label for the `shortdesc1` field.
   */
  get shortdesc1Label(): string {
    if (
      FrontendSettings.fieldSettings.recording.shortdesc1.label &&
      FrontendSettings.fieldSettings.recording.shortdesc1.label !== ""
    ) {
      return FrontendSettings.fieldSettings.recording.shortdesc1.label;
    }
    return "Kurztext 1";
  }

  /**
   * Returns the label for the `shortdesc2` field.
   */
  get shortdesc2Label(): string {
    if (
      FrontendSettings.fieldSettings.recording.shortdesc2.label &&
      FrontendSettings.fieldSettings.recording.shortdesc2.label !== ""
    ) {
      return FrontendSettings.fieldSettings.recording.shortdesc2.label;
    }
    return "Kurztext 2";
  }

  /**
   * Returns the label for the `shortdesc3` field.
   */
  get shortdesc3Label(): string {
    if (
      FrontendSettings.fieldSettings.recording.shortdesc3.label &&
      FrontendSettings.fieldSettings.recording.shortdesc3.label !== ""
    ) {
      return FrontendSettings.fieldSettings.recording.shortdesc3.label;
    }
    return "Kurztext 3";
  }

  /**
   * Returns the label for the `longdesc1` fields or button.
   */
  get longdesc1Label(): string {
    if (
      FrontendSettings.fieldSettings.recording.longdesc1.label &&
      FrontendSettings.fieldSettings.recording.longdesc1.label !== ""
    ) {
      return FrontendSettings.fieldSettings.recording.longdesc1.label;
    }
    return "Langtext 1";
  }

  /**
   * Returns the label for the `longdesc2` fields or button.
   */
  get longdesc2Label(): string {
    if (
      FrontendSettings.fieldSettings.recording.longdesc2.label &&
      FrontendSettings.fieldSettings.recording.longdesc2.label !== ""
    ) {
      return FrontendSettings.fieldSettings.recording.longdesc2.label;
    }
    return "Langtext 2";
  }

  /**
   * Returns the label for the `longdesc3` fields or button.
   */
  get longdesc3Label(): string {
    if (
      FrontendSettings.fieldSettings.recording.longdesc3.label &&
      FrontendSettings.fieldSettings.recording.longdesc3.label !== ""
    ) {
      return FrontendSettings.fieldSettings.recording.longdesc3.label;
    }
    return "Langtext 3";
  }
}
