
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import TimeInfo from "@/shared/data/time_info";
import FrontendSettings from "@/frontend/settings/settings";
import QuokkaTimePicker from "@/components/quokka_layout/ui/QuokkaTimePicker.vue";

/**
 * A list item containing fields for a {@link TimeInfo}.
 */
@Component({
  components: { QuokkaTimePicker },
})
export default class QuokkaEventRecordingAppointmentsFreeTimeRow extends Vue {
  /**
   * The {@link TimeInfo}
   */
  @Prop({ required: true }) timeInfo!: TimeInfo;

  /**
   * Whether the fields of this tab should be disabled or not.
   */
  @Prop() disabled!: boolean;

  /**
   * Whether this {@link TimeInfo} can be removed or not.
   *
   * Will enable/disable the remove button.
   */
  @Prop() isRemovable!: boolean;

  /**
   * Available rules for the fields to verify the value.
   */
  rules: { [key: string]: (value: string) => boolean | string } = {
    /**
     * A from-time is required, if full-day is not enabled or an until-time is given
     */
    from: (value: string): boolean | string => {
      if ((!value || value.trim() === "") && !this.timeInfo.full)
        return "Uhrzeit wählen";
      return true;
    },
  };

  /**
   * Whether the additional-info-field is visible or not.
   */
  get showAdditionalInfoField(): boolean {
    return FrontendSettings.fieldSettings.recording.appointments.additionalInfo
      .visible;
  }

  /**
   * Called on change of full-day.
   *
   * Validates the times-form to remove or add the error message to the from-field
   */
  @Watch("timeInfo.full")
  onFullDayChange(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$refs.timesForm?.validate();
  }

  /**
   * Event triggered, when user wants to delete this {@link TimeInfo}.
   */
  @Emit("remove-time-info")
  removeTimeInfo(): void {
    return;
  }
}
