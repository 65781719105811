
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import {
  AdvertCampaign,
  AdvertChannel,
  AdvertSlot,
  OrderAddress,
} from "@/shared/data/advert";
import { getPersonData } from "@/frontend/lib/api";
import FrontendLogger from "@/frontend/lib/logger";
import FrontendSettings from "@/frontend/settings/settings";

@Component
export default class QuokkaAdsRecordingContent extends Vue {
  /** Person billing address field's */
  personBillingAddressFields = [
    "salutation",
    "first_name",
    "last_name",
    "phone",
    "mobile",
    "email",
    "organization",
    "street",
    "zipcode",
    "community",
  ];
  /** The campaigns */
  @Prop({ required: true }) campaigns!: AdvertCampaign[];
  /** Selected channel data */
  @Prop({ required: true }) selectedChannel!: AdvertChannel;
  /** Selected slot data */
  @Prop({ required: true }) selectedSlot!: AdvertSlot;

  /** Variable to disable form and buttons while loading */
  loading = false;

  /** Salutions */
  salutationsData = ["Herr", "Frau", "Divers"];
  /** Open panel */
  panel: number[] = [];
  /** If different billing address allow */
  altInvoiceAddressAllow = "N";
  /** Different billing address settings from camping */
  altInvoiceAddressSettings: {
    [k: string]: unknown;
    label: { [k: string]: string };
    allow: { [k: string]: string };
    require: { [k: string]: string };
  } | null = null;

  emptyRequiredTextField = false;
  emptyRequiredPersonBillingData = false;

  /** Contains the texts from the form */
  differentBillingAddressFieldValues: OrderAddress = new OrderAddress();

  /** diffBillingAddressFormValid */
  diffBillingAddressFormValid = true;

  /** private address from person */
  personOrderAddress: OrderAddress = new OrderAddress();

  /** set person billing address */
  @Emit("set-person-billing-address")
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setPersonBillingAddress(data: OrderAddress): void {
    return;
  }

  /** set different billing address when filled and active */
  @Emit("set-different-billing-address")
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setDifferentBillingAddress(data: OrderAddress): void {
    return;
  }

  /*
   * Available rules for the address fields to verify the value.
   *
   * TODO: Add rules accordingly
   */
  rules: { [key: string]: (value: string) => boolean | string } = {
    required: (value: string): boolean | string => {
      if (!value || value.trim() === "") {
        this.emptyRequiredTextField = true;
        return "Pflichtfeld";
      }
      this.emptyRequiredTextField = false;
      return true;
    },
    salutation: (): boolean | string => {
      return true;
    },
    firstName: (): boolean | string => {
      return true;
    },
    lastName: (): boolean | string => {
      return true;
    },
    organization: (): boolean | string => {
      return true;
    },
    zipcode: (value: string): boolean | string => {
      if (/\d{4,8}/g.test(value)) return true;
      return "Postleitzahl ungültig";
    },
    community: (): boolean | string => {
      return true;
    },
    street: (value: string): boolean | string =>
      !value ||
      /\s\d{1,8}/.test(value) ||
      /^\d{1,8}/.test(value) ||
      "Adresse ungültig",
    phone: (): boolean | string => {
      return true;
    },
    mobile: (): boolean | string => {
      return true;
    },
    email: (value: string): boolean | string => {
      if (/.+@.+\..+/.test(value)) return true;
      return "E-Mail ungültig";
    },
    url: (): boolean | string => {
      return true;
    },
    attention: (): boolean | string => {
      return true;
    },
    country: (): boolean | string => {
      return true;
    },
    department: (): boolean | string => {
      return true;
    },
    pobox: (): boolean | string => {
      return true;
    },
    pocommunity: (): boolean | string => {
      return true;
    },
    pozip: (value: string): boolean | string => {
      if (/\d{4,8}/g.test(value)) return true;
      return "Postleitzahl ungültig";
    },
    vat_id: (): boolean | string => {
      return true;
    },
  };

  mounted(): void {
    this.loading = true;

    this.campaigns.forEach((campaign) => {
      breakLoop: for (const channel of campaign.advert_channels) {
        if (
          channel.advert_channel_id == this.selectedChannel?.advert_channel_id
        ) {
          for (const slot of channel.advert_slots) {
            if (slot.advert_slot_id == this.selectedSlot?.advert_slot_id) {
              this.altInvoiceAddressSettings =
                campaign.advert_partner.alt_invoice_address_settings;

              this.altInvoiceAddressAllow =
                campaign.advert_partner.alt_invoice_address_allow;
              break breakLoop;
            }
          }
        }
      }
    });

    getPersonData()
      .then((data) => {
        let personOrderAddressObj = new OrderAddress();

        if (data.firstName !== null)
          personOrderAddressObj.first_name = data.firstName;
        if (data.lastName !== null)
          personOrderAddressObj.last_name = data.lastName;
        if (data.email !== null) personOrderAddressObj.email = data.email;
        if (data.salutation !== "") {
          personOrderAddressObj.salutation = data.salutationLabel;
        }

        if (
          FrontendSettings.authenticatedArea.enableOfficialAddress &&
          (data.organization ||
            data.plz ||
            data.street ||
            data.community ||
            data.phone ||
            data.freeOrganization)
        ) {
          // Official Address
          if (data.freeOrganization !== null)
            personOrderAddressObj.organization = data.freeOrganization;
          if (data.street !== null) personOrderAddressObj.street = data.street;
          if (data.phone !== null) personOrderAddressObj.phone = data.phone;
          if (data.mobile !== null) personOrderAddressObj.mobile = data.mobile;
          if (data.plz !== null) personOrderAddressObj.zipcode = data.plz;
          if (data.community !== null)
            personOrderAddressObj.community = data.community;
          if (data.organization?.name) {
            personOrderAddressObj.organization = data.organization.name;
          }
        } else {
          // Private Address
          if (data.private_street !== null)
            personOrderAddressObj.street = data.private_street;
          if (data.private_phone !== null)
            personOrderAddressObj.phone = data.private_phone;
          if (data.private_mobile !== null)
            personOrderAddressObj.mobile = data.private_mobile;
          if (data.private_plz !== null)
            personOrderAddressObj.zipcode = data.private_plz;
          if (data.private_community !== null)
            personOrderAddressObj.community = data.private_community;
        }

        this.personOrderAddress = personOrderAddressObj;
      })
      .catch((e) => {
        FrontendLogger.error({
          message: "Failed to load field data for personal data change.",
          data: e,
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  goToOverview(): void {
    this.emptyRequiredTextField = false;
    this.emptyRequiredPersonBillingData = false;

    if (this.altInvoiceAddressSettings !== null) {
      if (
        this.differentBillingAddressFieldValues.salutation ||
        this.differentBillingAddressFieldValues.first_name ||
        this.differentBillingAddressFieldValues.last_name ||
        this.differentBillingAddressFieldValues.phone ||
        this.differentBillingAddressFieldValues.mobile ||
        this.differentBillingAddressFieldValues.email ||
        this.differentBillingAddressFieldValues.organization ||
        this.differentBillingAddressFieldValues.department ||
        this.differentBillingAddressFieldValues.street ||
        this.differentBillingAddressFieldValues.zipcode ||
        this.differentBillingAddressFieldValues.community ||
        this.differentBillingAddressFieldValues.country ||
        this.differentBillingAddressFieldValues.attention ||
        this.differentBillingAddressFieldValues.pobox ||
        this.differentBillingAddressFieldValues.pozip ||
        this.differentBillingAddressFieldValues.pocommunity ||
        this.differentBillingAddressFieldValues.vat_id
      ) {
        for (const fieldName in this.altInvoiceAddressSettings.require) {
          if (this.altInvoiceAddressSettings.require[fieldName] !== "Y") {
            continue;
          }

          if (!this.differentBillingAddressFieldValues[fieldName]) {
            this.emptyRequiredTextField = true;
            this.panel = [0];

            setTimeout(() => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              this.$refs.diffBillingAddressForm?.validate();
            }, 100);

            return;
          }
        }
      } else {
        for (const fieldName in this.altInvoiceAddressSettings.require) {
          if (this.altInvoiceAddressSettings.require[fieldName] !== "Y") {
            continue;
          }

          if (!this.personBillingAddressFields.includes(fieldName)) {
            this.emptyRequiredTextField = true;
            this.panel = [0];

            setTimeout(() => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              this.$refs.diffBillingAddressForm?.validate();
            }, 100);

            return;
          }

          if (!this.personOrderAddress[fieldName]) {
            this.emptyRequiredPersonBillingData = true;

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.$refs.personBillingAddressForm?.validate();
            this.emptyRequiredPersonBillingData = true;
            return;
          }
        }
      }

      this.setDifferentBillingAddress(this.differentBillingAddressFieldValues);
    }

    this.setPersonBillingAddress(this.personOrderAddress);
  }
}
