
import { Component, Prop, Vue } from "vue-property-decorator";
import QuokkaEventRubricSearch from "@/components/quokka_layout/QuokkaEventRubricSearch.vue";
import QuokkaEventAddressSearch from "@/components/quokka_layout/QuokkaEventAddressSearch.vue";
import RecordingEvent from "@/shared/data/recording_event";
import { EventRecordingFieldData } from "@/frontend/lib/event_recording_data";
import FrontendSettings from "@/frontend/settings/settings";
import { sanitizeContent } from "@/shared/lib/dompurify";

/**
 * Tab for the Event-Recording containing general information about the {@link RecordingEvent} like {@link Rubric}, location, promoter.
 */
@Component({
  components: { QuokkaEventAddressSearch, QuokkaEventRubricSearch },
  data() {
    return { sanitizeContent };
  },
})
export default class QuokkaEventRecordingGeneralTab extends Vue {
  /**
   * The {@link RecordingEvent} used as model.
   */
  @Prop({ required: true }) recordingEvent!: RecordingEvent;

  /**
   * The {@link EventRecordingFieldData} containing available data for the select fields.
   */
  @Prop({ default: null })
  eventRecordingFieldData!: EventRecordingFieldData | null;

  /**
   * Whether the fields of this tab should be disabled or not.
   */
  @Prop() disabled!: boolean;

  /**
   * Available rules for the fields to verify the value.
   */
  rules: { [key: string]: (value: string) => boolean | string } = {
    rubric: (value: string): boolean | string => {
      if (!value) {
        return "Rubrik wählen";
      }
      return true;
    },
    location: (value: string): boolean | string => {
      if (!value) {
        return "Veranstaltungsort wählen";
      }
      return true;
    },
  };

  /**
   * Returns the `generalInfo` defined in the settings.
   */
  get generalInfo(): string | null {
    return FrontendSettings.eventRecording.generalInfo;
  }
}
