
import { Component, Prop, Vue } from "vue-property-decorator";
import FrontendSettings from "@/frontend/settings/settings";
import Person from "@/shared/data/person";
import { sanitizeContent } from "@/shared/lib/dompurify";

@Component({
  data() {
    return { FrontendSettings, sanitizeContent };
  },
})
export default class QuokkaAdminMainView extends Vue {
  /**
   * The currently logged in {@link Person}.
   */
  @Prop({ required: true }) loggedInPerson!: Person;

  /** Page title */
  title = "";

  /**
   * List of available cards to build.
   */
  get cards(): {
    title: string;
    icon: string;
    routeName: string;
  }[] {
    const actions = [];

    if (FrontendSettings.applicationSettings.activateEventsRecording) {
      actions.push({
        title: "Veranstaltungen",
        icon: "mdi-calendar",
        routeName: "events-admin",
      });
    }

    if (FrontendSettings.applicationSettings.activateAdverts) {
      actions.push({
        title: FrontendSettings.languageSettings.lbl_advert,
        icon: "mdi-cart-arrow-down",
        routeName: "ads",
      });
    }

    if (FrontendSettings.applicationSettings.activateArticles) {
      actions.push({
        title: "Artikel",
        icon: "mdi-note-text-outline",
        routeName: "articles-admin",
      });
    }

    return actions;
  }

  mounted(): void {
    this.title = FrontendSettings.authenticatedArea.titleLandingPage;
    if (FrontendSettings.authenticatedArea.appendName) {
      this.title =
        this.title +
        " " +
        this.loggedInPerson.firstName +
        " " +
        this.loggedInPerson.lastName;
    }
  }
}
