
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import { InputAdditionField } from "@/shared/data/addition_field";
import QuokkaDatePicker from "@/components/quokka_layout/ui/QuokkaDatePicker.vue";
import QuokkaTimePicker from "../QuokkaTimePicker.vue";

/**
 * An input field for an {@link InputAdditionField} from field_config
 * TODO: min/maxvalue where applicable
 */
@Component({
  components: { QuokkaDatePicker, QuokkaTimePicker },
})
export default class QuokkaInputAdditionField extends Vue {
  /** The Model of this field */
  @VModel({ required: true }) model!: string;
  /** The {@link AdditionField} used as a model.*/
  @Prop({ required: true }) additionField!: InputAdditionField;
  /** Whether the field should be disabled or not */
  @Prop() disabled!: boolean;

  /** Rules for clientside validation */
  rules: { [key: string]: (value: string) => boolean | string } = {
    required: (value: string): boolean | string => {
      value = value ?? "";

      if (this.additionField.required && !value) {
        if (this.additionField.required_form_message)
          return this.additionField.required_form_message;
        return "Pflichtfeld";
      }
      if (
        value &&
        this.additionField.regextest &&
        !value.match(this.additionField.regextest)
      ) {
        return "Keine korrekte Angabe.";
      }
      if (
        this.additionField.maxlength &&
        this.additionField.maxlength > 0 &&
        value.length > this.additionField.maxlength
      ) {
        return "Eingabe überschreitet Maximallänge";
      }
      if (
        this.additionField.minlength &&
        this.additionField.minlength > 0 &&
        value.length < this.additionField.minlength
      ) {
        return "Eingabe unterschreitet Minimallänge";
      }
      return true;
    },
    numbers: (value: string): boolean | string => {
      value = value ?? "";
      if (value.match("/^-?\\d*\\.?\\d*$/")) {
        return true;
      }
      return "Nur Zahlen erlaubt.";
    },
    url: (value: string): boolean | string => {
      value = value ?? "";
      if (value.match("/^(https?|chrome):\\/\\/[^\\s$.?#].[^\\s]*$/gm")) {
        return true;
      }
      return "Komplette Url benötigt.";
    },
    email: (value: string): boolean | string => {
      value = value ?? "";
      if (
        value.match(
          '/^(([^<>()[]\\.,;:s@"]+(.[^<>()[]\\.,;:s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/'
        )
      ) {
        return true;
      }
      return "Komplette Url benötigt.";
    },
  };
}
