
import { Component, Prop, Vue } from "vue-property-decorator";
import QuokkaEventRubricSearch from "@/components/quokka_layout/QuokkaEventRubricSearch.vue";
import QuokkaEventAddressSearch from "@/components/quokka_layout/QuokkaEventAddressSearch.vue";
import { EventRecordingFieldData } from "@/frontend/lib/event_recording_data";
import RecordingEvent from "@/shared/data/recording_event";
import FrontendSettings from "@/frontend/settings/settings";
import { sanitizeContent } from "@/shared/lib/dompurify";

/**
 * Tab for the Event-Recording containing miscellaneous fields like priority or tags.
 */
@Component({
  components: { QuokkaEventAddressSearch, QuokkaEventRubricSearch },
  data() {
    return { sanitizeContent };
  },
})
export default class QuokkaEventRecordingOthersTab extends Vue {
  /**
   * The {@link RecordingEvent} used as model.
   */
  @Prop({ required: true }) recordingEvent!: RecordingEvent;

  /**
   * Whether the fields of this tab should be disabled or not.
   */
  @Prop() disabled!: boolean;

  /**
   * The {@link EventRecordingFieldData} containing available data for the select fields.
   */
  @Prop() eventRecordingFieldData!: EventRecordingFieldData;

  /**
   * Available rules for the fields to verify the value.
   */
  rules: { [key: string]: (value: string) => boolean | string } = {};

  /**
   * Whether the priority-field is visible or not.
   */
  get showPriorityField(): boolean {
    return FrontendSettings.fieldSettings.recording.priority.visible;
  }
  /**
   * Returns the `othersInfo` defined in the settings.
   */
  get othersInfo(): string | null {
    return FrontendSettings.eventRecording.othersInfo;
  }

  /**
   * Whether the tags-field is visible or not.
   */
  get showTagsField(): boolean {
    return FrontendSettings.fieldSettings.recording.tags.visible;
  }
}
