
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import {
  AdvertCampaign,
  AdvertChannel,
  AdvertContent,
  AdvertContentMediaUploaderData,
  AdvertMedium,
  AdvertSlot,
  AdvertSlotContent,
} from "@/shared/data/advert";
import MediaUploader from "@/components/ui/MediaUploader.vue";
import RecordingEvent from "@/shared/data/recording_event";
import FrontendSettings from "@/frontend/settings/settings";
import {
  getMediaConfData,
  getMediaConfReturnData,
  loadImageFromEvent,
  mediaConfData,
  MediaInfoReturnType,
} from "@/frontend/lib/api";
import {
  DisplayInformationDataType,
  MediaInformationData,
} from "@/shared/data/media_information";

@Component({
  components: { MediaUploader },
  data() {
    return { FrontendSettings };
  },
})
export default class QuokkaAdsRecordingContent extends Vue {
  /** The campaigns */
  @Prop({ required: true }) campaigns!: AdvertCampaign[];
  /** Selected channel data */
  @Prop({ required: true }) selectedChannel!: AdvertChannel;
  /** Selected slot data */
  @Prop({ required: true }) selectedSlot!: AdvertSlot;
  /** Selected event */
  @Prop({ required: true }) selectedRecordingEvent!: RecordingEvent;
  /** Selected medium */
  @Prop({ required: true }) selectedMedium!: AdvertMedium;

  /** Selected Media */
  availableMedia: DisplayInformationDataType[] = [];

  /** Contains the advert content */
  content: AdvertContent = new AdvertContent();
  /** Contains the texts from the form */
  contentFieldValues: { [key: string]: unknown } = {};

  /** Advert media data */
  advertContentMediaUploaderData: AdvertContentMediaUploaderData =
    AdvertContentMediaUploaderData.create();
  /** Advert additional image information fields */
  mediaConfigData: {
    [key: string]: { [key: string]: { [key: string]: unknown } };
  } = {};
  /** Contains the additional image texts */
  additionalImageInformation: { [key: string]: unknown } = {};

  loading = true;
  /** The field definition for slot*/
  contentDefinition: AdvertSlotContent[] = [];

  /**
   * Returns only the enabled {@link AdvertSlotContent} of {@link contentDefinition}.
   */
  get enabledContentDefinition(): AdvertSlotContent[] {
    console.log(
      this.contentDefinition.filter((content) => content.active === "Y")
    );

    return this.contentDefinition.filter((content) => content.active === "Y");
  }

  availableMediaFromEvent: (MediaInfoReturnType & { selected: boolean })[] = [];
  unavailableMediaFromEvent: MediaInfoReturnType[] = [];

  noRequiredMedium = false;
  emptyRequiredTextField = false;

  /** Display the fields in the specified order */
  fieldOrder: string[] = [
    "title",
    "shortdesc",
    "shortdesc2",
    "shortdesc3",
    "longdesc",
    "longdesc2",
    "longdesc3",
  ];

  /**
   * Prepares the data at the first load of component.
   */
  mounted(): void {
    this.loading = true;
    this.availableMedia = [];

    this.contentDefinition = [];
    this.campaigns.forEach((campaign) => {
      breakLoop: for (const channel of campaign.advert_channels) {
        if (
          channel.advert_channel_id == this.selectedChannel?.advert_channel_id
        ) {
          for (const slot of channel.advert_slots) {
            if (slot.advert_slot_id == this.selectedSlot?.advert_slot_id) {
              // sort content fields
              this.fieldOrder.forEach((orderField) => {
                slot.content_definition.forEach((slotContentDefinition) => {
                  if (slotContentDefinition.fieldName === orderField) {
                    this.contentDefinition.push(slotContentDefinition);
                  }
                });
              });
              break breakLoop;
            }
          }
        }
      }
    });

    getMediaConfData({
      type: mediaConfData.advert,
    })
      .then((response: getMediaConfReturnData) => {
        this.mediaConfigData = response["mediaConfigData"];
      })
      .catch((e) => {
        console.error(e);
      });

    this.loading = false;
  }

  /*
   * Available rules for the fields to verify the value.
   */
  rules: { [key: string]: (value: string) => boolean | string } = {
    required: (value: string): boolean | string => {
      if (!value || value.trim() === "") {
        return "Text darf nicht leer sein";
      }
      this.emptyRequiredTextField = false;
      return true;
    },
    requiredMediaText: (value: string): boolean | string => {
      if (!value || value.trim() === "") {
        return "Pflichtfeld";
      }
      return true;
    },
    title: (value: string): boolean | string => {
      const definition = this.getDefinitionForRules().find(
        (def) => def.fieldName === "title"
      );

      if (!definition || !definition.maxLength) {
        return true;
      }

      const maxLength = definition.maxLength;
      if (maxLength && value.length > maxLength) return "Titel ist zu lang";
      return true;
    },
    shortdesc: (value: string): boolean | string => {
      const definition = this.getDefinitionForRules().find(
        (def) => def.fieldName === "shortdesc"
      );

      if (!definition || !definition.maxLength) return true;

      const maxLength = definition.maxLength;
      if (maxLength && value.length > maxLength)
        return "Kurzbeschreibung ist zu lang";
      return true;
    },
    shortdesc2: (value: string): boolean | string => {
      const definition = this.getDefinitionForRules().find(
        (def) => def.fieldName === "shortdesc2"
      );

      if (!definition || !definition.maxLength) return true;

      const maxLength = definition.maxLength;
      if (maxLength && value.length > maxLength)
        return "Kurzbeschreibung 2 ist zu lang";
      return true;
    },
    shortdesc3: (value: string): boolean | string => {
      const definition = this.getDefinitionForRules().find(
        (def) => def.fieldName === "shortdesc3"
      );

      if (!definition || !definition.maxLength) return true;

      const maxLength = definition.maxLength;
      if (maxLength && value.length > maxLength)
        return "Kurzbeschreibung 3 ist zu lang";
      return true;
    },
    longdesc: (value: string): boolean | string => {
      const definition = this.getDefinitionForRules().find(
        (def) => def.fieldName === "longdesc"
      );

      if (!definition || !definition.maxLength) return true;

      const maxLength = definition.maxLength;
      if (maxLength && value.length > maxLength)
        return "Langbeschreibung ist zu lang";
      return true;
    },
    longdesc2: (value: string): boolean | string => {
      const definition = this.getDefinitionForRules().find(
        (def) => def.fieldName === "longdesc2"
      );

      if (!definition || !definition.maxLength) return true;

      const maxLength = definition.maxLength;
      if (maxLength && value.length > maxLength)
        return "Langbeschreibung 2 ist zu lang";
      return true;
    },
    longdesc3: (value: string): boolean | string => {
      const definition = this.getDefinitionForRules().find(
        (def) => def.fieldName === "longdesc3"
      );

      if (!definition || !definition.maxLength) return true;

      const maxLength = definition.maxLength;
      if (maxLength && value.length > maxLength)
        return "Langbeschreibung 3 ist zu lang";
      return true;
    },
  };

  getDefinitionForRules(): AdvertSlotContent[] {
    return this.contentDefinition;
  }

  @Watch("selectedRecordingEvent", { immediate: true })
  onSelectedEventChange(): void {
    this.content = new AdvertContent();
    if (this.selectedRecordingEvent?.event_id) {
      this.content.eventId = this.selectedRecordingEvent.event_id;

      this.selectedSlot.content_definition.forEach((def: AdvertSlotContent) => {
        if (
          Object.prototype.hasOwnProperty.call(
            this.selectedRecordingEvent.text,
            def.externalName
          )
        )
          this.contentFieldValues[def.fieldName] =
            this.selectedRecordingEvent.text[def.externalName];
      });

      this.availableMediaFromEvent = [];
      this.selectedRecordingEvent.uploaded_media.forEach((mediaData) => {
        loadImageFromEvent({
          load: mediaData.media_id,
        })
          .then((response: MediaInfoReturnType) => {
            let imgSelected = false;

            if (
              this.selectedSlot.image_amount !== null &&
              this.selectedSlot.image_amount >
                this.availableMediaFromEvent.length
            ) {
              imgSelected = true;
            }

            this.availableMediaFromEvent.push(
              Object.assign(response, { selected: imgSelected })
            );

            // Add display description tab for selected media
            this.selectedRecordingEvent.additionalImageInformation.forEach(
              (information) => {
                if (information.media_id == mediaData.media_id) {
                  let mediaInformation = {
                    media_id: information.media_id,
                    upload_key: information.upload_key,
                    title: information.title,
                    desc: information.desc,
                    author: information.author,
                    copyright: information.copyright,
                    agency: information.agency,
                    location: information.location,
                    width: response.width,
                    height: response.height,
                    mimetype: response.mimetype,
                    base64: response.base64,
                  };
                  this.availableMedia.push(mediaInformation);
                }
              }
            );
          })
          .catch((e) => {
            console.error(e);
          });
      });
    }
  }

  onFileUploaded(): void {
    this.noRequiredMedium = false;

    let countedSelectedMediaFromEvent = this.availableMediaFromEvent.filter(
      (avMedia) => avMedia.selected
    );

    let selectedMediaFilesCount =
      this.advertContentMediaUploaderData.upload_media.length +
      countedSelectedMediaFromEvent.length;

    if (
      this.selectedSlot.image_amount !== null &&
      selectedMediaFilesCount > this.selectedSlot.image_amount
    ) {
      let tmpAvailableMediaFromEvent: (MediaInfoReturnType & {
        selected: boolean;
      })[] = [];

      this.availableMediaFromEvent.forEach((availableMediaFromEvent) => {
        availableMediaFromEvent.selected = false;
        tmpAvailableMediaFromEvent.push(availableMediaFromEvent);
      });

      this.availableMediaFromEvent = tmpAvailableMediaFromEvent;

      // Add information tab for uploaded media
      this.advertContentMediaUploaderData.upload_media.forEach(
        (uploadMedia) => {
          let newMedia = true;
          this.availableMedia.forEach((media) => {
            if (media.media_id == 0 && media.upload_key === uploadMedia) {
              newMedia = false;
            }
          });
          if (newMedia) {
            this.availableMedia.push({
              media_id: 0,
              upload_key: uploadMedia,
              title: null,
              desc: null,
              author: null,
              copyright: null,
              agency: null,
              location: null,
              width: null,
              height: null,
              mimetype: null,
              base64: null,
            });
          }
        }
      );
    }
  }

  selectMedia(amKey?: number): void {
    if (amKey !== undefined) {
      this.availableMediaFromEvent[amKey].selected =
        !this.availableMediaFromEvent[amKey].selected;
    }

    if (this.availableMediaFromEvent.length > 0) {
      this.noRequiredMedium = false;

      let countedSelectedMediaFromEvent = this.availableMediaFromEvent.filter(
        (avMedia) => avMedia.selected
      );

      let selectedMediaFilesCount = countedSelectedMediaFromEvent.length;

      if (
        this.advertContentMediaUploaderData?.upload_media &&
        this.advertContentMediaUploaderData.upload_media.length > 0
      ) {
        selectedMediaFilesCount +=
          this.advertContentMediaUploaderData.upload_media.length;
      }

      if (
        this.selectedSlot.image_amount !== null &&
        selectedMediaFilesCount > this.selectedSlot.image_amount
      ) {
        if (amKey !== undefined) {
          this.availableMediaFromEvent[amKey].selected = false;
        }
        return;
      }
    }
  }

  setAdvertContent(): void {
    this.emptyRequiredTextField = false;

    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.$refs.adsContentForm?.validate();
    }, 100);

    for (const key in this.contentFieldValues) {
      if (Object.prototype.hasOwnProperty.call(this.contentFieldValues, key)) {
        const value = this.contentFieldValues[key] as string;
        if (Object.prototype.hasOwnProperty.call(this.rules, key)) {
          const rule = this.rules[key];
          const result = rule(value);

          if (result !== true) {
            const errorField = document.querySelector(
              'input[name="' + key + '"]'
            );
            if (errorField !== null) {
              errorField.scrollIntoView();
            }
            return;
          }
        }
      }
    }

    if (this.selectedSlot.image_required === "Y") {
      if (this.advertContentMediaUploaderData.upload_media.length <= 0) {
        const countedSelectedMediaFromEvent =
          this.availableMediaFromEvent.filter((avMedia) => avMedia.selected);

        if (countedSelectedMediaFromEvent.length <= 0) {
          this.noRequiredMedium = true;
        }
      }
    }

    let contentFieldValuesTmp = this.contentFieldValues;
    this.contentDefinition.forEach((conDefi) => {
      if (conDefi.required === "Y") {
        if (!contentFieldValuesTmp?.[conDefi.fieldName]) {
          this.emptyRequiredTextField = true;
        }
      }
    });

    if (
      (this.selectedSlot.image_required === "Y" && this.noRequiredMedium) ||
      this.emptyRequiredTextField
    ) {
      if (this.emptyRequiredTextField) {
        window.scrollTo(0, 0);
      }
      return;
    }

    let tmpContentData: unknown[] = [];
    let tmpContentFieldValues = this.contentFieldValues;

    this.contentDefinition.forEach((field) => {
      if (field.active == "Y") {
        tmpContentData.push({
          name: field.fieldName,
          value: tmpContentFieldValues[field.fieldName],
          label: field.fieldLabel,
        });
      }
    });

    this.content.advertContent = tmpContentData;
    this.content.advertMedia = this.advertContentMediaUploaderData;
    //this.content.additionalImageInformation = this.additionalImageInformation;
    let tmpAdditionalInformation: DisplayInformationDataType[] = [];
    this.availableMedia.forEach((media) => {
      tmpAdditionalInformation.push({
        media_id: media.media_id,
        upload_key: media.upload_key,
        title: media.title,
        desc: media.desc,
        author: media.author,
        copyright: media.copyright,
        agency: media.agency,
        location: media.location,
        width: null,
        height: null,
        mimetype: null,
        base64: null,
      });
    });

    this.content.additionalImageInformation = tmpAdditionalInformation;

    this.content.selectedMediaEventMediaId = [];
    this.availableMediaFromEvent.forEach((eventMedia) => {
      if (eventMedia.selected) {
        this.content.selectedMediaEventMediaId.push(eventMedia.media_id);
      }
    });

    this.selectContent(this.content);
  }

  @Watch("advertContentMediaUploaderData.upload_media")
  updateNewMedia(): void {
    let addedMedia: DisplayInformationDataType[] = [];
    // check all upload media and compare with those already in availableMedia
    this.advertContentMediaUploaderData.upload_media.forEach((media) => {
      let newMedia = true;
      this.availableMedia.forEach((available) => {
        if (available.upload_key == media) {
          newMedia = false;
        }
      });
      if (newMedia) {
        let data = {
          media_id: 0,
          upload_key: media,
          title: null,
          desc: null,
          author: null,
          copyright: null,
          agency: null,
          location: null,
          width: null,
          height: null,
          mimetype: null,
          base64: null,
        };
        addedMedia.push(data);
      }
    });

    // remove deleted media from tabs
    this.availableMedia.forEach((media, key) => {
      // check for removed upload media
      if (media.media_id == 0) {
        let deleted = true;
        this.advertContentMediaUploaderData.upload_media.forEach((upload) => {
          if (upload == media.upload_key) deleted = false;
        });
        if (deleted) this.availableMedia.splice(key, 1);
      }
    });

    // add new uploadMedia
    addedMedia.forEach((media) => {
      this.availableMedia.push(media);
    });
  }

  /**
   * Get the title from available data, prefers the given title
   * @param media
   */
  getMediaTitle(media: MediaInformationData): string {
    if (media.title && media.title !== "") {
      return media.title;
    }
    if (media.upload_key && media.upload_key !== "") {
      return media.upload_key.substring(33);
    }
    if (media.media_id && media.media_id > 0) {
      return "";
    }
    return "";
  }

  @Emit("content-data")
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  selectContent(contentData: AdvertContent): void {
    return;
  }
}
