import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";
import de from "vuetify/src/locale/de";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: {
      de,
    },
    current: "de",
  },
  icons: {
    iconfont: "mdi",
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        background: colors.grey.lighten3,
        "text-on-primary": colors.grey.lighten5,
      },
      dark: {
        background: "#121212",
        "text-on-primary": colors.grey.lighten5,
      },
    },
  },
});
