import { render, staticRenderFns } from "./QuokkaAdsListAdminView.vue?vue&type=template&id=0f483dfc&scoped=true&"
import script from "./QuokkaAdsListAdminView.vue?vue&type=script&lang=ts&"
export * from "./QuokkaAdsListAdminView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f483dfc",
  null
  
)

export default component.exports