
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import { CheckboxAdditionField } from "@/shared/data/addition_field";

/**
 * A checkbox for an {@link CheckboxAdditionField} from field_config
 */
@Component
export default class QuokkaCheckboxAdditionField extends Vue {
  /** The Model of this field */
  @VModel({ required: true }) model!: string;
  /** The {@link CheckboxAdditionField} used as a model.*/
  @Prop({ required: true }) additionField!: CheckboxAdditionField;
  /** Whether the field should be disabled or not */
  @Prop() disabled!: boolean;

  /** Rules for clientside validation */
  rules: { [key: string]: (value: string) => boolean | string } = {
    checkboxAdditionField: (value: string): boolean | string => {
      if (this.additionField.required && !value) {
        if (this.additionField.required_form_message)
          return this.additionField.required_form_message;
        return "Pflichtfeld";
      }
      return true;
    },
  };
}
