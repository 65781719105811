
import { Component, Vue, Watch } from "vue-property-decorator";
import Event from "@/shared/data/event";
import QuokkaEventListCard from "@/components/quokka_layout/QuokkaEventListCard.vue";
import { getEvents, GetEventsReturnType } from "@/frontend/lib/api";
import RouteHelper from "@/frontend/lib/route_helper";
import EventListFilter from "@/shared/lib/event-list-filter";
import FrontendSettings from "@/frontend/settings/settings";
import QuokkaEventListFilterContent from "@/components/quokka_layout/QuokkaEventListFilterContent.vue";

@Component({
  components: {
    QuokkaEventListFilterContent,
    QuokkaEventListCard,
  },
  data() {
    return {
      FrontendSettings,
    };
  },
})
export default class QuokkaEventListView extends Vue {
  showFilterDrawer = false;
  eventsLoading = true;
  pageNumber = 1;
  totalPageNumber = 1;
  events: Event[] = [];
  totalEventsAmount = 0;
  /**
   * The currently used {@link EventListFilter}.
   * Is set from and to the Vue instance for persistence when changing routes.
   */
  filter: EventListFilter;

  /**
   * A copy of {@link filter} used in the filter-popup.
   */
  changingFilter: EventListFilter = new EventListFilter();

  /**
   * The total amount of events as a preview.
   */
  totalFilterEventsAmountPreview = 0;

  /**
   * Whether the total amount of events to preview is loading or not.
   */
  totalFilterEventsAmountPreviewLoading = true;

  /**
   * The key of the last called Promise that fetches the total amount of events to preview.
   *
   * The total amount will only be set, if the key is the active key to prevent wrong values.
   */
  totalFilterEventsAmountPreviewActivePromiseKey = 0;

  updateFilterEventCountPreviewTimeout?: number;

  /**
   * This constructor sets the {@link filter} from the Vue instance, if available or creates a new one if not.
   */
  constructor() {
    super();
    this.filter = Vue.prototype.$eventFilter ?? new EventListFilter();
  }

  /**
   * Whether to show the login button or not.
   */
  get showLoginButton(): boolean {
    return FrontendSettings.authenticatedArea.showLoginSignupButton;
  }

  /**
   * Gets the results per page defined in settings.
   */
  get resultsPerPage(): number {
    return FrontendSettings.eventList.resultsPerPage;
  }

  /**
   * Checks, if events are available.
   */
  get hasEvents(): boolean {
    return this.events.length > 0;
  }

  /**
   * Gets the amount of available events as a preview for the filter.
   */
  loadFilterAmountPreview(): Promise<void> {
    this.totalFilterEventsAmountPreviewLoading = true;

    const promiseKey = +new Date();
    this.totalFilterEventsAmountPreviewActivePromiseKey = promiseKey;

    return getEvents({
      per_page: 1,
      query: this.changingFilter.searchQuery ?? undefined,
      start_date: this.changingFilter.dateFrom ?? undefined,
      end_date: this.changingFilter.dateUntil ?? undefined,
      communities: this.changingFilter.locationCommunity
        ? [this.changingFilter.locationCommunity]
        : undefined,
      rubric: this.changingFilter.rubric ?? undefined,
      tags: this.changingFilter.tag ?? undefined,
      range: this.changingFilter.locationRange ?? 0,
    })
      .then((data: GetEventsReturnType) => {
        if (
          promiseKey === this.totalFilterEventsAmountPreviewActivePromiseKey
        ) {
          this.totalFilterEventsAmountPreview = data.resultsCountOverall;
        }
      })
      .catch(() => {
        if (
          promiseKey === this.totalFilterEventsAmountPreviewActivePromiseKey
        ) {
          this.totalFilterEventsAmountPreview = 0;
        }
      })
      .finally(() => {
        if (
          promiseKey === this.totalFilterEventsAmountPreviewActivePromiseKey
        ) {
          this.totalFilterEventsAmountPreviewLoading = false;
        }
      });
  }

  /**
   * This watcher watches the filter and writes it into the Vue instance on change.
   */
  @Watch("filter", { deep: true, immediate: true })
  onFilterChange(): void {
    Vue.prototype.$eventFilter = this.filter;
  }

  @Watch("changingFilter", { deep: true })
  onChangingFilterChange(): void {
    this.totalFilterEventsAmountPreviewLoading = true;

    clearTimeout(this.updateFilterEventCountPreviewTimeout);
    this.updateFilterEventCountPreviewTimeout = setTimeout(() => {
      this.loadFilterAmountPreview();
    }, 500);
  }

  /**
   * Pushed the route to /login.
   */
  login(): void {
    this.$router.push({
      name: "login",
    });
  }

  /**
   * Loads the events into the events-list.
   * This will also enable loading animation.
   */
  loadEventList(): Promise<void> {
    this.eventsLoading = true;

    return getEvents({
      per_page: this.resultsPerPage,
      page: this.pageNumber,
      query: this.filter.searchQuery ?? undefined,
      start_date: this.filter.dateFrom ?? undefined,
      end_date: this.filter.dateUntil ?? undefined,
      communities: this.filter.locationCommunity
        ? [this.filter.locationCommunity]
        : undefined,
      range: this.filter.locationRange ?? 0,
      rubric: this.filter.rubric ?? undefined,
      tags: this.filter.tag ?? undefined,
    })
      .then((data: GetEventsReturnType) => {
        this.events = data.events;
        this.totalPageNumber = data.pagesCountOverall;
        this.totalEventsAmount = data.resultsCountOverall;
        this.totalFilterEventsAmountPreview = this.totalEventsAmount;
      })
      .finally(() => {
        this.eventsLoading = false;
      });
  }

  /**
   * Called, when the filter should be opened.
   */
  openFilter(): void {
    // Set the changingFilter
    this.changingFilter = new EventListFilter();
    Object.assign(this.changingFilter, this.filter);

    this.showFilterDrawer = true;
  }

  /**
   * Called, when an active filter chip is cleared.
   */
  clearChip(): void {
    this.loadEventList();
  }

  /**
   * Called, when user clicked on the search button in filter drawer.
   */
  search(): void {
    // Replace the filter with the changed filter
    this.filter = this.changingFilter;
    this.changingFilter = new EventListFilter();
    Object.assign(this.changingFilter, this.filter);

    this.loadEventList();
    this.showFilterDrawer = false;
    this.pageNumber = 1;
  }

  /**
   * Resets all active filters.
   */
  resetFilter(): void {
    this.changingFilter = new EventListFilter();
  }

  @Watch("$route", { deep: true, immediate: true })
  private onRouteChange(): void {
    // Check, if we got any params in route
    if (
      RouteHelper.hasQueryParam(this.$route, "page") &&
      parseInt(RouteHelper.getQueryParam(this.$route, "page")) >= 1
    ) {
      this.pageNumber = parseInt(
        RouteHelper.getQueryParam(this.$route, "page")
      );
    }

    // Load Events for the list
    this.loadEventList();
  }

  @Watch("pageNumber")
  private onPageNumberChange(): void {
    const query: Record<string, string> = {};

    if (this.pageNumber > 1) {
      query.page = this.pageNumber.toString();
    }

    this.$router.push({
      name: "events",
      query: query,
    });
    this.$vuetify.goTo("#event_list_title");
  }
}
