import DataStruct from "@/shared/data/datastruct";

/**
 * The Text Data-Class containing all properties for a Text.
 */
export default class Text extends DataStruct {
  /**
   * Definition of the key of this object.
   */
  [key: string]: unknown;
  title = "";
  shortdesc = "";
  shortdesc2 = "";
  shortdesc3 = "";
  longdesc = "";
  longdesc2 = "";
  longdesc3 = "";

  /**
   * Creates a new Text-object with the given data returned from the server.
   *
   * @param data
   */
  static fromServer(data: ServerTextDataType): Text {
    const text = new Text();

    // Load the data
    if (DataStruct.validateValue(data, "title", "string"))
      text.title = data.title;
    if (DataStruct.validateValue(data, "shortdesc", "string"))
      text.shortdesc = <string>data.shortdesc;
    if (DataStruct.validateValue(data, "shortdesc2", "string"))
      text.shortdesc2 = <string>data.shortdesc2;
    if (DataStruct.validateValue(data, "shortdesc3", "string"))
      text.shortdesc3 = <string>data.shortdesc3;
    if (DataStruct.validateValue(data, "longdesc", "string"))
      text.longdesc = <string>data.longdesc;
    if (DataStruct.validateValue(data, "longdesc2", "string"))
      text.longdesc2 = <string>data.longdesc2;
    if (DataStruct.validateValue(data, "longdesc3", "string"))
      text.longdesc3 = <string>data.longdesc3;

    return text;
  }
}

/**
 * The data structure defined by the MSUevent-Server.
 */
export type ServerTextDataType = {
  title: string;
  shortdesc?: string;
  shortdesc2?: string;
  shortdesc3?: string;
  longdesc?: string;
  longdesc2?: string;
  longdesc3?: string;
};
