
import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";
import { getAddresses, GetAddressReturnType } from "@/frontend/lib/api";
import Address from "@/shared/data/address";
import FrontendLogger from "@/frontend/lib/logger";

@Component
export default class QuokkaEventAddressSearch extends Vue {
  @VModel({ type: Address }) address!: Address | null;
  @Prop({ required: true }) addressType!:
    | "location"
    | "promoter"
    | "ticket_agency";

  @Prop() rules!: ((value: string) => boolean | string)[];

  @Prop() disabled!: boolean;

  /** If there is only a single result available */
  singleResult = false;

  /**
   * A label to use for the field.
   * If not set, a default label will be used.
   */
  @Prop() label!: string;

  availableAddresses: Address[] = [];

  get autocompleteItems(): (
    | Address
    | {
        searchLabel: string;
        id: string;
        disabled: boolean;
      }
  )[] {
    const items: (
      | Address
      | {
          searchLabel: string;
          id: string;
          disabled: boolean;
        }
    )[] = [...this.availableAddresses];

    if (
      this.resultsCountOverall &&
      this.availableAddresses.length < this.resultsCountOverall
    ) {
      items.push({
        searchLabel:
          "+ " +
          (this.resultsCountOverall - this.availableAddresses.length) +
          " weitere Ergebnisse",
        id: "__more_results__",
        disabled: true,
      });
    }

    return items;
  }

  /**
   * The overall amount of results available on the server for the current query.
   */
  resultsCountOverall: number | null = null;

  selectedAddressIsContained = false;
  addressSearchQuery: string | null = null;
  addressLoading = false;
  addressSearchTimeOut?: number;
  errorMessage = "";

  @Watch("address", { immediate: true })
  onSelectedAddressUpdate(): void {
    if (this.address) this.availableAddresses.push(this.address);
    else if (this.availableAddresses.length === 0)
      this.onAddressSearchQueryUpdate();
  }

  get addressSearchQueryString(): string {
    return this.addressSearchQuery ?? "";
  }

  get fieldLabel(): string {
    if (this.label > "") return this.label;
    switch (this.addressType) {
      case "location":
        return "Veranstaltungsort";
      case "promoter":
        return "Veranstalter";
      case "ticket_agency":
        return "Vorverkaufsstelle";
      default:
        return "Adresse";
    }
  }

  get prependIcon(): string {
    switch (this.addressType) {
      case "location":
        return "mdi-map-marker";
      case "promoter":
        return "mdi-account-tie";
      case "ticket_agency":
        return "";
      default:
        return "";
    }
  }

  /**
   * Hide the selected item, if only one address is available and this is the selected one.
   */
  get hideSelected(): boolean {
    return !(
      (this.availableAddresses.length === 1 &&
        this.address &&
        this.availableAddresses[0].address_id === this.address.address_id &&
        this.address.searchLabel
          .toLocaleLowerCase()
          .includes(this.addressSearchQueryString.toLocaleLowerCase())) ||
      this.selectedAddressIsContained
    );
  }

  get noDataText(): string {
    if (this.resultsCountOverall === null) {
      return "Tippen, um zu suchen...";
    }
    return "Kein Ergebnis";
  }

  mounted(): void {
    this.addressLoading = true;
    getAddresses({
      type: this.addressType,
      query: this.addressSearchQueryString,
      query_type: "fulltext",
      per_page: 100,
    })
      .then((response: GetAddressReturnType) => {
        this.resultsCountOverall = response.resultsCountOverall;
        this.availableAddresses = response.addresses;
        if (this.availableAddresses.length === 1) {
          this.address = this.availableAddresses[0];
          this.singleResult = true;
        }
      })
      .catch((e) => {
        this.errorMessage = "Beim Laden ist ein Fehler aufgetreten";
        FrontendLogger.error({
          message:
            "Failed to get available addresses for type '" +
            this.addressType +
            "'.",
          data: e,
        });
      })
      .finally(() => {
        this.addressLoading = false;
      });
  }

  @Watch("addressSearchQuery")
  public onAddressSearchQueryUpdate(): void {
    clearTimeout(this.addressSearchTimeOut);

    const timeoutTime = this.addressSearchQuery ? 700 : 0;

    this.addressSearchTimeOut = setTimeout(() => {
      this.addressLoading = true;
      getAddresses({
        type: this.addressType,
        query:
          this.addressSearchQuery === null
            ? undefined
            : this.addressSearchQuery,
        query_type: "fulltext",
        per_page: 100,
      })
        .then((response: GetAddressReturnType) => {
          this.resultsCountOverall = response.resultsCountOverall;
          this.availableAddresses = response.addresses;
          // Check, if the selected address is available in the addresses
          let isContained = false;
          if (this.address) {
            this.availableAddresses.forEach((obj: Address) => {
              if (this.address && obj.address_id === this.address.address_id)
                isContained = true;
            });
            if (!isContained) this.availableAddresses.push(this.address);
          }
          this.selectedAddressIsContained = isContained;
        })
        .catch((e) => {
          this.errorMessage = "Beim Laden ist ein Fehler aufgetreten";
          FrontendLogger.error({
            message:
              "Failed to get available addresses for type '" +
              this.addressType +
              "'.",
            data: e,
          });
        })
        .finally(() => {
          this.addressLoading = false;
        });
    }, timeoutTime);
  }
}
