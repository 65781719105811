import { render, staticRenderFns } from "./QuokkaSignUpView.vue?vue&type=template&id=596e6361&scoped=true&"
import script from "./QuokkaSignUpView.vue?vue&type=script&lang=ts&"
export * from "./QuokkaSignUpView.vue?vue&type=script&lang=ts&"
import style0 from "./QuokkaSignUpView.vue?vue&type=style&index=0&id=596e6361&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "596e6361",
  null
  
)

export default component.exports