var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"quokka_event_list"},[_c('div',[_vm._m(0),(_vm.showLoginButton)?_c('div',{staticClass:"d-flex align-center mx-2 mx-sm-4 mb-5 justify-end"},[_c('v-btn',{staticClass:"d-block d-md-none",attrs:{"color":"primary","large":""},on:{"click":_vm.login}},[_vm._v(" "+_vm._s(_vm.FrontendSettings.authenticatedArea.labelLogin)+" ")])],1):_vm._e(),_c('div',{staticClass:"d-flex align-center mx-2 mx-sm-4"},[_c('v-btn',{staticClass:"mr-10",attrs:{"disabled":_vm.eventsLoading,"color":"primary","large":""},on:{"click":_vm.openFilter}},[_c('v-icon',{attrs:{"left":"","size":"25px"}},[_vm._v(" mdi-filter-variant ")]),_vm._v(" Filter ")],1),(_vm.totalEventsAmount > 0)?_c('b',[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.totalEventsAmount))]),_vm._v(" "+_vm._s(_vm.totalEventsAmount === 1 ? "Ergebnis" : "Ergebnisse")+" ")]):_vm._e(),_c('v-spacer'),(_vm.showLoginButton)?_c('v-btn',{staticClass:"d-none d-md-block",attrs:{"color":"primary","large":""},on:{"click":_vm.login}},[_vm._v(" "+_vm._s(_vm.FrontendSettings.authenticatedArea.labelLogin)+" ")]):_vm._e()],1),_c('div',{staticClass:"mt-5 mb-5"},_vm._l((_vm.filter.getActiveFilterLabels()),function(activeFilter){return _c('v-chip',{key:activeFilter.filterName,staticClass:"mr-3",attrs:{"close":""},on:{"click:close":() => {
            activeFilter.clear();
            _vm.clearChip();
          }}},[_vm._v(_vm._s(activeFilter.label))])}),1),(_vm.eventsLoading || _vm.hasEvents)?_c('div',[_c('v-row',{staticClass:"my-xl-5 ma-sm-2",attrs:{"no-gutters":""}},_vm._l((_vm.eventsLoading ? _vm.resultsPerPage : _vm.events),function(event,i){return _c('v-col',{key:i,attrs:{"lg":"4","md":"6","sm":"12","xs":"12","cols":"12"}},[_c('QuokkaEventListCard',{attrs:{"event":_vm.eventsLoading ? undefined : event}})],1)}),1),(!_vm.eventsLoading && _vm.totalPageNumber > 1)?_c('v-pagination',{attrs:{"length":_vm.totalPageNumber,"circle":"","total-visible":"7"},model:{value:(_vm.pageNumber),callback:function ($$v) {_vm.pageNumber=$$v},expression:"pageNumber"}}):_vm._e()],1):_c('div',[_c('v-card',{staticClass:"no-events-found"},[_c('v-card-subtitle',{staticClass:"d-flex justify-center py-10"},[_c('div',{staticClass:"d-flex content"},[_c('div',{staticClass:"d-flex mr-5"},[_c('v-icon',{staticClass:"not-found-icon"},[_vm._v("mdi-magnify")])],1),_c('div',{staticClass:"info-text-container d-flex justify-center"},[_c('h1',{staticClass:"not-found-title"},[_vm._v("Keine Veranstaltungen gefunden")]),_c('p',{staticClass:"mt-3 mb-0 subtitle"},[_vm._v(" Wir konnten leider keine Veranstaltungen für Ihre Auswahl finden. Passen Sie Ihre Suchkriterien an. ")])])])])],1)],1)]),_c('v-navigation-drawer',{staticClass:"filter-drawer",attrs:{"stateless":"","absolute":"","width":"450px"},model:{value:(_vm.showFilterDrawer),callback:function ($$v) {_vm.showFilterDrawer=$$v},expression:"showFilterDrawer"}},[_c('div',{staticClass:"grey lighten-2 pa-5 d-flex justify-space-between align-center"},[_c('v-icon',{attrs:{"size":"25px"},on:{"click":function($event){_vm.showFilterDrawer = false}}},[_vm._v("mdi-close")]),_c('b',{staticClass:"d-flex"},[_c('div',{class:'mr-2 ' +
            (_vm.totalFilterEventsAmountPreviewLoading
              ? 'grey--text'
              : 'primary--text'),staticStyle:{"transition":"all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)"}},[_vm._v(" "+_vm._s(_vm.totalFilterEventsAmountPreview)+" ")]),_vm._v(" "+_vm._s(_vm.totalFilterEventsAmountPreview === 1 ? "Ergebnis" : "Ergebnisse")+" ")]),_c('v-btn',{attrs:{"disabled":_vm.eventsLoading,"color":"primary","large":""},on:{"click":_vm.search}},[_c('v-icon',{attrs:{"left":"","size":"25px"}},[_vm._v(" mdi-magnify ")]),_vm._v(" Suchen ")],1)],1),_c('QuokkaEventListFilterContent',{attrs:{"filter":_vm.changingFilter}}),_c('div',{staticClass:"px-10"},[_c('v-btn',{attrs:{"disabled":_vm.eventsLoading || !_vm.changingFilter.hasActiveFilter(),"block":"","color":"primary","outlined":"","x-large":""},on:{"click":_vm.resetFilter}},[_c('v-icon',{attrs:{"left":"","size":"25px"}},[_vm._v(" mdi-reload ")]),_vm._v(" Filter zurücksetzen ")],1)],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mb-10 mx-2 mx-sm-4"},[_c('h1',{staticClass:"text-uppercase word-break-break-word",attrs:{"id":"event_list_title"}},[_vm._v(" Veranstaltungen ")])])
}]

export { render, staticRenderFns }