import { render, staticRenderFns } from "./QuokkaPersonDetails.vue?vue&type=template&id=ad87414a&scoped=true&"
import script from "./QuokkaPersonDetails.vue?vue&type=script&lang=ts&"
export * from "./QuokkaPersonDetails.vue?vue&type=script&lang=ts&"
import style0 from "./QuokkaPersonDetails.vue?vue&type=style&index=0&id=ad87414a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad87414a",
  null
  
)

export default component.exports