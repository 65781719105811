
import { Component, Prop, Vue } from "vue-property-decorator";
import Authorization from "@/frontend/lib/auth";
import { RawLocation } from "vue-router";
import FrontendSettings from "@/frontend/settings/settings";
import Person from "@/shared/data/person";

/**
 * The {@link QuokkaAdminView} is a wrapper for all quokka-views shown inside the secured admin area.
 *
 * Provides the logged in {@link Person} to the router-view.
 */
@Component({
  data() {
    return { FrontendSettings };
  },
})
export default class QuokkaAdminView extends Vue {
  /**
   * The currently logged in {@link Person}.
   */
  @Prop({ required: true }) loggedInPerson!: Person;

  /**
   * Whether navigation should be hidden.
   */
  get hideNavigation(): boolean {
    // Placeholder if needed in the future
    return false;
  }

  mounted(): void {
    if (localStorage.getItem("dataUpdateNeeded") == "true") {
      this.$router.push({
        name: "change-person-data",
      });
    }
  }

  get actions(): {
    title: string;
    route?: RawLocation;
    action?: () => void;
  }[] {
    const actions = [];
    if (FrontendSettings.applicationSettings.activateEventsRecording) {
      actions.push({
        title: FrontendSettings.eventRecording.titleRecordingEvents,
        route: { name: "my-events" },
      });
    }

    if (FrontendSettings.applicationSettings.activateArticles) {
      actions.push({
        title: FrontendSettings.articleRecording.titleRecordingArticles,
        route: { name: "my-articles" },
      });
    }

    if (FrontendSettings.applicationSettings.activateAdverts) {
      actions.push({
        title: FrontendSettings.advertSettings.titleRecordingAdverts,
        route: { name: "my-ads" },
      });
    }

    actions.push({
      title: FrontendSettings.authenticatedArea.titleMyProfile,
      route: { name: "my-profile" },
    });
    actions.push({ title: "Abmelden", action: this.logout });
    return actions;
  }

  /**
   * Logs out the user and forwards it to the login screen.
   * TODO: Where to forward?
   * TODO: Add logout-reason
   */
  logout(): void {
    Authorization.clearToken();
    this.$router.push({
      name: "events",
    });
  }
}
