
import { Component, Vue } from "vue-property-decorator";
import AuthView from "@/views/layouts/quokka/auth/AuthView.vue";
import { login, LoginResponseType } from "@/frontend/lib/api";
import FrontendLogger from "@/frontend/lib/logger";
import RouteHelper from "@/frontend/lib/route_helper";
import Authorization from "@/frontend/lib/auth";

/**
 * Possible reasons, why the user is redirected to the {@link QuokkaLoginView}.
 * This {@link LogoutReason} can be attached to the route in the query like logout=REASON.
 */
export enum LogoutReason {
  NO_TOKEN = "no-token",
  EXPIRED = "expired",
  UNKNOWN_ERROR = "unknown-error",
}

/**
 * Available error codes to display a specific error message.
 */
type ErrorCode =
  | "invalid-credentials"
  | "person-blocked"
  | "person-not-verified"
  | "invalid-data"
  | "e-mail-not-confirmed"
  | "unknown-error";

/**
 * The login-{@link AuthView} for the quokka layout to login into the admin-area.
 */
@Component({
  components: { AuthView },
})
export default class QuokkaLoginView extends Vue {
  /** Clientside data validation */
  formValid = false;
  /** Username for Login */
  username: string | null = "";
  /** Password for Login */
  password: string | null = "";
  /** if password is displayed in clear text */
  showPassword = false;
  /** Variable to display loading animations and disable form */
  loading = false;
  /** rules for required fields */
  required = [(value: string): boolean | string => !!value || "Pflichtfeld"];

  /**
   * The snackbar message to show according to {@link LogoutReason} given in route.
   */
  logoutReasonSnackbar = "";

  /**
   * An error code to display a specific error message.
   */
  errorCode: ErrorCode | null = null;

  /**
   * Whether there is an error message to show in a snackbar or not.
   */
  error = false;

  /**
   * The error message to display in the snackbar.
   */
  get errorMessage(): string {
    switch (this.errorCode) {
      case "invalid-data":
        return "Bitte geben Sie Ihren Benutzernamen und Passwort ein.";
      case "invalid-credentials":
        return "Login oder Passwort sind nicht korrekt.";
      case "person-blocked":
        return "Dieser Account ist deaktivert.";
      case "person-not-verified":
        return "Dieser Account wurde noch nicht freigeschaltet.";
      case "e-mail-not-confirmed":
        return "Ihre E-Mail Adresse ist noch nicht bestätigt.";
      default:
        return "Irgendetwas ist schief gegangen.";
    }
  }

  mounted(): void {
    const userName =
      RouteHelper.getQueryParam<string>(this.$route, "u")?.trim() ?? "";
    if (userName) {
      this.username = userName;
    }

    const reason = RouteHelper.getQueryParam(this.$route, "logout");

    switch (reason) {
      case LogoutReason.NO_TOKEN:
        this.logoutReasonSnackbar = "Sie sind noch nicht angemeldet.";
        break;
      case LogoutReason.EXPIRED:
        this.logoutReasonSnackbar = "Ihre Anmeldung ist abgelaufen.";
        break;
      case LogoutReason.UNKNOWN_ERROR:
        this.logoutReasonSnackbar = "Ein unbekannter Fehler ist aufgetreten.";
        break;
      default:
        this.logoutReasonSnackbar = "";
    }

    // If we got a reason, we delete the query param from route
    if (this.logoutReasonSnackbar) {
      this.$router.replace({
        name: "login",
      });
    }
  }

  /** Submit login information to backend */
  onSubmit(): void {
    this.error = false;
    this.errorCode = null;

    if (this.username !== null && this.password !== null) {
      this.loading = true;
      login({ username: this.username, password: this.password })
        .then((authorization: LoginResponseType) => {
          // Login successful
          // Save Token
          Authorization.setToken(
            authorization.token,
            authorization.expire_datetime
          );
          // push to admin area, either to main area or to the agreement view, if data is missing
          if (authorization.conditions) {
            this.$router.push({
              name: "secure",
            });
          } else {
            // TODO: This should not happen like that
            // It should be checked whether data is missing or not for every request and then reject it.
            // Rejected requests should always forward to the my-profile page (or a specific page for that) which
            // should prevent that the user can get anywhere else
            // Current Workaround:
            // Set localStorage item 'dataUpdateNeeded' to 'true'
            // As long as this item is set, AdminView and others should redirect to QuokkaPersonDataChangeView
            localStorage.setItem("dataUpdateNeeded", "true");
            this.$router.push({
              name: "change-person-data",
            });
          }
        })
        .catch((e) => {
          this.errorCode = "unknown-error";

          if (e.response && e.response.data && e.response.data.error_code) {
            // We got an error code, see documentation for available codes we can get
            switch (e.response.data.error_code) {
              case 5:
                this.errorCode = "invalid-credentials";
                break;
              case 1:
                this.errorCode = "person-blocked";
                break;
              case 2:
                this.errorCode = "invalid-data";
                break;
              case 3:
                // E-Mail not confirmed => Push route to view to request a new confirmation link
                this.$router.push({
                  name: "request-confirm-mail",
                  params: {
                    username: this.username ?? "",
                  },
                });
                this.errorCode = "e-mail-not-confirmed";
                break;
              case 4:
                this.errorCode = "person-not-verified";
                break;
              default:
                this.errorCode = "unknown-error";
                break;
            }
          }
          FrontendLogger.error({ message: "Failed to login.", data: e });

          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
