
import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";
import Quill, { QuillOptionsStatic } from "quill";
import Delta from "quill-delta";
import { sanitizeContent } from "@/shared/lib/dompurify";

export type QuillOptions = {
  modules: {
    toolbar: ("bold" | "italic" | string)[][];
  };
};

/**
 * A component textarea component using Quill Editor
 */
@Component({
  data() {
    return { sanitizeContent };
  },
})
export default class QuillEditor extends Vue {
  /**
   * The input value.
   */
  @VModel({ required: true }) model!: string;

  /**
   * The {@link QuillOptions}.
   */
  @Prop({ required: true }) options!: Partial<QuillOptionsStatic> &
    Partial<QuillOptions>;

  /**
   * disable the editor
   */
  @Prop() disabled!: boolean;

  /**
   * disable the editor
   */
  @Prop() placeholder!: string;

  /**
   * label of the editor
   */
  @Prop() label!: string;

  /**
   * tooltip of the editor
   */
  @Prop() tooltip!: string;

  /**
   * max number of characters
   */
  @Prop() maxlength!: number;

  /**
   * min number of characters
   */
  @Prop() minlength!: number;

  /**
   * show counter
   */
  @Prop() counter!: number;

  /**
   * the editor needs to be filled out
   */
  @Prop() required!: boolean;

  /**
   * the text must match the regex
   */
  @Prop() regextest!: string;

  /**
   * id of the editor
   */
  editorId: string;

  /**
   * actual Character count
   */
  count: number;

  /**
   * error Message
   */
  errorMsg: string;

  /**
   * The Quill Editor object.
   */
  editor!: Quill;

  constructor() {
    super();

    // unique id for the editor
    this.editorId = "editor_" + Math.random().toString(36).substr(2, 9);
    this.count = 0;
    this.errorMsg = "";
  }

  mounted(): void {
    this.editor = new Quill(
      "#" + this.editorId,
      Object.assign(
        {
          theme: "snow",
          bounds: "editor_bounds",
          placeholder: this.placeholder,
        },
        this.options
      )
    );

    this.editor.on("text-change", () => {
      if (this.counter) this.count = this.editor.getText().length - 1;
      this.errorMsg = "";
      if (this.required && this.editor.getText().length - 1 == 0) {
        this.errorMsg = "Pflichtfeld";
      }
      if (
        this.maxlength &&
        this.maxlength > 0 &&
        this.editor.getText().length - 1 > this.maxlength
      ) {
        this.errorMsg = "Eingabe überschreitet Maximallänge";
      }
      if (
        this.minlength &&
        this.minlength > 0 &&
        this.editor.getText().length - 1 < this.minlength
      ) {
        this.errorMsg = "Eingabe unterschreitet Minimallänge";
      }
      if (
        this.regextest &&
        this.regextest.length > 0 &&
        !this.editor.getText().match(this.regextest)
      ) {
        this.errorMsg = "Keine korrekte Angabe";
      }
      if (this.errorMsg == "" || this.editor.getText().length <= 1) {
        // Get current string value
        const value = this.editor.root.innerHTML;
        //console.log("setModel", value.toString());
        this.model = value.toString().trim();
      }
    });

    // Convert pasted content into plain text to remove formatting
    // If we want to keep specific formats like bold etc, we could manually check for that in the nodes
    // This is currently done to avoid unwanted or strange formatting from other apps like Word.
    this.editor.clipboard.addMatcher(Node.ELEMENT_NODE, function (node) {
      const plaintext = node.innerText + "\n";
      return new Delta().insert(plaintext);
    });
  }

  @Watch("disabled")
  onDisabledChange(): void {
    if (this.disabled == true) {
      this.editor.disable();
    } else {
      this.editor.enable();
    }
  }
}
