import { StringValueListItem } from "@/shared/data/value_list";
import Tag from "@/shared/data/tag";
import Rubric from "@/shared/data/rubric";

/**
 * Contains data queried from the api for the fields.
 *
 * Used to load all required values on mount and cache them.
 */
export class EventRecordingFieldData {
  /**
   * Available {@link Rubric}s to select from.
   */
  rubrics: Rubric[] = [];

  /**
   * Available priority-values to select from.
   */
  priorities: StringValueListItem[] = [];

  /**
   * Available {@link Tag}s to select from.
   */
  tags: Tag[] = [];
}
