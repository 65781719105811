import Vue from "vue";
import { Settings } from "@/shared/data/settings";

/**
 * The abstract Settings class provides functions to get and set Settings for the frontend usage.
 *
 * The settings will be fetched from the connected MSUeventv5-API-Server endpoint at /onlineClientSettings.
 */
export default abstract class FrontendSettings extends Settings {
  private static _loadingPromise?: Promise<void>;

  /**
   * Returns set host url for this instance.
   * This host is used everytime the node-server is being requested.
   * If the Vue-application is called from the host of the server itself, the host needs to be `/`.
   * If the application is implemented in another host, this host has to be set to node-servers host.
   *
   * Host can be set in the implementation html-element in the "host" attribute:
   * `<div id="msu-event-online" host="<%= BASE_URL%>"></div>`
   *
   * If not set, it defaults to `/`.
   *
   * The returned host always contains a / at the end.
   */
  static get host(): string {
    return this.overrideHost && this.overrideHost > ""
      ? this.overrideHost
      : Vue.prototype.$host;
  }

  /**
   * Returns set embed-path for this instance.
   * This is the path used as base path for the router and when generating redirect links.
   *
   * The path will be taken from `window.location.pathname`.
   *
   * Example:
   * If the application is embedded at `host.de/`, the embed-path is `/`.
   * If the application is embedded at `host.de/content/`, the embed-path is `/content/`.
   *
   * If not set, it defaults to `/`.
   *
   * The returned path always contains a / at the end.
   */
  static get embedPath(): string {
    let path = window.location.pathname;
    if (path === "") {
      path = "/";
    }

    // Add / to the end
    if (!/\/$/.test(path)) path += "/";

    return path;
  }

  /**
   * Tries to load the settings from json-file in public directory.
   * If the settings file has already been loaded, the promise is resolved instantly.
   */
  static async load(): Promise<void> {
    if (this._loadingPromise) return this._loadingPromise;
    this._loadingPromise = new Promise<void>((resolve, reject) => {
      fetch(FrontendSettings.host + "api/client-settings")
        .then((response) => response.json())
        .then((settings) => {
          if (Object.keys(settings).length > 0) {
            this.loadFrom(settings);
            resolve();
          } else {
            reject("Failed to get frontend settings from api.");
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
    return this._loadingPromise;
  }

  /**
   * Whether the api-server is currently under maintenance or not.
   */
  public static maintenance = false;

  /**
   * Bound callbacks to call when the maintenance is enabled during a request.
   */
  private static maintenanceActiveCallbacks: (() => void)[] = [];

  /**
   * Binds a callback to be called when the maintenance is being enabled.
   *
   * @param callback
   */
  public static onMaintenanceActive(callback: () => void): void {
    this.maintenanceActiveCallbacks.push(callback);
  }

  /**
   * Enables the maintenance and calls all bound callbacks.
   */
  public static enableMaintenance(): void {
    this.maintenance = true;
    this.maintenanceActiveCallbacks.forEach((callback) => {
      callback();
    });
  }
}
