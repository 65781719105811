import { render, staticRenderFns } from "./QuokkaLayoutView.vue?vue&type=template&id=007b3d60&scoped=true&"
import script from "./QuokkaLayoutView.vue?vue&type=script&lang=ts&"
export * from "./QuokkaLayoutView.vue?vue&type=script&lang=ts&"
import style0 from "./QuokkaLayoutView.vue?vue&type=style&index=0&id=007b3d60&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "007b3d60",
  null
  
)

export default component.exports