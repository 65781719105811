
/** View for Media Upload, including texts and data for each uploaded media */
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { MediaUploaderData } from "@/shared/lib/media_uploader";
import MediaUploader from "@/components/ui/MediaUploader.vue";
import { loadImageFromEvent, MediaInfoReturnType } from "@/frontend/lib/api";
import {
  DisplayInformationDataType,
  MediaInformationData,
} from "@/shared/data/media_information";
@Component({
  components: { MediaUploader },
})
export default class QuokkaMediaUpload extends Vue {
  /** Whether the View is disabled or not */
  @Prop() disabled!: boolean;
  /** Additional image information fields */
  @Prop({ required: true }) mediaConfigData!: {
    [key: string]: { [key: string]: { [key: string]: unknown } };
  };
  /** The type of media upload */
  @Prop({ required: true }) mediaType!: "event" | "article" | "ads";
  /** Data for the media Upload */
  @Prop({ required: true }) mediaUploaderData!: MediaUploaderData;
  /** Max number of files */
  @Prop({ default: 10 }) maxFiles!: number;
  /** Loaded Media Files */
  @Prop({ required: true }) availableMedia!: DisplayInformationDataType[];
  /** Type of Content, either article or event */
  @Prop({ default: "event" }) origin!: string;

  rules: { [key: string]: (value: string) => boolean | string } = {
    required: (value: string): boolean | string => {
      if (!value || value.trim() === "") {
        return "Pflichtfeld";
      }
      return true;
    },
  };

  /**
   * Get the title from available data, prefers the given title
   * @param media
   */
  getMediaTitle(media: MediaInformationData): string {
    if (media.title && media.title !== "") {
      return media.title;
    }
    if (media.upload_key && media.upload_key !== "") {
      return media.upload_key.substring(33);
    }
    if (media.media_id && media.media_id > 0) {
      return "";
    }
    return "";
  }

  /** Add information tabs for newly uploaded media */
  @Watch("mediaUploaderData.upload_media", {
    deep: true,
    immediate: true,
  })
  updateUploadData(): void {
    let addedMedia: DisplayInformationDataType[] = [];

    // check all upload media and compare with those already in availableMedia
    this.mediaUploaderData.upload_media.forEach((media) => {
      let newMedia = true;
      this.availableMedia.forEach((available) => {
        if (available.upload_key == media) {
          newMedia = false;
        }
      });
      if (newMedia) {
        let data = {
          media_id: 0,
          upload_key: media,
          title: null,
          desc: null,
          author: null,
          copyright: null,
          agency: null,
          location: null,
          width: null,
          height: null,
          mimetype: null,
          base64: null,
        };
        addedMedia.push(data);
      }
    });

    // remove deleted media from tabs
    this.availableMedia.forEach((media, key) => {
      // check for removed upload media
      if (media.media_id == 0) {
        let deleted = true;
        this.mediaUploaderData.upload_media.forEach((upload) => {
          if (upload == media.upload_key) deleted = false;
        });
        if (deleted) this.availableMedia.splice(key, 1);
      }
    });

    // add new uploadMedia
    addedMedia.forEach((media) => {
      this.availableMedia.push(media);
    });
  }

  /** Update information tabs for uploaded media */
  @Watch("mediaUploaderData.uploaded_media", { deep: true, immediate: true })
  updateUploadedData(): void {
    this.availableMedia.forEach((media, key) => {
      // check for deleted uploaded media
      this.mediaUploaderData.uploaded_media.forEach((uploaded) => {
        if (uploaded.media_id == media.media_id) {
          if (uploaded.isToDelete()) {
            this.availableMedia.splice(key, 1);
          }
        }
      });
    });

    // get information for already uploaded media
    this.mediaUploaderData.uploaded_media.forEach((mediaData) => {
      let newMedia = true;

      // Check if already in availableMedia
      this.availableMedia.forEach((media) => {
        if (media.media_id == mediaData.media_id) {
          newMedia = false;
        }
      });

      if (newMedia && !mediaData.isToDelete()) {
        loadImageFromEvent({
          tableName: this.origin,
          load: mediaData.media_id,
        })
          .then((response: MediaInfoReturnType) => {
            this.mediaUploaderData.additionalImageInformation.forEach(
              (information) => {
                if (
                  information.media_id &&
                  mediaData.media_id == information.media_id
                ) {
                  this.availableMedia.push({
                    media_id: information.media_id,
                    upload_key: information.upload_key,
                    title: information.title,
                    desc: information.desc,
                    author: information.author,
                    copyright: information.copyright,
                    agency: information.agency,
                    location: information.location,
                    width: response.width,
                    height: response.height,
                    mimetype: response.mimetype,
                    base64: response.base64,
                  });
                }
              }
            );
          })
          .catch((e) => {
            console.error(e);
          });
      }
    });
  }
}
