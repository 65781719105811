
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import {
  AdvertCampaign,
  AdvertChannel,
  AdvertMedium,
  getChannels,
} from "@/shared/data/advert";
import { sanitizeContent } from "@/shared/lib/dompurify";

@Component({
  data() {
    return { sanitizeContent };
  },
})
export default class QuokkaAdsRecordingChannelSelectionView extends Vue {
  @Prop({ required: true }) campaigns!: AdvertCampaign[];
  @Prop({ required: true }) selectedMedium!: AdvertMedium;

  /**
   * Returns all available AdsMediums of all AdsCampaigns.
   */
  get availableChannels(): AdvertChannel[] {
    return getChannels(this.campaigns, this.selectedMedium);
  }

  /**
   * Set the selected channel.
   *
   * @param channel
   */
  @Emit("channel-select")
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  selectChannel(channel: AdvertChannel): void {
    return;
  }
}
