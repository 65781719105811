
import { Component, Prop, Vue } from "vue-property-decorator";
import Event from "@/shared/data/event";
import TimeInfo from "@/shared/data/time_info";
import { DateTime } from "luxon";
import { formatDate, formatTime, parseDate } from "@/shared/lib/datetime";
import EventDate from "@/shared/data/event_date";

/**
 * Type of calendar item.
 */
type CalendarDate = {
  name?: string;
  start?: string;
  end?: string;
  color: string;
  timed: boolean;
  eventTime: TimeInfo;
};

/**
 * Type to work around IDE-errors because of "undefined function".
 */
type CalendarComponentType = {
  checkChange(): void;
  next(): void;
  prev(): void;
};

/**
 * A component to build a calendar to show {@link EventDate} and their {@link TimeInfo} for given {@link Event}.
 */
@Component
export default class QuokkaEventDetailCalendar extends Vue {
  /**
   * The {@link Event} for which the {@link QuokkaEventDetailCalendar} is built.
   */
  @Prop({ required: true }) event!: Event;

  /**
   * The focused date of this calendar.
   */
  focus = "";

  /**
   * Whether the next date is currently visible or not.
   */
  nextDateVisible = true;

  /**
   * The shown {@link CalendarDate}s.
   * Will be filtered by the {@link updateRange} function
   */
  calendarDates: CalendarDate[] = [];

  /**
   * The {@link EventDate}s of the currently shown {@link CalendarDate}s.
   * Used for performant search.
   */
  shownEventDates: EventDate[] = [];

  /**
   * The {@link CalendarDate} to show inside the time popup.
   */
  detailTime: CalendarDate | null = null;

  /**
   * Whether to show the time detail popup or not.
   */
  showTimeDetailPopup = false;

  /**
   * The {@link EventDate} to show inside the date popup.
   */
  detailDate: EventDate | null = null;

  /**
   * Whether to show the date detail popup or not.
   */
  showDateDetailPopup = false;

  /**
   * The target DOM-Element to bind any popup to this location.
   */
  selectedElement: EventTarget | null = null;

  mounted(): void {
    (this.$refs?.calendar as unknown as CalendarComponentType)?.checkChange();

    // Focus on first EventDate
    this.focusNextDate();
  }

  /**
   * Focuses the calendar to today.
   */
  focusToday(): void {
    this.focus = "";
  }

  /**
   * Focuses the calendar to the next date.
   */
  focusNextDate(): void {
    this.focus =
      formatDate(this.event.nextEventDate?.dateObject, "yyyy-MM-dd") ?? "";
  }

  /**
   * Jumps to the next month.
   */
  prev(): void {
    (this.$refs?.calendar as unknown as CalendarComponentType)?.prev();
  }

  /**
   * Jumps to the previous month.
   */
  next(): void {
    (this.$refs?.calendar as unknown as CalendarComponentType)?.next();
  }

  /**
   * Opens the time detail popup.
   *
   * @param nativeEvent
   * @param event
   */
  openTimeDetailPopup({
    nativeEvent,
    event,
  }: {
    nativeEvent: PointerEvent;
    event: CalendarDate;
  }): void {
    const open = () => {
      this.showDateDetailPopup = false;

      this.detailTime = event;
      this.selectedElement = nativeEvent.target;
      requestAnimationFrame(() =>
        requestAnimationFrame(() => (this.showTimeDetailPopup = true))
      );
    };

    if (this.showTimeDetailPopup) {
      this.showTimeDetailPopup = false;
      requestAnimationFrame(() => requestAnimationFrame(() => open()));
    } else {
      open();
    }

    nativeEvent.stopPropagation();
  }

  /**
   * Opens the date detail popup.
   *
   * @param nativeEvent
   * @param event
   */
  openDateDetailPopup(
    { date }: { date: string },
    nativeEvent: PointerEvent
  ): void {
    this.focus = date;

    const open = () => {
      this.showTimeDetailPopup = false;

      const detailDate = this.shownEventDates.find(
        (obj) => obj.formatDate("yyyy-MM-dd") == date
      );
      if (!detailDate) {
        console.error(
          "Failed to open date details. Could not corresponding find EventDate."
        );
        return;
      }

      // Check, if we got any times for this date
      if (detailDate.timeInfo.length <= 0) {
        return;
      }

      this.detailDate = detailDate;
      this.selectedElement = nativeEvent.target;
      requestAnimationFrame(() =>
        requestAnimationFrame(() => (this.showDateDetailPopup = true))
      );
    };

    if (this.showDateDetailPopup) {
      this.showDateDetailPopup = false;
      requestAnimationFrame(() => requestAnimationFrame(() => open()));
    } else {
      open();
    }

    nativeEvent.stopPropagation();
  }

  /**
   * Filters the visible dates and sets them into the dates-array.
   *
   * @param start
   * @param end
   */
  updateRange({
    start,
    end,
  }: {
    start: { date: string };
    end: { date: string };
  }): void {
    const dates: CalendarDate[] = [];
    const eventDates: EventDate[] = [];

    const rangeStartDate = formatDate(
      parseDate(start.date, "yyyy-MM-dd")?.minus({ day: 7 }),
      "yyyy-MM-dd"
    );

    const rangeEndDate = formatDate(
      parseDate(end.date, "yyyy-MM-dd")?.plus({ day: 7 }),
      "yyyy-MM-dd"
    );

    // Foreach date
    for (const date of this.event.dates) {
      let formattedDate = undefined;
      if (
        date.dateObject &&
        (formattedDate = formatDate(date.dateObject, "yyyy-MM-dd"))
      ) {
        // Check, if this date is in the range to filter
        if (rangeStartDate && rangeStartDate > formattedDate) {
          // This date before range
          continue;
        } else if (rangeEndDate && formattedDate > rangeEndDate) {
          // This date (and all coming next) are after the range
          break;
        }

        // Add EventDate
        eventDates.push(date);

        // Foreach time
        for (const timeInfo of date.timeInfo) {
          // Get from datetime
          let start = DateTime.fromObject(date.dateObject.toObject());
          let end: DateTime | undefined = undefined;

          if (!timeInfo.full && timeInfo.fromTime) {
            // Add the "from" time to the "start"-datetime
            start = start.set({
              minute: timeInfo.fromTime.minute,
              hour: timeInfo.fromTime.hour,
            });

            // Check for end-time
            if (timeInfo.untilTime) {
              end = DateTime.fromObject(date.dateObject.toObject());
              end = end.set({
                minute: timeInfo.untilTime.minute,
                hour: timeInfo.untilTime.hour,
              });
            }

            dates.push({
              name: end ? " - " + formatTime(end, "HH:mm") : undefined,
              eventTime: timeInfo,
              start: formatDate(start, "yyyy-MM-dd HH:mm"),
              end: formatDate(end, "yyyy-MM-dd HH:mm"),
              color: "primary",
              timed: true,
            });
          } else {
            // Full day
            dates.push({
              name: "ganztags",
              eventTime: timeInfo,
              start: formatDate(start, "yyyy-MM-dd"),
              color: "primary",
              timed: false,
            });
          }
        }
      }
    }

    this.calendarDates = dates;
    this.shownEventDates = eventDates;

    // Check, if the next date is currently visible in this month
    const startDate = parseDate(start.date, "yyyy-MM-dd");
    const nextDate = this.event.nextEventDate?.dateObject;
    this.nextDateVisible =
      !startDate ||
      !nextDate ||
      (nextDate.month === startDate.month && nextDate.year === startDate.year);
  }

  /**
   * Returns the label for the given {@link TimeInfo}.
   * The label includes the time, when the event takes place.
   *
   * @param timeInfo
   */
  getTimeInfoLabel(timeInfo: TimeInfo): string {
    // Full day, if given so or neither from nor until time are available
    if (timeInfo.full || (!timeInfo.fromTime && !timeInfo.untilTime)) {
      return "ganztags";
    }

    // Start time
    let title = formatTime(timeInfo.fromTime, "HH:mm") ?? "";

    let endTimeFormatted = undefined;
    if (
      timeInfo.untilTime &&
      (endTimeFormatted = formatTime(timeInfo.untilTime, "HH:mm"))
    ) {
      // Add "to" label
      title += title > "" ? " bis " : "Bis ";

      // Add to time
      title += endTimeFormatted;
    } else {
      // We only got a start date => Add a "from" label
      title = "Ab " + title;
    }

    // Add suffix
    title += " Uhr";

    return title;
  }
}
