var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_vm._t("actions",null,{"availableRecordingEvents":_vm.availableRecordingEvents}),(_vm.loading === false && _vm.availableRecordingEvents !== null)?_c('div',{staticClass:"mt-4"},[(_vm.eventListAdminDisplayType !== 'ads-event-selection')?_c('div',{staticClass:"d-flex justify-space-between"},[_c('v-btn',{staticClass:"mr-10",attrs:{"disabled":_vm.loading,"color":"primary","large":""},on:{"click":function($event){return _vm.openFilter()}}},[_c('v-icon',{attrs:{"left":"","size":"25px"}},[_vm._v(" mdi-filter-variant ")]),_vm._v(" Filter ")],1),(_vm.totalEventsAmount > 0)?_c('b',[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.totalEventsAmount))]),_vm._v(" "+_vm._s(_vm.totalEventsAmount === 1 ? "Ergebnis" : "Ergebnisse")+" ")]):_vm._e(),(
          (_vm.availableRecordingEvents === null ||
            _vm.availableRecordingEvents.length > 0) &&
          _vm.eventListAdminDisplayType !== 'ads-event-selection'
        )?_c('router-link',{staticClass:"ma-0 pa-0",attrs:{"to":{ name: 'events-recording' }}},[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.availableRecordingEvents === null,"x-large":""}},[_vm._v(" "+_vm._s(_vm.titleButton)+" ")])],1):_vm._e()],1):(_vm.eventListAdminDisplayType === 'ads-event-selection')?_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{staticClass:"mr-10",attrs:{"disabled":_vm.loading,"color":"primary","large":""},on:{"click":function($event){return _vm.openFilter()}}},[_c('v-icon',{attrs:{"left":"","size":"25px"}},[_vm._v(" mdi-filter-variant ")]),_vm._v(" Filter ")],1),(_vm.totalEventsAmount > 0)?_c('b',[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.totalEventsAmount))]),_vm._v(" "+_vm._s(_vm.totalEventsAmount === 1 ? "Ergebnis" : "Ergebnisse")+" ")]):_vm._e()],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"mt-5 mb-5"},_vm._l((_vm.filter.getActiveFilterLabels()),function(activeFilter){return _c('v-chip',{key:activeFilter.filterName,staticClass:"mr-3",attrs:{"close":""},on:{"click:close":() => {
          activeFilter.clear();
          _vm.clearChip();
        }}},[_vm._v(_vm._s(activeFilter.label))])}),1),(
      _vm.availableRecordingEvents &&
      _vm.availableRecordingEvents.length > 0 &&
      _vm.loading === false
    )?_c('v-list',{attrs:{"elevation":_vm.elevation}},[_c('div',_vm._l((_vm.availableRecordingEvents),function(recordingEvent,i){return _c('div',{key:i},[(_vm.eventListAdminDisplayType === 'ads-event-selection')?_c('div',[(i !== 0)?_c('v-divider'):_vm._e(),_c('QuokkaEventListAdminMyEventListItem',{attrs:{"recording-event":recordingEvent,"event-list-admin-display-type":_vm.eventListAdminDisplayType},on:{"event-select":_vm.onEventSelect,"event-deleted":_vm.removeEventFromAvailableList}})],1):(_vm.eventListAdminDisplayType !== 'ads-event-selection')?_c('div',[(i !== 0)?_c('v-divider'):_vm._e(),_c('QuokkaEventListAdminMyEventListItem',{attrs:{"recording-event":recordingEvent,"event-list-admin-display-type":_vm.eventListAdminDisplayType},on:{"event-select":_vm.onEventSelect,"event-deleted":_vm.removeEventFromAvailableList}})],1):_vm._e()])}),0)]):_vm._e(),(_vm.loading === true)?_c('v-list',{attrs:{"elevation":_vm.elevation}},[_c('div',_vm._l((5),function(i){return _c('div',{key:i},[(i !== 1)?_c('v-divider'):_vm._e(),_c('QuokkaEventListAdminMyEventListItem',{attrs:{"recording-event":null,"event-list-admin-display-type":_vm.eventListAdminDisplayType}})],1)}),0)]):_vm._e(),(_vm.totalPageNumber > 1)?_c('v-pagination',{attrs:{"length":_vm.totalPageNumber,"circle":"","total-visible":"7"},model:{value:(_vm.pageNumber),callback:function ($$v) {_vm.pageNumber=$$v},expression:"pageNumber"}}):_vm._e(),_c('v-navigation-drawer',{staticClass:"filter-drawer",attrs:{"stateless":"","absolute":"","width":"450px"},model:{value:(_vm.showFilterDrawer),callback:function ($$v) {_vm.showFilterDrawer=$$v},expression:"showFilterDrawer"}},[_c('div',{staticClass:"grey lighten-2 pa-5 d-flex justify-space-between align-center"},[_c('v-icon',{attrs:{"size":"25px"},on:{"click":function($event){_vm.showFilterDrawer = false}}},[_vm._v("mdi-close")]),_c('b',{staticClass:"d-flex"},[_c('div',{class:'mr-2 ' +
            (_vm.totalFilterEventsAmountPreviewLoading
              ? 'grey--text'
              : 'primary--text'),staticStyle:{"transition":"all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)"}},[_vm._v(" "+_vm._s(_vm.totalFilterEventsAmountPreview)+" ")]),_vm._v(" "+_vm._s(_vm.totalFilterEventsAmountPreview === 1 ? "Ergebnis" : "Ergebnisse")+" ")]),_c('v-btn',{attrs:{"disabled":_vm.eventsLoading,"color":"primary","large":""},on:{"click":_vm.search}},[_c('v-icon',{attrs:{"left":"","size":"25px"}},[_vm._v(" mdi-magnify ")]),_vm._v(" Suchen ")],1)],1),_c('QuokkaEventListFilterContent',{attrs:{"filter":_vm.changingFilter}}),_c('div',{staticClass:"px-10"},[_c('v-btn',{attrs:{"disabled":_vm.eventsLoading || !_vm.changingFilter.hasActiveFilter(),"block":"","color":"primary","outlined":"","x-large":""},on:{"click":_vm.resetFilter}},[_c('v-icon',{attrs:{"left":"","size":"25px"}},[_vm._v(" mdi-reload ")]),_vm._v(" Filter zurücksetzen ")],1)],1)],1),(
      _vm.loading === false &&
      _vm.eventListAdminDisplayType === 'event-list' &&
      _vm.availableRecordingEvents !== null &&
      _vm.availableRecordingEvents.length === 0
    )?_c('AlertCard',{attrs:{"type":"information","icon":null,"text":"Sie haben noch keine Veranstaltung erfasst","text-size":"h5","action":{
      label: _vm.titleButton,
      button: {
        to: { name: 'events-recording' },
      },
    }}}):_vm._e(),(
      _vm.loading === false &&
      _vm.eventListAdminDisplayType === 'ads-event-selection' &&
      _vm.availableRecordingEvents !== null &&
      _vm.availableRecordingEvents.length === 0
    )?_c('AlertCard',{attrs:{"type":"information","icon":null,"text":"Sie haben noch keine Veranstaltung erfasst","text-size":"h5","action":{
      label: _vm.titleButton,
      button: {
        callback: () => _vm.onEventSelect('no_event'),
      },
    }}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }