
import { Component, Vue } from "vue-property-decorator";
import QuokkaAdminView from "@/views/layouts/quokka/admin/QuokkaAdminView.vue";
import { getAdvertCampaigns } from "@/frontend/lib/api";
import {
  AdvertCampaign,
  AdvertChannel,
  AdvertContent,
  AdvertMedium,
  AdvertSlot,
  getChannels,
  OrderAddress,
  OrderItem,
} from "@/shared/data/advert";
import QuokkaAdsRecordingMediumSelectionView from "@/views/layouts/quokka/admin/ads/QuokkaAdsRecordingMediumSelectionView.vue";
import QuokkaAdsRecordingChannelSelectionView from "@/views/layouts/quokka/admin/ads/QuokkaAdsRecordingChannelSelectionView.vue";
import QuokkaAdsRecordingSlotSelectionView from "@/views/layouts/quokka/admin/ads/QuokkaAdsRecordingSlotSelectionView.vue";
import QuokkaAdsRecordingWhenView from "@/views/layouts/quokka/admin/ads/QuokkaAdsRecordingWhenView.vue";
import QuokkaAdsRecordingEventSelection from "@/views/layouts/quokka/admin/ads/QuokkaAdsRecordingEventSelection.vue";
import QuokkaAdsRecordingContent from "@/views/layouts/quokka/admin/ads/QuokkaAdsRecordingContent.vue";
import QuokkaAdsRecordingOverview from "@/views/layouts/quokka/admin/ads/QuokkaAdsRecordingOverview.vue";
import QuokkaAdsRecordingBillingAddress from "@/views/layouts/quokka/admin/ads/QuokkaAdsRecordingBillingAddress.vue";
import QuokkaAdsRecordingExistingEvent from "@/views/layouts/quokka/admin/ads/QuokkaAdsRecordingExistingEvent.vue";
import QuokkaAdsRecordingNewEvent from "@/views/layouts/quokka/admin/ads/QuokkaAdsRecordingNewEvent.vue";
import RecordingEvent from "@/shared/data/recording_event";
import FrontendSettings from "@/frontend/settings/settings";
import QuokkaAdsLandingPage from "@/views/layouts/quokka/admin/ads/QuokkaAdsLandingPage.vue";
import QuokkaAdsSuccessBookingOverview from "@/views/layouts/quokka/admin/ads/QuokkaAdsSuccessBookingOverview.vue";
import { sanitizeContent } from "@/shared/lib/dompurify";

/**
 * Type definition of the tab definition
 */
type TabDefinition = {
  /**
   * The {@link Tab} index.
   */
  index: Tab;
  /**
   * The title to display for this tab.
   */
  title: string;
  /**
   * The title to display for this tab.
   */
  subtitle?: string;
  /**
   * Function called, when the user clicks the "back" button.
   *
   * This function should reset set data accordingly and set the {@link activeTabIndex}.
   */
  undo?: () => void;
  /**
   * The progress index
   */
  progressIndex: number;
  /**
   * Show or hide back button
   */
  backBtn: boolean;
};

/**
 * Available Tabs for the AdsRecording.
 */
enum Tab {
  /**
   * Tab to select an {@link AdvertMedium}.
   */
  SELECT_MEDIUM,
  /**
   * Tab to select an {@link AdvertChannel}.
   */
  SELECT_CHANNEL,
  /**
   * Tab to select an {@link AdvertSlot}.
   */
  SELECT_SLOT,
  /**
   * Tab to build {@link OrderItem}.
   */
  SELECT_WHEN,
  /**
   * Tab to select an event for advertising.
   */
  SELECT_EVENT_SELECTION,
  /**
   * Tab to select an existing event for advertising.
   */
  SELECT_EXISTING_EVENT,
  /**
   * Tab to record an event for advertising.
   */
  SELECT_NEW_EVENT,
  /**
   * Tab for recording the advertising content.
   */
  SELECT_CONTENT,
  /**
   * Tab for entering the billing address.
   */
  SELECT_BILLING_ADDRESS,
  /**
   * Tab for the overview of the advertising order.
   */
  ORDER_OVERVIEW,
  /**
   * Tab for landing on this page after order
   */
  LANDING_PAGE,
  /**
   * Tab for success overview of the advertising order.
   */
  SUCCESS_BOOKING_OVERVIEW,
}

/**
 * A view that implements tabs to record an advert.
 */
@Component({
  data() {
    return { Tabs: Tab, sanitizeContent };
  },
  components: {
    QuokkaAdsSuccessBookingOverview,
    QuokkaAdsRecordingNewEvent,
    QuokkaAdsRecordingExistingEvent,
    QuokkaAdsRecordingBillingAddress,
    QuokkaAdsRecordingOverview,
    QuokkaAdsRecordingContent,
    QuokkaAdsRecordingEventSelection,
    QuokkaAdsRecordingWhenView,
    QuokkaAdsRecordingSlotSelectionView,
    QuokkaAdsRecordingMediumSelectionView,
    QuokkaAdsRecordingChannelSelectionView,
    QuokkaAdminView,
    QuokkaAdsLandingPage,
  },
})
export default class QuokkaAdsRecordingView extends Vue {
  /**
   * List of available AdvertCampaigns
   */
  campaigns: AdvertCampaign[] | null = null;

  /**
   * Determines the active tab.
   */
  activeTabIndex = Tab.SELECT_MEDIUM;

  /**
   * The selected AdvertMedium
   */
  selectedMedium: AdvertMedium | null = null;

  /**
   * The selected AdvertChannel
   */
  selectedChannel: AdvertChannel | null = null;

  /**
   * The selected AdvertSlot
   */
  selectedSlot: AdvertSlot | null = null;

  /**
   * The current {@link OrderItem}s.
   */
  currentOrderItems: OrderItem[] = [];

  /**
   * The content of the current {@link OrderItem}s.
   */
  advertContent: AdvertContent | null = null;

  /**
   * The billing address of the logged-in person.
   */
  advertPersonBillingAddress: OrderAddress | null = null;

  /**
   * The different billing address of the logged-in person.
   */
  advertDifferentBillingAddress: OrderAddress | null = null;

  /**
   * The cached event.
   */
  recordingEvent: RecordingEvent | null = null;

  /**
   * gets the assigned AdvertCampaigns from the API
   */
  mounted(): void {
    getAdvertCampaigns()
      .then((advertCampaigns: AdvertCampaign[]) => {
        this.campaigns = advertCampaigns;
      })
      .catch((e) => {
        console.error(e);
      });
  }

  /**
   * Calculates the progress of the progress bar.
   */
  get progress(): number {
    let tab = this.activeTab;

    return (
      (tab.progressIndex /
        this.tabDefinitions[this.tabDefinitions.length - 1].progressIndex) *
      100
    );
  }

  /**
   * Navigate to advert tab.
   *
   * @param adsTabName
   */
  goToAdsTab(adsTabName: string): void {
    switch (adsTabName) {
      case "successOverview":
        this.activeTabIndex = Tab.SUCCESS_BOOKING_OVERVIEW;
    }
  }

  /**
   * Defines the individual Tabs
   */
  tabDefinitions: TabDefinition[] = [
    // Select Medium - Tab
    {
      index: Tab.SELECT_MEDIUM,
      title: FrontendSettings.advertSettings.titleMedium,
      subtitle: FrontendSettings.advertSettings.medium_info_text,
      progressIndex: 1,
      backBtn: false,
    },
    // Select Channel -Tab
    {
      index: Tab.SELECT_CHANNEL,
      undo: this.undoSelectChannel,
      title: FrontendSettings.advertSettings.titleChannel,
      subtitle: FrontendSettings.advertSettings.channel_info_text,
      progressIndex: 2,
      backBtn: true,
    },
    // Select Slot -Tab
    {
      index: Tab.SELECT_SLOT,
      undo: this.undoSlot,
      title: FrontendSettings.advertSettings.titleSlot,
      subtitle: FrontendSettings.advertSettings.slot_info_text,
      progressIndex: 3,
      backBtn: true,
    },
    // Select When -Tab
    {
      index: Tab.SELECT_WHEN,
      undo: this.undoWhen,
      title: FrontendSettings.advertSettings.titleWhen,
      subtitle: FrontendSettings.advertSettings.when_info_text,
      progressIndex: 4,
      backBtn: true,
    },
    // Event selection -Tab
    {
      index: Tab.SELECT_EVENT_SELECTION,
      title: FrontendSettings.advertSettings.titleEventSelection,
      subtitle: FrontendSettings.advertSettings.event_selection_info_text,
      undo: this.undoEventSelection,
      progressIndex: 5,
      backBtn: true,
    },
    // Add existing event -Tab
    {
      index: Tab.SELECT_EXISTING_EVENT,
      undo: this.undoExistingEvent,
      title: FrontendSettings.advertSettings.titleEventExisting,
      subtitle: FrontendSettings.advertSettings.event_existing_info_text,
      progressIndex: 5,
      backBtn: true,
    },
    // Add new event -Tab
    {
      index: Tab.SELECT_NEW_EVENT,
      undo: this.undoNewEvent,
      title: FrontendSettings.advertSettings.titleEventNew,
      subtitle: FrontendSettings.advertSettings.event_new_info_text,
      progressIndex: 5,
      backBtn: true,
    },
    // Select Content -Tab
    {
      index: Tab.SELECT_CONTENT,
      undo: this.undoContent,
      title: FrontendSettings.advertSettings.titleContent,
      subtitle: FrontendSettings.advertSettings.content_info_text,
      progressIndex: 6,
      backBtn: true,
    },
    // Select differentBillingAddress -Tab
    {
      index: Tab.SELECT_BILLING_ADDRESS,
      undo: this.undoBilligAddress,
      title: FrontendSettings.advertSettings.titleBillingAddress,
      subtitle: FrontendSettings.advertSettings.billing_address_info_text,
      progressIndex: 7,
      backBtn: true,
    },
    // Overview -Tab
    {
      index: Tab.ORDER_OVERVIEW,
      undo: this.undoOverview,
      title: FrontendSettings.advertSettings.titleOverview,
      subtitle: FrontendSettings.advertSettings.overview_info_text,
      progressIndex: 8,
      backBtn: true,
    },
    // Landing page
    {
      index: Tab.LANDING_PAGE,
      title: FrontendSettings.advertSettings.titleSuccess,
      subtitle: FrontendSettings.advertSettings.landing_page_info_text,
      progressIndex: 8,
      backBtn: false,
    },
    // successful booking overview
    {
      index: Tab.SUCCESS_BOOKING_OVERVIEW,
      title: FrontendSettings.advertSettings.titleShowAdverts,
      subtitle: FrontendSettings.advertSettings.success_overview_info_text,
      undo: this.undoSuccessfulOverview,
      progressIndex: 8,
      backBtn: true,
    },
  ];

  // <editor-fold desc="Tab functions">
  // <editor-fold desc="Tab.SELECT_MEDIUM">
  /**
   * Sets the given {@link AdvertMedium} as {@link selectedMedium} and forwards the tab.
   *
   * Also checks the amount of available {@link AdvertChannel}s and skips the channel-selection if not required.
   *
   * @param medium
   */
  selectMedium(medium: AdvertMedium): void {
    this.selectedMedium = medium;

    this.selectedChannel = null;
    this.activeTabIndex = Tab.SELECT_CHANNEL;

    // Check, how many Channels we got => if we only have one, we can skip the channel selection
    const channels = getChannels(this.campaigns, this.selectedMedium);
    if (channels.length == 1) {
      // Skip Channel
      this.selectChannel(channels[0]);
      this.activeTabIndex = Tab.SELECT_SLOT;
    } else {
      this.activeTabIndex = Tab.SELECT_CHANNEL;
    }
  }
  // </editor-fold>

  // <editor-fold desc="Tab.SELECT_CHANNEL">
  /**
   * set the selected Advert Channel
   * Called by an Event
   * @param channel
   */
  selectChannel(channel: AdvertChannel): void {
    this.selectedChannel = channel;

    this.activeTabIndex = Tab.SELECT_SLOT;
  }

  undoSelectChannel(): void {
    this.activeTabIndex = Tab.SELECT_MEDIUM;
  }
  // </editor-fold>

  // <editor-fold desc="Tab.SELECT_SLOT">

  /**
   * set the selected Advert Slot
   * Called by an Event
   * @param slot
   */
  selectSlot(slot: AdvertSlot): void {
    this.selectedSlot = slot;
    this.activeTabIndex = Tab.SELECT_WHEN;
  }

  undoSlot(): void {
    this.selectedSlot = null;
    this.activeTabIndex = Tab.SELECT_CHANNEL;

    let channels = getChannels(this.campaigns, this.selectedMedium);
    if (channels.length == 1) {
      // unset Channel
      this.selectedSlot = null;
      this.selectedMedium = null;
      this.activeTabIndex = Tab.SELECT_MEDIUM;
    }
  }
  // </editor-fold>

  // <editor-fold desc="Tab.SELECT_WHEN">
  setCurrentOrderItems(data: OrderItem[]): void {
    this.currentOrderItems = data;
  }

  whenGoNext(): void {
    this.activeTabIndex = Tab.SELECT_EVENT_SELECTION;
  }

  undoWhen(): void {
    this.currentOrderItems = [];

    this.activeTabIndex = Tab.SELECT_SLOT;
  }
  // </editor-fold>

  // <editor-fold desc="Tab.SELECT_EVENT_SELECTION">

  /**
   * @param type  - new / existing / content
   */
  eventSelection(type: string): void {
    switch (type) {
      case "new":
        this.activeTabIndex = Tab.SELECT_NEW_EVENT;
        break;
      case "existing":
        this.activeTabIndex = Tab.SELECT_EXISTING_EVENT;
        break;
      case "content":
        this.activeTabIndex = Tab.SELECT_CONTENT;
        break;
    }
  }

  undoEventSelection(): void {
    this.activeTabIndex = Tab.SELECT_WHEN;
  }

  /**
   * Sets the ID of the assigned event in ContentData
   *
   * @param event
   */
  linkEvent(event: RecordingEvent | string): void {
    if (this.advertContent === null) {
      this.advertContent = new AdvertContent();
    }

    if (typeof event === "string" && event === "no_event") {
      this.activeTabIndex = Tab.SELECT_NEW_EVENT;
      return;
    }

    if (typeof event === "object") {
      this.advertContent.eventId = event.event_id;
      this.recordingEvent = event;

      this.activeTabIndex = Tab.SELECT_CONTENT;
    }
  }
  // </editor-fold>

  // <editor-fold desc="Tab.SELECT_EXISTING_EVENT">

  undoExistingEvent(): void {
    this.activeTabIndex = Tab.SELECT_EVENT_SELECTION;
  }
  // </editor-fold>

  // <editor-fold desc="Tab.SELECT_NEW_EVENT">
  undoNewEvent(): void {
    this.activeTabIndex = Tab.SELECT_EVENT_SELECTION;
  }
  // </editor-fold>

  // <editor-fold desc="Tab.SELECT_CONTENT">
  selectContent(contentData: AdvertContent): void {
    this.advertContent = contentData;
    this.activeTabIndex = Tab.SELECT_BILLING_ADDRESS;
  }

  undoContent(): void {
    this.advertContent = null;

    this.activeTabIndex = Tab.SELECT_WHEN;
  }
  // </editor-fold>

  // <editor-fold desc="Tab.SELECT_BILLING_ADDRESS">
  /**
   * Set the billing address from logged-in person.
   *
   * @param data
   */
  setPersonBillingAddress(data: OrderAddress): void {
    this.advertPersonBillingAddress = data;

    this.activeTabIndex = Tab.ORDER_OVERVIEW;
  }

  /**
   * Set the different billing address
   *
   * @param data
   */
  setDifferentBillingAddress(data: OrderAddress): void {
    this.advertDifferentBillingAddress = data;
  }

  undoBilligAddress(): void {
    this.activeTabIndex = Tab.SELECT_CONTENT;
  }

  // </editor-fold>

  // <editor-fold desc="Tab.ORDER_OVERVIEW">
  /**
   * Sets the order as successfully booked
   */
  setIsOrderCompleted(): void {
    this.activeTabIndex = Tab.LANDING_PAGE;
  }

  undoOverview(): void {
    this.activeTabIndex = Tab.SELECT_BILLING_ADDRESS;
  }
  // </editor-fold>

  // <editor-fold desc="Tab.SUCCESS_BOOKING_OVERVIEW">
  undoSuccessfulOverview(): void {
    this.activeTabIndex = Tab.LANDING_PAGE;
  }
  // </editor-fold>
  // </editor-fold>

  /**
   * Returns the active {@link TabDefinition}.
   */
  get activeTab(): TabDefinition {
    let activeTab: TabDefinition | undefined;

    for (const tab of this.tabDefinitions) {
      if (tab.index === this.activeTabIndex) {
        activeTab = tab;
        break;
      }
    }

    return Object.assign(
      activeTab ?? {
        undo: () => {
          return;
        },
        title: "",
        progressIndex: 0,
        backBtn: false,
      }
    );
  }
}
