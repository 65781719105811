
import { Component, Prop, Vue } from "vue-property-decorator";
import RecordingArticle from "@/shared/data/recording_article";
import FrontendSettings from "@/frontend/settings/settings";
import { sanitizeContent } from "@/shared/lib/dompurify";
import QuokkaMediaUpload from "@/components/quokka_layout/ui/QuokkaMediaUpload.vue";
import { DisplayInformationDataType } from "@/shared/data/media_information";

/**
 * Tab for the Article-Recording containing media.
 */
@Component({
  components: { QuokkaMediaUpload },
  data() {
    return { sanitizeContent };
  },
})
export default class QuokkaArticleRecordingMediaTab extends Vue {
  /** The {@link RecordingArticle} used as model. */
  @Prop({ required: true }) recordingArticle!: RecordingArticle;

  /** Whether the fields of this tab should be disabled or not. */
  @Prop() disabled!: boolean;

  /**
   * Advert additional image information fields
   */
  @Prop({ required: true }) mediaConfigData!: {
    [key: string]: { [key: string]: { [key: string]: unknown } };
  };

  /** Loaded Media Files */
  @Prop({ required: true }) availableMedia!: DisplayInformationDataType[];

  /**
   * Returns the `mediaInfo` defined in the settings.
   */
  get mediaInfo(): string | null {
    return FrontendSettings.articleRecording.mediaInfo;
  }
}
