
import { Component, Vue } from "vue-property-decorator";
import QuokkaEventListView from "@/views/layouts/quokka/QuokkaEventListView.vue";
import QuokkaEventDetailsView from "@/views/layouts/quokka/QuokkaEventDetailsView.vue";
import RouteHelper from "@/frontend/lib/route_helper";

@Component({
  components: {
    QuokkaEventDetailsView,
    QuokkaEventListView,
  },
})
export default class QuokkaLayoutView extends Vue {
  /**
   * Checks, if an event_id is given.
   */
  get hasEventId(): boolean {
    return RouteHelper.hasParam(this.$route, "event_id");
  }

  /**
   * Returns the set event_id in route.
   */
  get eventId(): number {
    return parseInt(RouteHelper.getParam(this.$route, "event_id"));
  }
}
