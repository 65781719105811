
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import { TextareaAdditionField } from "@/shared/data/addition_field";
import QuillEditor from "@/components/quokka_layout/ui/QuillEditor.vue";

/**
 * A Textarea field for an {@link TextareaAdditionField} from field_config
 */
@Component({
  components: { QuillEditor },
})
export default class QuokkaTextareaAdditionField extends Vue {
  /** The Model of this field */
  @VModel({ required: true }) model!: string;
  /** The {@link AdditionField} used as a model.*/
  @Prop({ required: true }) additionField!: TextareaAdditionField;
  /** Whether the field should be disabled or not */
  @Prop() disabled!: boolean;

  /** Rules for clientside validation */
  rules: { [key: string]: (value: string) => boolean | string } = {
    textareaAdditionField: (value: string): boolean | string => {
      value = value ?? "";
      if (this.additionField.required && !value) {
        if (this.additionField.required_form_message)
          return this.additionField.required_form_message;
        return "Pflichtfeld";
      }
      if (
        value &&
        this.additionField.regextest &&
        !value.match(this.additionField.regextest)
      ) {
        return "Keine korrekte Angabe.";
      }
      if (
        this.additionField.maxlength &&
        this.additionField.maxlength > 0 &&
        value.length > this.additionField.maxlength
      ) {
        return "Eingabe überschreitet Maximallänge";
      }
      if (
        this.additionField.minlength &&
        this.additionField.minlength > 0 &&
        value.length < this.additionField.minlength
      ) {
        return "Eingabe unterschreitet Minimallänge";
      }
      return true;
    },
  };
}
