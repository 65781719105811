import DataStruct from "@/shared/data/datastruct";
import { FormDefinition, ServerFormDefinitionType } from "@/shared/data/form";
import { AdditionField } from "@/shared/data/addition_field";

/**
 * ArticleDefinition Data class containing all information about Article Definition available for specified Article
 */
export class ArticleDefinition extends DataStruct {
  /**
   * Definition of the Form to build the additional fields in.
   */
  formDefinition: FormDefinition;
  publicationDateConfig: PublicationDateType;
  promoter_enabled: boolean;
  location_enabled: boolean;
  tags_enabled: boolean;
  links_enabled: boolean;
  media_enabled: boolean;
  title_field: string;
  begin_date_enabled: boolean;

  /** Constructor */
  protected constructor(data: {
    formDefinition: FormDefinition;
    publicationDateConfig: PublicationDateType;
    promoter_enabled: boolean;
    location_enabled: boolean;
    tags_enabled: boolean;
    links_enabled: boolean;
    media_enabled: boolean;
    title_field: string;
    begin_date_enabled: boolean;
  }) {
    super();
    this.formDefinition = data.formDefinition;
    this.publicationDateConfig = data.publicationDateConfig;

    if (DataStruct.validateValue(data, "promoter_enabled", "boolean")) {
      this.promoter_enabled = data.promoter_enabled;
    } else {
      throw new TypeError(
        "Could not initialize ArticleDefinition. Invalid 'promoter_enabled'."
      );
    }

    if (DataStruct.validateValue(data, "location_enabled", "boolean")) {
      this.location_enabled = data.location_enabled;
    } else {
      throw new TypeError(
        "Could not initialize ArticleDefinition. Invalid 'location_enabled'."
      );
    }

    if (DataStruct.validateValue(data, "tags_enabled", "boolean")) {
      this.tags_enabled = data.tags_enabled;
    } else {
      throw new TypeError(
        "Could not initialize ArticleDefinition. Invalid 'tags_enabled'."
      );
    }

    if (DataStruct.validateValue(data, "links_enabled", "boolean")) {
      this.links_enabled = data.links_enabled;
    } else {
      throw new TypeError(
        "Could not initialize ArticleDefinition. Invalid 'links_enabled'."
      );
    }

    if (DataStruct.validateValue(data, "media_enabled", "boolean")) {
      this.media_enabled = data.media_enabled;
    } else {
      throw new TypeError(
        "Could not initialize ArticleDefinition. Invalid 'media_enabled'."
      );
    }

    if (DataStruct.validateValue(data, "title_field", "string")) {
      this.title_field = data.title_field;
    } else {
      throw new TypeError(
        "Could not initialize ArticleDefinition. Invalid 'title_field'."
      );
    }

    if (DataStruct.validateValue(data, "begin_date_enabled", "boolean")) {
      this.begin_date_enabled = data.begin_date_enabled;
    } else {
      throw new TypeError(
        "Could not initialize ArticleDefinition. Invalid 'begin_date_enabled'."
      );
    }
  }

  /**
   * Creates a new object with the given data returned from the server.
   * @param data
   * @throws TypeError
   */
  static fromServer(data: ServerArticleDefinitionType): ArticleDefinition {
    let a = 0;
    const formDefinition: FormDefinition = [];
    formDefinition[a] = [];
    // Sorting fields for rows
    data.formDefinition.sort((a, b) => a.y - b.y);

    // Sort fields into rows
    data.formDefinition.forEach((item) => {
      if (a < item.y) {
        a = a + 1;
        formDefinition[a] = [];
      }
      if (item.additionField != null) {
        formDefinition[a].push({
          id: item.id,
          x: item.x,
          y: item.y,
          w: item.w,
          h: item.h,
          additionField: AdditionField.fromServer(item.additionField),
        });
      } else {
        formDefinition[a].push({
          id: item.id,
          x: item.x,
          y: item.y,
          w: item.w,
          h: item.h,
        });
      }
    });

    return new ArticleDefinition({
      formDefinition: formDefinition,
      publicationDateConfig: data.publicationDateConfig,
      promoter_enabled: data.promoter_enabled,
      location_enabled: data.location_enabled,
      tags_enabled: data.tags_enabled,
      links_enabled: data.links_enabled,
      media_enabled: data.media_enabled,
      title_field: data.title_field,
      begin_date_enabled: data.begin_date_enabled,
    });
  }
}

/** Data Type for ArticleDefinition from Server **/
export type ServerArticleDefinitionType = {
  formDefinition: ServerFormDefinitionType;
  publicationDateConfig: PublicationDateType;
  promoter_enabled: boolean;
  location_enabled: boolean;
  tags_enabled: boolean;
  links_enabled: boolean;
  media_enabled: boolean;
  title_field: string;
  begin_date_enabled: boolean;
};

/** Data Type for PublicationDates **/
export type PublicationDateType = {
  publication_dates_type: DatesType;
  publication_min_dates: number;
  publication_max_dates: number;
  publication_min_days_start: number;
  publication_max_days_end: number;
};

export type DatesType = "amount" | "period";
