
import { Component, Vue } from "vue-property-decorator";
import AuthView from "@/views/layouts/quokka/auth/AuthView.vue";
import { changePassword } from "@/frontend/lib/api";
import FrontendLogger from "@/frontend/lib/logger";
import RouteHelper from "@/frontend/lib/route_helper";

/**
 * The password change page.
 */
@Component({
  components: { AuthView },
})
export default class QuokkaPasswordResetView extends Vue {
  /** Contains the person's new password */
  password: string | null = null;
  /** Contains the repeated new password of the person */
  passwordRepeat: string | null = null;
  /** Contains the token for unique identification */
  resetToken!: string;

  /** Clientside data validation of signup form */
  formValid = false;

  /** Variables to toggle cleartext of password field */
  showPassword = false;
  /** Variables to toggle cleartext of password repeat field */
  showPasswordRepeat = false;

  /** Display Snackbars */
  snackbar = false;
  /** Displayed Text in Snackbar */
  snackbarText = "Einige verpflichtende Angaben sind nicht vollständig!";
  /** Variable to disable form and buttons while loading */
  loading = false;
  /** Variable to disable form when success is returned */
  success = false;

  /** Rules for validating form fields  */
  rules = {
    requiredRules: (value: string): boolean | string => {
      if (!value || value.trim().length <= 0) return "Pflichtfeld";
      else return true;
    },
    passwordRepeat: (value: string): boolean | string => {
      if (value !== this.password) return "Passwörter stimmen nicht überein";
      return true;
    },
    passwordLength: (value: string): boolean | string => {
      if (value != null && value.length <= 7)
        return "Passwort braucht mindestens 8 Zeichen";
      this.password = value;
      return true;
    },
    passwordSymbols: (value: string): boolean | string => {
      if (/[!@§$%&?#+*-]+/.test(value)) {
        this.password = value;
        return true;
      }
      return "Passwort muss Sonderzeichen enthalten";
    },
  };

  mounted(): void {
    this.resetToken =
      RouteHelper.getQueryParam<string>(this.$route, "t")?.trim() ?? "";
  }

  /** Submit signup data to backend */
  onSubmit(): void {
    if (this.formValid) {
      if (this.password?.trim() == this.passwordRepeat?.trim()) {
        this.loading = true;

        changePassword(this.password?.trim(), this.resetToken)
          .then(() => {
            this.success = true;
          })
          .catch((e) => {
            FrontendLogger.error({
              message: "Failed to request password reset.",
              data: e,
            });
            this.snackbar = true;
            this.snackbarText = "Irgendetwas ist schief gegangen.";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.snackbar = true;
        this.snackbarText = "Die Passwörter stimmen nicht überein.";
      }
    } else {
      this.snackbar = true;
      this.snackbarText = "Bitte geben Sie Ihr neues Passwort ein.";
    }
  }
}
