
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import RecordingEvent from "@/shared/data/recording_event";
import { EventRecordingFieldData } from "@/frontend/lib/event_recording_data";
import QuokkaEventRecordingGeneralTab from "@/components/quokka_layout/recording/QuokkaEventRecordingGeneralTab.vue";
import QuokkaEventRecordingAppointmentsTab from "@/components/quokka_layout/recording/QuokkaEventRecordingAppointmentsTab.vue";
import QuokkaEventRecordingTextsTab from "@/components/quokka_layout/recording/QuokkaEventRecordingTextsTab.vue";
import QuokkaEventRecordingMediaTab from "@/components/quokka_layout/recording/QuokkaEventRecordingMediaTab.vue";
import QuokkaEventRecordingOthersTab from "@/components/quokka_layout/recording/QuokkaEventRecordingOthersTab.vue";
import QuokkaEventRecordingFreeFieldsTab from "@/components/quokka_layout/recording/QuokkaEventRecordingFreeFieldsTab.vue";
import FrontendSettings from "@/frontend/settings/settings";
import {
  createRecordingEvent,
  getMediaConfData,
  getMediaConfReturnData,
  getRecordingEvent,
  getRubrics,
  getStringValueList,
  getTags,
  mediaConfData,
  updateRecordingEvent,
} from "@/frontend/lib/api";
import FrontendLogger from "@/frontend/lib/logger";
import {
  RecordingEventAppointmentsFree,
  RecordingEventAppointmentsSeries,
} from "@/shared/data/event_appointments";
import { DateTime } from "luxon";
import { formatDate } from "@/shared/lib/datetime";
import AlertCard from "@/components/quokka_layout/ui/AlertCard.vue";
import QuokkaReportPreview from "@/components/quokka_layout/QuokkaReportPreview.vue";
import {
  DisplayInformationDataType,
  MediaInformationData,
} from "@/shared/data/media_information";
import QuokkaEventRecordingTicketsTab from "@/components/quokka_layout/recording/QuokkaEventRecordingTicketsTab.vue";
import QuokkaEventRecordingAddRubricsTab from "@/components/quokka_layout/recording/QuokkaEventRecordingAddRubricsTab.vue";

/**
 * The available tabs.
 */
type RecordingTabs =
  | "general"
  | "appointments"
  | "texts"
  | "addRubrics"
  | "ticketAgency"
  | "free"
  | "media"
  | "others";

/**
 * The available display types for this event recording component.
 *
 * - event-recording: used in the default {@link QuokkaEventRecordingView}.
 * - ads-event-recording: used embedded in the ads recording to create and select an event.
 */
export type EventRecordingAdminDisplayType =
  | "event-recording"
  | "ads-event-recording";

/**
 * Builds the event recording tabs to create or edit an {@link RecordingEvent}.
 *
 * If an event-id is given via the property, the {@link RecordingEvent} is loaded.
 * Use display-type to change some behaviour.
 */
@Component({
  components: { QuokkaReportPreview, AlertCard },
  data() {
    return {
      FrontendSettings,
    };
  },
})
export default class QuokkaEventRecordingAdmin extends Vue {
  /**
   * An optional {@link RecordingEvent.event_id} to load an existing {@link RecordingEvent}.
   */
  @Prop({ default: null }) eventId!: number | null;

  /**
   * The display type of this event-recording-component.
   *
   * - recording
   * Display "My Events" and "Create/Save" button at the top.
   * Build "Event created" {@link AlertCard} with button to create another event.
   *
   * - ads-event-recording
   * Build "Event created" {@link AlertCard} with button "Select" to call the "event-select" event.
   */
  @Prop({ required: true, default: "event-recording" })
  eventRecordingAdminDisplayType!: EventRecordingAdminDisplayType;

  /**
   * The {@link RecordingEvent} which is recorded in this view.
   */
  recordingEvent: RecordingEvent = RecordingEvent.create();

  /**
   * The {@link EventRecordingFieldData} containing available data for the select fields
   * to transfer them into the tab-views.
   */
  eventRecordingFieldData: EventRecordingFieldData =
    new EventRecordingFieldData();

  /**
   * Advert additional image information fields
   */
  mediaConfigData: {
    [key: string]: { [key: string]: { [key: string]: unknown } };
  } = {};

  /**
   * Whether this view is loading or not.
   * The view loads a few data for the available fields (and possibly a {@link RecordingEvent}).
   */
  isLoading = true;

  /**
   * Whether this view is currently saving the {@link RecordingEvent} or not.
   * Will disable all fields.
   */
  saving = false;

  /** Title for the Free Fields Tab if set */
  titleFreeFields = "Freie Felder";

  /**
   * List of available panels to build.
   */
  panels: {
    key: RecordingTabs;
    title: string;
    component: unknown;
    /**
     * Whether this tab should be visible or not.
     */
    visible?: boolean;
  }[] = [
    {
      key: "general",
      title: "Veranstaltungsdaten",
      component: QuokkaEventRecordingGeneralTab,
    },
    {
      key: "appointments",
      title: "Termine",
      component: QuokkaEventRecordingAppointmentsTab,
    },
    {
      key: "texts",
      title: "Texte",
      component: QuokkaEventRecordingTextsTab,
    },
    {
      key: "addRubrics",
      title: "Zusätzliche Rubriken",
      component: QuokkaEventRecordingAddRubricsTab,
      visible: !(
        FrontendSettings.fieldSettings.recording.addRubrics.rubricsMax === 0 &&
        FrontendSettings.fieldSettings.recording.addRubrics.rubricsMin === 0
      ),
    },
    {
      key: "ticketAgency",
      title: "Verkaufsstellen",
      component: QuokkaEventRecordingTicketsTab,
      visible: FrontendSettings.eventRecording.showTicketTab,
    },
    {
      key: "media",
      title: "Bilder",
      component: QuokkaEventRecordingMediaTab,
      visible: FrontendSettings.eventRecording.showMediaTab,
    },
    {
      key: "others",
      title: "Sonstiges",
      component: QuokkaEventRecordingOthersTab,
      visible:
        FrontendSettings.fieldSettings.recording.priority.visible ||
        FrontendSettings.fieldSettings.recording.tags.visible,
    },
  ];

  /**
   * Index of opened panel.
   */
  expansionPanelsModel = 0;

  /**
   * Index of opened panel of the heading expansion panels.
   */
  expansionPanelsModelHeading = 0;

  /**
   * Whether the form with all fields is valid or not.
   */
  formValid = true;

  /**
   * Whether the {@link RecordingEvent} has been created or not.
   * If true, the form will be hidden and a success message will be displayed
   */
  eventCreated = false;

  /** Loaded Media Files for media upload*/
  availableMedia: DisplayInformationDataType[] = [];

  /**
   * Returns the label for the button shown in the {@link AlertCard} when {@link RecordingEvent} was created.
   */
  get eventCreatedNextButtonLabel(): string {
    if (this.eventRecordingAdminDisplayType === "event-recording") {
      return "Weitere Veranstaltung erfassen";
    } else if (this.eventRecordingAdminDisplayType === "ads-event-recording") {
      return (
        "Diese " + FrontendSettings.languageSettings.lbl_advert + " verknüpfen"
      );
    }
    return "";
  }

  /**
   * Returns the elevation for the event-created {@link AlertCard} depending on the {@link eventRecordingAdminDisplayType}.
   */
  get eventCreatedCardElevation(): number {
    switch (this.eventRecordingAdminDisplayType) {
      case "ads-event-recording":
        return 0;
      case "event-recording":
      default:
        return 2;
    }
  }

  /**
   * An error message shown in a snackbar.
   */
  errorSnackbarMessage = "";

  /**
   * A success message shown in a snackbar.
   */
  successSnackbarMessage = "";

  /**
   * Loads required data for the form.
   */
  mounted(): void {
    if (
      FrontendSettings.eventRecording.showFreeFields &&
      FrontendSettings.eventRecording.additionalFieldsFormDefinition
    ) {
      if (
        FrontendSettings.eventRecording.labelFreeFields &&
        FrontendSettings.eventRecording.labelFreeFields !== ""
      ) {
        this.titleFreeFields = FrontendSettings.eventRecording.labelFreeFields;
      }
      this.panels = [
        {
          key: "general",
          title: "Veranstaltungsdaten",
          component: QuokkaEventRecordingGeneralTab,
        },
        {
          key: "appointments",
          title: "Termine",
          component: QuokkaEventRecordingAppointmentsTab,
        },
        {
          key: "texts",
          title: "Texte",
          component: QuokkaEventRecordingTextsTab,
        },
        {
          key: "addRubrics",
          title: "Zusätzliche Rubriken",
          component: QuokkaEventRecordingAddRubricsTab,
          visible: !(
            FrontendSettings.fieldSettings.recording.addRubrics.rubricsMax ===
              0 &&
            FrontendSettings.fieldSettings.recording.addRubrics.rubricsMin === 0
          ),
        },
        {
          key: "ticketAgency",
          title: "Verkaufsstellen",
          component: QuokkaEventRecordingTicketsTab,
          visible: FrontendSettings.eventRecording.showTicketTab,
        },
        {
          key: "free",
          title: this.titleFreeFields,
          component: QuokkaEventRecordingFreeFieldsTab,
        },
        {
          key: "media",
          title: "Bilder",
          component: QuokkaEventRecordingMediaTab,
          visible: FrontendSettings.eventRecording.showMediaTab,
        },
        {
          key: "others",
          title: "Sonstiges",
          component: QuokkaEventRecordingOthersTab,
          visible:
            FrontendSettings.fieldSettings.recording.priority.visible ||
            FrontendSettings.fieldSettings.recording.tags.visible,
        },
      ];
    }

    const promises: Promise<unknown>[] = [];

    // Load Mediaconfig
    getMediaConfData({
      type: mediaConfData.event,
    })
      .then((response: getMediaConfReturnData) => {
        this.mediaConfigData = response.mediaConfigData;
      })
      .catch((e) => {
        console.error(e);
      });

    // Load available field values

    // Rubrics
    promises.push(
      getRubrics({ tree: "master" }).then((rubrics) => {
        this.eventRecordingFieldData.rubrics = rubrics;
      })
    );

    // Tags
    promises.push(
      getTags({ type: "event" }).then((tags) => {
        this.eventRecordingFieldData.tags = tags;
      })
    );

    // Event-Priority
    promises.push(
      getStringValueList({
        type: "event_priority",
      }).then((priorities) => {
        this.eventRecordingFieldData.priorities = priorities;
      })
    );

    // RecordingEvent
    if (this.eventId) {
      promises.push(
        getRecordingEvent(this.eventId).then((recordingEvent) => {
          this.recordingEvent = recordingEvent;
        })
      );
    }

    Promise.all(promises)
      .catch((e) => {
        // TODO: Fehler anzeigen
        FrontendLogger.error({
          message: "Failed to load field data for event recording.",
          data: e,
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  /**
   * Emitted when user created a new event.
   *
   * Only available, if the displayType is set to a selectable type.
   *
   * @param eventId
   */
  @Emit("event-select")
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onEventSelect(eventId: number | RecordingEvent): void {
    return;
  }

  /**
   * Tries to open the given tab.
   *
   * @param tab
   */
  openTab(tab: RecordingTabs): void {
    // Search index of tab
    const index = this.panels.findIndex((panel) => panel.key === tab);
    if (index >= 0) {
      this.expansionPanelsModel = index;
    }
  }

  /**
   * Shows given error message and opens the given tab.
   * It will also validate the "recordingForm" to mark the fields after the tab is opened.
   *
   * @param message
   * @param openTab
   */
  onValidationError(message: string, openTab: RecordingTabs): false {
    this.errorSnackbarMessage = message;
    this.openTab(openTab);

    // We validate again after we opened the tab to mark the errors
    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.$refs.recordingForm?.validate();
    }, 100);

    return false;
  }

  /**
   * Tries to create the {@link RecordingEvent} with currently set data.
   */
  saveRecordingEvent(): void {
    this.errorSnackbarMessage = "";
    this.successSnackbarMessage = "";

    // Validate the values. This will only validate visible fields, hidden fields of closed tabs are ignored.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$refs.recordingForm?.validate();

    // Validate values
    if (!this.validateRecordingEventData()) {
      return;
    }

    //Loop through links and add protocol where necessary
    let pattern = /(^https:\/\/|^http:\/\/)/g;
    if (this.recordingEvent.links.length > 0) {
      for (let recordingLink of this.recordingEvent.links) {
        if (recordingLink.link && !pattern.test(recordingLink.link.trim())) {
          if (!pattern.test(recordingLink.link.trim())) {
            recordingLink.link = "https://" + recordingLink.link.trim();
          }
        }
      }
    }

    // Saving
    this.saving = true;

    // Prepare event for save
    this.recordingEvent.prepareForSave();

    const savePromise = this.recordingEvent.event_id
      ? updateRecordingEvent(this.recordingEvent)
          .then((recordingEvent) => {
            // Event updated
            this.recordingEvent = recordingEvent;
            this.successSnackbarMessage =
              "Die Veranstaltung wurde aktualisiert.";
          })
          .catch((e) => {
            FrontendLogger.error({
              message: "Failed to update RecordingEvent",
              scope: "api",
              data: e,
            });
            this.errorSnackbarMessage =
              "Die Veranstaltung konnte nicht aktualisiert werden. Ein unbekannter Fehler ist aufgetreten.";
          })
          .then(() => {
            if (FrontendSettings.eventRecording.showReportPreview) {
              // Update Report Preview
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              this.$refs["reportPreview"].executeReports();
            }
          })
          .catch((e) => {
            FrontendLogger.error({
              message: "Failed to update report preview",
              scope: "ui",
              data: e,
            });
            this.errorSnackbarMessage =
              "Ein unbekannter Fehler ist aufgetreten.";
          })
      : createRecordingEvent(this.recordingEvent)
          .then((recordingEvent: RecordingEvent) => {
            // Event created
            this.eventCreated = true;
            this.recordingEvent = recordingEvent;
          })
          .catch((e) => {
            FrontendLogger.error({
              message: "Failed to create RecordingEvent",
              scope: "api",
              data: e,
            });
            this.errorSnackbarMessage =
              "Die Veranstaltung konnte nicht angelegt werden. Ein unbekannter Fehler ist aufgetreten.";
          });

    savePromise.finally(() => {
      this.saving = false;
    });
  }

  /**
   * Validates the set data of the {@link RecordingEvent}.
   */
  validateRecordingEventData(): boolean {
    // Rubric
    if (!this.recordingEvent.rubric) {
      return this.onValidationError("Es ist keine Rubrik gewählt.", "general");
    }

    // add rubrics
    if (
      FrontendSettings.fieldSettings.recording.addRubrics.rubricsMin > 0 &&
      (!this.recordingEvent.add_rubrics ||
        this.recordingEvent.add_rubrics.length <
          FrontendSettings.fieldSettings.recording.addRubrics.rubricsMin)
    ) {
      return this.onValidationError(
        "Bitte mindestens " +
          FrontendSettings.fieldSettings.recording.addRubrics.rubricsMin +
          " Zusatzrubriken wälen.",
        "addRubrics"
      );
    }
    if (
      FrontendSettings.fieldSettings.recording.addRubrics.rubricsMax > 0 &&
      this.recordingEvent.add_rubrics &&
      this.recordingEvent.add_rubrics.length >
        FrontendSettings.fieldSettings.recording.addRubrics.rubricsMax
    ) {
      return this.onValidationError(
        "Die maximal Anzahl der zusätzlichen Rubriken wurde überschritten, bitte auf " +
          FrontendSettings.fieldSettings.recording.addRubrics.rubricsMax +
          " Zusatzrubriken begrenzen.",
        "addRubrics"
      );
    }

    // Location
    if (!this.recordingEvent.location) {
      return this.onValidationError(
        "Es ist kein Veranstaltungsort gewählt.",
        "general"
      );
    }

    // Appointments - Error messages are set in the function
    try {
      this.validateAppointments();
    } catch (e) {
      return this.onValidationError(e.message, "appointments");
    }

    // Title
    if (
      !this.recordingEvent.text.title ||
      this.recordingEvent.text.title.trim() === ""
    ) {
      return this.onValidationError("Es ist kein Titel gegeben.", "texts");
    }

    // Check max length of all the text fields
    const recordingFieldSettings = FrontendSettings.fieldSettings.recording;

    // Title
    if (
      recordingFieldSettings.title.maxLength &&
      this.recordingEvent.text.title &&
      this.recordingEvent.text.title.length >
        recordingFieldSettings.title.maxLength
    ) {
      return this.onValidationError("Der Titel ist zu lang.", "texts");
    }
    // Shortdesc 1
    if (
      recordingFieldSettings.shortdesc1.maxLength &&
      this.recordingEvent.text.shortdesc &&
      this.recordingEvent.text.shortdesc.length >
        recordingFieldSettings.shortdesc1.maxLength
    ) {
      return this.onValidationError(
        (FrontendSettings.fieldSettings.recording.shortdesc1.label ??
          "Kurztext") + " ist zu lang.",
        "texts"
      );
    }
    // Longdesc 1
    if (
      recordingFieldSettings.longdesc1.maxLength &&
      this.recordingEvent.text.longdesc &&
      this.recordingEvent.text.longdesc.length >
        recordingFieldSettings.longdesc1.maxLength
    ) {
      return this.onValidationError(
        (FrontendSettings.fieldSettings.recording.longdesc1.label ??
          "Langtext") + " ist zu lang.",
        "texts"
      );
    }
    // Shortdesc 2
    if (
      recordingFieldSettings.shortdesc2.maxLength &&
      this.recordingEvent.text.shortdesc2 &&
      this.recordingEvent.text.shortdesc2.length >
        recordingFieldSettings.shortdesc2.maxLength
    ) {
      return this.onValidationError(
        (FrontendSettings.fieldSettings.recording.shortdesc2.label ??
          "Kurztext 2") + " ist zu lang.",
        "texts"
      );
    }
    // Longdesc 2
    if (
      recordingFieldSettings.longdesc2.maxLength &&
      this.recordingEvent.text.longdesc2 &&
      this.recordingEvent.text.longdesc2.length >
        recordingFieldSettings.longdesc2.maxLength
    ) {
      return this.onValidationError(
        (FrontendSettings.fieldSettings.recording.longdesc2.label ??
          "Langtext 2") + " ist zu lang.",
        "texts"
      );
    }
    // Shortdesc 3
    if (
      recordingFieldSettings.shortdesc3.maxLength &&
      this.recordingEvent.text.shortdesc3 &&
      this.recordingEvent.text.shortdesc3.length >
        recordingFieldSettings.shortdesc3.maxLength
    ) {
      return this.onValidationError(
        (FrontendSettings.fieldSettings.recording.shortdesc3.label ??
          "Kurztext 3") + " ist zu lang.",
        "texts"
      );
    }
    // Longdesc 3
    if (
      recordingFieldSettings.longdesc3.maxLength &&
      this.recordingEvent.text.longdesc3 &&
      this.recordingEvent.text.longdesc3.length >
        recordingFieldSettings.longdesc3.maxLength
    ) {
      return this.onValidationError(
        (FrontendSettings.fieldSettings.recording.longdesc3.label ??
          "Langtext 3") + " ist zu lang.",
        "texts"
      );
    }

    // Links
    for (const link of this.recordingEvent.links) {
      if (!link.link || link.link.trim() === "") {
        return this.onValidationError("Ein Link hat keine URL.", "texts");
      }
      if (!link.description || link.description.trim() === "") {
        return this.onValidationError(
          "Ein Link hat keinen Anzeigetext.",
          "texts"
        );
      }
    }

    // Media
    if (!this.recordingEvent.fileUploadReady) {
      return this.onValidationError(
        "Es wurden nicht alle Medien erfolgreich hochgeladen.",
        "media"
      );
    }

    // Get media information from availableMedia
    if (this.availableMedia.length > 0) {
      let tmpInformation: MediaInformationData[] = [];
      this.availableMedia.forEach((data) => {
        tmpInformation.push({
          agency: data.agency,
          author: data.author,
          copyright: data.copyright,
          desc: data.desc,
          media_id: data.media_id,
          title: data.title,
          upload_key: data.upload_key,
          location: data.location,
        });
      });
      this.recordingEvent.additionalImageInformation =
        Object.assign(tmpInformation);
    }

    // Validate media information
    if (
      this.recordingEvent.upload_media.length > 0 ||
      (this.recordingEvent.uploaded_media.length > 0 &&
        this.recordingEvent.uploaded_media.length >
          this.recordingEvent.countRemovedUploads)
    ) {
      if (this.mediaConfigData.additionalImageInformationFields) {
        let error = false;
        this.recordingEvent.additionalImageInformation.forEach(
          (information) => {
            // check agency
            if (
              Object.prototype.hasOwnProperty.call(
                this.mediaConfigData.additionalImageInformationFields,
                "agency"
              )
            ) {
              if (
                this.mediaConfigData.additionalImageInformationFields.agency
                  .require &&
                this.mediaConfigData.additionalImageInformationFields.agency
                  .require === "Y"
              ) {
                if (!information.agency) {
                  error = true;
                  return this.onValidationError(
                    "Agency wurde nicht ausgefüllt.",
                    "media"
                  );
                }
              }
            }
            // check author
            if (
              Object.prototype.hasOwnProperty.call(
                this.mediaConfigData.additionalImageInformationFields,
                "author"
              )
            ) {
              if (
                this.mediaConfigData.additionalImageInformationFields.author
                  .require &&
                this.mediaConfigData.additionalImageInformationFields.author
                  .require === "Y"
              ) {
                if (!information.author) {
                  error = true;
                  return this.onValidationError(
                    "Author wurde nicht ausgefüllt.",
                    "media"
                  );
                }
              }
            }

            // check copyright
            if (
              Object.prototype.hasOwnProperty.call(
                this.mediaConfigData.additionalImageInformationFields,
                "copyright"
              )
            ) {
              if (
                this.mediaConfigData.additionalImageInformationFields.copyright
                  .require &&
                this.mediaConfigData.additionalImageInformationFields.copyright
                  .require === "Y"
              ) {
                if (!information.copyright) {
                  error = true;
                  return this.onValidationError(
                    "Copyright wurde nicht ausgefüllt.",
                    "media"
                  );
                }
              }
            }

            // check description
            if (
              Object.prototype.hasOwnProperty.call(
                this.mediaConfigData.additionalImageInformationFields,
                "desc"
              )
            ) {
              if (
                this.mediaConfigData.additionalImageInformationFields.desc
                  .require &&
                this.mediaConfigData.additionalImageInformationFields.desc
                  .require === "Y"
              ) {
                if (!information.desc) {
                  error = true;
                  return this.onValidationError(
                    "Beschreibung wurde nicht ausgefüllt.",
                    "media"
                  );
                }
              }
            }

            // check location
            if (
              Object.prototype.hasOwnProperty.call(
                this.mediaConfigData.additionalImageInformationFields,
                "location"
              )
            ) {
              if (
                this.mediaConfigData.additionalImageInformationFields.location
                  .require &&
                this.mediaConfigData.additionalImageInformationFields.location
                  .require === "Y"
              ) {
                if (!information.location) {
                  error = true;
                  return this.onValidationError(
                    "Location wurde nicht ausgefüllt.",
                    "media"
                  );
                }
              }
            }

            // check title
            if (
              Object.prototype.hasOwnProperty.call(
                this.mediaConfigData.additionalImageInformationFields,
                "title"
              )
            ) {
              if (
                this.mediaConfigData.additionalImageInformationFields.title
                  .require &&
                this.mediaConfigData.additionalImageInformationFields.title
                  .require === "Y"
              ) {
                if (!information.title) {
                  error = true;
                  return this.onValidationError(
                    "Titel wurde nicht ausgefüllt.",
                    "media"
                  );
                }
              }
            }
          }
        );
        if (error) {
          return false;
        }
      }
    }
    return true;
  }

  /**
   * Function called on button click in {@link AlertCard} after {@link RecordingEvent} was created.
   *
   * For DisplayType "event_recording":
   * Resets this view.
   * - Sets {@link recordingEvent} to a new {@link RecordingEvent}
   * - Resets open tab
   * - Sets {@link eventCreated} to false
   *
   * For DisplayType "ads_event_recording":
   * - Sets {@link recordingEvent} to a new {@link RecordingEvent}
   * - Resets open tab
   * - Calls the "event_select" event with the event_id
   */
  continueAfterCreation(): void {
    if (this.eventRecordingAdminDisplayType === "event-recording") {
      this.eventCreated = false;
    } else if (this.eventRecordingAdminDisplayType === "ads-event-recording") {
      this.onEventSelect(this.recordingEvent);
    }
    this.recordingEvent = RecordingEvent.create();
    this.expansionPanelsModel = 0;
  }

  /**
   * Validates the currently set appointments.
   *
   * @returns {true} on success
   * @throws {Error} on failure
   *
   * @private
   */
  private validateAppointments(): true {
    if (!this.recordingEvent.appointments) {
      throw new Error("Es sind keine Termine erfasst.");
    }

    switch (this.recordingEvent.appointments.type) {
      case "free": {
        let freeAppointments = this.recordingEvent
          .appointments as RecordingEventAppointmentsFree;

        if (freeAppointments.dates.length <= 0) {
          throw new Error("Es ist kein Termin erfasst.");
        }

        // Check, that all event-dates have a date and at least one time
        for (const eventDate of freeAppointments.dates) {
          if (!eventDate.dateObject) {
            throw new Error(
              "Ein Termin kann nicht ohne Datum angelegt werden."
            );
          }

          // Check, that the date hasn't passed
          const today = DateTime.now();

          const formattedDate = formatDate(eventDate.dateObject, "yyyy-MM-dd");
          const formattedToday = formatDate(today, "yyyy-MM-dd");

          if (!formattedDate || !formattedToday) {
            FrontendLogger.error({
              message:
                "Something went wrong trying to validate date-value. Could not format date or today's date.",
              scope: "invalid-data",
              data: {
                formattedDate,
                formattedToday,
              },
            });
            throw new Error("Irgendetwas ist schief gelaufen.");
          }

          // Check, if the date has passed
          if (formattedDate < formattedToday) {
            throw new Error("Mindestens ein Termin ist abgelaufen.");
          }

          if (eventDate.timeInfo.length <= 0) {
            throw new Error(
              "Ein Termin benötigt mindestens eine Veranstaltungszeit."
            );
          }

          // Check, that every timeInfo is valid
          // A time-info is valid, if it is full day or has at least a start date
          for (const timeInfo of eventDate.timeInfo) {
            if (!timeInfo.full && !timeInfo.fromTime) {
              throw new Error(
                "Eine Veranstaltungszeit muss entweder ganztags oder zu einer bestimmten Uhrzeit stattfinden."
              );
            }
          }
        }

        break;
      }
      case "series": {
        const appointmentsSeries = this.recordingEvent
          .appointments as RecordingEventAppointmentsSeries;

        // Check, that we got a start and end date
        if (!appointmentsSeries.start_date) {
          throw new Error("Eine Terminserie benötigt ein Startdatum.");
        }
        if (!appointmentsSeries.end_date) {
          throw new Error("Eine Terminserie benötigt ein Enddatum.");
        }
        if (
          appointmentsSeries.start_date.toMillis() >=
          appointmentsSeries.end_date.toMillis()
        ) {
          throw new Error("Das Enddatum muss nach dem Startdatum sein.");
        }

        // Check, that we got at least on timeInfo for any day
        let timeInfoAmount = 0;

        // Check, that every timeInfo is valid
        // A time-info is valid, if it is full day or has at least a start date
        for (const appointments of Object.values(
          appointmentsSeries.appointmentsPerDay
        )) {
          if (appointments) {
            for (const timeInfo of appointments) {
              if (!timeInfo.full && !timeInfo.fromTime) {
                throw new Error(
                  "Eine Veranstaltungszeit muss entweder ganztags oder zu einer bestimmten Uhrzeit stattfinden."
                );
              } else {
                timeInfoAmount++;
              }
            }
          }
        }

        if (timeInfoAmount <= 0) {
          throw new Error("Es ist kein Termin erfasst.");
        }

        break;
      }
      default: {
        throw new Error("Ein unbekannter Fehler ist aufgetreten.");
      }
    }

    return true;
  }
}
