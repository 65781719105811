
import { Component, Vue } from "vue-property-decorator";
import AuthView from "@/views/layouts/quokka/auth/AuthView.vue";
import { requestConfirmEMail } from "@/frontend/lib/api";
import RouteHelper from "@/frontend/lib/route_helper";
import FrontendLogger from "@/frontend/lib/logger";

/**
 * The password change page.
 */
@Component({
  components: { AuthView },
})
export default class QuokkaRequestConfirmEMailView extends Vue {
  /**
   * The username for which the confirmation link should be resent.
   */
  username: string | null = null;

  /**
   * Whether the request is pending or not.
   */
  loading = false;

  /**
   * Whether the request has been made or not.
   *
   * If null, the api-request is still pending.
   */
  requested: boolean | null = null;

  /**
   * Whether there is an error message to show in a snackbar or not.
   */
  error = false;

  /**
   * The error message to display in the snackbar.
   */
  errorMessage = "";

  /**
   * Tries to get a username from route params to pre-fill the field.
   */
  mounted(): void {
    this.username =
      RouteHelper.getParam<string>(this.$route, "username")?.trim() ?? null;
  }

  request(): void {
    this.errorMessage = "";
    this.error = false;

    if ((this.username?.trim() ?? "") == "") {
      // Missing username
      this.errorMessage = "Der Loginname ist leer.";
      this.error = true;
    }

    this.loading = true;

    requestConfirmEMail(this.username ?? "")
      .then(() => {
        this.requested = true;
      })
      .catch((e) => {
        FrontendLogger.error({
          message: "Failed to confirm E-Mail.",
          data: e,
          scope: "api",
        });

        this.errorMessage = "Irgendetwas ist schief gegangen.";

        if (e.response && e.response.data && e.response.data.error_code) {
          // We got an error code, see documentation for available codes we can get
          switch (e.response.data.error_code) {
            case 100:
              this.errorMessage =
                "Der eingegebene Loginname konnte nicht gefunden werden.";
              break;
          }
        }

        this.error = true;
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
