
import { Component, Emit, Vue } from "vue-property-decorator";
import QuokkaEventRecordingAdmin from "@/components/quokka_layout/recording/QuokkaEventRecordingAdmin.vue";
import RecordingEvent from "@/shared/data/recording_event";

/**
 * TODO: doc
 */
@Component({
  components: { QuokkaEventRecordingAdmin },
})
export default class QuokkaAdsRecordingNewEvent extends Vue {
  /**
   * TODO: Docs
   * @param event
   */
  @Emit("event-select")
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onEventSelect(event: RecordingEvent): void {
    return;
  }
}
